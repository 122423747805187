import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
	bankaccountQuestion,
	bankaccountYesNo,
	cookingresourceLists,
	cookingresourceQuestion,
	drinkingwaterLists,
	drinkingwaterplaceLists,
	drinkingwaterplaceQuestion,
	drinkingwaterQuestion,
	lightresourceLists,
	lightresourceQuestion,
	numberOfBankAccount,
	bankAccountMale,
	bankAccountFemale,
	roadLists,
	roadQuestion,
	salttypeLists,
	salttypeQuestion,
	toiletresourceLists,
	toilettypeQuestion,
	wastemanagementLists,
	dirtyWaterQuestion,
	dirtyWaterManagement,
	wasteQuestion,
	waterPurificationQn,
	waterPurification,
	houseFacilityQuestion,
	houseFacility,
	toiletQuestion,
	toiletYesNo,
	toiletNoQuestion,
	noToiletLists,
	toiletYesNoUseQn,
	toiletYesNoUse,
	isElectricMeterQn,
	isElectricMeter,
} from "../../../../variable/houseSurvey";
import RadioButton from "../../../common/frequent/RadioButton";
import TextField from "../../../common/frequent/TextField";
import Selection from "../../../common/frequent/Selection";
import CheckBox from "../../../common/frequent/CheckBox";
import MultipleSelect from "../../../common/frequent/MultipleSelect";

class FamilyUsage extends Component {
	handleChange = (name, value) => {
		this.props.handleChange(`secondaryHouse.${name}`, value);
	};
	render() {
		const { secondaryHouse } = this.props;
		const errors = this.props.errors && this.props.errors.errors;
		return (
			<Grid container direction="column">
				<Grid container>
					<Selection
						error={errors && errors.waterSource}
						question={drinkingwaterQuestion}
						name="waterSource"
						selectList={drinkingwaterLists}
						value={secondaryHouse.waterSource}
						isVisible
						handleChange={this.handleChange}
						other={secondaryHouse.waterSourceOther}
					/>
				</Grid>
				<Grid container>
					<Selection
						question={drinkingwaterplaceQuestion}
						name="distanceToWaterSource"
						selectList={drinkingwaterplaceLists}
						value={secondaryHouse.distanceToWaterSource}
						isVisible
						handleChange={this.handleChange}
						other={secondaryHouse.distanceToWaterSourceOther}
					/>
				</Grid>

				<Grid container>
					<MultipleSelect
						question={cookingresourceQuestion}
						name="cookingSource"
						value={secondaryHouse.cookingSource}
						tagLists={cookingresourceLists}
						isVisible
						handleChange={this.handleChange}
						other={secondaryHouse.cookingSourceOther}
					/>
				</Grid>

				<Grid container>
					<Selection
						error={errors && errors.electricSource}
						question={lightresourceQuestion}
						name="electricSource"
						selectList={lightresourceLists}
						value={secondaryHouse.electricSource}
						isVisible
						handleChange={this.handleChange}
						other={secondaryHouse.electricSourceOther}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible={secondaryHouse.electricSource === "electric"}
						question={isElectricMeterQn}
						name="isElectricityMeter"
						radioLists={isElectricMeter}
						value={secondaryHouse.isElectricityMeter}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={toiletQuestion}
						name="isToilet"
						radioLists={toiletYesNo}
						value={secondaryHouse.isToilet}
					/>
				</Grid>
				<Grid container>
					<Selection
						question={toilettypeQuestion}
						name="toiletYes"
						selectList={toiletresourceLists}
						value={secondaryHouse.toiletYes}
						isVisible={secondaryHouse.isToilet === "yes"}
						handleChange={this.handleChange}
						// other={secondaryHouse.toiletYesOther}
					/>
				</Grid>
				<Grid container>
					<Selection
						question={toiletNoQuestion}
						name="toiletNo"
						selectList={noToiletLists}
						value={secondaryHouse.toiletNo}
						isVisible={secondaryHouse.isToilet === "no"}
						handleChange={this.handleChange}
						other={secondaryHouse.toiletNoOther}
					/>
				</Grid>
				<Grid container>
					<Selection
						question={toiletYesNoUseQn}
						name="toiletYesButNoUse"
						selectList={toiletYesNoUse}
						value={secondaryHouse.toiletYesButNoUse}
						isVisible={secondaryHouse.isToilet === "yes_but_not_us"}
						handleChange={this.handleChange}
						other={secondaryHouse.toiletYesButNoUseOther}
					/>
				</Grid>
				<Grid container>
					<Selection
						question={wasteQuestion}
						name="wasteDisposal"
						selectList={wastemanagementLists}
						value={secondaryHouse.wasteDisposal}
						isVisible
						handleChange={this.handleChange}
						other={secondaryHouse.wasteDisposalOther}
					/>
				</Grid>

				<Grid container>
					<Selection
						question={waterPurificationQn}
						name="waterPurification"
						selectList={waterPurification}
						value={secondaryHouse.waterPurification}
						isVisible
						handleChange={this.handleChange}
						other={secondaryHouse.waterPurificationOther}
					/>
				</Grid>

				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={bankaccountQuestion}
						name="bankAccount"
						radioLists={bankaccountYesNo}
						value={secondaryHouse.bankAccount}
					/>
				</Grid>

				<Grid container>
					<TextField
						question={bankAccountMale}
						name="bankAccountMale"
						require
						placeholder="कति"
						value={secondaryHouse.bankAccountMale}
						isVisible={secondaryHouse.bankAccount === "account_yes"}
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid container>
					<TextField
						question={bankAccountFemale}
						name="bankAccountFemale"
						require
						placeholder="कति"
						value={secondaryHouse.bankAccountFemale}
						isVisible={secondaryHouse.bankAccount === "account_yes"}
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={salttypeQuestion}
						name="saltUsed"
						radioLists={salttypeLists}
						value={secondaryHouse.saltUsed}
					/>
				</Grid>
				<Grid container>
					<MultipleSelect
						question={houseFacilityQuestion}
						name="houseFacility"
						value={secondaryHouse.houseFacility}
						tagLists={houseFacility}
						isVisible
						handleChange={this.handleChange}
						other={secondaryHouse.houseFacilityOther}
					/>
				</Grid>

				<Grid xs={6}>
					<TextField
						name="motorcycle"
						require
						placeholder="मोटरसाइकल (संख्या)"
						value={secondaryHouse.motorcycle}
						isVisible={
							secondaryHouse.houseFacility &&
							secondaryHouse.houseFacility.includes("motorcycle")
						}
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid xs={6}>
					<TextField
						name="scooter"
						require
						placeholder="स्कुटर (संख्या)"
						value={secondaryHouse.scooter}
						isVisible={
							secondaryHouse.houseFacility &&
							secondaryHouse.houseFacility.includes("scooter")
						}
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid xs={6}>
					<TextField
						name="car"
						require
						placeholder="कार (संख्या)"
						value={secondaryHouse.car}
						isVisible={
							secondaryHouse.houseFacility &&
							secondaryHouse.houseFacility.includes("car")
						}
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid xs={6}>
					<TextField
						name="jeep"
						require
						placeholder="जिप (संख्या)"
						value={secondaryHouse.jeep}
						isVisible={
							secondaryHouse.houseFacility &&
							secondaryHouse.houseFacility.includes("jeep")
						}
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid xs={6}>
					<TextField
						name="tractor"
						require
						placeholder="ट्राकटर (संख्या)"
						value={secondaryHouse.tractor}
						isVisible={
							secondaryHouse.houseFacility &&
							secondaryHouse.houseFacility.includes("tractor")
						}
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid xs={6}>
					<TextField
						name="truck"
						require
						placeholder="ट्रक (संख्या)"
						value={secondaryHouse.truck}
						isVisible={
							secondaryHouse.houseFacility &&
							secondaryHouse.houseFacility.includes("truck")
						}
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid xs={6}>
					<TextField
						name="auto"
						require
						placeholder="अटो (संख्या)"
						value={secondaryHouse.auto}
						isVisible={
							secondaryHouse.houseFacility &&
							secondaryHouse.houseFacility.includes("auto")
						}
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid xs={6}>
					<TextField
						name="tempo"
						require
						placeholder="टेम्पो (संख्या)"
						value={secondaryHouse.tempo}
						isVisible={
							secondaryHouse.houseFacility &&
							secondaryHouse.houseFacility.includes("tempo")
						}
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid xs={6}>
					<TextField
						name="dozar"
						require
						placeholder="डोज़र (संख्या)"
						value={secondaryHouse.dozar}
						isVisible={
							secondaryHouse.houseFacility &&
							secondaryHouse.houseFacility.includes("dozar")
						}
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid xs={6}>
					<TextField
						name="tipper"
						require
						placeholder="टिप्पर (संख्या)"
						value={secondaryHouse.tipper}
						isVisible={
							secondaryHouse.houseFacility &&
							secondaryHouse.houseFacility.includes("tipper")
						}
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid xs={6}>
					<TextField
						name="scavater"
						require
						placeholder="स्काभेटर (संख्या)"
						value={secondaryHouse.scavater}
						isVisible={
							secondaryHouse.houseFacility &&
							secondaryHouse.houseFacility.includes("skavater")
						}
						handleChange={this.handleChange}
					/>
				</Grid>

				<Grid xs={6}>
					<TextField
						name="otherCount"
						require
						placeholder="अन्य (संख्या)"
						value={secondaryHouse.otherCount}
						isVisible={
							secondaryHouse.houseFacility &&
							secondaryHouse.houseFacility.includes("other")
						}
						handleChange={this.handleChange}
					/>
				</Grid>
			</Grid>
		);
	}
}
FamilyUsage.propTypes = {
	classes: PropTypes.object.isRequired,
};
export default FamilyUsage;
