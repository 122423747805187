import React from "react";
import { GeoJSON, Marker, Popup } from "react-leaflet";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import L from "leaflet";
import { Grid } from "@material-ui/core/es";
import PopupHouse from "./PopupHouse";

import iconUrl from "../../../image/pin24.png";
import educationUrl from "../../../image/education.png";
import officeUrl from "../../../image/office.png";
import industriesUrl from "../../../image/industries.png";
import infrastructureUrl from "../../../image/infrastructure.png";
import workshopUrl from "../../../image/workshop.png";
import agricultureUrl from "../../../image/agriculture.png";
import homestayUrl from "../../../image/homestay.png";
import economicUrl from "../../../image/economic.png";
import healthUrl from "../../../image/health.png";

export default function GeoJSONWithLayer(props) {
  const onHouseClick = (houseNumber) => (event) => {
    props.houseClick(houseNumber);
  };

  const housePopUp = (properties) => {
    console.log("property", properties);
    if (!properties.institutionsType) {
      return (
        <div>
          <img
            src={`/api/imageUpload/image/${properties.id}_house.jpg`}
            alt={properties.houseNumer}
            width={300}
            height={200}
            style={{ objectFit: "cover" }}
          />
          <br />
          <br />
          <b>House Number:</b> {properties.houseNumber}
          <br />
          <b>Ward Number:</b> {properties.wardNumber}
          <br />
          <b>Owner Name:</b> {properties.ownerName}
          <br />
          <b>Tole Name:</b> {properties.toleName}
          <br />
          {properties.contactNumber && (
            <span>
              <b>Contact:</b> {properties.contactNumber}
            </span>
          )}{" "}
          <br />
          <Button
            color="primary"
            onClick={onHouseClick(properties.houseNumber)}
          >
            View Detail
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <img
            src={`/api/imageUpload/instituteImage/${properties.photo}`}
            alt={properties.officeName}
            width={300}
            height={200}
            style={{ objectFit: "cover" }}
          />
          <br />
          <b>Office Name:</b> {properties.officeName}
          <br />
          <b>Ward Number:</b> {properties.wardNumber}
          <br />
          <b>Institution Type:</b> {properties.institutionsType}
          <br />
        </div>
      );
    }
  };

  const handleOnEachFeature = (feature, layer) => {
    let popupContent = null;
    if (props.popupContent.length) {
      if (props.popupContent == "ghar") {
        popupContent = `<img src={/api/imageUpload/image/${feature.properties.houseNumer}_house.jpg} alt={properties.houseNumer} width={200} height={200}/>`;
        // popupContent = "<strong>House Number :</strong> "+feature.properties.houseNumber+"<br/><strong>Owner Name :</strong> "+feature.properties.ownerName;
        // popupContent = <PopupHouse properties = {feature.properties}/>
      } else {
        popupContent = props.popupContent;
      }
    } else if (feature.properties && feature.properties.popupContent) {
      popupContent = feature.properties.name;
    }

    if (props.type)
      layer.bindPopup(`${popupContent}`, {
        maxWidth: 700,
      });
    else
      layer.bindPopup(`${popupContent}`, {
        maxWidth: 700,
      });

    layer.on({
      mouseover: (e) => {
        layer.openPopup();
      },
      mouseout: (e) => {
        // layer.closePopup();
      },
    });
  };
  // console.log(JSON.stringify(props.data));

  if (
    props.data.features.findIndex((each) => each.geometry.type === "Point") !==
    -1
  ) {
    return props.data.features.map((eachFeature) => {
      // euta euta points aaune eta bata ho;
      const properties = eachFeature.properties;
      const eachCoordinates = eachFeature.geometry.coordinates;
      var pointerIcon = L.Icon.extend({
        options: {
          siconUrl: `../../../image/pin24.png`,
          iconAnchor: [12, 24],
        },
      });

      var icon;
      if (properties.institutionsType === "education") {
        icon = new pointerIcon({
          iconUrl: educationUrl,
        });
      } else if (properties.institutionsType === "offices") {
        icon = new pointerIcon({ iconUrl: officeUrl });
      } else if (properties.institutionsType === "industries") {
        icon = new pointerIcon({ iconUrl: industriesUrl });
      } else if (properties.institutionsType === "agriculture") {
        icon = new pointerIcon({ iconUrl: agricultureUrl });
      } else if (properties.institutionsType === "economic") {
        icon = new pointerIcon({ iconUrl: economicUrl });
      } else if (properties.institutionsType === "homestay") {
        icon = new pointerIcon({ iconUrl: homestayUrl });
      } else if (properties.institutionsType === "workshop") {
        icon = new pointerIcon({ iconUrl: workshopUrl });
      } else if (properties.institutionsType === "health") {
        icon = new pointerIcon({ iconUrl: healthUrl });
      } else if (properties.institutionsType === "infrastructure") {
        icon = new pointerIcon({ iconUrl: infrastructureUrl });
      } else {
        icon = new pointerIcon({ iconUrl: iconUrl });
      }

      return (
        <Marker position={[eachCoordinates[1], eachCoordinates[0]]} icon={icon}>
          <Popup>{housePopUp(properties)}</Popup>
        </Marker>
      );
    });
  }

  return <GeoJSON {...props} onEachFeature={handleOnEachFeature} />;
}
