import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import { no, selectedLanguage, yes } from "../../../../../variable/global";
import {
	kattha,
	gharJaggaTitle,
	hoousesType,
	houseCriteriaFullfill,
	houseLandUsedLiving,
	houseMapPass,
	houseRent,
	houseroofType,
	paisa,
	dhur,
	bigha,
	totalHouseCount,
	totalHouseRoom,
	totalHouseStorey,
	houseConstructionCertificate,
	houseAge,
} from "../../../../../variable/houseSurvey";
import {
	QuestionAnswer,
	Title,
} from "../../../../common/frequent/QuestionAnswer";

function mapValue(value, obj) {
	return obj[value];
}
class SearchByHouseNumber extends Component {
	getYesNo = (data) => (data ? yes[selectedLanguage] : no[selectedLanguage]);
	render() {
		//
		// const totalArea = { english: "", nepali: "जम्मा जग्गा क्षेत्रफल" };
		// const ropani = { english: "", nepali: "रोपनी" };
		// const aana = { english: "", nepali: "आना" };
		// const paisa = { english: "", nepali: "पैसा" };

		//
		const { classes, houseland } = this.props;
		return (
			<Grid item container direction="row">
				<Title title={gharJaggaTitle} />
				<Grid item container>
					<Grid item container direction="row">
						{houseland.houseCount && (
							<Grid item xs={6}>
								<QuestionAnswer
									variant="h6"
									question={totalHouseCount[selectedLanguage]}
									answer={
										houseland &&
										houseland.houseCount &&
										getNepalNumber(houseland.houseCount)
									}
								/>
							</Grid>
						)}
						{houseland.totalStorey && (
							<Grid item xs={6}>
								<QuestionAnswer
									variant="h6"
									question={totalHouseStorey[selectedLanguage]}
									answer={
										houseland &&
										houseland.totalStorey &&
										getNepalNumber(houseland.totalStorey)
									}
								/>
							</Grid>
						)}
					</Grid>
					<Grid item container direction="row">
						{houseland.totalRoom && (
							<Grid item xs={6}>
								<QuestionAnswer
									variant="h6"
									question={totalHouseRoom[selectedLanguage]}
									answer={
										houseland &&
										houseland.totalRoom &&
										getNepalNumber(houseland.totalRoom)
									}
								/>
							</Grid>
						)}
						<Grid item xs={6}>
							<QuestionAnswer
								variant="h6"
								question={houseLandUsedLiving[selectedLanguage]}
								answer={mapValue(houseland.houseUsedLiving, {
									niji: "निजि आफ्नै",
									rent: "भाडामा",
									qarter: "संस्थागत (सरकारी वा संस्थाको क्वार्टर )",
								})}
							/>
						</Grid>
					</Grid>
					<Grid item container direction="row">
						<Grid item xs={6}>
							<QuestionAnswer
								variant="h6"
								question={houseMapPass[selectedLanguage]}
								answer={this.getYesNo(houseland.hasHouseMapPass)}
							/>
						</Grid>
						<Grid item xs={6}>
							<QuestionAnswer
								variant="h6"
								question={houseCriteriaFullfill[selectedLanguage]}
								answer={mapValue(houseland.doHouseCriteriaFullfill, {
									criteria_yes: "छ",
									criteria_no: "छैन",
									donot_know_cri: "थाहा छैन",
								})}
							/>
						</Grid>
					</Grid>
					<Grid item container direction="row">
						<Grid item xs={6}>
							<QuestionAnswer
								variant="h6"
								question={houseroofType[selectedLanguage]}
								answer={mapValue(houseland.roofType, {
									steel: "जस्ता पाता वा च्यादर छाना भएको",
									roof_grass: "फुस वा खरको छाना भएको",
									tiles: "टायल, खपड वा ढुंगाको छाना भएको",
									rcc: "आरसीसी ढलान भएको",
									wooden: "काठ वा फल्याकको छाना भएको",
								})}
							/>
						</Grid>
						<Grid item xs={6}>
							<QuestionAnswer
								variant="h6"
								question={hoousesType[selectedLanguage]}
								answer={mapValue(houseland.houseType, {
									aarcc: "आरसीसी",
									stone_house: "ढुंगाको घर सिमेन्टको जोडाइ",
									break_house: "पक्कि इँटाको घर सिमेन्टको जोडाइ",
									tin: "जस्ताले बेरेको",
									bamboo_house: "बास /टाटि घर",
									sentar_beraa: "सेन्टर बेरा",
									block_and_cement: "ब्लक र सिमेन्टको जोडाइ",
									stone_mud_house: "ढुंगाको घर माटोको जोडाई",
									wooden: "काठको खम्बा (काठको घर )",
								})}
							/>
						</Grid>
					</Grid>
					<Grid item container direction="row">
						<Grid item xs={6}>
							<QuestionAnswer
								variant="h6"
								question={houseConstructionCertificate[selectedLanguage]}
								answer={this.getYesNo(houseland.houseConstructionCertificate)}
							/>
						</Grid>
						<Grid item xs={6}>
							<QuestionAnswer
								variant="h6"
								question={houseAge[selectedLanguage]}
								answer={getNepalNumber(houseland.houseAge)}
							/>
						</Grid>
					</Grid>
					<Grid item container direction="row">
						<Grid item xs={6}>
							<QuestionAnswer
								variant="h6"
								question={houseRent[selectedLanguage]}
								answer={this.getYesNo(houseland.houseRent)}
							/>
						</Grid>
					</Grid>

					<Grid item container direction="column">
						<QuestionAnswer question="एलानी जग्गा क्षेत्रफल" />
						<Grid item>
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										<TableCell align="left">
											{bigha[selectedLanguage]}
										</TableCell>
										<TableCell align="left">
											{kattha[selectedLanguage]}
										</TableCell>
										<TableCell align="left">{dhur[selectedLanguage]}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell align="left">
											{houseland &&
												houseland.yelani &&
												houseland.yelani.ropani &&
												getNepalNumber(houseland.yelani.ropani)}
										</TableCell>
										<TableCell align="left">
											{houseland &&
												houseland.yelani &&
												houseland.yelani.aana &&
												getNepalNumber(houseland.yelani.aana)}
										</TableCell>
										<TableCell align="left">
											{houseland &&
												houseland.yelani &&
												houseland.yelani.paisa &&
												getNepalNumber(houseland.yelani.paisa)}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</Grid>
					</Grid>
					<Grid item container direction="column">
						<QuestionAnswer question="नम्बरी जग्गा क्षेत्रफल" />
						<Grid item>
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										<TableCell align="left">
											{bigha[selectedLanguage]}
										</TableCell>
										<TableCell align="left">
											{kattha[selectedLanguage]}
										</TableCell>
										<TableCell align="left">{dhur[selectedLanguage]}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell align="left">
											{houseland &&
												houseland.nambari &&
												houseland.nambari.ropani &&
												getNepalNumber(houseland.nambari.ropani)}
										</TableCell>
										<TableCell align="left">
											{houseland &&
												houseland.nambari &&
												houseland.nambari.aana &&
												getNepalNumber(houseland.nambari.aana)}
										</TableCell>
										<TableCell align="left">
											{houseland &&
												houseland.nambari &&
												houseland.nambari.paisa &&
												getNepalNumber(houseland.nambari.paisa)}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

const styles = (theme) => ({
	table: {
		width: "auto",
	},
});

SearchByHouseNumber.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchByHouseNumber);
