import {
	Avatar,
	Checkbox,
	FormControl,
	FormControlLabel,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { message } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getCount } from "../../actions/analysisAction";
import { loginUser } from "../../actions/authAction";
import villageImage from "../../image/village.jpg";
import { selectedLanguage, gaupalikaWard } from "../../variable/global";
class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			showPassword: false,
			errors: {},
		};
	}
	componentDidMount() {
		this.props.getCount();
		if (this.props.auth.isAuthenticated) {
			this.props.history.push("/home");
		}
	}
	setErrorNull = () => {
		this.setState({
			errors: {},
		});
	};

	static getDerivedStateFromProps(nextProps) {
		if (nextProps.auth.isAuthenticated) {
			nextProps.history.push("/home");
		}
	}
	componentDidUpdate(nextProps) {
		if (nextProps.errors !== this.props.errors) {
			this.setState({
				errors: this.props.errors,
			});
		}
	}

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	handleClickShowPassword = () => {
		this.setState((state) => ({ showPassword: !state.showPassword }));
	};

	onSubmit = (event) => {
		event.preventDefault();
		const newUser = {
			emailOrUsername: this.state.email,
			password: this.state.password,
		};
		this.props
			.loginUser(newUser, this.props.history)
			.then()
			.catch((err) => {
				console.log("err dataa", err.response);
				this.setState({
					errors: err.response.data && err.response.data.errors,
				});
				message.error("Error on Login");
			});
	};

	render() {
		const { classes } = this.props;

		return (
			<div className={classes.parent}>
				<div className={classes.backgroundImage} />

				<main className={classes.main}>
					<Paper className={classes.paper} elevation={15}>
						<Typography variant="h5">
							{gaupalikaWard[selectedLanguage]}
						</Typography>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Sign in
						</Typography>
						<form className={classes.form}>
							<FormControl fullWidth>
								<TextField
									onFocusCapture={this.setErrorNull}
									className={classes.textField}
									id="outlined-email-input-1"
									error={this.state.errors.email}
									helperText={this.state.errors.email}
									label="Email or Username"
									value={this.state.email}
									type="email"
									name="email"
									autoComplete="email"
									margin="normal"
									variant="outlined"
									onChange={this.handleChange("email")}
								/>
							</FormControl>
							<FormControl fullWidth>
								<TextField
									id="password"
									label="Password"
									onFocusCapture={this.setErrorNull}
									type={this.state.showPassword ? "text" : "password"}
									name="password"
									autoComplete="current-password"
									margin="normal"
									variant="outlined"
									disabled={false}
									className={classes.textField}
									error={this.state.errors.password}
									helperText={this.state.errors.password}
									value={this.state.password}
									onChange={this.handleChange("password")}
									onKeyPress={(ev) => {
										if (ev.key === "Enter") {
											// Do code here
											ev.preventDefault();
											this.onSubmit(ev);
										}
									}}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="Toggle password visibility"
													onClick={this.handleClickShowPassword}
												>
													{this.state.showPassword ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</FormControl>

							<FormControlLabel
								control={<Checkbox value="remember" color="primary" />}
								label="Remember me"
							/>
							<Button
								fullWidth
								variant="contained"
								color="primary"
								onClick={this.onSubmit}
								className={classes.submit}
							>
								Sign in
							</Button>
						</form>
					</Paper>
				</main>
			</div>
		);
	}
}
const styles = (theme) => ({
	parent: {},
	backgroundImage: {
		backgroundImage: `url(${villageImage})`,
		backgroundRepeat: "no-repeat",
		height: "100%",
		backgroundSize: "cover",
		backgroundPosition: "center center",
		position: "absolute",
		width: "100%",
		filter: "blur(4px)",
	},
	main: {
		width: "90%",
		display: "block", // Fix IE 11 issue.
		marginLeft: "auto",
		marginRight: "auto",
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: "auto",
			marginRight: "auto",
		},
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	paper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
			theme.spacing.unit * 3
		}px`,
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
});

Login.propTypes = {
	classes: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
	loginUser: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	errors: state.errors,
});
export default withStyles(styles)(
	connect(mapStateToProps, { loginUser, getCount })(withRouter(Login))
);
