import {UserDeleteOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, Col, Icon, message, Row, Select, Spin, Typography,} from "antd";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {ward} from "../../variable/global";
import {Grid} from "@material-ui/core";
import MultipleSelect from "../../components/common/frequent/MultipleSelect";
import RadioButton from "../../components/common/frequent/RadioButton";
import {
	answererName,
	deathCertificate,
	deathCertificateQn,
	deathGender,
	deathInYearReasonQuestion,
	deathName,
	deathReason,
	migrationType, newWardQuestion,
	toleOneQn,
	toleQnAll,
	toleWardFive,
	toleWardFour,
	toleWardOne, toleWardSeven, toleWardSix,
	toleWardThree,
	toleWardTwo,
	wardNumber,
	wardQuestion,
} from "../../variable/houseSurvey";
import Axios from "axios";
import FullScreenDialog from "../../components/common/frequent/dialog/FullScreen";
import HouseDetail from "../../components/dashboard/search/advancedHouseSearch/houseNumber/HouseDetail";
import TextField from "../../components/common/frequent/TextField";

const { Title } = Typography;
const { Option } = Select;

const MigrationForm = ({ handleChange, thisError, form }) => {
	const toleName = [
		toleWardOne,
		toleWardTwo,
		toleWardThree,
		toleWardFour,
		toleWardFive,
		toleWardSix,
		toleWardSeven,
	]
	return (
		<Grid
			container
			direction="column"
			style={{
				width: "100%",
				border: "1px solid  rgba(0, 0, 0, 0.19)",
				borderTop: "none !important",
				padding: `16px 8px 16px 16px`,
				borderBottomRightRadius: 5,
				borderBottomLeftRadius: 5,
				marginBottom: 20,
			}}
		>

				<Grid container>
					<RadioButton
						value={form['migrationType']}
						name={'migrationType'}
						question={{
							nepali: 'बसाइ सराई को प्रकार:',
							english: 'Migration Type:'
						}}
						isVisible
						handleChange={handleChange}
						radioLists={migrationType}

					/>
			</Grid>

			{
				form.migrationType === 'immigration' && <Link to={'/survey-by-house/add'}>
					<Button type='primary'>नयाँ घर थप्नुहोस</Button>
				</Link>
			}

			{
				form.migrationType === 'internal_migration' && <Grid container>
					<Grid container xs={12}>
						<TextField
							question={{
								nepali: "बसाइँसराई गर्नुको कारण?",
								english: "Migration Reason?",
							}}
							name="migrationReason"
							require
							value={form.migrationReason}
							isVisible
							handleChange={handleChange}
						/>

					</Grid>
					<Grid container>
						<RadioButton
							handleChange={handleChange}
							isVisible
							question={newWardQuestion}
							name="wardNumber"
							radioLists={wardNumber}
							value={form.wardNumber}
						/>
					</Grid>

					<TextField
						question={{
							nepali: "मार्गको नाम (घर छेउको बाटो )",
							english: "Street name",
						}}
						name="streetName"
						require
						value={form.streetName}
						isVisible
						handleChange={handleChange}
					/>

					{form.wardNumber >= 0 && <Grid container>
						<RadioButton
							isVisible
							handleChange={handleChange}
							question={toleQnAll}
							name="toleName"
							radioLists={toleName[form.wardNumber]}
							value={form.toleNameOne}
						/>
					</Grid>}
				</Grid>
			}

		</Grid>
	);
};

export default function VitalMigration({ history }) {
	const [form, setForm] = useState({
	});
	const [houseOpen, setHouseOpen] = useState(false);
	const [houseOfWard, setHouseOfWard] = useState([]);
	const [fetching, setFetching] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	const handleInputChange = (name) => (value) => {
		setForm((prevValue) => ({ ...prevValue, [name]: value }));
		if (name == "ward" && form.ward !== value) fetchHouseId(value);
		else if (name == "houseId" && form.houseId !== value) {
		//	TODO
		}
	};
	const fetchHouseId = (ward) => {
		setHouseOfWard([]);
		setFetching(true);
		Axios.get(`/api/vital/birth/?ward=${ward}`)
			.then((response) => {
				const data = response.data.data.map((house) => ({
					text: house.houseNumber + "",
					value: house._id,
				}));
				setHouseOfWard(data);
			})
			.finally((each) => {
				setFetching(false);
			});
	};

	const onSubmit = () => {
		const requiredFields = [
			"migrationType",
			"migrationReason",
			'wardNumber',
			"streetName",
			"toleName",
			"houseId"
		];
		for (const each of requiredFields)
			if (!form[each])
				return message.error("कृपया सबै ठाउ भर्नुहोस् : " + each);
		if (false) return message.warning("कृपया सबै ठाउ भर्नुहोस्");
		if (!form.houseId) return message.warning("वार्ड र घर चयन गर्नुहोस");
		// else Axios ni done vao
		setSubmitting(true);
		Axios.put(`/api/vital/birth/migrate`, {
			...form,

		})
			.then((res) => {
				message.success("बसाइँ सराई दर्ता भएको  छ");
				history.push("/vital-registration");
			})
			.catch((err) => {
				message.error("Error On Saving Data");
			})
			.finally(() => {
				setSubmitting(false);
			});
	};

	return (
		<Row
			style={{
				marginBottom: 50,
			}}
		>
			<Row>
				{houseOpen && (
					<FullScreenDialog
						name="houseOpen"
						open={houseOpen}
						title={`House Detail `}
						handleClose={() => setHouseOpen(false)}
					>
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="flex-start"
						>
							{(() => {
								let house = houseOfWard.find(
									(each) => each.value == form.houseId
								);
								return (
									<HouseDetail
										houseId={form && form.houseId && form.houseId}
										houseNumber={house.text}
									/>
								);
							})()}
						</Grid>
					</FullScreenDialog>
				)}
				<Breadcrumb>
					<Breadcrumb.Item href="/">
						<Icon type="home" />
					</Breadcrumb.Item>
					<Link to="/vital-registration">
						<Breadcrumb.Item>
							<Icon
								component={() => (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										viewBox="0 0 24 24"
									>
										<path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z" />
									</svg>
								)}
							/>
							<span>घटना दर्ता</span>
						</Breadcrumb.Item>
					</Link>
					<Breadcrumb.Item>
						<UserDeleteOutlined />
						<span>मृत्यु दर्ता</span>
					</Breadcrumb.Item>
				</Breadcrumb>
			</Row>
			<Row
				style={{
					marginTop: 50,
				}}
			>
				<Title
					level={3}
					style={{
						fontSize: 28,
						color: "#3d7ec5",
					}}
				>
					बसाई सराइका लागि फारम भर्नुहोस्
				</Title>
			</Row>
			<Row>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						marginBottom: 20,
					}}
				>
					<span>वडा चयन गर्नुहोस:</span>
					<Select
						showSearch={true}
						size="large"
						style={{
							minWidth: 300,
						}}
						placeholder="वडा रोज्नुहोस"
						onChange={handleInputChange("ward")}
						style={{ minWidth: 250, marginLeft: 8 }}
					>
						{ward.map((each) => (
							<Option value={each.value}>{each.ward}</Option>
						))}
					</Select>
				</div>

				<Row xs={24} md={12}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: 20,
						}}
					>
						<span
							style={{
								display: "flex-inline",
							}}
						>
							बसाई सराइ गर्नुपर्ने को घर नम्बर:
						</span>
						<Select
							disabled={!form.ward}
							size="large"
							showSearch
							// labelInValue
							value={form.houseId}
							placeholder="घर नम्बर खोज्नुहोस"
							notFoundContent={
								fetching ? (
									<Spin size="small" />
								) : houseOfWard.length == 0 ? (
									<span>Not Found</span>
								) : null
							}
							filterOption={(input, option) =>
								option.props.children
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							onChange={handleInputChange("houseId")}
							style={{ minWidth: 250, marginLeft: 8 }}
						>
							{houseOfWard.map((d) => (
								<Option key={d.value}>{d.text}</Option>
							))}
						</Select>
					</div>
				</Row>

			{(() => {
					let house = houseOfWard.find((each) => each.value == form.houseId);
					if (house)
						return (
							<Col
								style={{
									marginTop: 10,
									marginBottom: 10,
								}}
							>
								<Button
									type="link"
									onClick={() => setHouseOpen(true)}
									style={{
										padding: 0,
									}}
								>
									<span
										style={{
											fontSize: 20,
										}}
									>
										{house.text} घर नम्बर को विवरण हेर्नुहोस्
									</span>
								</Button>
							</Col>
						);
					else return null;
				})()}
			</Row>
			<Row>
				<MigrationForm
					handleChange={(name, value) => handleInputChange(name)(value)}
					form={form}
					thisError={{}}
				/>
			</Row>
			<Row
				type="flex"
				style={{
					marginTop: 20,
				}}
			>
				<Col>
					<Link to="/vital-registration">
						<Button
							style={{
								fontSize: 15,
							}}
						>
							Cancel
						</Button>
					</Link>
				</Col>
				<Col>
					<Button
						onClick={onSubmit}
						type="primary"
						style={{
							fontSize: 15,
							marginLeft: 15,
						}}
					>
						Submit
					</Button>
				</Col>
			</Row>
		</Row>
	);
}
