import { Grid, Typography } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import Axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Card from "../../common/Card/Card.jsx";
import CardBody from "../../common/Card/CardBody.jsx";
import CardHeader from "../../common/Card/CardHeader";
import GridContainer from "../../common/Grid/GridContainer.jsx";
import GridItem from "../../common/Grid/GridItem.jsx";
import ListDaughters from "./ListDaughters";
import { CSVLink } from "react-csv";
import { Button } from "antd";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import isEmpty from "../../../utils/validation/is-empty";

class index extends Component {
	state = {
		daughters: [],
		loading: true,
		sn: "",
		houseNumber: "",
		wardNumber: "",
		daughterName: "",
		csvData: [],
	};
	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value }, () =>
			this.filterData()
		);
	};
	componentDidMount() {
		this.fetchhouseList();
	}

	getNewDataList = () => {
		Axios.get(`/api/v1Mapping/fetchNewHouses`)
			.then((res) => {
				this.fetchhouseList();
			})
			.catch((err) => {
				console.log("Cannot fetch data", err);
			});
	};

	fetchhouseList = () => {
		const ward = parseInt(sessionStorage.getItem("ward") || 1);
		Axios.get(`/api/daughterMarriage/list/${ward}`)
			.then((res) => {
				console.log(res);
				const csvData = this.getCSVData(res.data);
				const filterData = res.data.filter(
					(each) => each.age <= 23 && each.marriageAge <= 20
				);
				this.setState({
					daughters: filterData,
					temp: filterData,
					csvData,
					loading: false,
				});
			})
			.catch((err) => {
				console.log("dataSurvey err", err);
			});
	};

	getGender = (gender) => {
		if (gender === "female") return "महिला";
		if (gender === "male") return "पुरुष";
	};

	getMarriageStatus = (type) => {
		if (type === "Marriage") return "विवाहित";
		if (type === "seperate") return "छुट्टिएको";
		if (type === "widow") return "विधुर /विधुवा";
		if (type === "single") return "एकल";
		if (type === "devorce") return "पारपाचुके भएको";
	};

	getCSVData = (data) => {
		let tile = [];
		let header = [
			"क्र. स.",
			"सर्बेक्षक कोड",
			"वडा नम्बर",
			"घर नम्बर",
			"छोरीको नाम",
			"लिङ्ग",
			"बैबाहिक अवस्था",
			"उमेर",
			"बिवाहा हुदाको उमेर",
			// "latitude",
			// "longitude"
		];
		tile.push(header);
		data.forEach((each, i) => {
			let row = [
				getNepaliNumber(i + 1),
				(each && each.dataCollectionCode && each.dataCollectionCode) || "",
				getNepaliNumber(each.wardNumber) || "",
				getNepaliNumber(each.houseNumber) || "",
				each.fullName || "",
				this.getGender(each.gender) || "",
				this.getMarriageStatus(each.martialStatus) || "",
				getNepaliNumber(each.age) || 0,
				getNepaliNumber(each.marriageAge) || 0,
				// each.house.oldVDC || "",
				// getNepaliNumber(each.house.oldWardNumber) || "",
				// each.house.streetName || "",
				// each.house.gps ? getNepaliNumber(each.house.gps.latitude) : "",
				// each.house.gps ? getNepaliNumber(each.house.gps.longitude) : ""
			];

			tile.push(row);
		});
		return tile;
	};

	filterData = () => {
		const { temp, houseNumber, wardNumber, daughterName } = this.state;

		var houseNumberFilter = isEmpty(houseNumber)
			? temp
			: temp
					.filter((houseNum) =>
						houseNum.houseNumber.toString().startsWith(houseNumber)
					)
					.sort((a, b) => a.houseNumber - b.houseNumber);

		var houseWardNumberFilter = isEmpty(wardNumber)
			? houseNumberFilter
			: houseNumberFilter.filter((wardHouse) =>
					wardHouse.wardNumber.toString().startsWith(wardNumber)
			  );
		var houseDaughterNameFilter = isEmpty(daughterName)
			? houseWardNumberFilter
			: houseWardNumberFilter.filter((each) =>
					each.fullName.toString().includes(daughterName)
			  );
		const csvData = this.getCSVData(houseDaughterNameFilter);
		this.setState({ daughters: houseDaughterNameFilter, csvData });
	};

	render() {
		const { classes } = this.props;
		const {
			daughters,
			loading,
			csvData,
			houseNumber,
			daughterName,
			wardNumber,
		} = this.state;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader color="primary">
							<Grid
								container
								direction="row"
								justify="space-between"
								alignItems="center"
							>
								<Grid item>
									<Typography variant="h6" className={classes.cardTitleWhite}>
										३ बर्ष भित्र छोरीको विवाहको सर्वेक्षण
									</Typography>
								</Grid>

								<Grid item>
									{!loading && (
										<CSVLink
											filename={`house_survey_export.csv`}
											data={csvData}
										>
											<Button
												type="primary"
												icon="download"
												size={"default"}
												style={{ marginRight: 10 }}
											>
												Export to Excel
											</Button>
										</CSVLink>
									)}
									{/* <Button
										type="primary"
										icon="download"
										size={"default"}
										style={{ marginRight: 10 }}
										onClick={this.getNewDataList}
									>
										Get New Data
									</Button> */}
									{/* <Link to="/survey-by-daughterMarriage/add">
										<Fab
											color="primary"
											aria-label="Add"
											className={classes.fab}
										>
											<AddIcon />
										</Fab>
									</Link> */}
								</Grid>
							</Grid>
						</CardHeader>
						<CardBody>
							{loading ? (
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="center"
								>
									<Grid item>
										<ClipLoader
											sizeUnit={"px"}
											size={84}
											color={"#00ACC1"}
											loading={loading}
										/>
									</Grid>
								</Grid>
							) : (
								<ListDaughters
									daughters={daughters}
									wardNumber={wardNumber}
									houseNumber={houseNumber}
									ownerName={daughterName}
									handleChange={this.handleChange}
									houselist={this.fetchhouseList}
									filterData={this.filterData}
									getMarriageStatus={this.getMarriageStatus}
								/>
							)}
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const styles = (theme) => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0",
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF",
		},
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1",
		},
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3,
	},
	table: {
		minWidth: 500,
	},
	tableWrapper: {
		overflowX: "auto",
	},
	fab: {
		// float: "right"
	},
});

export default withStyles(styles)(index);
