import axios from "axios";
import jwt_decode from "jwt-decode";
import setAuthToken from "../utils/setAuthToken";
import { GET_ERRORS, SET_CURRENT_USER, SHOW_HOUSE } from "./types";

export const editHouseSurvey = (id) => (dispatch) => {
	console.log("editHouseSurvey", id);
	axios
		.get(`/api/housesurvey/listhouse/${id}`)
		.then((res) => {
			dispatch({
				type: SHOW_HOUSE,
				payload: res.data,
			});
		})
		.catch();
};
export const registerUser = (userData, history) => (dispatch) => {
	axios
		.post("/api/users/register", userData)
		.then((res) => {
			res.data.success && history.push("/users");
		})
		.catch((err) => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			});
		});
};

export const deleteUser = (userId) => {
	return axios
		.post("/api/users/remove", { userId })
		.then((res) => {
			console.log("res", res.data);
			return Promise.resolve(true);
		})
		.catch((err) => {
			return Promise.reject(false);
		});
};

export const loginUser = (userData) => (dispatch) => {
	return axios
		.post("/api/users/login", userData)
		.then((res) => {
			if (res.data.isError) {
				return dispatch({
					type: GET_ERRORS,
					payload: res.data.errors,
				});
			} else {
				// Save to localStorage
				const { token } = res.data;

				// Set token to ls

				if (token) {
					localStorage.setItem("jwtToken", token);
					// Set token to Auth header
					setAuthToken(token);
					// Decode token to get user data
					const decoded = jwt_decode(token);
					console.log("decoded authAction", decoded.ward);
					sessionStorage.setItem("role", decoded.role || 0);
					sessionStorage.setItem("ward", decoded.ward || 0);
					// sessionStorage.setItem("role", decoded.role);
					// sessionStorage.setItem("ward", decoded.ward);

					// Set current user
					dispatch(setCurrentUser(decoded));
				}
			}
			return Promise.resolve(res);
		})
		.catch((err) => Promise.reject(err));
};

//set logged in user
export const setCurrentUser = (decoded) => {
	return {
		type: SET_CURRENT_USER,
		payload: decoded,
	};
};

export const logoutUser = () => (dispatch) => {
	//remove token from localstorage
	localStorage.removeItem("jwtToken");
	//remove authHead for future request by sending empty parameter
	setAuthToken();
	//set current User to {}
	dispatch(setCurrentUser());
};

export const updateUser = (userId, history) => (dispatch) => {
	axios
		.post("/api/users/editUser", userId)
		.then((res) => {
			res.data.success && history.push("/users");
		})
		.catch((err) => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			});
		});
};

export const editUser = (data) => (dispatch) => {
	return axios
		.post("/api/users/editUser", data)
		.then((res) =>
			dispatch({
				type: "UPDATE_USER_SUCCESS",
			})
		)
		.catch((err) => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			});
		});
};

export const updatePassword = (data) => (dispatch) => {
	axios
		.post("/api/users/change-password", data)
		.then((res) => {
			dispatch(logoutUser());
		})
		.catch((err) => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			});
		});
};
