import api from 'axios';

export const getSchool = (schoolId) =>
	api.get(`/api/institute/school/fetch/${schoolId}`);

export const getPersonalIncident = (personalIncidentId) =>
	api.get(`/api/institute/personalIncident/fetch/${personalIncidentId}`);

export const getFishFarm = (fishFarmId) =>
	api.get(`/api/institute/fishfarm/fetch/${fishFarmId}`);

export const getMedical = (medicalId) =>
	api.get(`/api/institute/medical/fetch/${medicalId}`);
export const getSports = (sportsId) =>
	api.get(`/api/institute/sports/fetch/${sportsId}`);

export const getSocialSecurity = (socialSecurityId) =>
	api.get(`/api/institute/socialSecurity/fetch/${socialSecurityId}`);
export const getDrinkingWater = (drinkingWaterId) =>
	api.get(`/api/institute/drinkingWater/fetch/${drinkingWaterId}`);

export const getHospital = (hospitalId) =>
	api.get(`/api/institute/hospital/fetch/${hospitalId}`);

export const getHealthPost = (hospitalId) =>
	api.get(`/api/institute/healthPost/fetch/${hospitalId}`);

export const getClub = (clubId) =>
	api.get(`/api/institute/club/fetch/${clubId}`);

export const getInstitute = (instituteId) =>
	api.get(`/api/institute/institution/fetch/${instituteId}`);

export const getPolice = (policeId) =>
	api.get(`/api/institute/police/fetch/${policeId}`);

export const getBank = (bankId) =>
	api.get(`/api/institute/bank/fetch/${bankId}`);

export const getCommunityHall = (hallId) =>
	api.get(`/api/institute/communityBuilding/fetch/${hallId}`);

export const getQuarter = (quarterId) =>
	api.get(`/api/institute/quarter/fetch/${quarterId}`);

export const getTourismPlace = (tourismId) =>
	api.get(`/api/institute/tourismPlace/fetch/${tourismId}`);

export const getPark = (parkId) =>
	api.get(`/api/institute/park/fetch/${parkId}`);

export const getHotel = (hotelId) =>
	api.get(`/api/institute/hotel/fetch/${hotelId}`);

export const getMine = (mineId) =>
	api.get(`/api/institute/mine/fetch/${mineId}`);

export const getForest = (forestId) =>
	api.get(`/api/institute/forest/fetch/${forestId}`);

export const getNikunja = (nikunjaId) =>
	api.get(`/api/institute/nikunja/fetch/${nikunjaId}`);

export const getBridge = (bridgeId) =>
	api.get(`/api/institute/bridge/fetch/${bridgeId}`);

export const getGround = (groundId) =>
	api.get(`/api/institute/ground/fetch/${groundId}`);

export const getMarket = (marketId) =>
	api.get(`/api/institute/market/fetch/${marketId}`);

export const getHerb = (herbId) =>
	api.get(`/api/institute/herbs/fetch/${herbId}`);

export const getCableCar = (cableCarId) =>
	api.get(`/api/institute/cableCar/fetch/${cableCarId}`);

export const getTuin = (tuinId) =>
	api.get(`/api/institute/tuin/fetch/${tuinId}`);

export const getMainRiver = (mainRiverId) =>
	api.get(`/api/institute/mainRiver/fetch/${mainRiverId}`);

export const getTower = (towerId) =>
	api.get(`/api/institute/tower/fetch/${towerId}`);

export const getFmStation = (fmId) =>
	api.get(`/api/institute/fmStation/fetch/${fmId}`);

export const getNewspaper = (newspaperId) =>
	api.get(`/api/institute/newspaper/fetch/${newspaperId}`);

export const getCableNetwork = (cableId) =>
	api.get(`/api/institute/cableNetwork/fetch/${cableId}`);

export const getTemple = (templeId) =>
	api.get(`/api/institute/temple/fetch/${templeId}`);

export const getIndustry = (industryId) =>
	api.get(`/api/institute/mainBusiness/fetch/${industryId}`);

export const getFestival = (festivalId) =>
	api.get(`/api/institute/festival/fetch/${festivalId}`);

export const getBusTerminal = (busId) =>
	api.get(`/api/institute/busTerminal/fetch/${busId}`);

export const getPublicToilet = (toiletId) =>
	api.get(`/api/institute/publicToilet/fetch/${toiletId}`);

export const getRoadNetwork = (roadId) =>
	api.get(`/api/institute/roadNetwork/fetch/${roadId}`);

export const getFreeWifi = (wifiId) =>
	api.get(`/api/institute/freeWifi/fetch/${wifiId}`);

export const getAnimal = (animalId) =>
	api.get(`/api/institute/animal/fetch/${animalId}`);

export const getGrazingField = (fieldId) =>
	api.get(`/api/institute/grazingField/fetch/${fieldId}`);

export const getAnimalFarm = (farmId) =>
	api.get(`/api/institute/animalFarm/fetch/${farmId}`);

export const getServiceCenter = (serviceCenterId) =>
	api.get(`/api/institute/serviceCenter/fetch/${serviceCenterId}`);

export const getRmFamily = (rmFamilyId) =>
	api.get(`/api/institute/rmFamily/fetch/${rmFamilyId}`);

export const getRmStaff = (rmStaffId) =>
	api.get(`/api/institute/rmStaff/fetch/${rmStaffId}`);

export const getElectricity = (electricityId) =>
	api.get(`/api/institute/electricity/fetch/${electricityId}`);

export const getTransmissionLine = (lineId) =>
	api.get(`/api/institute/transmissionLine/fetch/${lineId}`);

export const getCollege = (collegeId) =>
	api.get(`/api/institute/college/fetch/${collegeId}`);

export const getDisasterInformation = (disasterId) =>
	api.get(`/api/institute/disasterInformation/fetch/${disasterId}`);

export const getNaturalDisaster = (naturalId) =>
	api.get(`/api/institute/naturalDisaster/fetch/${naturalId}`);

export const getTechnicalEducation = (technicalId) =>
	api.get(`/api/institute/technicalEducation/fetch/${technicalId}`);
