import {
  Chip,
  ExpansionPanel,
  withStyles,
  Icon,
  IconButton,
} from "@material-ui/core";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandIcon from "@material-ui/icons/ExpandMore";

import Grid from "@material-ui/core/Grid";
import React, { Component } from "react";
import {
  isChildMarriageInFamilyQn,
  isChildMarriageInFamily,
  childMarriageCount,
  childMartialStatusQn,
  childMartialStatus,
  childMarriageAge,
  childMarriageTypeQn,
  childMarriageType,
  childMarriageEducationQn,
  childMarriageEducation,
  childMarriageOccupationQn,
  childMarriageOccupation,
  childMarriageKidQn,
  childMarriageKid,
  memberName,
  ageQuestion,
  genderQuestion,
  gender,
} from "../../../../variable/houseSurvey";
import PropTypes from "prop-types";

import BoxBorder from "../../../common/frequent/BoxBorder";
import MultipleSelect from "../../../common/frequent/MultipleSelect";
import RadioButton from "../../../common/frequent/RadioButton";
import TextField from "../../../common/frequent/TextField";
import BoxBorderArray from "../../../common/frequent/BoxBorderArray";
import ArrayWholeComponent from "../../../common/ArrayWholeComponent";
import DeleteIcon from "@material-ui/icons/Delete";
class ChildMarriage extends Component {
  state = {
    index: -1,
    activeIndex: 0,
    expanded: "childMarriage0",
  };

  handleExpansion = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChange = (name, value) => {
    this.props.handleChange(`childMarriage.${name}`, value);
  };

  resetThisForm = (index) => (event) => {
    const { childMarriage } = this.props;
    const total = parseInt(childMarriage.childCount);
    if (total > 0) this.props.handleResetDeath(index);
  };

  getChildMarriageForm = () => {
    const { childMarriage, classes } = this.props;
    const total = parseInt(childMarriage.childCount);
    const error = false;
    if (!isNaN(total) && total > 0) {
      return (
        <Grid container direction="column">
          {[...Array(total).keys()].map((each) => (
            <ExpansionPanel
              key={each}
              style={{ padding: 0, backgroundColor: "transparent" }}
              expanded={this.state.expanded === `childMarriage${each}`}
              onChange={this.handleExpansion(`childMarriage${each}`)}
            >
              <ExpansionPanelSummary
                className={classes.summary}
                expandIcon={<ExpandIcon className={classes.white} />}
              >
                <Grid
                  container
                  className={error ? classes.headerError : classes.header}
                />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.details}>
                <Grid
                  container
                  direction="column"
                  className={classes.eachDeath}
                >
                  <Grid container>
                    <TextField
                      question={memberName}
                      name={`fullName${each}`}
                      require
                      placeholder="नाम"
                      value={childMarriage[`fullName${each}`]}
                      isVisible
                      handleChange={this.handleChange}
                    />
                  </Grid>
                  <Grid container>
                    <RadioButton
                      handleChange={this.handleChange}
                      isVisible
                      question={genderQuestion}
                      name={`gender${each}`}
                      radioLists={gender}
                      value={childMarriage[`gender${each}`]}
                    />
                  </Grid>

                  <Grid container>
                    <TextField
                      type="number"
                      question={ageQuestion}
                      name={`age${each}`}
                      require
                      placeholder="उमेर"
                      value={childMarriage[`age${each}`]}
                      isVisible
                      handleChange={this.handleChange}
                    />
                  </Grid>

                  <Grid container>
                    <RadioButton
                      handleChange={this.handleChange}
                      isVisible
                      question={childMartialStatusQn}
                      name={`martialStatus${each}`}
                      radioLists={childMartialStatus}
                      value={childMarriage[`martialStatus${each}`]}
                    />
                  </Grid>

                  <Grid container>
                    <TextField
                      type="number"
                      question={childMarriageAge}
                      name={`marriageAge${each}`}
                      require
                      placeholder="उमेर"
                      value={childMarriage[`marriageAge${each}`]}
                      isVisible
                      handleChange={this.handleChange}
                    />
                  </Grid>

                  <Grid container>
                    <RadioButton
                      handleChange={this.handleChange}
                      isVisible
                      question={childMarriageTypeQn}
                      name={`marriageType${each}`}
                      radioLists={childMarriageType}
                      value={childMarriage[`marriageType${each}`]}
                    />
                  </Grid>

                  <Grid container>
                    <RadioButton
                      handleChange={this.handleChange}
                      isVisible
                      question={childMarriageEducationQn}
                      name={`education${each}`}
                      radioLists={childMarriageEducation}
                      value={childMarriage[`education${each}`]}
                    />
                  </Grid>

                  <Grid container>
                    <RadioButton
                      handleChange={this.handleChange}
                      isVisible
                      question={childMarriageOccupationQn}
                      name={`occupation${each}`}
                      radioLists={childMarriageOccupation}
                      value={childMarriage[`occupation${each}`]}
                    />
                  </Grid>

                  <Grid container>
                    <RadioButton
                      handleChange={this.handleChange}
                      isVisible={
                        childMarriage[`childMarriageGender${each}`] === "female"
                      }
                      question={childMarriageKidQn}
                      name={`isFemaleGenderChild${each}`}
                      radioLists={childMarriageKid}
                      value={childMarriage[`isFemaleGenderChild${each}`]}
                    />
                  </Grid>

                  <Grid
                    container
                    direction="row-reverse"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <IconButton
                        onClick={this.resetThisForm(each)}
                        aria-label="Delete"
                        className={classes.margin}
                      >
                        <DeleteIcon fontSize="large" style={{ color: "red" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </Grid>
      );
    }
  };
  render() {
    const { childMarriage } = this.props;
    return (
      <Grid container direction="column">
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={isChildMarriageInFamilyQn}
            name="isChildMarriage"
            radioLists={isChildMarriageInFamily}
            value={childMarriage.isChildMarriage}
          />
        </Grid>

        <Grid container>
          <TextField
            question={childMarriageCount}
            name="childCount"
            require
            type="number"
            placeholder="कति जना  "
            value={childMarriage.childCount}
            isVisible={childMarriage.isChildMarriage === "yes"}
            handleChange={this.handleChange}
          />
        </Grid>

        <Grid container item xs={6}>
          {childMarriage.isChildMarriage === "yes" &&
            this.getChildMarriageForm()}
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  header: {
    width: "100%",
    height: 20,
    backgroundColor: "#00A97c",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    padding: 20,
  },
  headerError: {
    height: 20,
    padding: 20,
    width: "100%",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    backgroundColor: "#FB3E44",
  },
  container: {
    backgroundColor: "transparent",
    // padding: 0,
    // margin: 0,
    border: "none",
  },
  summary: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  category: {
    // minHeight: 30,
    color: "#FFF",
  },
  details: {
    padding: 0,
    margin: 0,
    flexWrap: "nowrap",
    // border: "none",
    // marginTop: theme.spacing.unit * 1
  },
  noteContainer: {
    // margin: theme.spacing.unit
    flexWrap: "nowrap",
  },
  white: {
    color: "#FFF",
  },
  eachLink: {
    marginTop: 1,
  },
  eachDeath: {
    width: "100%",
    border: "1px solid  rgba(0, 0, 0, 0.19)",
    borderTop: "none !important",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 1}px ${
      theme.spacing.unit * 2
    }px ${theme.spacing.unit * 2}px`,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    marginBottom: 20,
  },
});

ChildMarriage.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ChildMarriage);
