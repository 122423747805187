import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
// import { Typography } from "@material-ui/core";
import FullScreen from "@material-ui/icons/Fullscreen";
import randomMC from "random-material-color";
import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Card from "../../../common/Card/Card.jsx";
import CardHeader from "../../../common/Card/CardHeader";
import CardIcon from "../../../common/Card/CardIcon";

class ChartList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			color: (() => {
				let colors = [];
				[...Array(this.props.chartData.data.length).keys()].forEach(() => {
					let color = randomMC.getColor();
					if (colors.indexOf(color) === -1) return colors.push(color);
				});
				return colors;
			})(),
		};
	}
	handleClickOpen = () => {
		this.props.handleClickOpen(this.props.name);
	};

	render() {
		const { classes, chartData, label, isFullScreen, noTitle } = this.props;
		return (
			<Card chart color="info">
				<CardHeader color="success" stats icon>
					<Grid
						style={{ marginBottom: 20 }}
						container
						direction="row"
						justify="space-between"
						alignItems="flex-start"
					>
						{!noTitle && (
							<Grid item>
								<CardIcon color="success" className={classes.iconWidth}>
									{chartData &&
										chartData.title &&
										(label ? (
											<p style={{ fontSize: "1rem" }}>{label}</p>
										) : (
											<p style={{ fontSize: "1rem" }}>
												{` ${chartData.title}`}
											</p>
										))}
								</CardIcon>
							</Grid>
						)}
						{isFullScreen && (
							<Grid item>
								<FullScreen
									onClick={this.handleClickOpen}
									className={classes.fullIcon}
								/>
							</Grid>
						)}

						{this.props.match && (
							<Grid item className={classes.mt15}>
								<Link
									to={`${this.props.match.url}${chartData.route}`}
									className={classes.link}
								>
									<Button color="primary" className={classes.button}>
										विवरण हेर्नुहोस
									</Button>
								</Link>
							</Grid>
						)}
					</Grid>
					<Bar
						type="bar"
						data={{
							labels: chartData.label,
							datasets: [
								{
									label: "संख्या",
									data: chartData.data,
									backgroundColor: this.state.color,
									borderWidth: 2,
								},
							],
						}}
						options={{
							legend: {
								display: false,
							},
							tooltips: {
								callbacks: {
									label: function (tooltipItem) {
										return tooltipItem.yLabel;
									},
								},
							},
							scales: {
								yAxes: [
									{
										ticks: {
											autoSkip: false,
											beginAtZero: true,
										},
									},
								],
								xAxes: [
									{
										ticks: {
											autoSkip: false,
											beginAtZero: true,
										},
									},
								],
							},
						}}
					/>
				</CardHeader>

				{/* <Table className={classes.table}>
						<TableBody>
							<TableRow>
								{chartData &&
									chartData.label &&
									chartData.label.length > 0 &&
									chartData.label.map((header, i) => (
										<TableCell key={i}>{header}</TableCell>
									))}
							</TableRow>

							<TableRow>
								{chartData &&
									chartData.data &&
									chartData.data.length > 0 &&
									chartData.data.map((header, i) => (
										<TableCell key={i} className={classes.zero}>
											{" "}
											{header}
										</TableCell>
									))}
							</TableRow>
						</TableBody>
					</Table> */}

				{/* <table>
						<tr>
							{chartData &&
								chartData.label &&
								chartData.label.length > 0 &&
								chartData.label.map((header, i) => <th key={i}>{header}</th>)}
						</tr>
						<tr>
							{chartData &&
								chartData.data &&
								chartData.data.length > 0 &&
								chartData.data.map((header, i) => (
									<td key={i} className={classes.zero}>
										{" "}
										{header}
									</td>
								))}
						</tr>
					</table> */}
			</Card>
		);
	}
}

export default withStyles(dashboardStyle)(ChartList);
