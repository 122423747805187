import { Grid, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Divider,
	Form,
	Icon,
	Input,
	InputNumber,
	message,
	Modal,
	Radio,
	Select,
	Typography,
	Upload,
	Tooltip,
} from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import {
	add,
	remove,
	address,
	mainAnimalQn,
	name,
	wardQuestion,
	photo,
	area,
} from "../../../variable/institute";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import { getAnimal } from "./api";
const { Option } = Select;
const { TextArea } = Input;

class Animal extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};
	state = {
		// image upload state
		uniqueIdentifier:
			Number(this.random(10000, 99999)).toFixed(0) +
			Number(this.random(1000, 9999)).toFixed(0),
		previewVisible: false,
		previewImage: "",
		loaded: 0,
		name: "",
		ward: "",
		address: "",
		fileList: {
			animalImage: {},
		},
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			(prevState) => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg",
					},
				},
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: (ProgressEvent) => {
				this.setState({
					loaded: Math.round(
						(ProgressEvent.loaded / ProgressEvent.total) * 100
					),
				});
			},
		})
			.then((res) => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = (imageName) => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then((res) => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	handleSelectChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	handleInputChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	componentDidMount() {
		let animalId = this.props.match.params.id;
		if (animalId) {
			getAnimal(animalId).then((res) =>
				this.setState({
					name: res.data.name,
					ward: res.data.ward,
					address: res.data.address,
					// image
					fileList: {
						animalImage: {
							fileName: res.data.uniqueIdentifier + "_animal.jpg",
							image: [
								{
									uid: "-1",
									name: `${res.data.uniqueIdentifier}_animal.jpg`,
									status: "done",
									url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_animal.jpg`,
								},
							],
						},
					},
				})
			);
		}
	}

	onSubmit = (event) => {
		event.preventDefault();
		const newInstitute = {
			name: this.state.name,
			ward: this.state.ward,
			address: this.state.address,
			uniqueIdentifier: this.state.uniqueIdentifier,
		};

		if (this.props.isUpdate) {
			newInstitute.id = this.props.match.params.id;
			this.props.addInstitutionSurvey(
				"animal",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
		} else
			this.props.addInstitutionSurvey(
				"animal",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
		// this.props.history.push(
		//   `/survey-by-institution/show/${this.props.match.params.category}`
		// );
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing={3}
					>
						<Grid item xs={6}>
							<Form.Item label={name[selectedLanguage]}>
								<TextArea
									onChange={this.handleInputChange("name")}
									value={this.state.name}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={wardQuestion[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("ward", val)}
									value={this.state.ward}
								>
									{ward.map((woda) => (
										<Option value={woda.value}>{woda.ward}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={address[selectedLanguage]}>
								<TextArea
									onChange={this.handleInputChange("address")}
									value={this.state.address}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={photo[selectedLanguage]}>
								<Fragment>
									{!this.state.isView ? (
										<Upload
											name="file"
											// beforeUpload={this.props.beforeUpload}
											multiple={false}
											// action="/api/imageUpload/upload"
											accept="image/*"
											listType="picture-card"
											fileList={
												fileList.animalImage && fileList.animalImage.image
											}
											onPreview={this.handlePreview}
											onChange={(fileList) =>
												this.handleChangeFile("animalImage", fileList, "animal")
											}
											disabled={this.state.isView}
										>
											{fileList &&
											fileList.animalImage &&
											fileList.animalImage.image &&
											fileList.animalImage.image.length === 1
												? null
												: uploadButton}
										</Upload>
									) : (
										<Modal
											visible={previewVisible}
											footer={null}
											onCancel={this.handleCancel}
										>
											<img
												alt="example"
												style={{ width: "100%" }}
												src={previewImage}
											/>
										</Modal>
									)}
								</Fragment>
							</Form.Item>
						</Grid>

						<Grid xs={12}>
							<Button
								type="primary"
								htmlType="submit"
								disabled={this.state.isView}
							>
								{this.props.isUpdate ? "update" : add[selectedLanguage]}
							</Button>
						</Grid>
					</Grid>
				</Form>
			</Paper>
		);
	}
}

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addInstitutionSurvey })(withRouter(Animal))
);
