import { Grid, IconButton, Modal } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Radio,
	Form,
	Icon,
	Input,
	message,
	Select,
	Typography,
	Upload,
} from "antd";
import Axios from "axios";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import {
	add,
	wardQuestion,
	photo,
	address,
	name,
	groundTypeQn,
	groundType,
} from "../../../variable/institute";
import { getGround } from "./api";

const { TextArea } = Input;

const { Option } = Select;

class Ground extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};
	state = {
		// image upload state
		uniqueIdentifier:
			Number(this.random(10000, 99999)).toFixed(0) +
			Number(this.random(1000, 9999)).toFixed(0),
		previewVisible: false,
		previewImage: "",
		fileList: {
			groundImage: [],
		},
		loaded: 0,
		// other
		photo: "",
		address: "",
		name: "",
		groundType: [],
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			(prevState) => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg",
					},
				},
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: (ProgressEvent) => {
				this.setState({
					loaded: Math.round(
						(ProgressEvent.loaded / ProgressEvent.total) * 100
					),
				});
			},
		})
			.then((res) => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = (imageName) => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then((res) => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	componentDidMount() {
		let groundId = this.props.match.params.id;
		if (groundId) {
			getGround(groundId).then((res) =>
				this.setState({
					address: res.data.address,
					name: res.data.name,
					groundType: res.data.groundType,

					// image
					fileList: {
						groundImage: {
							fileName: res.data.uniqueIdentifier + "_ground.jpg",
							image: [
								{
									uid: "-1",
									name: `${res.data.uniqueIdentifier}_ground.jpg`,
									status: "done",
									url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_ground.jpg`,
								},
							],
						},
					},
				})
			);
		}
	}

	onSubmit = (event) => {
		event.preventDefault();
		const newInstitute = {
			uniqueIdentifier: this.state.uniqueIdentifier,
			address: this.state.address,
			name: this.state.name,
			groundType: this.state.groundType,
		};

		if (this.props.isUpdate) {
			newInstitute.id = this.props.match.params.id;
			this.props.addInstitutionSurvey(
				"ground",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
		} else
			this.props.addInstitutionSurvey(
				"ground",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
	};
	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};
	handleSelectChange = (name, value) => {
		console.log("handle ", name, value);
		this.setState({
			[name]: value,
		});
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form {...formItemLayout} onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
					>
						<Grid xs={6}>
							<Form.Item label={name[selectedLanguage]}>
								<Input
									onChange={this.handleChange("name")}
									value={this.state.name}
								/>
							</Form.Item>
						</Grid>

						<Grid xs={6}>
							<Form.Item label={address[selectedLanguage]}>
								<Input
									onChange={this.handleChange("address")}
									value={this.state.address}
								/>
							</Form.Item>
						</Grid>

						<Grid xs={6}>
							<Form.Item label={groundTypeQn[selectedLanguage]}>
								<Select
									mode="multiple"
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("groundType", val)}
									value={this.state.groundType}
								>
									{groundType.map((type) => (
										<Option value={type.value}>{type[selectedLanguage]}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid xs={6}>
							<Form.Item label={photo[selectedLanguage]}>
								<Fragment>
									{!this.state.isView ? (
										<Upload
											name="file"
											// beforeUpload={this.props.beforeUpload}
											multiple={false}
											// action="/api/imageUpload/upload"
											accept="image/*"
											listType="picture-card"
											fileList={
												fileList.groundImage && fileList.groundImage.image
											}
											onPreview={this.handlePreview}
											onChange={(fileList) =>
												this.handleChangeFile("groundImage", fileList, "ground")
											}
											disabled={this.state.isView}
										>
											{fileList &&
											fileList.groundImage &&
											fileList.groundImage.image &&
											fileList.groundImage.image.length === 1
												? null
												: uploadButton}
										</Upload>
									) : (
										<Modal
											visible={previewVisible}
											footer={null}
											onCancel={this.handleCancel}
										>
											<img
												alt="example"
												style={{ width: "100%" }}
												src={previewImage}
											/>
										</Modal>
									)}
								</Fragment>
							</Form.Item>
						</Grid>

						<Grid xs={12}>
							<Button
								type="primary"
								htmlType="submit"
								disabled={this.state.isView}
							>
								{add[selectedLanguage]}
							</Button>
						</Grid>
					</Grid>
				</Form>
			</Paper>
		);
	}
}

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const formItemLayout = {
	labelCol: {
		xs: { span: 20 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addInstitutionSurvey })(withRouter(Ground))
);
