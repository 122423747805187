import React, { Component } from "react";
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Paper,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import withStyles from "@material-ui/core/styles/withStyles";
import PrintIcon from "@material-ui/icons/Print";
import { totalWard } from "../../../config";
import ReactToPrint from "../../common/frequent/print/ReactToPrint";
// import getNepaliNumber from "get-nepali-number";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import FamilyScroll from "../../common/frequent/dialog/FamilyScroll";
import "./style.css";
import { Fragment } from "react";

class FamilyLandConsumption extends Component {
	constructor(props) {
		super(props);
		this.state = {
			beforePrint: false,
			component: null,
			ward: 0,
			argumentTobePassed: [],
			open: false,
		};
	}

	handleAfterPrint = () => {
		this.setState({ beforePrint: false });
	};

	handleBeforePrint = () => {
		this.setState({ beforePrint: true });
	};

	getContent = () => {
		return this.setState(
			{ beforePrint: true, component: this.componentRef },
			() => {
				return this.state.component;
			}
		);
	};

	handleOpen = (argumentTobePassed) => (event) => {
		this.setState({
			argumentTobePassed,
			open: true,
		});
	};

	handleClose = () => {
		this.setState({
			argumentTobePassed: [],
			open: false,
		});
	};

	render() {
		const { classes, data, columns } = this.props;
		const { ward } = this.state;
		const style =
			columns.functions !== "null"
				? { color: "blue", textDecoration: "underline" }
				: {};
		return (
			<Grid>
				<Grid container justify="space-between" alignItems="flex-start">
					<Grid item>
						<Typography variant="h6" className={classes.cardTitleWhite}>
							{columns.title}
						</Typography>
					</Grid>

					{columns.functions !== "null" && (
						<FamilyScroll
							handleClose={this.handleClose}
							open={this.state.open}
							args={this.state.argumentTobePassed}
							functions={columns.functions}
						/>
					)}

					<Grid item>
						{
							<ReactToPrint
								style={{ marginTop: 200 }}
								onBeforePrint={this.handleBeforePrint}
								onAfterPrint={this.handleAfterPrint}
								trigger={() => (
									<Fab
										variant="extended"
										aria-label="Delete"
										className={classes.fab}
									>
										<PrintIcon className={classes.printIcon} />
									</Fab>
								)}
								data={this.state.component}
								content={this.getContent}
							/>
						}
					</Grid>
				</Grid>
				<Grid ref={(el) => (this.componentRef = el)}>
					<Paper className={classes.root}>
						<Table
							className={classes.table}
							stickyHeader
							aria-label="sticky table"
						>
							<TableHead>
								<TableRow>
									<TableCell />
									<TableCell align="center" colSpan={2}>
										आफ्नो परिवारको नाममा रहेको जग्गा
									</TableCell>
									<TableCell align="center" colSpan={2}>
										अरुको नाममा प्रयोग गर्ने
									</TableCell>
									<TableCell />
									<TableCell />
								</TableRow>
								<TableRow>
									<TableCell>{columns.reportTitle[0]}</TableCell>

									{columns.ward[0].data.map((w1) => (
										<TableCell>{w1.label}</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{[...Array(totalWard).keys()].map((eachWard) => {
									const rowData = columns.ward.find(
										(each) => parseInt(each.ward) === parseInt(eachWard + 1)
									);
									return (
										(ward === 0 || ward === eachWard + 1) && (
											<TableRow key={eachWard}>
												<TableCell>
													{columns.reportTitle[eachWard + 1]}
												</TableCell>
												{rowData.data.map((eachData) => (
													<TableCell
														style={style}
														align="left"
														// onClick={this.handleOpen(eachData.args)}
														onClick={
															columns.functions !== "null" &&
															eachData.args &&
															eachData.args.length > 0
																? this.handleOpen(eachData.args)
																: undefined
														}
													>
														{getNepaliNumber(eachData.value)}
													</TableCell>
												))}
												{columns.total && (
													<TableCell
														style={style}
														align="left"
														// onClick={
														// 	columns.functions !== "null" &&
														// 	this.handleOpen(columns.total[eachWard])
														// }
													>
														{getNepaliNumber(columns.total[eachWard])}
													</TableCell>
												)}
											</TableRow>
										)
									);
								})}
								<TableRow>
									<TableCell>
										{columns.reportTitle[columns.reportTitle.length - 1]}
									</TableCell>
									{(() => {
										const rowData = columns.ward.find(
											(each) => parseInt(each.ward) === parseInt(0)
										);
										return rowData.data.map((eachData) => (
											<TableCell
												style={style}
												align="left"
												onClick={
													columns.functions !== "null" &&
													this.handleOpen(eachData.args)
												}
											>
												{getNepaliNumber(eachData.value)}
											</TableCell>
										));
									})()}

									{columns.total && (
										<TableCell
											style={{
												color: "blue",
												textDecoration: "underline",
											}}
											// align="left"
											// onClick={this.handleOpen(eachData.args)}
										>
											{getNepaliNumber(columns.total[columns.total.length - 1])}
										</TableCell>
									)}
								</TableRow>
							</TableBody>
						</Table>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const styles = (theme) => ({
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3,
		overflowX: "auto",
	},
});

export default withStyles(styles)(FamilyLandConsumption);
