import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import { no, selectedLanguage, yes } from "../../../../../variable/global";
import {
	familyTitle,
	waterSource,
	cookingSource,
	electricSource,
	isElectricityMeterQn,
	toiletType,
	toiletQuestion,
	toiletTypeNo,
	toiletTypeNoUse,
	anyBankAccount,
	numberofBankAccount,
	homeAge,
	saltUsed,
	wasteDisposal,
	riskAreaAroundHouseQn,
	meansOfMunicipalInformationQn,
	waterPurification,
	waterPurificationQn,
	drinkingwaterplaceQuestion,
	illTreatment,
} from "../../../../../variable/houseSurvey";
import {
	QuestionAnswer,
	Title,
} from "../../../../common/frequent/QuestionAnswer";

const NoImageFound = require("../../../../../image/NoImageFound.jpg");

function mapValue(value, obj) {
	return obj[value];
}

class HouseIntro extends Component {
	getYesNo = (data) => (data ? yes[selectedLanguage] : no[selectedLanguage]);
	multimapValue = (name, object) =>
		name && name.map((each) => object[each]).join(",");
	render() {
		const { classes, houseNumber, secondaryHouseDetails } = this.props;
		return (
			<Grid item container direction="column" className={classes.container}>
				<Title title={familyTitle} />
				<Grid item container direction="column">
					{/* first */}
					<Grid
						item
						container
						direction="row"
						className={classes.eachQnAns}
						spacing={24}
					>
						<Grid item xs={4}>
							<QuestionAnswer
								question={waterSource[selectedLanguage]}
								answer={mapValue(secondaryHouseDetails.waterSource, {
									tap_home: "पाइपधारा घर कम्पाउण्डमा",
									tap_public: "पाइप धारा सार्वजनिक",
									handpump: "ट्युबवेल/हेन्डपम्प",
									well_cover: "ढाकिएको इनार कुवा",
									well_uncover: "नढाकिएको इनार / कुवा",
									main_water: "मुलको पानि",
									river: "नदि खोला",
								})}
							/>
						</Grid>
						<Grid item xs={4}>
							<QuestionAnswer
								question={drinkingwaterplaceQuestion[selectedLanguage]}
								answer={mapValue(secondaryHouseDetails.distanceToWaterSource, {
									home_courtyard: "घर आँगनमै छ",
									"10_min_walk": "१० मिनेट सम्मको पैदल",
									half_hour_walk: "आधा घण्टा सम्मको दुरी",
									"1_hour_walk": "१ घण्टा वा सो भन्दा माथि",
								})}
							/>
						</Grid>
						<Grid item xs={4}>
							<QuestionAnswer
								question={waterPurificationQn[selectedLanguage]}
								answer={mapValue(secondaryHouseDetails.waterPurification, {
									no_purificatio: "शुद्दिकरण गरिदैन",
									boil: "मालेर",
									filter: "फिल्टर गरेर",
									checimal: "औषधि हालेर",
									solar: "सोडीस प्रविधि",
								})}
							/>
						</Grid>
						<Grid item xs={4}>
							<QuestionAnswer
								question={electricSource[selectedLanguage]}
								answer={mapValue(secondaryHouseDetails.electricSource, {
									electric: "बिधुतलाईन",
									kerosene: "मट्टीतेल/टुकी",
									solar: "सोलार",
									bio_gas: "वायो ग्यास",
								})}
							/>
						</Grid>
						{secondaryHouseDetails.electricSource === "electric" && (
							<Grid item xs={4}>
								<QuestionAnswer
									question={isElectricityMeterQn[selectedLanguage]}
									answer={this.getYesNo(
										secondaryHouseDetails.isElectricityMeter
									)}
								/>
							</Grid>
						)}
						<Grid item xs={4}>
							<QuestionAnswer
								question={cookingSource[selectedLanguage]}
								answer={this.multimapValue(
									secondaryHouseDetails.cookingSource,
									{
										wood_general: "दाउरा/काठ(साधरण चुलो )",
										wood_specific: "दाउरा/काठ(सुधारिएको चुलो )",
										kerosene: "मट्टीतेल/स्टोभ",
										lpg_gas: "एल.पी.जी.ग्यास",
										bio_gas: "वायो ग्यास",
										electricity: "विधुत",
										solar: "सौर्य उर्जा",
										guithaa: "गुइठा",
									}
								)}
							/>
						</Grid>
					</Grid>
					{/* end */}

					<Grid
						item
						container
						direction="row"
						className={classes.eachQnAns}
						spacing={24}
					>
						<Grid item xs={4}>
							<QuestionAnswer
								question={toiletQuestion[selectedLanguage]}
								answer={mapValue(secondaryHouseDetails.toilet.isToilet, {
									yes: "छ",
									no: "छैन (शौचालय नभएको )",
									yes_but_not_us: "छ तर प्रयोग गरेको छैन",
								})}
							/>
						</Grid>
						{secondaryHouseDetails.toilet.isToilet === "yes" && (
							<Grid item xs={4}>
								<QuestionAnswer
									question={toiletType[selectedLanguage]}
									answer={mapValue(secondaryHouseDetails.toilet.toiletYes, {
										public_tank: "फ्लस भएको सार्वजनिक ढलमा मिसिने",
										private_tank: "फ्लस भएको सेफ्टी ट्याङ्कमा मिसिने",
										ordinary_tank: "साधारण गोबर ग्यास प्लान्टमा मिसिने",
										ordinary: "साधारण",
									})}
								/>
							</Grid>
						)}
						{secondaryHouseDetails.toilet.isToilet === "no" && (
							<Grid item xs={4}>
								<QuestionAnswer
									question={toiletTypeNo[selectedLanguage]}
									answer={mapValue(secondaryHouseDetails.toilet.toiletNo, {
										no_land: "जग्गा नभएर",
										no_money: "बनाउने पैसा नभएर",
										neighbour_use: "छिमेकीको प्रयोग गरेर",
									})}
								/>
							</Grid>
						)}
						{secondaryHouseDetails.toilet.isToilet === "yes_but_not_us" && (
							<Grid item xs={4}>
								<QuestionAnswer
									question={toiletTypeNoUse[selectedLanguage]}
									answer={mapValue(
										secondaryHouseDetails.toilet.toiletYesButNoUse,
										{
											not_usable: "प्रयोग गर्न नमिल्ने भएर",
											old_cannot_afford: "पुरानो तर अर्को बनाउन नसकेर",
											using_others: "रुको प्रयोग गरिन्छ",
											no_door: "ढोका नभएर",
										}
									)}
								/>
							</Grid>
						)}
						<Grid item xs={4}>
							<QuestionAnswer
								question={anyBankAccount[selectedLanguage]}
								answer={this.getYesNo(secondaryHouseDetails.bankAccount)}
							/>
						</Grid>
						{secondaryHouseDetails.bankAccount && (
							<Grid item xs={4}>
								<QuestionAnswer
									question="महिला संख्या"
									answer={
										secondaryHouseDetails.bankAccountFemale &&
										getNepalNumber(secondaryHouseDetails.bankAccountFemale)
									}
								/>
							</Grid>
						)}
						{secondaryHouseDetails.bankAccount && (
							<Grid item xs={4}>
								<QuestionAnswer
									question="पुरुष संख्या"
									answer={
										secondaryHouseDetails.bankAccountMale &&
										getNepalNumber(secondaryHouseDetails.bankAccountMale)
									}
								/>
							</Grid>
						)}
					</Grid>

					<Grid
						item
						container
						direction="row"
						className={classes.eachQnAns}
						spacing={24}
					>
						<Grid item xs={4}>
							<QuestionAnswer
								question={saltUsed[selectedLanguage]}
								answer={
									secondaryHouseDetails.saltUsed &&
									mapValue(secondaryHouseDetails.saltUsed, {
										iodine_salt: "आयोडिन्युक्त नून",
										dhiko_salt: "ढिको नून",
										open_salt: "खुल्ला नून",
									})
								}
							/>
						</Grid>

						<Grid item xs={4}>
							<QuestionAnswer
								question={wasteDisposal[selectedLanguage]}
								answer={
									secondaryHouseDetails.wasteDisposal &&
									mapValue(secondaryHouseDetails.wasteDisposal, {
										garbage_burn: "संकलित फोहोर जलाउने",
										gobar_gas: "गोबर ग्यास प्लान्टमा प्रयोग गर्ने",
										organic_mal: "जैविक मल बनाउने",
										unmanaged: "अव्यवस्थित तबरले बाहिर जथाभावी फ्याल्ने",
										private_org: "निजि संस्थाले घरबाटै उठाउनेs",
									})
								}
							/>
						</Grid>

						<Grid item xs={4}>
							<QuestionAnswer
								question={riskAreaAroundHouseQn[selectedLanguage]}
								answer={
									secondaryHouseDetails.riskAreaAroundHouse &&
									this.multimapValue(
										secondaryHouseDetails.riskAreaAroundHouse,
										{
											earthquake: "भूकम्प",
											flood: "बाढी",
											landslide: "पहिरो",
											hurricane: "हावाहुरी",
											kataan: "ढुवान वा कटान",
											no_risk: "जोखिम नभएको",
										}
									)
								}
							/>
						</Grid>

						<Grid item xs={4}>
							<QuestionAnswer
								question={meansOfMunicipalInformationQn[selectedLanguage]}
								answer={
									secondaryHouseDetails.meansOfMunicipalInformation &&
									this.multimapValue(
										secondaryHouseDetails.meansOfMunicipalInformation,
										{
											mobile_app: "मोबाइल एप",
											radio: "रेडियो",
											local_leader: "स्थानीय नेताहरुबाट",
											newspaper_arti: "पत्रपत्रिका / समाचारपत्र",
											government_off: "सरकारी अधिकारि",
											nongov_officer: "गैर सरकारी कर्मचारिबाट",
											information_ri: "सुचनाको हक प्रयोग गर्ने",
											dont_know: "थाह छैन",
										}
									)
								}
							/>
						</Grid>
						<Grid item xs={4}>
							<QuestionAnswer
								question={illTreatment[selectedLanguage]}
								answer={mapValue(secondaryHouseDetails.illTreatment, {
									dhaami_jhakri: "धामी झाक्री",
									medical: "औषधि पसल",
									clinic: "क्लिनिक",
									health_post: "स्वस्थ चौकी",
									private_hospit: "जिल्लाका निजि अस्पताल",
									public_hospita: "जिल्ला बाहिर सरकारी अस्पताल",
									healthworker: "स्थानीय स्वास्थ्यकर्मी",
									district_govt_: "जिल्लाका सरकारी अस्पताल",
								})}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

const styles = (theme) => ({
	container: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 2,
	},
	table: {
		width: "auto",
	},
});

HouseIntro.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HouseIntro);
