import React from "react";
import {
  Fab,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Button,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import ClipLoader from "react-spinners/ClipLoader";
import FullScreenDialog from "../../../common/frequent/dialog/FullScreen";
import EachMember from "../../../common/frequent/FamilyMember";
import axios from "axios";
import { connect } from "react-redux";
import {
  clearHouseDetail,
  getFamilyById,
  getHouseByHouseNumber,
} from "../../../../actions/searchAction";
import GetHouseDetail from "../../../dashboard/search/advancedHouseSearch/houseNumber/GetHouseDetail";
import getNepaliNumber from "../../../../utils/getNepaliNumber";
import { allToleName } from "../../../../variable/houseSurvey";

class ScrollDialog extends React.Component {
  constructor(props) {
    super(props);
    props.clearHouseDetail();
  }
  state = {
    families: [],
    familyMemberOpen: false,
    houseOpen: false,
    houseNumber: null,
    member: {},
    house: {},
  };
  componentDidMount() {}
  componentDidUpdate(prevprops) {
    if (this.props.open && this.props.args !== prevprops.args) {
      const { args, functions } = this.props;
      console.log("args", args, functions);
      axios
        .post("/api/analysis/generic/family", {
          function: functions,
          args,
        })
        .then((res) => {
          this.setState({ families: res.data });
        });
    }
  }

  mapReligion = (type) => {
    if (type === "hindu") return "हिन्दु";
    if (type === "buddhist") return "बौद्ध";
    if (type === "muslim") return "इस्लाम (मुस्लिम)";
    if (type === "christian") return "इसाई (क्रिश्चियन)";
    if (type === "kirat") return "किंरात";
    if (type === "other_religion") return "अन्य";
  };

  mapGender = (gender) => (gender === "male" ? "पुरुष" : "महिला");

  getToleName = (value) => {
    var tole = allToleName.filter((each) => each.value === value);

    return tole && tole[0] ? tole[0].nepali : "अन्य";
  };

  onNameClick = (event) => {
    const id = event.target.id;
    console.log("memberId", id);
    this.setState({ familyMemberOpen: true }, () => {
      this.props.getFamilyById(id);
    });
  };

  onHouseNumberClick = (event) => {
    const houseNumber = event.target.id;
    this.setState({ houseOpen: true, houseNumber }, () => {
      this.props.getHouseByHouseNumber(houseNumber);
    });
  };

  handleCloseMember = (name) => {
    this.setState({ [name]: false, member: {} });
  };

  handleCloseHouse = (name) => {
    this.setState({ [name]: false, house: {} });
  };

  render() {
    const { open, handleClose, classes } = this.props;

    return (
      <div>
        {this.state.familyMemberOpen && (
          <FullScreenDialog
            name="familyMemberOpen"
            open={this.state.familyMemberOpen}
            title={`Member detail `}
            handleClose={this.handleCloseMember}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              {this.props.member && (
                <Grid item xs={9} className={classes.memberDialog}>
                  <EachMember member={this.props.member} />
                </Grid>
              )}
            </Grid>
          </FullScreenDialog>
        )}

        {this.state.houseOpen && (
          <FullScreenDialog
            name="houseOpen"
            open={this.state.houseOpen}
            title={`House Detail `}
            handleClose={this.handleCloseHouse}
          >
            <Grid container spacing={3}>
              {this.props.load ? (
                <Grid
                  style={{ marginTop: "40vh" }}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <ClipLoader
                      sizeUnit={"px"}
                      size={84}
                      color={"#00ACC1"}
                      loading={this.props.load}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                >
                  {this.props.house && (
                    <Grid item xs={9}>
                      <GetHouseDetail
                        houseNumber={this.props.houseNumber}
                        house={this.props.house}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </FullScreenDialog>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">Family Detail</DialogTitle>
          <DialogContent dividers={true}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S.N</TableCell>
                  <TableCell>नाम</TableCell>
                  <TableCell>लिङ्ग</TableCell>
                  <TableCell>धर्म</TableCell>
                  <TableCell>घर नं</TableCell>
                  <TableCell>टोलको नाम </TableCell>
                  <TableCell>फोन नम्बर</TableCell>
                  <TableCell>वडा नं</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.state.families.map((each, i) => (
                  <TableRow>
                    <TableCell>{getNepaliNumber(i + 1)}</TableCell>
                    <TableCell>
                      <p
                        className={classes.button}
                        id={each._id}
                        onClick={this.onNameClick}
                      >
                        {each.fullName}
                      </p>
                    </TableCell>
                    <TableCell>{this.mapGender(each.gender)}</TableCell>
                    <TableCell>{this.mapReligion(each.religion)}</TableCell>
                    <TableCell>
                      <p
                        className={classes.button}
                        id={
                          each.house ? each.house.houseNumber : each.houseNumber
                        }
                        onClick={this.onHouseNumberClick}
                      >
                        {each.house
                          ? getNepaliNumber(each.house.houseNumber)
                          : getNepaliNumber(each.houseNumber)}
                      </p>
                    </TableCell>
                    <TableCell>
                      {each.house
                        ? this.getToleName(each.house.toleName)
                        : this.getToleName(each.toleName)}
                    </TableCell>
                    <TableCell>{getNepaliNumber(each.contactNumber)}</TableCell>
                    <TableCell>
                      {each.house
                        ? getNepaliNumber(each.house.wardNumber)
                        : getNepaliNumber(each.wardNumber)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  button: {
    cursor: "pointer",
    color: "#2196f3",
  },
  memberDialog: {
    marginTop: theme.spacing.unit * 3,
  },
  lengthFamily: {
    color: "white",
    fontSize: "1rem",
  },
});

const mapStateToProps = ({ search: { member, house, load } }) => ({
  member,
  house,
  load,
});
export default withStyles(styles)(
  connect(mapStateToProps, {
    getFamilyById,
    getHouseByHouseNumber,
    clearHouseDetail,
  })(ScrollDialog)
);
