import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import NoSsr from "@material-ui/core/NoSsr";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import {
	autoSuggetionString,
	searchOption,
	placeholderName,
	rangeValue,
	booleanAttribute,
	isBoolean,
	HouseSearchIndex,
	houseNeutralSearch,
} from "../../../../../variable/advanceSearch";
import { selectedLanguage, gaupalika } from "../../../../../variable/global";
import isEmpty from "../../../../../utils/validation/is-empty";
import getNepaliNumber from "../../../../../utils/getNepaliNumber";
class IntegrationReactSelect extends React.Component {
	state = {
		multi: null,
		genderShow: false,
		genderValue: "male",
		casteShow: false,
		casteValue: "jajati_aadiwas",
	};

	NoOptionsMessage = (props) => {
		return (
			<Typography
				color="textSecondary"
				className={props.selectProps.classes.noOptionsMessage}
				{...props.innerProps}
			>
				{props.children}
			</Typography>
		);
	};

	inputComponent = ({ inputRef, ...props }) => {
		return <div ref={inputRef} {...props} />;
	};

	Control = (props) => {
		return (
			<TextField
				fullWidth
				InputProps={{
					inputComponent: this.inputComponent,
					inputProps: {
						className: props.selectProps.classes.input,
						inputRef: props.innerRef,
						children: props.children,
						...props.innerProps,
					},
				}}
				{...props.selectProps.textFieldProps}
			/>
		);
	};

	Option = (props) => {
		return (
			<MenuItem
				buttonRef={props.innerRef}
				selected={props.isFocused}
				component="div"
				style={{
					fontWeight: props.isSelected ? 500 : 400,
				}}
				{...props.innerProps}
			>
				{props.children}
			</MenuItem>
		);
	};

	Placeholder = (props) => {
		return (
			<Typography
				color="textSecondary"
				className={props.selectProps.classes.placeholder}
				{...props.innerProps}
			>
				{props.children}
			</Typography>
		);
	};

	ValueContainer = (props) => {
		return (
			<div className={props.selectProps.classes.valueContainer}>
				{props.children}
			</div>
		);
	};

	onChipClicked = (props) => {
		this.props.onOptionSelected(props);
	};

	getArrayValueAsComma = (state, matchValue) => {
		return searchOption[matchValue]
			.filter((each) => state[matchValue].includes(each.value))
			.map((each) => each[selectedLanguage])
			.join(", ");
	};

	getArrayValueAsDash = (state, matchValue) => {
		return state[matchValue].join("-");
	};

	getBooleanValue = (state, matchValue) =>
		state[matchValue] == booleanAttribute[matchValue] ? "छ" : "छैन";

	MultiValue = (props) => {
		const state = this.props.state;
		const matchLabel = props.children;
		var matchObj =
			autoSuggetionString[
				autoSuggetionString.findIndex((x) => x[selectedLanguage] === matchLabel)
			];
		const matchValue = matchObj.value;

		var label = "";
		if (isEmpty(state[matchValue])) label = matchLabel;
		else if (Array.isArray(state[matchValue])) {
			if (rangeValue.includes(matchValue))
				label = `${matchLabel} : ${this.getArrayValueAsDash(
					state,
					matchValue
				)}`;
			else
				label = `${matchLabel} : ${this.getArrayValueAsComma(
					state,
					matchValue
				)}`;
		} else if (isBoolean.includes(matchValue))
			label = `${matchLabel} : ${this.getBooleanValue(state, matchValue)}`;
		else label = `${matchLabel} : ${matchValue} `;

		return (
			<Chip
				onClick={() => {
					this.onChipClicked(props.children);
				}}
				tabIndex={-1}
				label={label}
				className={classNames(props.selectProps.classes.chip, {
					[props.selectProps.classes.chipFocused]: props.isFocused,
				})}
				onDelete={() => {
					props.removeProps.onClick();
					this.props.clearOnCloseTag(matchValue);
				}}
				deleteIcon={<CancelIcon {...props.removeProps} />}
			/>
		);
	};

	Menu = (props) => {
		return (
			<Paper
				square
				className={classNames(
					props.selectProps.classes.paper,
					this.props.classes.upper
				)}
				{...props.innerProps}
			>
				{props.children}
			</Paper>
		);
	};

	handleChange = (value) => {
		this.props.handleSelectChange(value);
	};

	onHandleChange = (event) => {
		this.setState({ value: event.target.value });
	};

	filterFunctionHouse = {
		true: (suggestion) =>
			HouseSearchIndex.includes(suggestion.value) ||
			houseNeutralSearch.includes(suggestion.value),
		false: (suggestion) => !HouseSearchIndex.includes(suggestion.value),
	};

	getSuggestionsList = (selectedDetailOption) =>
		autoSuggetionString.filter(
			this.filterFunctionHouse[selectedDetailOption === "house"]
		);
	render() {
		const {
			classes,
			theme,
			state,
			selection,
			selectedDetailOption,
		} = this.props;
		const { genderShow, casteShow } = this.state;
		const suggestions = this.getSuggestionsList(selectedDetailOption).map(
			(suggestion) => ({
				value: suggestion.value,
				label: suggestion[selectedLanguage],
			})
		);

		const components = {
			Control: this.Control,
			Menu: this.Menu,
			MultiValue: this.MultiValue,
			NoOptionsMessage: this.NoOptionsMessage,
			Option: this.Option,
			Placeholder: this.Placeholder,
			ValueContainer: this.ValueContainer,
		};

		const selectStyles = {
			input: (base) => ({
				...base,
				color: theme.palette.text.primary,
				"& input": {
					font: "inherit",
				},
			}),
		};

		return (
			<div className={classes.root}>
				<NoSsr>
					<Select
						classes={classes}
						styles={selectStyles}
						textFieldProps={{
							label: "",
							InputLabelProps: {
								shrink: true,
							},
						}}
						options={suggestions}
						components={components}
						value={selection}
						onChange={this.handleChange}
						placeholder={
							gaupalika[selectedLanguage] + placeholderName[selectedLanguage]
						}
						isMulti
					/>
				</NoSsr>
			</div>
		);
	}
}

IntegrationReactSelect.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		height: "auto",
	},
	input: {
		display: "flex",
		padding: 0,
	},
	valueContainer: {
		display: "flex",
		flexWrap: "wrap",
		flex: 1,
		alignItems: "center",
		overflow: "hidden",
	},
	chip: {
		margin: `${(theme.spacing.unit * 1) / 2}px ${
			(theme.spacing.unit * 1) / 4
		}px`,
	},
	chipFocused: {
		backgroundColor: emphasize(
			theme.palette.type === "light"
				? theme.palette.grey[300]
				: theme.palette.grey[700],
			0.08
		),
	},
	noOptionsMessage: {
		padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 2}px`,
	},
	singleValue: {
		fontSize: 16,
	},
	placeholder: {
		position: "absolute",
		left: 2,
		fontSize: 16,
	},
	paper: {
		position: "absolute",
		zIndex: 1,
		marginTop: theme.spacing.unit * 1,
		left: 0,
		right: 0,
	},
	divider: {
		height: theme.spacing.unit * 2,
	},
	formControl: {
		margin: theme.spacing.unit * 3,
	},
	group: {
		margin: `${theme.spacing.unit * 1}px 0`,
	},
	upper: {
		"z-index": 3,
	},
});

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
