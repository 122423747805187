import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  familyInLocalOrganQuestion,
  familyInvolvementLocalOrgan,
  familyLocalGovInvolve,
  familyLocalGovInvolveQuestion,
  foodAlternate,
  foodAlternateQuestion,
  foodForMonth,
  foodForMonthQuestion,
  mainIncomeSource,
  mainIncomeSourceQuestion,
  totalExpenditure,
  totalIncome,
  loanTakenInFamilyQuestion,
  loanTakenInFamily,
  loanQuestion,
  loan,
  bankrate,
  personrate,
  sahakarirate,
  laghubittrate,
  farmerrate,
  femalerate,
  otherrate,
  loanTakingReasonQuestion,
  loanTakingReason,
  dhukutiKarobar,
  dhukutiKarobarCount,
  dhukutiKarobarYesNo,
  cashLoanGivenQuestion,
  cashLoanGiven,
  cashLoanRate,
  skillTrainingQuestion,
  skillTraining,
  skillDetailQuestion,
  skillDetail
} from "../../../../variable/houseSurvey";
import BoxBorder from "../../../common/frequent/BoxBorder";
import MultipleSelect from "../../../common/frequent/MultipleSelect";
import RadioButton from "../../../common/frequent/RadioButton";
import TextField from "../../../common/frequent/TextField";
import Selection from "../../../common/frequent/Selection";
import CheckBox from "../../../common/frequent/CheckBox";
import { Typography } from "@material-ui/core/es";
class Economics extends Component {
  handleChange = (name, value) => {
    this.props.handleChange(`economics.${name}`, value);
  };
  render() {
    const { economics } = this.props;
    const errors = this.props.errors && this.props.errors.errors;
    return (
      //आर्थिक क्षेत्र् जानकारी
      <Grid container direction="column">
        {/* <Grid container>
					<Selection
						error={errors && errors.mainIncomeSource}
						question={mainIncomeSourceQuestion}
						name="mainIncomeSource"
						selectList={mainIncomeSource}
						value={economics.mainIncomeSource}
						isVisible
						handleChange={this.handleChange}
						other={economics.mainIncomeSourceOther}
					/>
				</Grid> */}
        {/* <Grid container>
          <MultipleSelect
            error={errors && errors.mainIncomeSource}
            question={mainIncomeSourceQuestion}
            name="mainIncomeSource"
            value={economics.mainIncomeSource}
            tagLists={mainIncomeSource}
            isVisible
            handleChange={this.handleChange}
            other={economics.mainIncomeSourceOther}
          />
        </Grid> */}
        <Grid container>
          <TextField
            question={totalIncome}
            name="totalIncome"
            type="number"
            placeholder="वार्षिक आम्दानी"
            value={economics.totalIncome}
            isVisible
            handleChange={this.handleChange}
          />
        </Grid>
        <Grid container>
          <TextField
            question={totalExpenditure}
            name="totalExpenditure"
            type="number"
            placeholder="वार्षिक खर्च"
            value={economics.totalExpenditure}
            isVisible
            handleChange={this.handleChange}
          />
        </Grid>
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={foodForMonthQuestion}
            name="foodForMonth"
            radioLists={foodForMonth}
            value={economics.foodForMonth}
            other={economics.foodForMonthOther}
          />
        </Grid>
        {/* <Grid container>
					<MultipleSelect
						error={errors && errors.foodAlternate}
						question={foodAlternateQuestion}
						name="foodAlternate"
						value={economics.foodAlternate}
						tagLists={foodAlternate}
						isVisible={
							economics.foodForMonth === "3_month" ||
							economics.foodForMonth === "4_6_month" ||
							economics.foodForMonth === "7_9_month"
						}
						handleChange={this.handleChange}
						other={economics.foodAlternateOther}
					/>
				</Grid> */}
        {/* ends आर्थिक क्षेत्र् जानकारी */}

        {/* ऋण सम्बन्धि */}
        {/* ends ऋण सम्बन्धि */}

        {/* सामाजिक संघ संस्थामा संग्लगनता तथा सिप तालिम */}

        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={familyInLocalOrganQuestion}
            name="familyInvolvementLocalOrgan"
            radioLists={familyInvolvementLocalOrgan}
            value={economics.familyInvolvementLocalOrgan}
          />
        </Grid>
        <Grid container>
          <MultipleSelect
            question={familyLocalGovInvolveQuestion}
            name="familyLocalGovInvolve"
            value={economics.familyLocalGovInvolve}
            tagLists={familyLocalGovInvolve}
            isVisible={economics.familyInvolvementLocalOrgan === "local_yes"}
            handleChange={this.handleChange}
            other={economics.familyLocalGovInvolveOther}
          />
        </Grid>
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={loanTakenInFamilyQuestion}
            name="loanTakenInFamily"
            radioLists={loanTakenInFamily}
            value={economics.loanTakenInFamily}
          />
        </Grid>
        <BoxBorder
          isVisible={
            economics.loanTakenInFamily &&
            economics.loanTakenInFamily.includes("loan_yes")
          }
        >
          <Grid container>
            <CheckBox
              checkList={loan}
              value={economics.loan}
              name="loan"
              isVisible={economics.loanTakenInFamily === "loan_yes"}
              handleCompChange={this.handleChange}
              other={economics.loanOther}
            />
          </Grid>
        </BoxBorder>
        <Grid container>
          <MultipleSelect
            question={loanTakingReasonQuestion}
            name="loanTakingReason"
            value={economics.loanTakingReason}
            tagLists={loanTakingReason}
            isVisible={economics.loanTakenInFamily === "loan_yes"}
            handleChange={this.handleChange}
            other={economics.loanTakingReasonOther}
          />
        </Grid>

        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={dhukutiKarobar}
            name="dhukutiKarobar"
            radioLists={dhukutiKarobarYesNo}
            value={economics.dhukutiKarobar}
          />
        </Grid>

        <BoxBorder
          isVisible={
            economics.dhukutiKarobar && economics.dhukutiKarobar === "yes"
          }
        >
          <Grid container>
            <TextField
              question={dhukutiKarobarCount}
              name="economics.dhukutiAmount"
              type="number"
              placeholder="यदि छ भने कति ?"
              value={economics.dhukutiAmount}
              isVisible
              handleChange={this.handleChange}
            />
          </Grid>
        </BoxBorder>
        {/* <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={cashLoanGivenQuestion}
            name="cashLoanGiven"
            radioLists={cashLoanGiven}
            value={economics.cashLoanGiven}
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            name="cashLoanRate"
            type="number"
            placeholder="छ भने कति प्रतिशत (%) व्याज दरमा दिनु भएको छ ?"
            value={economics.cashLoanRate}
            isVisible={economics.cashLoanGiven == "cash_loan_yes"}
            handleChange={this.handleChange}
          />
        </Grid> */}
      </Grid>
    );
  }
}
const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 3
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
    display: "flex"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing.unit / 4
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3
  },
  flexColumn: {
    flexDirection: "column"
  }
});
Economics.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Economics);
