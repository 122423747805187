import React, { Component } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import withStyles from "@material-ui/core/styles/withStyles";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "../../common/frequent/print/ReactToPrint";
// import getNepaliNumber from "get-nepali-number";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import FamilyScroll from "../../common/frequent/dialog/FamilyScroll";
import "./style.css";

class reportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beforePrint: false,
      component: null,
      argumentTobePassed: [],
      open: false,
    };
  }

  handleAfterPrint = () => {
    this.setState({ beforePrint: false });
  };

  handleBeforePrint = () => {
    this.setState({ beforePrint: true });
  };

  getContent = () => {
    return this.setState(
      { beforePrint: true, component: this.componentRef },
      () => {
        return this.state.component;
      }
    );
  };

  handleOpen = (argumentTobePassed) => (event) => {
    this.setState({
      argumentTobePassed,
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      argumentTobePassed: [],
      open: false,
    });
  };

  render() {
    const { classes, data } = this.props;
    const { male, female, single, third, reportTitle, functions } = data;
    const style =
      functions !== "null"
        ? { color: "blue", textDecoration: "underline" }
        : {};
    return (
      <Grid>
        <Grid container justify="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6" className={classes.cardTitleWhite}>
              {data.title}
            </Typography>
          </Grid>

          {functions !== "null" && (
            <FamilyScroll
              handleClose={this.handleClose}
              open={this.state.open}
              args={this.state.argumentTobePassed}
              functions={functions}
            />
          )}

          <Grid item>
            {
              <ReactToPrint
                style={{ marginTop: 200 }}
                onBeforePrint={this.handleBeforePrint}
                onAfterPrint={this.handleAfterPrint}
                trigger={() => (
                  <Fab
                    variant="extended"
                    aria-label="Delete"
                    className={classes.fab}
                  >
                    <PrintIcon className={classes.printIcon} />
                  </Fab>
                )}
                data={this.state.component}
                content={this.getContent}
              />
            }
          </Grid>
        </Grid>
        <Grid ref={(el) => (this.componentRef = el)}>
          <Paper className={classes.root}>
            <Table>
              <TableHead>
                <TableRow>
                  {data.reportTitle.map((header) => (
                    <TableCell>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {male &&
                  male.map((m, id) => (
                    <TableRow key={id}>
                      <TableCell component="th" scope="row">
                        {m.label}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={style}
                        onClick={
                          functions !== "null" &&
                          this.handleOpen(female[id].args)
                        }
                      >
                        {getNepaliNumber(female[id].value)}
                      </TableCell>
                      <TableCell
                        style={style}
                        align="left"
                        onClick={
                          functions !== "null" && this.handleOpen(male[id].args)
                        }
                      >
                        {getNepaliNumber(m.value)}
                      </TableCell>
                      <TableCell
                        style={style}
                        align="left"
                        onClick={
                          functions !== "null" &&
                          this.handleOpen(third[id].args)
                        }
                      >
                        {getNepaliNumber(third[id].value)}
                      </TableCell>
                      <TableCell
                        style={style}
                        align="left"
                        onClick={
                          functions !== "null" && this.handleOpen(m.args)
                        }
                      >
                        {getNepaliNumber(single[id].value)}
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell colspan={1}>जम्मा</TableCell>
                  {male &&
                    [female, male, third].map((each) => (
                      <TableCell>
                        {getNepaliNumber(each.reduce((c, a) => c + a.value, 0))}
                      </TableCell>
                    ))}
                  <TableCell>
                    {getNepaliNumber(single.reduce((c, a) => c + a.value, 0))}
                  </TableCell>
                </TableRow>
                <TableRow />
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
});

export default withStyles(styles)(reportTable);
