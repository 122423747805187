import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
	childLaborQuestion,
	childLabour,
	childLabourQuestion,
	meansOfMunicipalInformationQn,
	meansOfMunicipalInformation,
	illTreament,
	illTreamentQuestion,
	isChildMarriageExistsQn,
	isChildMarriageExists,
	childMarriageExistsQn,
	childMarriageExists,
	controlChildMarriageQn,
	controlChildMarriageList,
	isRevolutionCaseQn,
	isRevolutionCase,
	protestTypeQn,
	protestType,
	whichSideForProtestQn,
	whichSideForProtest,
	effectFromProtestQn,
	effectFromProtest,
	effectFromCoronaQn,
	effectFromCorona,
} from "../../../../variable/houseSurvey";
import TextField from "../../../common/frequent/TextField";
import RadioButton from "../../../common/frequent/RadioButton";
import BoxBorder from "../../../common/frequent/BoxBorder";
import Selection from "../../../common/frequent/Selection";
import CheckBox from "../../../common/frequent/CheckBox";
import MultipleSelect from "../../../common/frequent/MultipleSelect";

class WomenChild extends Component {
	handleChange = (name, value) => {
		console.log("WC", name, value);
		this.props.handleChange(`womenChild.${name}`, value);
	};
	render() {
		const { womenChild } = this.props;
		const errors = this.props.errors && this.props.errors.errors;
		return (
			<Grid container>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={childLabourQuestion}
						name="childLabour"
						radioLists={childLabour}
						value={womenChild.childLabour}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={childLaborQuestion}
						name="childLabor"
						radioLists={childLabour}
						value={womenChild.childLabor}
					/>
				</Grid>{" "}
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={isChildMarriageExistsQn}
						name="childMarriageCustom"
						radioLists={isChildMarriageExists}
						value={womenChild.childMarriageCustom}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible={womenChild.childMarriageCustom === "yes"}
						question={childMarriageExistsQn}
						name="childMarriageReason"
						radioLists={childMarriageExists}
						value={womenChild.childMarriageReason}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={controlChildMarriageQn}
						name="childMarriageControl"
						radioLists={controlChildMarriageList}
						value={womenChild.childMarriageControl}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={isRevolutionCaseQn}
						name="isEffectFromProtest"
						radioLists={isRevolutionCase}
						value={womenChild.isEffectFromProtest}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible={womenChild.isEffectFromProtest === "yes"}
						question={protestTypeQn}
						name="protestType"
						radioLists={protestType}
						value={womenChild.protestType}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible={womenChild.protestType === "combaties"}
						question={whichSideForProtestQn}
						name="civilWarSide"
						radioLists={whichSideForProtest}
						value={womenChild.civilWarSide}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible={womenChild.isEffectFromProtest === "yes"}
						question={effectFromProtestQn}
						name="effectTypeFromProtest"
						radioLists={effectFromProtest}
						value={womenChild.effectTypeFromProtest}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={effectFromCoronaQn}
						name="effectFromPandemic"
						radioLists={effectFromCorona}
						value={womenChild.effectFromPandemic}
					/>
				</Grid>
				<Grid container>
					<Selection
						question={illTreamentQuestion}
						name="illTreament"
						selectList={illTreament}
						value={womenChild.illTreament}
						isVisible
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid container>
					<MultipleSelect
						question={meansOfMunicipalInformationQn}
						name="meansOfMunicipalInformation"
						value={womenChild.meansOfMunicipalInformation}
						tagLists={meansOfMunicipalInformation}
						isVisible
						handleChange={this.handleChange}
					/>
				</Grid>
			</Grid>
		);
	}
}
WomenChild.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default WomenChild;
