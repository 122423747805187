import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import { no, selectedLanguage, yes } from "../../../../../variable/global";
import {
	aandolanHeader,
	aandolanYesNo,
	aandolanEffect,
	combatSide,
	painFromAandolan,
} from "../../../../../variable/houseSurvey";
import {
	QuestionAnswer,
	Title,
} from "../../../../common/frequent/QuestionAnswer";

const NoImageFound = require("../../../../../image/NoImageFound.jpg");

function mapValue(value, obj) {
	return obj[value];
}

class Aandolan extends Component {
	getYesNo = (data) => (data ? yes[selectedLanguage] : no[selectedLanguage]);
	render() {
		const { classes, houseNumber, janaAandolan } = this.props;
		return (
			<Grid item container direction="column" className={classes.container}>
				<Title title={aandolanHeader} />
				<Grid item container direction="column">
					{/* first */}
					<Grid
						item
						container
						direction="row"
						className={classes.eachQnAns}
						spacing={24}
					>
						<Grid item xs={4}>
							<QuestionAnswer
								question={aandolanYesNo[selectedLanguage]}
								answer={this.getYesNo(janaAandolan.isEffectFromProtest)}
							/>
						</Grid>
						{janaAandolan.isEffectFromProtest && (
							<div>
								<Grid item xs={4}>
									<QuestionAnswer
										question={aandolanEffect[selectedLanguage]}
										answer={mapValue(janaAandolan.protestType, {
											janaandolan: "जनआन्दोलन",
											combaties: "ससत्र जनयुद्द",
											both: "दुवै",
										})}
									/>
								</Grid>
								<Grid item xs={4}>
									<QuestionAnswer
										question={painFromAandolan[selectedLanguage]}
										answer={mapValue(janaAandolan.effectTypeFromProtest, {
											death: "मृत्यु",
											bepatta: "बेपत्ता",
											injured: "घाइते",
										})}
									/>
								</Grid>
								{janaAandolan.protestType === "combaties" && (
									<Grid item xs={4}>
										<QuestionAnswer
											question={combatSide[selectedLanguage]}
											answer={mapValue(janaAandolan.civilWarSide, {
												government: "राज्य",
												dissident: "तत्कालिन बिद्रोही पक्ष",
												both: "दुवै",
											})}
										/>
									</Grid>
								)}
							</div>
						)}
					</Grid>
					{/* end */}
				</Grid>
			</Grid>
		);
	}
}

const styles = (theme) => ({
	container: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 2,
	},
	table: {
		width: "auto",
	},
});

Aandolan.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Aandolan);
