import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AspectRatio from "react-aspect-ratio";
import ImageZoom from "react-medium-image-zoom";
import getNepalNumber from "../../../utils/getNepaliNumber";
import { no, selectedLanguage, yes } from "../../../variable/global";
import {
	age,
	marriageAge,
	education,
	name,
	gender,
	occupation,
	martialStatus,
	daughterTitle,
	martialType,
} from "../../../variable/marriedDaughterSurvey";
import { QuestionAnswer, Title } from "../../common/frequent/QuestionAnswer";

function mapValue(value, obj) {
	return obj[value];
}

class DaughterInfo extends Component {
	state = {
		link: "old",
	};
	getYesNo = (data) => (data ? yes[selectedLanguage] : no[selectedLanguage]);

	getNepaliName = (fieldName, value) => {
		switch (fieldName) {
			case "gender":
				if (value === "male") {
					return "पुरुष";
				} else {
					return "महिला";
				}
			case "marriageType":
				if (value === "arrange") {
					return "मागी बिवाह";
				} else if (value === "elloped") {
					return "भागि बिवाह";
				} else if (value === "love_marriage") {
					return "प्रेम बिवाह";
				} else {
					return "अन्य";
				}
			case "martialStatus":
				if (value === "Marriage") {
					return "विवाहित";
				} else if (value === "seperate") {
					return "छुट्टिएको";
				} else if (value === "widow") {
					return "विधुर /विधुवा";
				} else if (value === "single") {
					return "एकल";
				} else {
					return "पारपाचुके भएको";
				}
			case "education":
				if (value === "litrate") {
					return "साक्षर";
				} else if (value === "uneducate") {
					return "निरक्षर";
				} else if (value === "primary_education") {
					return "वाल विकास";
				} else if (value === "basic") {
					return "आधारभूत";
				} else if (value === "secondary") {
					return "माध्यमिक";
				} else if (value === "graduate") {
					return "स्नातक";
				} else {
					return "स्नातक माथी";
				}
			case "occupation":
				if (value === "agriculture") {
					return "कृषि तथा पशुपालन";
				} else if (value === "housewife") {
					return "गृहणी";
				} else if (value === "business") {
					return "ब्यापार व्यावसाय";
				} else if (value === "employee") {
					return "नकारी जागिर";
				} else if (value === "unemployee") {
					return "बेरोजगार";
				} else {
					return "अन्य";
				}
			default:
				break;
		}
	};

	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props.daughterDetails !== prevProps.daughterDetails) {
			this.setState({ link: "updated" });
		}
	}

	render() {
		const { classes, daughter, data, daughterDetails } = this.props;
		const { link } = this.state;
		return (
			<Grid item container direction="column" className={classes.container}>
				<Title title={daughterTitle} />

				<Grid item container direction="row">
					<Grid item container xs={12}>
						<Grid item container direction="row" className={classes.eachQnAns}>
							<Grid item xs={6}>
								<QuestionAnswer
									question={name[selectedLanguage]}
									answer={data && data.fullName && data.fullName}
								/>
							</Grid>
							<Grid item xs={6}>
								<QuestionAnswer
									question={gender[selectedLanguage]}
									answer={
										data &&
										data.gender &&
										this.getNepaliName("gender", data.gender)
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<QuestionAnswer
									question={age[selectedLanguage]}
									answer={data && data.age && getNepalNumber(data.age)}
								/>
							</Grid>
							<Grid item xs={6}>
								<QuestionAnswer
									question={education[selectedLanguage]}
									answer={
										data &&
										data.education &&
										this.getNepaliName("education", data.education)
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<QuestionAnswer
									question={martialStatus[selectedLanguage]}
									answer={
										data &&
										data.martialStatus &&
										this.getNepaliName("martialStatus", data.martialStatus)
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<QuestionAnswer
									question={marriageAge[selectedLanguage]}
									answer={
										data && data.marriageAge && getNepalNumber(data.marriageAge)
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<QuestionAnswer
									question={martialType[selectedLanguage]}
									answer={
										data &&
										data.marriageType &&
										this.getNepaliName("marriageType", data.marriageType)
									}
								/>
							</Grid>

							<Grid item xs={6}>
								<QuestionAnswer
									question={occupation[selectedLanguage]}
									answer={
										data &&
										data.occupation &&
										this.getNepaliName("occupation", data.occupation)
									}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

const styles = (theme) => ({
	container: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 2,
	},
	tableHeader: {
		fontSize: "1rem",
	},
	firstChildTD: {
		paddingLeft: 0,
	},
	lastChildTD: {
		paddingRight: 0,
	},
});

DaughterInfo.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DaughterInfo);
