import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { Tabs } from "antd";
import Axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { reportList } from "../../../variable/report";
import FamilyAnalysisTable from "./reportTable";
import ReportTable from "./table";
import EducationTable from "./educationTable";
import BirthCertificate from "./birthCertificate";
import FamilyLandConsumption from "./familyLandConsumption";
import SearchOption from "./SearchOption";
import FullScreenDialog from "../../common/frequent/dialog/FullScreen";
import ReactToPrint from "../../common/frequent/print/ReactToPrint";
import { Fab, Chip, Typography, Card } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import BarGraph from "./charts/ChartList";

const TabPane = Tabs.TabPane;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wardData: 0,
      analysisList: [],
      chart: [],
      chartData: {},
      barFull: false,
      childList: [],
      loading: false,
      beforePrint: false,
      component: null,
      tabValue: 0,
      chipIndexValue: 0,
      title: [],
      tableType: "",
    };
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  clearData = () => this.setState({ chart: [], loading: true });

  componentDidMount() {
    this.fetchReport();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.tabValue !== this.state.tabValue ||
      prevState.chipIndexValue !== this.state.chipIndexValue
    ) {
      this.fetchReport();
    }
  }

  onClick = (e) => {
    console.log("Content: ", e.currentTarget.dataset.id);
  };

  fetchReport(isLastElement) {
    var tabValue = this.state.tabValue;
    var ward = this.state.wardData;
    var chipIndexValue = this.state.chipIndexValue;
    const option = reportList[chipIndexValue].child[tabValue].value;
    // this.clearData();
    this.setState({
      loading: true,
    });
    let factor = reportList[this.state.chipIndexValue].child.find(
      (each) => each.value === option
    ).title;
    let table = reportList[this.state.chipIndexValue].child.find(
      (each) => each.value === option
    ).table;
    let apiURL = factor === "family" ? "family-detail" : "house-detail";
    Axios.get(`/api/analysis/${apiURL}/${option}`)
      .then((res) => {
        if (tabValue === this.state.tabValue) {
          // let { chart, analysisList } = this.state;

          let appendData = { ...res.data, table: factor };
          this.setState({
            chart: [appendData],
            loading: false,
            tableType: table,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        // dispatch({
        // 	type: GET_ERRORS,
        // 	payload: err.response.data
        // });
      });
  }

  buildChart = () => {
    let { chart } = this.state;
    console.log("afmily-cahrt", chart);
    // let chartData;
    if (chart && chart[0] && chart[0].ward && chart[0].ward.length > 0) {
      let selectedChart =
        chart &&
        chart[0].ward &&
        chart[0].ward.find((each) => parseInt(each.ward) === 0); //change this
      let chartHouseData = false;
      if (chart && selectedChart) {
        chartHouseData = {
          title: "Static",
          label: selectedChart.data.map((each) => each.label),
          data: selectedChart.data.map((each) => each.value),
        };
        console.log("hart-house", chartHouseData);
        return chartHouseData;
      }
    } else if (
      chart &&
      chart[0] &&
      chart[0].single &&
      chart[0].single.length > 0
    ) {
      console.log("in here family", chart[0].single);
      let chartFamilyData = {
        label: [],
        data: [],
      };
      chartFamilyData = {
        label: chart[0].single.map((each) => each.label),
        data: chart[0].single.map((each) => each.value),
      };
      console.log("hart-family", chartFamilyData);
      return chartFamilyData;
    }
  };

  getFullBarChart = () => {
    let chartData = this.buildChart();
    return (
      <FullScreenDialog
        name="barFull"
        open={this.state.barFull}
        title="बार ग्राफ"
        handleClose={this.handleClose}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          {chartData && (
            <Grid item xs={8}>
              <BarGraph noTitle chartData={chartData} />
            </Grid>
          )}
        </Grid>
      </FullScreenDialog>
    );
  };

  getBarGraph = () => {
    let chartData = this.buildChart();
    return (
      <div>
        <Grid item container>
          <BarGraph
            name="barFull"
            isFullScreen={true}
            handleClickOpen={this.handleClickOpen}
            chartData={chartData}
            label="बार ग्राफ"
          />
        </Grid>
      </div>
    );
  };

  handleClickOpen = (name) => {
    this.setState({ [name]: true });
  };

  handleClose = (name) => {
    this.setState({ [name]: false });
  };

  handleAfterPrint = () => {
    this.setState({ beforePrint: false });
  };

  handleBeforePrint = () => {
    this.setState({ beforePrint: true });
  };

  getContent = () => {
    return this.setState(
      { beforePrint: true, component: this.printRef },
      () => {
        return this.state.component;
      }
    );
  };

  handleClick = (value, index) => {
    this.setState({ childList: value, chipIndexValue: index, tabValue: 0 });
  };

  onTabChange = (active) => {
    console.log("active", active);
    // let chartData = this.buildChart();
    this.setState({ tabValue: active });
  };

  changeTable = (eachChart) => {
    if (this.state.tableType === "genderWise") {
      return (
        <FamilyAnalysisTable
          beforePrint={this.state.beforePrint}
          subtitle=""
          data={eachChart}
        />
      );
    } else if (this.state.tableType === "wardWise") {
      return (
        <ReportTable
          beforePrint={this.state.beforePrint}
          subtitle=""
          data={eachChart.result}
          columns={eachChart}
        />
      );
    } else if (this.state.tableType === "educationTable") {
      return (
        <EducationTable
          beforePrint={this.state.beforePrint}
          subtitle=""
          data={eachChart}
        />
      );
    } else if (this.state.tableType === "birthCertificate") {
      return (
        <BirthCertificate
          beforePrint={this.state.beforePrint}
          subtitle=""
          data={eachChart.result}
          columns={eachChart}
        />
      );
    } else if (this.state.tableType === "familyLandConsumption") {
      return (
        <FamilyLandConsumption
          beforePrint={this.state.beforePrint}
          subtitle=""
          data={eachChart.result}
          columns={eachChart}
        />
      );
    }
  };

  render() {
    const { classes } = this.props;
    const {
      chipIndexValue,
      loading,
      title,
      chart,
      tabValue,
      childList,
      tableType,
      chartData,
    } = this.state;

    return (
      <div>
        <Typography variant="h6">रिपोर्ट</Typography>

        <Card className={classes.card} fullwidth>
          <Grid container spacing={3}>
            {reportList.map((eachReport, i) => {
              return (
                <Grid item xs={2}>
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={eachReport.nepali}
                    onClick={() => this.handleClick(eachReport.child, i)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Card>
        {loading && (
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <ClipLoader
                sizeUnit={"px"}
                size={84}
                color={"#00ACC1"}
                loading={true}
              />
            </Grid>
          </Grid>
        )}
        <Card className={classes.card} fullwidth>
          <Tabs
            // defaultActiveKey="0"
            tabPosition={"left"}
            // style={{ display: "flex", width: "100%" }}
            onChange={this.onTabChange}
          >
            {reportList[chipIndexValue].child.map((each, key) => (
              <TabPane tab={each.nepali} key={key}>
                {chart.length !== 0 &&
                  chart.map((eachChart) => (
                    <div>
                      {this.getFullBarChart()}
                      {eachChart.chartAvailable && this.getBarGraph(eachChart)}
                      {this.changeTable(eachChart)}
                    </div>
                  ))}
              </TabPane>
            ))}
          </Tabs>
        </Card>
      </div>
    );
  }
}
const styles = (theme) => ({
  // card: {
  // 	display: "flex",
  // 	padding: theme.spacing.unit * 2,
  // 	marginBottom: theme.spacing.unit * 2,
  // },
  // button: {
  // 	margin: theme.spacing.unit,
  // },
  // ward: {
  // 	width: "80%",
  // 	marginTop: theme.spacing.unit * 2,
  // },
  // root: {
  // 	display: "flex",
  // 	flexWrap: "wrap",
  // },
  // formControl: {
  // 	margin: theme.spacing.unit,
  // 	width: "80%",
  // 	// minWidth: 120,
  // 	// maxWidth: 300
  // },
  // chips: {
  // 	display: "flex",
  // 	flexWrap: "wrap",
  // },
  // chip: {
  // 	margin: theme.spacing.unit / 4,
  // },
  // noLabel: {
  // 	marginTop: theme.spacing.unit * 3,
  // },
  // flexColumn: {
  // 	flexDirection: "column",
  // },
});

SearchOption.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ analysis: { loading, chart } }) => ({
  chart,
  loading,
});

// export default withStyles(styles)(
// 	connect(mapStateToProps, {
// 		getAnalaysisFamilyDetailChartData,
// 		getAnalaysisHouseReportDetailChartData,
// 	})(index)
// );

export default withStyles(styles)(index);
