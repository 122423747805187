import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@material-ui/core";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import getNepaliNumber from "../../../../../utils/getNepaliNumber";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import {
	clearHouseDetail,
	getFamilyById,
	getHouseByHouseId,
} from "../../../../../actions/searchAction";
import { selectedLanguage } from "../../../../../variable/global";
// import Table from "../../../../common/Table/Table.jsx";
import Card from "../../../../common/Card/Card.jsx";
import CardBody from "../../../../common/Card/CardBody.jsx";
import CardHeader from "../../../../common/Card/CardHeader.jsx";
import FullScreenDialog from "../../../../common/frequent/dialog/FullScreen";
import EachMember from "../../../../common/frequent/FamilyMember";
import GridContainer from "../../../../common/Grid/GridContainer.jsx";
import GridItem from "../../../../common/Grid/GridItem.jsx";
import GetHouseDetail from "../houseNumber/GetHouseDetail";
import TablePaginationActionsWrapped from "./TablePaginationActions";
import { Empty } from "antd";

class NamePagination extends Component {
	constructor(props) {
		super(props);
		props.clearHouseDetail();
	}
	state = {
		familyMemberOpen: false,
		member: {},
		houseOpen: false,
		house: {},
		houseNumber: null,
	};
	getGender = (gender) => {
		if (gender === "female") return "महिला";
		else if (gender === "male") return "पुरुष";
		else return "तेश्रो लिंगी";
	};

	onNameClick = (event) => {
		const id = event.target.id;
		this.setState({ familyMemberOpen: true }, () => {
			this.props.getFamilyById(id);
		});
	};

	onHouseNumberClick = (event) => {
		const houseNumber = event.target.id;
		this.setState({ houseOpen: true, houseNumber }, () => {
			this.props.getHouseByHouseId(houseNumber);
		});
	};

	componentDidUpdate(prevProps) {
		if (prevProps.member !== this.props.member) {
			this.setState({
				member: this.props.member,
			});
		}

		if (prevProps.house !== this.props.house) {
			this.setState({
				house: this.props.house,
			});
		}
	}

	handleCloseMember = (name) => {
		this.setState({ [name]: false, member: {} });
	};

	handleCloseHouse = (name) => {
		this.setState({ [name]: false, house: {} });
	};

	mapRelation = (relation) => {
		if (relation === "self_house_own") return "घरमुली आफै";
		if (relation === "husband") return "श्रीमान";
		if (relation === "wife") return "श्रीमती";
		if (relation === "mother") return "आमा";
		if (relation === "father") return "बुवा";
		if (relation === "son") return "छोरा";
		if (relation === "daughter") return "छोरी";
		if (relation === "buhari") return "बुहारी";
		if (relation === "sashu") return "सासु";
		if (relation === "sasura") return "ससुरा";
		if (relation === "kaka") return "काका";
		if (relation === "kaki") return "काकी";
		if (relation === "fupu") return "फुपू";
		if (relation === "fupaju") return "फुपाजु";
		if (relation === "mama") return "मामा";
		if (relation === "maiju") return "माइजु";
		if (relation === "nati") return "नाती";
		if (relation === "natini") return "नातिनी";
		if (relation === "daju") return "दाजु";
		if (relation === "bhai") return "भाई";
		if (relation === "didi") return "दिदि";
		if (relation === "bahini") return "बहिनि";
		if (relation === "bhauju") return "भाउजु";
		if (relation === "bhai_buhari") return "भाई बुहारी";
		if (relation === "jethan") return "जेठान";
		if (relation === "thulo_bbuwa") return "ठुलो बुवा";
		if (relation === "thulo_aama") return "ठुलो आमा";
		if (relation === "grand_father") return "हजुर बुवा";
		if (relation === "grand_mother") return "हजुर आमा";
		if (relation === "other") return "अन्य";
	};

	render() {
		const {
			classes,
			title,
			subtitle,
			tableHeader,
			rows,
			rowsPerPage,
			page,
			handleChangePage,
			handleChangeRowsPerPage,
		} = this.props;

		// const count = this.getTotalCount(rows);

		const emptyRows =
			rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader color="primary">
							<Typography variant="h6" className={classes.cardTitleWhite}>
								{title}
							</Typography>
							<Typography variant="body2" className={classes.cardCategoryWhite}>
								{subtitle}
							</Typography>
						</CardHeader>
						{this.state.member &&
							this.state.member.detail &&
							this.state.familyMemberOpen && (
								<FullScreenDialog
									name="familyMemberOpen"
									open={this.state.familyMemberOpen}
									title={`Member detail `}
									handleClose={this.handleCloseMember}
								>
									<Grid
										container
										direction="row"
										justify="center"
										alignItems="flex-start"
									>
										{this.state.member && (
											<Grid item xs={9} className={classes.memberDialog}>
												<EachMember member={this.state.member} />
											</Grid>
										)}
									</Grid>
								</FullScreenDialog>
							)}

						{this.state.houseOpen && (
							<FullScreenDialog
								name="houseOpen"
								open={this.state.houseOpen}
								title={`House Detail `}
								handleClose={this.handleCloseHouse}
							>
								<Grid container spacing={24}>
									{this.props.load ? (
										<Grid
											style={{ marginTop: "40vh" }}
											container
											direction="row"
											justify="center"
											alignItems="center"
										>
											<Grid item>
												<ClipLoader
													sizeUnit={"px"}
													size={84}
													color={"#00ACC1"}
													loading={this.props.load}
												/>
											</Grid>
										</Grid>
									) : (
										<Grid
											container
											direction="row"
											justify="center"
											alignItems="flex-start"
										>
											{this.state.house && (
												<Grid item xs={9}>
													<GetHouseDetail
														hideImage={true}
														houseNumber={
															this.state.house &&
															this.state.house.houseDetails &&
															this.state.house.houseDetails.houseNumber
														}
														house={this.state.house}
													/>
												</Grid>
											)}
										</Grid>
									)}
								</Grid>
							</FullScreenDialog>
						)}
						<CardBody>
							{/* Table here */}
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										{tableHeader.map((header) => (
											<TableCell>{header[selectedLanguage]}</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.length !== 0 ? (
										rows
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((row, id) => (
												<TableRow key={id}>
													<TableCell component="th" scope="row">
														{getNepaliNumber(page * rowsPerPage + (id + 1))}
													</TableCell>
													<TableCell align="left">
														{getNepaliNumber(row.house.wardNumber)}
													</TableCell>
													<TableCell align="left">
														<p
															className={classes.button}
															id={row.house._id}
															onClick={this.onHouseNumberClick}
														>
															{getNepaliNumber(row.house.houseNumber)}
														</p>
													</TableCell>
													<TableCell align="left">
														<p
															className={classes.button}
															id={row._id}
															onClick={this.onNameClick}
														>
															{row.fullName}
														</p>
													</TableCell>
													<TableCell align="left">
														{this.getGender(row.gender)}
													</TableCell>
													<TableCell align="left">
														{getNepaliNumber(row.age)}
													</TableCell>
													<TableCell align="left">
														{this.mapRelation(row.answererRelation)}
													</TableCell>
													<TableCell align="left">
														{getNepaliNumber(row.contactNumber)}
													</TableCell>
												</TableRow>
											))
									) : (
										<Grid
											container
											direction="row"
											justify="center"
											alignItems="center"
										>
											<Grid item>
												<Empty />
											</Grid>
										</Grid>
									)}
									<TableRow />
								</TableBody>
								<TableFooter>
									<TableRow>
										<TablePagination
											rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
											colSpan={7}
											count={rows.length}
											rowsPerPage={rowsPerPage}
											page={page}
											SelectProps={{
												native: true,
											}}
											onChangePage={handleChangePage}
											onChangeRowsPerPage={handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActionsWrapped}
										/>
									</TableRow>
								</TableFooter>
							</Table>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const styles = (theme) => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0",
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF",
		},
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1",
		},
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3,
	},
	table: {
		minWidth: 500,
	},
	tableWrapper: {
		overflowX: "auto",
	},
	button: {
		cursor: "pointer",
		color: "#2196f3",
	},
	memberDialog: {
		marginTop: theme.spacing.unit * 5,
	},
});
NamePagination.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ search: { member, house, load } }) => ({
	member,
	house,
	load,
});
export default withStyles(styles)(
	connect(mapStateToProps, {
		getFamilyById,
		getHouseByHouseId,
		clearHouseDetail,
	})(NamePagination)
);
