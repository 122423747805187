import React, { useEffect, useState } from "react";
import axios from "axios";
import { message, Spin } from "antd";
import GetHouseDetail from "./GetHouseDetail";
import ClipLoader from "react-spinners/ClipLoader";
import { Grid } from "@material-ui/core";

export default function HouseDetail({ houseId, houseNumber }) {
	const [houseDetail, setHouseDetail] = useState(null);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		axios
			.get(`/api/searchhouseById/housesearchId/${houseId}`)
			.then((res) => {
				setHouseDetail(res.data);
			})
			.catch((err) => {
				message.error("Error On fetching house detail.");
			})
			.finally(() => {
				setLoading(false);
			});
	}, [houseId]);
	console.log(loading, "house survey data", houseDetail);
	return loading ? (
		<Grid
			style={{ marginTop: "40vh" }}
			container
			direction="row"
			justify="center"
			alignItems="center"
		>
			<Grid item>
				<ClipLoader
					sizeUnit={"px"}
					size={84}
					color={"#00ACC1"}
					loading={loading}
				/>
			</Grid>
		</Grid>
	) : houseDetail ? (
		<GetHouseDetail houseNumber={houseNumber} house={houseDetail} />
	) : null;
}
