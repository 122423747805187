const name = { english: "Name", nepali: "नाम" };
const gender = { english: "Gender", nepali: "लिङ्ग" };
const age = { english: "Age", nepali: "उमेर" };
const add = { nepali: 'थप्नुहोस', english: 'Add' };
const daughterTitle ={
	english: "DaughterTitle",
	nepali: 'छोरीको विवाहको सर्वेक्षण'
};
const marriageAge = { english: "marriage Age", nepali: "बिवाहा हुदाको उमेर" };
const martialStatus = {
	english: "Martial Status",
	nepali: "बैबाहिक अवस्था कस्तो छ ?",
};
const martialStatusList = [
	{
		nepali: "विवाहित",
		english: "Marriage",
		value: "Marriage",
	},
	{
		nepali: "छुट्टिएको",
		english: "Seperated",
		value: "seperate",
	},
	{
		nepali: "विधुर /विधुवा",
		english: "widow",
		value: "widow",
	},
	{
		nepali: "एकल",
		english: "single",
		value: "single",
	},
	{
		nepali: "पारपाचुके भएको",
		english: "devorce",
		value: "devorce",
	},
];
const martialType = {
	english: "Martial Type",
	nepali: "बिवाहाको प्रकार",
};
const martialTypeList = [
	{
		nepali: "मागी बिवाह",
		english: "arrange",
		value: "arrange",
	},
	{
		nepali: "भागि बिवाह",
		english: "elloped",
		value: "elloped",
	},
	{
		nepali: "प्रेम बिवाह",
		english: "love marriage",
		value: "love_marriage",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];
const education = {
	english: "Education",
	nepali: "शैक्षिकस्तर",
};
const educationList = [
	{
		nepali: "साक्षर",
		english: "litrate",
		value: "litrate",
	},
	{
		nepali: "निरक्षर",
		english: "uneducate",
		value: "uneducate",
	},
	{
		nepali: "वाल विकास",
		english: "primary education",
		value: "primary_education",
	},
	{
		nepali: "आधारभूत",
		english: "basic",
		value: "basic",
	},
	{
		nepali: "माध्यमिक",
		english: "secondary",
		value: "secondary",
	},
	{
		nepali: "स्नातक",
		english: "graduate",
		value: "graduate",
	},
	{
		nepali: "स्नातक माथी",
		english: "above graduate",
		value: "above_graduate",
	},
];
const occupation = {
	english: "Occupation",
	nepali: "पेशा",
};

const occupationList = [
	{
		nepali: "कृषि तथा पशुपालन",
		english: "agriculture",
		value: "agriculture",
	},
	{
		nepali: "गृहणी",
		english: "housewife",
		value: "housewife",
	},
	{
		nepali: "ब्यापार व्यावसाय",
		english: "business",
		value: "business",
	},
	{
		nepali: "नकारी जागिर",
		english: "employee",
		value: "employee",
	},
	{
		nepali: "बेरोजगार",
		english: "unemployee",
		value: "unemployee",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];
const isChild = {
	english: "have children or not ?",
	nepali: "के उंहाको बच्चा छ ?",
};

module.exports = {
	name,
	gender,
	age,
	marriageAge,
	martialStatus,
	martialStatusList,
	martialType,
	martialTypeList,
	education,
	educationList,
	occupation,
	occupationList,
	isChild,
	daughterTitle,
	add
};
