import { Grid, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  Button,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Select,
  Typography,
  Upload,
  Tooltip,

} from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import {
  add,
  pondAddress,
  pondArea,
  annualProduction,
  annualIncome,
  ownerName,
  wardQuestion,
} from "../../../variable/institute";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import { getFishFarm } from "./api";
const { Option } = Select;
const { Textarea} = Input;


class FishFarm extends Component {
  random = (low, high) => {
    return Math.random() * (high - low) + low;
  };
  state = {
  
    pondArea: "",
    ward: "",
    pondAddress: "",
    
    annualProduction: "",
    annualIncome: "",
    ownerName: "",
    
  };


  handleSelectChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  componentDidMount() {
    let fishFarmId = this.props.match.params.id;
   
    if (fishFarmId) {
      getFishFarm(fishFarmId).then(res =>
        this.setState({
          pondArea: res.data.pondArea,
          pondAddress: res.data.pondAddress,
          ward: res.data.ward,
          annualProduction: res.data.annualProduction,
          annualIncome: res.data.annualIncome,
          ownerName: res.data.ownerName,
        
        })
      );
    }
  }

  onSubmit = event => {
    event.preventDefault();
    const newInstitute = {
      pondArea: this.state.pondArea,
      // uniqueIdentifier: this.state.uniqueIdentifier,
      ward: this.state.ward,
      pondAddress: this.state.pondAddress,
      annualProduction: this.state.annualProduction,
      annualIncome: this.state.annualIncome,
     
      ownerName: this.state.ownerName
    };

    if (this.props.isUpdate) {
      newInstitute.id = this.props.match.params.id;
      this.props.addInstitutionSurvey(
        "fishfarm",
        newInstitute,
        this.props.history,
        this.props.match.params.category
      );
    } else
      this.props.addInstitutionSurvey(
        "fishfarm",
        newInstitute,
        this.props.history,
        this.props.match.params.category
      );
  };

  render() {
    const { classes, title } = this.props;
  
  
    return (
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5">{title}</Typography>
        <Form onSubmit={this.onSubmit}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={6}>
              <Form.Item label={pondAddress[selectedLanguage]}>
                <Input
                  onChange={this.handleInputChange("pondAddress")}
                  value={this.state.pondAddress}
                  disabled={this.state.isView}
                />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item label={wardQuestion[selectedLanguage]}>
                <Select
                  placeholder="------------"
                  onChange={val => this.handleSelectChange("ward", val)}
                  value={this.state.ward}
                  disabled={this.state.isView}
                >
                  {ward.map(woda => (
                    <Option value={woda.value}>{woda.ward}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item label={pondArea[selectedLanguage]}>
                <Input
                type= "number"
                min = {0}
                  onChange={this.handleInputChange("pondArea")}
                  value={this.state.pondArea}
                  disabled={this.state.isView}
                />
              </Form.Item>
            </Grid>
           
            <Grid item xs={6}>
              <Form.Item label={annualProduction[selectedLanguage]}>
                <Input
                  type="number"
                  min={0}
                  addonAfter="KG"
                  onChange={this.handleInputChange("annualProduction")}
                  value={this.state.annualProduction}
                />
              </Form.Item>
            </Grid>
            

            <Grid item xs={6}>
              <Form.Item label={annualIncome[selectedLanguage]}>
                <Input
                type= "number"
                min= {0}
                addonAfter="rupees"
                  onChange={this.handleInputChange("annualIncome")}
                  value={this.state.annualIncome}
                  disabled={this.state.isView}
                />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item label={ownerName[selectedLanguage]}>
                <Input
                  onChange={this.handleInputChange("ownerName")}
                  value={this.state.ownerName}
                  disabled={this.state.isView}
                />
              </Form.Item>
            </Grid>
          

            <Grid item xs={12}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={this.state.isView}
              >
                {this.props.isUpdate ? "update" : add[selectedLanguage]}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  }
});

const mapStateToProps = state => ({});

export default withStyles(styles)(
  connect(mapStateToProps, { addInstitutionSurvey })(withRouter(FishFarm))
);
