import { Grid, Typography, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { no, selectedLanguage, yes } from "../../../../../variable/global";
import {
	QuestionAnswer,
	Title,
} from "../../../../common/frequent/QuestionAnswer";
import {
	womenchild,
	childLabourQuestion,
	childLabor,
	isStillChildMarriage,
	reasonForChildMarriage,
	controlChildMarriage,
	suggestionTitle,
	effectFromPandemic,
} from "../../../../../variable/houseSurvey";
import { mapValues } from "lodash";

function mapValue(value, obj) {
	return obj[value];
}

class SearchByHouseNumber extends Component {
	getYesNo = (data) => (data ? yes[selectedLanguage] : no[selectedLanguage]);
	render() {
		const { classes, womenChild, house } = this.props;
		return (
			<Fragment>
				<Grid container item direction="column" className={classes.container}>
					<Title title={womenchild} />
					<Grid item container direction="column">
						<Grid
							item
							container
							direction="row"
							className={classes.eachQnAns}
							spacing={24}
						>
							<Grid item xs={6}>
								<QuestionAnswer
									question={childLabourQuestion[selectedLanguage]}
									answer={this.getYesNo(womenChild.childLabourQuestion)}
								/>
							</Grid>
							<Grid item xs={6}>
								<QuestionAnswer
									question={childLabor[selectedLanguage]}
									answer={this.getYesNo(womenChild.childLabor)}
								/>
							</Grid>
						</Grid>

						<Grid item container direction="row" className={classes.eachQnAns}>
							<Grid item xs={6}>
								<QuestionAnswer
									question={isStillChildMarriage[selectedLanguage]}
									answer={mapValue(womenChild.childMarriageCustom, {
										yes: "छ",
										no: "छैन",
										unknown: "थाहा छैन",
									})}
								/>
							</Grid>
							{womenChild.childMarriageCustom === "yes" && (
								<Grid item xs={6}>
									<QuestionAnswer
										question={reasonForChildMarriage[selectedLanguage]}
										answer={mapValue(womenChild.childMarriageReason, {
											tradition: "पहिला देखि चलेको परम्परा भएर",
											poverty: "गरीबि",
											increment: "उमेर बडदै जादा दाइजो पनि बढ्ने भएर",
											love_rel: "आफै प्रेम सम्बन्ध भएर",
											friend_circle: "साथीको लायालायामा लागेर /देखासिखी गरेर",
											no_easy_access_of_good_family:
												"राम्रो घरको केटा /केटी सजिलै नपाउने भएर",
											disable: "अपांगताको कारण",
											uneducate: "ज्ञान नभएर वा अशिक्षित भएकाले",
											unknown: "थाहा छैन",
											others: "अन्य (खुलाउनु होस )",
										})}
									/>
								</Grid>
							)}
							<Grid item xs={6}>
								<QuestionAnswer
									question={controlChildMarriage[selectedLanguage]}
									answer={mapValue(womenChild.childMarriageControl, {
										child_awarness: "वालवालिकाहरुमा जनचेतना",
										parent_awarness: "आमा बुवा /अभिभावकमा जनचेतना",
										law: "कानुन लागु गरेर",
										compulsary_education: "अनिवार्य शिक्षा",
										regulation_of_active_child_club:
											"वाल क्लबहरु सक्रिय र परिचालित",
										active_regulation: "सुरक्षा समितिहरु सक्रिय र परिचालित",
										diverse_income: "आम्दानिमुलक गतिविधि बढाउने",
										job_oppurtunities: "रोजगारीको अवसर",
										trainning_business: "ब्यब्सायिक तालिमहरुको अवसरहरु उपलब्ध",
										higher_education: "उच्च शिक्षाको अवसर",
										road_drama: "प्रभाबकारी सडक नाटक गाउँ गाउँमा देखाउने",
										unknown: "थाहा छैन",
										others: "अन्य (खुलाउने)",
									})}
								/>
							</Grid>
							<Grid item xs={6}>
								<QuestionAnswer
									question={effectFromPandemic[selectedLanguage]}
									answer={mapValue(womenChild.effectFromPandemic, {
										unemployment: "परिवारको सदश्यको रोजगार गुमेको",
										psychological: "परिवार सदश्यमा मानसिक असर",
										jhaijhagadaa: "आर्थिक अभाबले गर्दा परिवारमा कलह /झैँझगडा",
										business: "ब्यापार - व्यवासय बन्द गर्नु परेको",
										agriculture: "कृषि उत्पादन बिक्रि नभएको",
										khana_problem: "घर परिवार सदस्यमा खानलाई समस्या",
										others: "अन्य (खुलाउनु होस् )",
									})}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Divider style={{ margin: "20px auto" }} />
				{house.suggestion !== null && (
					<Grid container item direction="column" className={classes.container}>
						<Title title={suggestionTitle} />
						<Grid item container direction="column">
							<Grid
								item
								container
								direction="row"
								className={classes.eachQnAns}
								spacing={24}
							>
								<Grid item xs={6}>
									<Typography variant="h6">{house.suggestion}</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Fragment>
		);
	}
}

const styles = (theme) => ({});

SearchByHouseNumber.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchByHouseNumber);
