import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import AddMarriedDaughter from "./AddMarriedDaughter";
// import EditHouseHold from "./EditHouseHold";
import Home from "./Home";

class index extends Component {
	render() {
		return (
			<div>
				<Switch>
					<Route
						path={`${this.props.match.url}/add`}
						render={(props) => <AddMarriedDaughter {...props} />}
					/>
					<Route
						path={`${this.props.match.url}/edit/:houseId`}
						render={(props) => <AddMarriedDaughter {...props} />}
					/>

					<Route path={`${this.props.match.url}`} component={Home} />
				</Switch>
			</div>
		);
	}
}

export default withRouter(index);
