import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import Table from "@material-ui/core/Table";
import { TableHead, TableRow, TableCell, Grid } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import ClipLoader from "react-spinners/ClipLoader";
import GetHouseDetail from "../../../dashboard/search/advancedHouseSearch/houseNumber/GetHouseDetail";
import {
	clearHouseDetail,
	getHouseByHouseId,
} from "../../../../actions/searchAction";
import FullScreenDialog from "./FullScreen";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { connect } from "react-redux";
import getNepaliNumber from "../../../../utils/getNepaliNumber";
import { allToleName } from "../../../../variable/houseSurvey";
class HouseScroll extends React.Component {
	state = {
		loading: false,
		houses: [],
		house: {},
		houseNumber: null,
		houseOpen: false,
	};

	onHouseNumberClick = (hn) => {
		this.setState({ houseOpen: true, houseNumber: hn }, () => {
			this.props.getHouseByHouseId(hn);
		});
	};
	handleCloseHouse = (name) => {
		this.setState({ [name]: false, house: {} });
	};
	componentDidMount() {}

	componentDidUpdate(prevprops) {
		if (prevprops.house !== this.props.house) {
			this.setState({
				house: this.props.house,
			});
		}
		if (this.props.open && this.props.args !== prevprops.args) {
			const { args, functions } = this.props;
			this.setState({ loading: true });
			axios
				.post("/api/analysis/generic/house", { function: functions, args })
				.then((res) => {
					console.log("response from api", res.data);
					this.setState({ houses: res.data, loading: false });
				});
		}
	}

	getToleName = (value) => {
		var tole = allToleName.filter((each) => each.value === value);

		return tole && tole[0] ? tole[0].nepali : "अन्य";
	};

	render() {
		const { open, handleClose } = this.props;
		const { loading, houses, houseOpen } = this.state;
		return (
			<div>
				<Dialog
					open={open}
					onClose={handleClose}
					scroll="paper"
					aria-labelledby="scroll-dialog-title"
				>
					<DialogTitle id="scroll-dialog-title">House Detail</DialogTitle>
					<DialogContent dividers={true}>
						{loading ? (
							<Grid
								container
								direction="row"
								justify="center"
								alignItems="center"
							>
								<Grid item>
									<ClipLoader
										sizeUnit={"px"}
										size={84}
										color={"#00ACC1"}
										loading={loading}
									/>
								</Grid>
							</Grid>
						) : (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>S.N</TableCell>
										<TableCell>घर नं</TableCell>
										<TableCell>टोलको नाम </TableCell>
										<TableCell>वडा नं</TableCell>
										<TableCell>उत्तरदाताको नाम</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{houses &&
										houses.map((each, i) => (
											<TableRow>
												<TableCell>{getNepaliNumber(i + 1)}</TableCell>
												<TableCell>
													{getNepaliNumber(each.houseNumber)}
												</TableCell>
												<TableCell>{this.getToleName(each.toleName)}</TableCell>
												<TableCell>
													{getNepaliNumber(each.wardNumber)}
												</TableCell>
												<TableCell>{each.answererName}</TableCell>
												<TableCell>
													<VisibilityIcon
														onClick={() => this.onHouseNumberClick(each._id)}
														// className={classnames(classes.preview, classes.icon)}
													/>
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							OK
						</Button>
					</DialogActions>
				</Dialog>
				{houseOpen && (
					<FullScreenDialog
						name="houseOpen"
						open={houseOpen}
						title={`House Detail `}
						handleClose={this.handleCloseHouse}
					>
						<Grid container spacing={24}>
							{this.props.load ? (
								<Grid
									style={{ marginTop: "40vh" }}
									container
									direction="row"
									justify="center"
									alignItems="center"
								>
									<Grid item>
										<ClipLoader
											sizeUnit={"px"}
											size={84}
											color={"#00ACC1"}
											loading={this.props.load}
										/>
									</Grid>
								</Grid>
							) : (
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="flex-start"
								>
									{this.state.house && (
										<Grid item xs={9}>
											<GetHouseDetail house={this.state.house} />
										</Grid>
									)}
								</Grid>
							)}
						</Grid>
					</FullScreenDialog>
				)}
			</div>
		);
	}
}

const mapStateToProps = ({ search: { house, load } }) => ({
	house,
	load,
});
export default connect(mapStateToProps, {
	clearHouseDetail,
	getHouseByHouseId,
})(HouseScroll);
