import {
	SHOW_MARRIED_DAUGHTER,
	GET_MARRIED_DAUGHTER_ERROR,
	SAVE_MARRIED_DAUGHTER,
} from "../actions/types";

const initialState = {
	marriedDaughter: {},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SHOW_MARRIED_DAUGHTER:
			return {
				...state,
				marriedDaughter: action.payload && action.payload,
				loading: true,
			};
		case GET_MARRIED_DAUGHTER_ERROR:
			return {
				...state,
				errors: action.payload,
			};
		case SAVE_MARRIED_DAUGHTER:
			return {
				...state,
				marriedDaughter: action.payload && action.payload,
			};

		default:
			return state;
	}
}
