import {UserAddOutlined, UserDeleteOutlined} from "@ant-design/icons";
import {
	Breadcrumb,
	Icon,
	Upload,
	Row,
	Typography,
	Select,
	Form,
	Col,
	message,
	Button,
	Spin,
} from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { selectedLanguage, ward } from "../../variable/global";

import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import { Grid, IconButton } from "@material-ui/core";
import Selection from "../../components/common/frequent/Selection";
import TextField1 from "@material-ui/core/TextField";
import CheckBox from "../../components/common/frequent/CheckBox";
import DeleteIcon from "@material-ui/icons/Delete";

import BoxBorder from "../../components/common/frequent/BoxBorder";
import MultipleSelect from "../../components/common/frequent/MultipleSelect";
import RadioButton from "../../components/common/frequent/RadioButton";
import TextField from "../../components/common/frequent/TextField";
import { QuestionAnswer } from "../../components/common/frequent/QuestionAnswer";
import {
	anyDeath,
	deathAge, deathCertificate, deathCertificateQn,
	deathGender,
	deathInYear,
	deathInYeargenderQuestion,
	deathInYearReasonQuestion,
	deathName,
	deathQuestion,
	deathReason,
} from "../../variable/houseSurvey";

// import {
// 	wardNumber,
// 	aadibasi,
// 	aadibasiQuestion,
// 	abroadCountry,
// 	abroadCountryQuestion,
// 	abroadReason,
// 	abroadReasonQuestion,
// 	abroadYearQuestion,
// 	answererRelation,
// 	answererRelationQuestion,
// 	bloodgroup,
// 	bloodgroupQuestion,
// 	casteList,
// 	casteQuestion,
// 	contactNo,
// 	education,
// 	educationQuestion,
// 	gender,
// 	genderQuestion,
// 	healthcondition,
// 	healthconditionQuesiton,
// 	industry,
// 	industryQuestion,
// 	job,
// 	jobQuestion,
// 	livingStatus,
// 	livingStatusQuestion,
// 	marriage,
// 	marriageQuestion,
// 	memberName,
// 	mentalCardQuestion,
// 	mentalCardtype,
// 	mentalCardTypeQuestion,
// 	mentalCardYesNo,
// 	mentalcondition,
// 	mentalconditionQuestion,
// 	mentaltype,
// 	mentaltypeQuestion,
// 	mentalyesno,
// 	mentalYesNoQuestion,
// 	occupation,
// 	occupationQuestion,
// 	pahadi,
// 	pahadiQuestion,
// 	preprimary,
// 	preprimaryQuestion,
// 	primary,
// 	primaryQuestion,
// 	raiQuestion,
// 	raiType,
// 	religionList,
// 	religionQuestion,
// 	secondary,
// 	secondaryQuestion,
// 	security,
// 	securityQuestion,
// 	teacher,
// 	teacherQuestion,
// 	unhealthy,
// 	unhealthyQuestion,
// 	insuranceYesNo,
// 	insuranceQuestion,
// 	insuranceListQuestion,
// 	insuranceLists,
// 	votercard,
// 	votercardQuestion,
// 	mothertongueQuestion,
// 	mothertongue,
// 	familyFemale,
// 	familyMale,
// 	totalMember,
// 	familyThirdGender,
// 	citizenshipFrontPhoto,
// 	citizenshipBackPhoto,
// 	ageQuestion,
// 	dateQuestion,
// 	skillTrainingQuestion,
// 	skillTraining,
// 	skillDetailQuestion,
// 	skillDetail,
// } from "../../variable/houseSurvey";
import Axios from "axios";
import FullScreenDialog from "../../components/common/frequent/dialog/FullScreen";
import HouseDetail from "../../components/dashboard/search/advancedHouseSearch/houseNumber/HouseDetail";
const { Title } = Typography;
const { Option } = Select;

const DeathForm = ({ handleChange, thisError, form }) => {
	return (
		<Grid
			container
			direction="column"
			style={{
				width: "100%",
				border: "1px solid  rgba(0, 0, 0, 0.19)",
				borderTop: "none !important",
				padding: `16px 8px 16px 16px`,
				borderBottomRightRadius: 5,
				borderBottomLeftRadius: 5,
				marginBottom: 20,
			}}
		>
			<Grid
				container
				style={{
					display: "flex",
				}}
			>
				<QuestionAnswer
					inline
					defaultValue="ब्यक्ति रोज्नुहोस"
					question={deathName[selectedLanguage]}
					answer={form[`deathName`]}
				/>
			</Grid>
			<Grid
				container
				style={{
					display: "flex",
				}}
			>
				<QuestionAnswer
					inline
					defaultValue="ब्यक्ति रोज्नुहोस"
					question={deathInYeargenderQuestion[selectedLanguage]}
					answer={form[`deathGender`]}
				/>
			</Grid>
			<Grid
				container
				style={{
					display: "flex",
				}}
			>
				<QuestionAnswer
					inline
					defaultValue="ब्यक्ति रोज्नुहोस"
					question={deathAge[selectedLanguage]}
					answer={form[`deathAge`]}
				/>
			</Grid>

			<Grid container>
				<MultipleSelect
					question={deathInYearReasonQuestion}
					name={`deathReason`}
					value={form[`deathReason`]}
					tagLists={deathReason}
					isVisible
					handleChange={handleChange}
					other={form["deathReasonOther"]}
				/>
			</Grid>

			<Grid container>
				<RadioButton
					value={form['isDeathCertificate']}
					name={'isDeathCertificate'}
					question={deathCertificateQn}
					isVisible
					handleChange={handleChange}
					radioLists={deathCertificate}

				/>
			</Grid>

		</Grid>
	);
};

export default function VitalDeath({ history }) {
	const [form, setForm] = useState({
		dateofAdopting: moment(),
	});
	const [houseOpen, setHouseOpen] = useState(false);
	const [houseOfWard, setHouseOfWard] = useState([]);
	const [families, setFamilies] = useState([]);
	const [fetching, setFetching] = useState(true);
	const [fetchingFamilies, setFetchingFamilies] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	const handleInputChange = (name) => (value) => {
		setForm((prevValue) => ({ ...prevValue, [name]: value }));
		if (name == "ward" && form.ward !== value) fetchHouseId(value);
		else if (name == "houseId" && form.houseId !== value) fetchFamilies(value);
		else if (name == "deathPerson") {
			const selectedMember = families.find((each) => each.value == value);
			if (selectedMember) {
				const body = {
					family: selectedMember._id,
					deathName: selectedMember.fullName,
					deathGender: selectedMember.gender,
					deathAge: selectedMember.age,
				};
				console.log("selectedMember", body);
				setForm((prevValue) => ({ ...prevValue, ...body }));
			}
		}
	};
	const fetchHouseId = (ward) => {
		setHouseOfWard([]);
		setFetching(true);
		Axios.get(`/api/vital/birth/?ward=${ward}`)
			.then((response) => {
				const data = response.data.data.map((house) => ({
					text: house.houseNumber + "",
					value: house._id,
				}));
				setHouseOfWard(data);
			})
			.finally((each) => {
				setFetching(false);
			});
	};

	const genderList = {
		male: "पुरुष",
		female: "महिला",
		third_gender: "तेश्रो लिंगी",
	};
	const fetchFamilies = (houseId) => {
		setFamilies([]);
		setFetchingFamilies(true);
		Axios.get(`/api/vital/family/family-from-house/${houseId}`)
			.then((response) => {
				const data = response.data.data.map((family) => ({
					text: family.fullName + ` (${genderList[family.gender]})`,
					value: family._id,
					...family,
				}));
				setFamilies(data);
			})
			.finally((each) => {
				setFetchingFamilies(false);
			});
	};

	const onSubmit = () => {
		const requiredFields = [
			"deathName",
			"deathAge",
			'deathGender',
			"deathReason",
		];
		for (const each of requiredFields)
			if (!form[each])
				return message.error("कृपया सबै ठाउ भर्नुहोस् : " + each);
		if (false) return message.warning("कृपया सबै ठाउ भर्नुहोस्");
		if (!form.houseId) return message.warning("वार्ड र घर चयन गर्नुहोस");
		// else Axios ni done vao
		console.log(form);
		setSubmitting(true);
		Axios.put(`/api/vital/family/declareDeath`, {
			...form,

		})
			.then((res) => {
				message.success("सदस्यको मृत्यु दर्ता भएको  छ");
				history.push("/vital-registration");
			})
			.catch((err) => {
				message.error("Error On Saving Data");
			})
			.finally(() => {
				setSubmitting(false);
			});
	};

	return (
		<Row
			style={{
				marginBottom: 50,
			}}
		>
			<Row>
				{houseOpen && (
					<FullScreenDialog
						name="houseOpen"
						open={houseOpen}
						title={`House Detail `}
						handleClose={() => setHouseOpen(false)}
					>
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="flex-start"
						>
							{(() => {
								let house = houseOfWard.find(
									(each) => each.value == form.houseId
								);
								return (
									<HouseDetail
										houseId={form && form.houseId && form.houseId}
										houseNumber={house.text}
									/>
								);
							})()}
						</Grid>
					</FullScreenDialog>
				)}
				<Breadcrumb>
					<Breadcrumb.Item href="/">
						<Icon type="home" />
					</Breadcrumb.Item>
					<Link to="/vital-registration">
						<Breadcrumb.Item>
							<Icon
								component={() => (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										viewBox="0 0 24 24"
									>
										<path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z" />
									</svg>
								)}
							/>
							<span>घटना दर्ता</span>
						</Breadcrumb.Item>
					</Link>
					<Breadcrumb.Item>
						<UserDeleteOutlined />
						<span>मृत्यु दर्ता</span>
					</Breadcrumb.Item>
				</Breadcrumb>
			</Row>
			<Row
				style={{
					marginTop: 50,
				}}
			>
				<Title
					level={3}
					style={{
						fontSize: 28,
						color: "#3d7ec5",
					}}
				>
					मृत्युदर्ताका लागि फारम भर्नुहोस्
				</Title>
			</Row>
			<Row>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						marginBottom: 20,
					}}
				>
					<span>वडा चयन गर्नुहोस:</span>
					<Select
						showSearch={true}
						size="large"
						style={{
							minWidth: 300,
						}}
						placeholder="वडा रोज्नुहोस"
						onChange={handleInputChange("ward")}
						style={{ minWidth: 250, marginLeft: 8 }}
					>
						{ward.map((each) => (
							<Option value={each.value}>{each.ward}</Option>
						))}
					</Select>
				</div>

				<Row xs={24} md={12}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: 20,
						}}
					>
						<span
							style={{
								display: "flex-inline",
							}}
						>
							मृत ब्यक्ति को घर नम्बर:
						</span>
						<Select
							disabled={!form.ward}
							size="large"
							showSearch
							// labelInValue
							value={form.houseId}
							placeholder="घर नम्बर खोज्नुहोस"
							notFoundContent={
								fetching ? (
									<Spin size="small" />
								) : houseOfWard.length == 0 ? (
									<span>Not Found</span>
								) : null
							}
							filterOption={(input, option) =>
								option.props.children
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							onChange={handleInputChange("houseId")}
							style={{ minWidth: 250, marginLeft: 8 }}
						>
							{houseOfWard.map((d) => (
								<Option key={d.value}>{d.text}</Option>
							))}
						</Select>
					</div>
				</Row>

				{/* <Col xs={24} md={12}> */}
				{/* <Form.Item
						label="मृतकको विवरण"
						style={{
							display: "flex",
						}}
					> */}

				{/* </Form.Item> */}
				{/* </Col> */}

				{(() => {
					let house = houseOfWard.find((each) => each.value == form.houseId);
					if (house)
						return (
							<Col
								style={{
									marginTop: 10,
									marginBottom: 10,
								}}
							>
								<Button
									type="link"
									onClick={() => setHouseOpen(true)}
									style={{
										padding: 0,
									}}
								>
									<span
										style={{
											fontSize: 20,
										}}
									>
										{house.text} घर नम्बर को विवरण हेर्नुहोस्
									</span>
								</Button>
							</Col>
						);
					else return null;
				})()}
			</Row>
			<Row>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						marginBottom: 20,
					}}
				>
					<span>मृतकको विवरण:</span>
					<Select
						disabled={!form.houseId}
						size="large"
						showSearch
						// labelInValue
						value={form.deathPerson}
						placeholder="मृतक रोज्नुहोस"
						notFoundContent={
							fetchingFamilies ? (
								<Spin size="small" />
							) : houseOfWard.length == 0 ? (
								<span>Not Found</span>
							) : null
						}
						filterOption={(input, option) =>
							option.props.children.toLowerCase().includes(input.toLowerCase())
						}
						onChange={handleInputChange("deathPerson")}
						style={{ minWidth: 250, marginLeft: 8 }}
					>
						{families.map((d) => (
							<Option key={d.value}>{d.text}</Option>
						))}
					</Select>
				</div>
			</Row>
			<Row>
				<DeathForm
					handleChange={(name, value) => handleInputChange(name)(value)}
					form={form}
					thisError={{}}
				/>
			</Row>
			<Row
				type="flex"
				style={{
					marginTop: 20,
				}}
			>
				<Col>
					<Link to="/vital-registration">
						<Button
							style={{
								fontSize: 15,
							}}
						>
							Cancel
						</Button>
					</Link>
				</Col>
				<Col>
					<Button
						onClick={onSubmit}
						type="primary"
						style={{
							fontSize: 15,
							marginLeft: 15,
						}}
					>
						Submit
					</Button>
				</Col>
			</Row>
		</Row>
	);
}
