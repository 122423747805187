import { Grid, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  Button,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Select,
  Typography,
  Upload,
  Tooltip,

} from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import {
  add,
  gender,
  name,
  age,
  gameName,
  sportsLevel,
  genderQn,
  wardQuestion,
  prize,
} from "../../../variable/institute";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import { getSports } from "./api";
const { Option } = Select;
const { TextArea } = Input;


class Sports extends Component {
  random = (low, high) => {
    return Math.random() * (high - low) + low;
  };
  state = {
    // image upload state
    // uniqueIdentifier:
    //   this.props.match.params.category +
    //   "_" +
    //   this.random(10000, 99999) +
    //   this.random(1000, 9999),
    // previewVisible: false,
    // previewImage: "",
    //loaded: 0,
    name: "",
    ward: "",
    gender: "",
   
    age: "",
    gameName: "",
    sportsLevel: "",
    prize:""
  };


  handleSelectChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  componentDidMount() {
    let sportsId = this.props.match.params.id;
   
    if (sportsId) {
      getSports(sportsId).then(res =>
        this.setState({
          name: res.data.name,
          gender: res.data.gender,
          ward: res.data.ward,
          age: res.data.age,
          gameName: res.data.gameName,
          prize : res.data.prize,
          sportsLevel: res.data.sportsLevel,
        
        })
      );
    }
  }

  onSubmit = event => {
    event.preventDefault();
    const newInstitute = {
      name: this.state.name,
      ward: this.state.ward,
      gender: this.state.gender,
      age: this.state.age,
      gameName: this.state.gameName,
      prize : this.state.prize.toString(),
      sportsLevel: this.state.sportsLevel
    };

    if (this.props.isUpdate) {
      newInstitute.id = this.props.match.params.id;
      this.props.addInstitutionSurvey(
        "sports",
        newInstitute,
        this.props.history,
        this.props.match.params.category
      );
    } else
      this.props.addInstitutionSurvey(
        "sports",
        newInstitute,
        this.props.history,
        this.props.match.params.category
      );
  };

  render() {
    const { classes, title } = this.props;
  
  
    return (
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5">{title}</Typography>
        <Form onSubmit={this.onSubmit}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={6}>
              <Form.Item label={name[selectedLanguage]}>
                <Input
                  onChange={this.handleInputChange("name")}
                  value={this.state.name}
                  disabled={this.state.isView}
                />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item label={wardQuestion[selectedLanguage]}>
                <Select
                  placeholder="------------"
                  onChange={val => this.handleSelectChange("ward", val)}
                  value={this.state.ward}
                  disabled={this.state.isView}
                >
                  {ward.map(woda => (
                    <Option value={woda.value}>{woda.ward}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item label={genderQn[selectedLanguage]}>
              <Select
                  placeholder="------------"
                  onChange={val => this.handleSelectChange("gender", val)}
                  value={this.state.gender}
                >
                  {gender.map(type => (
                    <Option value={type.value}>{type[selectedLanguage]}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Grid>

            <Grid item xs={6}>
              <Form.Item label={age[selectedLanguage]}>
                <Input
                  type="number"
                  min={0}
                  onChange={this.handleInputChange("age")}
                  value={this.state.age}
                />
              </Form.Item>
            </Grid>
            

            <Grid item xs={6}>
              <Form.Item label={prize[selectedLanguage]}>
              <Tooltip
                  trigger={["focus"]}
                  title="Please insert comma seperated value"
                  placement="topLeft"
                 
                >
                  <TextArea
                    name="prize"
                    value={this.state.prize}
                    onChange={this.handleInputChange("prize")}
                    rows={4}
                  />
                  </Tooltip>
            
              </Form.Item>
            </Grid>

            <Grid item xs={6}>
              <Form.Item label={gameName[selectedLanguage]}>
                <Input
                  onChange={this.handleInputChange("gameName")}
                  value={this.state.gameName}
                  disabled={this.state.isView}
                />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item label={sportsLevel[selectedLanguage]}>
                <Input
                  onChange={this.handleInputChange("sportsLevel")}
                  value={this.state.sportsLevel}
                  disabled={this.state.isView}
                />
              </Form.Item>
            </Grid>
          

            <Grid item xs={12}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={this.state.isView}
              >
                {this.props.isUpdate ? "update" : add[selectedLanguage]}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  }
});

const mapStateToProps = state => ({});

export default withStyles(styles)(
  connect(mapStateToProps, { addInstitutionSurvey })(withRouter(Sports))
);
