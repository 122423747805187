const aandolanHeader = { nepali: "द्वन्द वा जनआन्दोलन प्रवाभित", english: "" };
const aandolanYesNo = {
	nepali: "परिवारमा द्वन्द वा जनआन्दोलन प्रवाभित सदस्य हुनुहुन्छ ?",
	english: "",
};
const aandolanEffect = {
	nepali: "कुन किसिमको प्रभावित",
	english: "",
};
const combatSide = {
	nepali: "ससत्र जनयुद्द हो भने कुन पक्षबाट",
	english: "",
};
const painFromAandolan = {
	nepali: "कस्तो किसिमको पिडित",
	english: "",
};
const effectFromPandemic = {
	nepali: "कोरोना महामारीले तपाइको परिवारमा पारेको प्रमुख असर ?",
	english: "",
};
const illTreatment = {
	english: "",
	nepali: "बिरामी हुदा स्वस्थ उपचार सेवाको लागी प्रथम पटक कहाँ जानु हुन्छ?",
};
const agricultureTitle = {
	english: "Agricultural production",
	nepali: "कृषि उत्पादन",
};
const irrigation = { english: "Irrigation", nepali: "खेती सिचाइ" };
const mal = { english: "Use of fertilizer", nepali: "मलको प्रयोग" };
const malType = { english: "Types of fertilizer", nepali: "मलको किसिम" };
const waliType = { english: "Types of crops", nepali: "बाली प्रकार" };
const wali = { english: "Crops", nepali: "बाली" };
const area = { english: "Area", nepali: "क्षेत्रफल" };
const productionShow = {
	english: "Production quantity",
	nepali: "उत्पादन परिमाण(के.जि.मा)",
};
const sales = { english: "Sales quantity", nepali: "बिक्री परिमाण(के.जि.मा)" };
const production = { english: "Profit", nepali: "बार्षिक आम्दानी" };
const expense = { english: "Profit", nepali: "बार्षिक खर्च" };
const agriInfo = { english: "Agriculture information", nepali: "कृषि जानकारी" };
const yearlyInfo = { english: "Yearly income", nepali: "वार्षिक आम्दानी" };
const beefishTitle = {
	nepali: "माछा मौरी र रेशम पालन",
	english: "Fish,bee and resham rearing",
};
const beefishType = { english: "Types", nepali: "किसिम" };
const beefishTotal = { english: "Total", nepali: "कुल" };
const beefishProduction = { english: "Production", nepali: "उत्पादन" };
const fish = { nepali: "माछा", english: "Fish" };
const silk = { nepali: "रेशम", english: "Resham" };
const bee = { nepali: "मौरी", english: "Bee" };
const beefishYesNo = {
	nepali: "माछा मौरी र रेशम पालन गरिएको",
	english: "Rearing of fish , bee and resham",
};
const businessQ = { nepali: "व्यवसाय कार्य", english: "Business work" };
const businessType = { nepali: "किसिम", english: "Types" };
const businessTypeName = { nepali: "नाम", english: "Name" };
const businessProfit = { nepali: "आम्दानी", english: "Profit" };
const businessName = {
	nepali: "घरेलु व्यवसाय कार्य",
	english: "Domestic business work",
};
const herbName = { nepali: "जडिबुटी ", english: "Medical herbs" };
const gharTitle = { english: "Introduction of house", nepali: "घरको परिचय" };
const genderMale = { nepali: "महिला", english: "Female", value: "" };
const genderFemale = { nepali: "पुरुष", english: "Male", value: "" };
const genderThird = {
	nepali: "तेस्रो लिङ्गी",
	english: "Third gender",
	value: "",
};
const genderTotal = { nepali: "जम्मा", english: "total", value: "" };
const economic = {
	nepali: "आर्थिक क्षेत्र जानकारी",
	english: "Economic area information",
};
const mainincome = {
	nepali: "वार्षिक आम्दानीको मुख्य स्रोत",
	english: "Main source of yearly income",
};
const yearlyIncome = { nepali: "वार्षिक आम्दानी ", english: "Yearly income" };
const yearlyExpenditure = {
	nepali: "वार्षिक खर्च",
	english: "Yearly expenditure",
};
const loanGiven = {
	nepali: "नगद ऋण दिनु भएको छ",
	english: "Given loan in cash",
};
const loanGivenInterest = { nepali: "व्याज दर", english: "Interest rate" };
const loanTaken = { nepali: "ऋण लिनु भएको छ", english: "Do you take loan" };
const loanType = { nepali: "rऋणको प्रकर ", english: "Types of loan" };
const loanInterest = { nepali: "व्याज दर", english: "Interest rate" };
const houseFacilityTitle = {
	nepali: "घरको सुविधाहरु",
	english: "Facilities of house",
};
const facilityType = { nepali: "किसिम", english: "Types" };
const facilityCount = { nepali: "संख्या", english: "Number" };
const childMarriageHeader = {
	nepali: "३ बर्ष भित्र छोरीको विवाह",
	english: "",
};
const familyTitle = {
	english: "Use by family",
	nepali: "परिवारले प्रयोग गर्ने बारेमा",
};
const waterSource = {
	english: "Main sources of drinking water",
	nepali: "खानेपानीको मुख्य श्रोत",
};
const distanceToWaterSource = {
	english: "",
	nepali: "घरको खानेपानी लिन जान ,भर्न र आउन कति समय लाग्छ?",
};
const cookingSource = {
	english: "Main sources of cooking",
	nepali: "खाना पकाउने मुख्य श्रोत",
};
const electricSource = {
	nepali: "वत्तिको मुख्य श्रोत",
	english: "Main source of electricity",
	value: "",
};
const isElectricityMeterQn = {
	nepali: "घरमा बिधुत मिटर जडान गरिएको छ कि छैन ?",
	english: "Main source of electricity",
	value: "",
};
const toiletQuestion = {
	nepali: "घरमा शौचालय छ/छैन",
	english: "Is toilet available in house",
	value: "",
};
const toiletType = {
	nepali: "शौचालयको किसिम",
	english: "Types of toilet",
	value: "",
};
const toiletTypeNo = {
	english: "",
	nepali: "शौचालय नभएको कारण",
	value: "",
};
const toiletTypeNoUse = {
	english: "",
	nepali: "शौचालय किन संचालन नगरेको ?",
	value: "",
};
const anyBankAccount = {
	nepali: "बैंक तथा वित्तीय सस्थामा खाता",
	english: "Account in bank and finance",
	value: "",
};
const numberofBankAccount = { nepali: "खाता", english: "account", value: "" };
const homeAge = { nepali: "घरको उमेर", english: "House age", value: "" };
const saltUsed = { nepali: "नुनको किसिम", english: "Types of salt", value: "" };
const hasinsur = { nepali: "परिवारको बीमा विवरण", english: "" };
const insuranceDetail = {
	nepali: "परिवारको बिमा विवरण",
	english: "Family insurance detail",
	value: "",
};
const totalHouseCount = { english: "No of house", nepali: "घर संख्या" };
const totalMatanCount = { english: "No of matan", nepali: "मतान संख्या" };
const gharJaggaTitle = {
	english: "House land information",
	nepali: "घरजग्गा जानकारी",
};
const totalHouseRoom = { english: "Total room", nepali: "जम्मा कोठा" };
const totalHouseStorey = { english: "Total floor", nepali: "जम्मा तल्ला" };
const houseMapPass = { english: "Map pass", nepali: "नक्सा पास " };
const houseCriteriaFullfill = { english: "Criteria", nepali: "मापदण्ड " };
const houseLandUsedLiving = { english: "Ownership", nepali: "स्वामित्व" };
const houseroofType = { english: "Types of roof", nepali: "छानाको प्रकार" };
const hoousesType = { english: "Types of house", nepali: "घर को प्रकार" };
const wasteDisposal = {
	english: "Disposal of waste",
	nepali: "फोहोर मैलो  व्यवस्थापन ",
};
const road = { english: "Road facility", nepali: "सडकको सुबिधा " };
const houseRent = { english: "Room rent", nepali: "घर भाडामा" };
const houseConstructionCertificate = {
	english: "",
	nepali: "घर निर्माण प्रमाण पत्र लिनु भएको छ ?",
};
const totalArea = {
	english: "Total land area",
	nepali: "जम्मा जग्गा क्षेत्रफल",
};
const ropani = { english: "Ropani", nepali: "रोपनी" };
const aana = { english: "Aana", nepali: "आना" };
const paisa = { english: "Paisa", nepali: "पैसा" };
const bigha = { english: "", nepali: "बिघा" };
const kattha = { english: "", nepali: "कठा" };
const dhur = { english: "", nepali: "धुर" };
const liveStockInfo = {
	english: "Animal, birds information",
	nepali: "पशु पन्छी जानकारी",
};
const livestockName = { english: "Animal,birds", nepali: "पशु पन्छी" };
const livestockDetail = {
	nepali: "पशुपालन गरेको छ कि छैन",
	english: "Rear animal or not",
};
const animalCount = {
	english: "No of animal and birds",
	nepali: "पशु पन्छी संख्या",
};
const annualIncome = { english: "Total income", nepali: "जम्मा आम्दानी" };
const skills = { nepali: "व्यवसायिक सिप तालिम", english: "Skill and training" };
const skillType = {
	nepali: "सिप तालिम क्षेत्र",
	english: "Skill and training center",
};
const skillMaleNo = { nepali: "जम्मा पुरुष", english: "Total male" };
const skillFemaleNo = { nepali: "जम्मा महिला", english: "Total female" };
const skillYesNo = {
	nepali: "व्यवसायिक सिप तालिम प्राप्त गरेका",
	english: "Achive skill and training",
};
const welfare = {
	nepali: "सामाजिक सुरक्षा भत्ता",
	english: "Social security Allowance",
};
const welfareType = { nepali: "प्रकार", english: "Types" };
const welfareMale = { nepali: "पुरुष", english: "Male" };
const welfareFemale = { nepali: "महिला", english: "Female" };
const bhattaYesNo = {
	nepali: "सामाजिक सुरक्षा भत्ता प्राप्त गर्ने",
	english: "Getting social security Allowance",
};
const dirtyWaterQuestion = {
	nepali: "घरबाट निस्कने फोहरपानीको ब्यबस्थापन",
	english: "",
};

const dirtyWaterManagement = [
	{
		nepali: "करेसाबारीमा",
		english: "",
		value: "gardenfiel",
	},
	{
		nepali: "ढलमा मिसाएको",
		english: "",
		value: "drain",
	},
	{
		nepali: "खाडलमा जम्मा गर्ने",
		english: "",
		value: "ponding",
	},
	{
		nepali: "अब्यबस्थित छाड्ने",
		english: "",
		value: "unmanaged",
	},
];

const riskAreaAroundHouseQn = {
	nepali: "घर कस्तो प्रकारको जिखिममा छ?",
	english: "",
};

const riskAreaAroundHouse = [
	{
		nepali: "भूकम्प",
		english: "",
		value: "earthquake",
	},
	{
		nepali: "बाढी",
		english: "",
		value: "flood",
	},
	{
		nepali: "पहिरो",
		english: "",
		value: "landslide",
	},
	{
		nepali: "हावाहुरी",
		english: "",
		value: "hurricane",
	},
	{
		nepali: "ढुवान वा कटान",
		english: "",
		value: "kataan",
	},
	{
		nepali: "जोखिम नभएको",
		english: "",
		value: "no_risk",
	},
];

const meansOfMunicipalInformationQn = {
	nepali: "गाउँपालिकाको सूचना के माध्यम बाट प्राप्त गर्नुहुन्छ ?",
	english: "",
};

const meansOfMunicipalInformation = [
	{
		nepali: "मोबाइल एप",
		english: "",
		value: "mobile_app",
	},
	{
		nepali: "रेडियो",
		english: "",
		value: "radio",
	},
	{
		nepali: "स्थानीय नेताहरुबाट",
		english: "",
		value: "local_leader",
	},
	{
		nepali: "पत्रपत्रिका / समाचारपत्र",
		english: "",
		value: "newspaper_arti",
	},
	{
		nepali: "सरकारी अधिकारि",
		english: "",
		value: "government_off",
	},
	{
		nepali: "गैर सरकारी कर्मचारिबाट",
		english: "",
		value: "nongov_officer",
	},
	{
		nepali: "सुचनाको हक प्रयोग गर्ने",
		english: "",
		value: "information_ri",
	},
	{
		nepali: "थाह छैन",
		english: "",
		value: "dont_know",
	},
];
const waterPurificationQn = {
	nepali: "तपाईले पिउने पानीको शुद्धीकरण कसरी गर्नुहुन्छ ?",
	english: "",
};

const waterPurification = [
	{
		nepali: "शुद्दिकरण गरिदैन",
		english: "",
		value: "no_purificatio",
	},
	{
		nepali: "उमालेर",
		english: "",
		value: "boil",
	},
	{
		nepali: "फिल्टर गरेर",
		english: "",
		value: "filter",
	},
	{
		nepali: "औषधि हालेर",
		english: "",
		value: "checimal",
	},
	{
		nepali: "सोडीस प्रविधि",
		english: "",
		value: "solar",
	},
	{
		nepali: "अन्य",
		english: "",
		value: "other",
	},
];

const womenchild = {
	nepali: "महिला तथा बालबालिका",
	english: "Female and chindren",
};
const childLabor = {
	nepali: "१६ वर्ष मुनिका कामदार",
	english: "Worker below age of 16",
};

//मकैखेति गरिएको विवरण
const agriMakaiInfo = {
	english: "Miaze farming detail",
	nepali: "मकैखेति गरिएको विवरण",
};
const agriMakaiAreaQuestion = {
	english: "Maize farming area",
	nepali: "मकैखेति गरिएको क्षेत्रफ़ल",
};
const agriMakaiArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//मकैखेति उत्पादन (मुरी पाथीमा)
const agriMakaiProduction = {
	english: "Production of maize ( in muri and pathi)",
	nepali: "मकैखेति उत्पादन (मुरी पाथीमा)",
};
const agriMakaiProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//मकैखेति विक्री परिमाण (मुरी पाथीमा)
const agriMakaiSale = {
	english: "Sale maize",
	nepali: "मकैखेति विक्री परिमाण (मुरी पाथीमा)",
};

//मकैखेति END

//कोदोखेति गरिएको विवरण
const agriKodoInfo = {
	english: "Kodo farming detail",
	nepali: "कोदोखेति गरिएको विवरण",
};
const agriKodoAreaQuestion = {
	english: "Kodo farming area",
	nepali: "कोदोखेति गरिएको क्षेत्रफ़ल",
};
const agriKodoArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//कोदोखेति उत्पादन (मुरी पाथीमा)
const agriKodoProduction = {
	english: "Production of kodo in pathi",
	nepali: "कोदोखेति उत्पादन (मुरी पाथीमा)",
};
const agriKodoProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//कोदोखेति  विक्री परिमाण (मुरी पाथीमा)
const agriKodoSale = {
	english: "Quantity of kodo sales",
	nepali: "कोदोखेति  विक्री परिमाण (मुरी पाथीमा)",
};

//कोदोखेति END

//फापरखेति गरिएको विवरण
const agriFaparInfo = {
	english: "Fapar farming detail",
	nepali: "फापरखेति गरिएको विवरण",
};
const agriFaparAreaQuestion = {
	english: "Area of faper farming",
	nepali: "फापरखेति गरिएको क्षेत्रफ़ल",
};
const agriFaparArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//फापरखेति उत्पादन (मुरी पाथीमा)
const agriFaparProduction = {
	english: "Production of faper",
	nepali: "फापरखेति उत्पादन (मुरी पाथीमा)",
};
const agriFaparProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//फापरखेति विक्री परिमाण (मुरी पाथीमा)
const agriFaparSale = {
	english: "Quantity of faper sales in muri and pathi",
	nepali: "फापरखेति विक्री परिमाण (मुरी पाथीमा)",
};

//फापरखेति END
//माथि भन्दा अन्यखेति कति प्रकार को अन्नवाली गरिएको छ ?
const agriAnyKheti = {
	english: "Other crops detail",
	nepali: "माथि भन्दा अन्य खेति कति प्रकारको अन्नवाली गरिएको छ ?",
	value: "",
};
const agriAnyInfo = {
	english: "Other crops detail",
	nepali: "अन्य अन्नखेति गरिएको विवरण",
};
const agriAnyInfoTitle = {
	engish: "Mention if any",
	nepali: "अन्य भए उल्लेख गर्नु हाेस।",
	value: "",
};
const agriAnyKhetiInfo = {
	english: "Farming detail",
	nepali: "खेति गरिएको विवरण",
	value: "",
};
const agriAnyKhetiAreaQuestion = {
	english: "Other crop farming detail",
	nepali: "अन्यखेति गरिएको क्षेत्रफ़ल",
};
const agriAnyKhetiArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//अन्यखेति उत्पादन (मुरी पाथीमा)
const agriAnyKhetiProduction = {
	english: "Other crops production in muri",
	nepali: "अन्यखेति उत्पादन (मुरी पाथीमा)",
};
const agriAnyKhetiProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//अन्यखेति विक्री परिमाण (मुरी पाथीमा)
const agriAnyKhetiSale = {
	english: "Other crops sales quantity in muri and pathi",
	nepali: "अन्यखेति विक्री परिमाण (मुरी पाथीमा)",
};
const agriAnyKhetiSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "अन्यखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income of other crops?",
		value: "",
	},
];
//अन्यखेति END

//दालवालिको उत्पादन तथा बिक्रि विवरण
//If xa vaney   कुन कुन दालवालिको उत्पादन तथा बिक्रि विवरण छन् ?
const agriDaalTitle = {
	english: "Which daal has production and sales detail?",
	nepali: " कुन कुन दालवालीको उत्पादन तथा बिक्रि विवरण छन् ?",
};
const agriDaalType = [
	{
		nepali: "कालो दाल",
		english: "Black daal",
		value: "black_daal",
	},
	{
		nepali: "सिमि",
		english: "Simi",
		value: "simi",
	},
	{
		nepali: "राजमा",
		english: "Rajma",
		value: "musuri",
	},
	{
		nepali: "मटर /केराउ",
		english: "Matar/Kerau",
		value: "chana",
	},
	{
		nepali: "भट्मास",
		english: "Bhatmas",
		value: "bhatmas",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
// कालो दालखेति गरिएको विवरण
const blackDaalInfo = { english: "", nepali: " कालो दालखेति गरिएको विवरण" };
const blackDaalAreaQuestion = {
	english: "Area of black daal farming",
	nepali: " कालो दालखेति गरिएको क्षेत्रफ़ल्",
};
const blackDaalArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//कालो दालखेति उत्पादन (मुरी पाथीमा)
const blackDaalProduction = {
	english: "Production of black daal in muri and pathi",
	nepali: "कालो दालखेति उत्पादन (मुरी पाथीमा)",
};
const blackDaalProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// कालो दालखेति विक्री परिमाण (मुरी पाथीमा)
const agriBlackDaalSale = {
	english: "Black daal sales quantity (muri and pathi)",
	nepali: " कालो दालखेति विक्री परिमाण (मुरी पाथीमा)",
};
const blackDaalSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// कालो दालखेति END

// रहर दालखेति गरिएको विवरण
const raharInfo = {
	english: "Rahar daal farming detail",
	nepali: " रहर दालखेति गरिएको विवरण",
};
const raharAreaQuestion = {
	english: "Rahar daal farming area",
	nepali: " रहर दालखेति गरिएको क्षेत्रफ़ल",
};
const raharArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//रहर दालखेति उत्पादन (मुरी पाथीमा)
const raharProduction = {
	english: "Production of Rahar daal(muri and pathi)",
	nepali: "रहर दालखेति उत्पादन (मुरी पाथीमा)",
};
const raharProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// रहर दालखेति विक्री परिमाण (मुरी पाथीमा)
const raharSale = {
	english: "Rahar daal sales quantity(muri and pathi)",
	nepali: " रहर दालखेति विक्री परिमाण (मुरी पाथीमा)",
};
const raharSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// रहर दालखेति END

// मुसुरी दालखेति गरिएको विवरण
const musuriInfo = { english: "", nepali: " मुसुरी दालखेति गरिएको विवरण" };
const musuriAreaQuestion = {
	english: "Area of farming mussuri daal",
	nepali: " मुसुरी दालखेति गरिएको क्षेत्रफ़ल",
};
const musuriArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//मुसुरी दालखेति उत्पादन (मुरी पाथीमा)
const musuriProduction = {
	english: "Production of musuri daal (muri and pathi)",
	nepali: "मुसुरी दालखेति उत्पादन (मुरी पाथीमा)",
};
const musuriProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// मुसुरी दालखेति विक्री परिमाण (मुरी पाथीमा)
const musuriSale = {
	english: "Musuri daal sales quantity(muri and pathi)",
	nepali: " मुसुरी दालखेति विक्री परिमाण (मुरी पाथीमा)",
};
const musuriSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// मुसुरी दालखेति END

// चना दालखेति गरिएको विवरण
const chanaInfo = {
	english: "Chana daal farming detail",
	nepali: " चना दालखेति गरिएको विवरण",
};
const chanaAreaQuestion = {
	english: "Chana daal farming area",
	nepali: " चना दालखेति गरिएको क्षेत्रफ़ल",
};
const chanaArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//चना दालखेति उत्पादन (मुरी पाथीमा)
const chanaProduction = {
	english: "Production of chana daal ?",
	nepali: "चना दालखेति उत्पादन (मुरी पाथीमा)",
};
const chanaProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// चना दालखेति विक्री परिमाण (मुरी पाथीमा)
const chanaSale = {
	english: "Chana daal sales quantity(muri and pathi)",
	nepali: " चना दालखेति विक्री परिमाण (मुरी पाथीमा)",
};
const chanaSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// चना दालखेति END
// भट्मास दालखेति गरिएको विवरण
const bhatmasInfo = { english: "", nepali: " भट्मास दालखेति गरिएको विवरण" };
const bhatmasAreaQuestion = {
	english: "Bhatmas daal farming area",
	nepali: " भट्मास दालखेति गरिएको क्षेत्रफ़ल",
};
const bhatmasArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//भट्मास दालखेति उत्पादन (मुरी पाथीमा)
const bhatmasProduction = {
	english: "Bhatmas daal production(muri pathi)",
	nepali: "भट्मास दालखेति उत्पादन (मुरी पाथीमा)",
};
const bhatmasProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// भट्मास दालखेति विक्री परिमाण (मुरी पाथीमा)
const bhatmasSale = {
	english: "Bhatmas daal sale quantity(muri and pathi)",
	nepali: " भट्मास दालखेति विक्री परिमाण (मुरी पाथीमा)",
};
const bhatmasSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// भट्मास दालखेति END
// गहत दालखेति गरिएको विवरण
const gahatInfo = { english: "Gahat ", nepali: " गहत दालखेति गरिएको विवरण" };
const gahatAreaQuestion = {
	english: "Gahat daal farming area",
	nepali: " गहत दालखेति गरिएको क्षेत्रफ़ल",
};
const gahatArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//गहत दालखेति उत्पादन (मुरी पाथीमा)
const gahatProduction = {
	english: "Gahat daal production(muri and pathi)",
	nepali: "गहत दालखेति उत्पादन (मुरी पाथीमा)",
};
const gahatProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// गहत दालखेति विक्री परिमाण (मुरी पाथीमा)
const gahatSale = {
	english: "Gahat daal sales quantity(muri and pathi)",
	nepali: " गहत दालखेति विक्री परिमाण (मुरी पाथीमा)",
};
const gahatSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// गहत दालखेति END

// मस्याम दालखेति गरिएको विवरण
const mashyamInfo = {
	english: "Mashyang daal farming detail",
	nepali: " मस्याम दालखेति गरिएको विवरण",
};
const mashyamAreaQuestion = {
	english: "Mashyang daal farming area ",
	nepali: " मस्याम दालखेति गरिएको क्षेत्रफ़ल",
};
const mashyamArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//मस्याम दालखेति उत्पादन (मुरी पाथीमा)
const mashyamProduction = {
	english: "Mashyang daal production(muri and pathi)",
	nepali: "मस्याम दालखेति उत्पादन (मुरी पाथीमा)",
};
const mashyamProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// मस्याम दालखेति विक्री परिमाण (मुरी पाथीमा)
const mashyamSale = {
	english: "Mashyang daal sale quantity(muri and pathi)",
	nepali: " मस्याम दालखेति विक्री परिमाण (मुरी पाथीमा)",
};
const mashyamSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// मस्याम दालखेति END
//अन्य दालखेति  START
//माथि भन्दा अन्य दालखेति  कति प्रकार को अन्नवाली गरिएको छ ?
const anyDaalKheti = {
	english: "",
	nepali: "माथि भन्दा अन्य दालखेति  कति प्रकार को अन्नवाली गरिएको छ ?",
	value: "",
};
const anyDaalInfo = { english: "", nepali: "अन्य दालखेति गरिएको विवरण" };
const anyDaalInfoTitle = {
	engish: "Mention if any",
	nepali: "अन्य भए उल्लेख गर्नु होस्।",
	value: "",
};
const anyDaalKhetiInfo = {
	english: "Farming detail",
	nepali: "खेति गरिएको विवरण",
	value: "",
};
const anyDaalKhetiAreaQuestion = {
	english: "Other daal farming area ",
	nepali: "अन्य दालखेति  गरिएको क्षेत्रफ़ल्",
};
const anyDaalKhetiArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//अन्य दालखेति  उत्पादन (मुरी पाथीमा)
const anyDaalKhetiProduction = {
	english: "Other daal production(muri and pathi)",
	nepali: "अन्य दालखेति  उत्पादन (मुरी पाथीमा)",
};
const anyDaalKhetiProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//अन्य दालखेति  विक्री परिमाण (मुरी पाथीमा)
const anyDaalKhetiSale = {
	english: "Other daal sale quantity(muri and pathi)",
	nepali: "अन्य दालखेति  विक्री परिमाण (मुरी पाथीमा)",
};
const anyDaalKhetiSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//अन्य दालखेति END
//दाल वालि END

//तेलहन उत्पादन START
//तेलहन उत्पादन तथा बिक्रि विवरण
//If xa vaney   कुन कुन तेलहन उत्पादन तथा बिक्रि विवरण छन् ?
const agriOilTitle = {
	english: "Which oil crop are produced and sales? ",
	nepali: "कुन कुन तेलहन उत्पादन तथा बिक्रि विवरण छन् ?",
};
const agriOilType = [
	{
		nepali: "तोरी/सर्स्यु",
		english: "Tori",
		value: "tori",
	},
	{
		nepali: "आलस/तिल",
		english: "Aalash",
		value: "aalash",
	},
	{
		nepali: "फिलुंगे",
		english: "Filunge",
		value: "filunge",
	},
	{
		nepali: "सिलाम",
		english: "Sunflower",
		value: "sunflower",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
// तोरीखेति गरिएको विवरण
const tooriInfo = {
	english: "Detail of tori farming",
	nepali: " तोरीखेति गरिएको विवरण",
};
const tooriAreaQuestion = {
	english: "Area of tori farming",
	nepali: " तोरीखेति गरिएको क्षेत्रफ़ल्",
};
const tooriArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//तोरी उत्पादन (मुरी पाथीमा)
const tooriProduction = {
	english: "Tori production (muri and pathi)",
	nepali: "तोरीखेति उत्पादन (मुरी पाथीमा)",
};
const tooriProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// तोरीखेति विक्री परिमाण (मुरी पाथीमा)
const tooriSale = {
	english: "Tori sales production",
	nepali: " तोरीखेति विक्री परिमाण (मुरी पाथीमा)",
};
const tooriSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "तोरीखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income from tori?",
		value: "",
	},
];
//तोरीखेति END

//सर्स्युखेति START
// सर्स्युखेति गरिएको विवरण
const suryaInfo = {
	english: "Detail of sarsu farming",
	nepali: " सर्स्युखेति गरिएको विवरण",
};
const suryaAreaQuestion = {
	english: "Area of sarsu farming",
	nepali: " सर्स्युखेति गरिएको क्षेत्रफ़ल्",
};
const suryaArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//सर्स्युखेति उत्पादन (मुरी पाथीमा)
const suryaProduction = {
	english: "Production of sarsu (muri and pathi)",
	nepali: "सर्स्युखेति उत्पादन (मुरी पाथीमा)",
};
const suryaProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// सर्स्युखेति विक्री परिमाण (मुरी पाथीमा)
const suryaSale = {
	english: "Sales quantity of sarsu",
	nepali: " सर्स्युखेति विक्री परिमाण (मुरी पाथीमा)",
};
const suryaSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "सर्स्युखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income from sarsu production?",
		value: "",
	},
];
//सर्स्युखेति END

//आलसखेति START
// आलसखेति गरिएको विवरण
const aalashInfo = {
	english: "Detail of aalash farming",
	nepali: " आलसखेति गरिएको विवरण",
};
const aalashAreaQuestion = {
	english: "Area of aalash farming",
	nepali: " आलसखेति गरिएको क्षेत्रफ़ल्",
};
const aalashArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//आलसखेति उत्पादन (मुरी पाथीमा)
const aalashProduction = {
	english: "Aalash production (muri and pathi)",
	nepali: "आलसखेति उत्पादन (मुरी पाथीमा)",
};
const aalashProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// आलसखेति विक्री परिमाण (मुरी पाथीमा)
const aalashSale = {
	english: "Aalash sales quantity(muri and pathi)",
	nepali: " आलसखेति विक्री परिमाण (मुरी पाथीमा)",
};
const aalashSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "आलसखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income from aalash production?",
		value: "",
	},
];
//आलसखेति END

//फिलुंगेखेति START
// फिलुंगेखेति गरिएको विवरण
const filungeInfo = { english: "", nepali: " फिलुंगेखेति गरिएको विवरण" };
const filungeAreaQuestion = {
	english: "Area of Filunge farming",
	nepali: " फिलुंगेखेति गरिएको क्षेत्रफ़ल्",
};
const filungeArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//फिलुंगेखेति उत्पादन (मुरी पाथीमा)
const filungeProduction = {
	english: "Production of filunge (muri pathi)",
	nepali: "फिलुंगेखेति उत्पादन (मुरी पाथीमा)",
};
const filungeProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// फिलुंगेखेति विक्री परिमाण (मुरी पाथीमा)
const filungeSale = {
	english: "Sales quantity of filunge(muri and pathi)",
	nepali: " फिलुंगेखेति विक्री परिमाण (मुरी पाथीमा)",
};
const filungeSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "फिलुंगेखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income of filunge production?",
		value: "",
	},
];
//फिलुंगेखेति END

//तिल खेति START
// तिल खेति गरिएको विवरण
const tilInfo = {
	english: "Detail of teel farming?",
	nepali: " तिल खेति गरिएको विवरण",
};
const tilAreaQuestion = { english: "", nepali: " तिल खेति गरिएको क्षेत्रफ़ल्" };
const tilArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//तिल खेति उत्पादन (मुरी पाथीमा)
const tilProduction = {
	english: "Teel production(muri pathi)",
	nepali: "तिल खेति उत्पादन (मुरी पाथीमा)",
};
const tilProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// तिल खेति विक्री परिमाण (मुरी पाथीमा)
const tilSale = {
	english: "Sales quantity of teel(muri pathi)",
	nepali: " तिल खेति विक्री परिमाण (मुरी पाथीमा)",
};
const tilSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "तिल खेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income of teel production?",
		value: "",
	},
];
//तिल खेति END

//सूर्यमुखी खेति START
// सूर्यमुखी खेति गरिएको विवरण
const sunflowerInfo = {
	english: "Sunflower farming detail",
	nepali: " सूर्यमुखी खेति गरिएको विवरण",
};
const sunflowerAreaQuestion = {
	english: "Area of sunflower farming",
	nepali: " सूर्यमुखी खेति गरिएको क्षेत्रफ़ल",
};
const sunflowerArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//सूर्यमुखी खेति उत्पादन (मुरी पाथीमा)
const sunflowerProduction = {
	english: "Sunflower production(muri pathi)",
	nepali: "सूर्यमुखी खेति उत्पादन (मुरी पाथीमा)",
};
const sunflowerProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// सूर्यमुखी खेति विक्री परिमाण (मुरी पाथीमा)
const sunflowerSale = {
	english: "Sales quantity of sunflower(muri pathi)",
	nepali: " सूर्यमुखी खेति विक्री परिमाण (मुरी पाथीमा)",
};
const sunflowerSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "सूर्यमुखी खेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income from sunflower?",
		value: "",
	},
];
//सूर्यमुखीखेति END
//अन्य तेलहनखेति  START
//माथि भन्दा अन्य तेलहनखेति  कति प्रकार को अन्नवाली गरिएको छ ?
const anyOilKheti = {
	english: "Types of oilseed",
	nepali: "माथि भन्दा अन्य तेलहनखेति  कति प्रकार को अन्नवाली गरिएको छ ?",
	value: "",
};
const anyOilInfo = {
	english: "Detail of other oilseed",
	nepali: "अन्य तेलहनखेति गरिएको विवरण",
};
const anyOilInfoTitle = {
	engish: "Mention if any",
	nepali: "अन्य भए उल्लेख गर्नु हाेस।",
	value: "",
};
const anyOilKhetiInfo = {
	english: "Detail of farming",
	nepali: "खेति गरिएको विवरण",
	value: "",
};
const anyOilKhetiAreaQuestion = {
	english: "Area of other oilseed farming",
	nepali: "अन्य तेलहनखेति  गरिएको क्षेत्रफ़ल्",
};
const anyOilKhetiArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//अन्य तेलहनखेति  उत्पादन (मुरी पाथीमा)
const anyOilKhetiProduction = {
	english: "Production of other oilseed (muri pathi)",
	nepali: "अन्य तेलहनखेति  उत्पादन (मुरी पाथीमा)",
};
const anyOilKhetiProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//अन्य तेलहनखेति  विक्री परिमाण (मुरी पाथीमा)
const anyOilKhetiSale = {
	english: "Sales quantity of other oilseed",
	nepali: "अन्य तेलहनखेति  विक्री परिमाण (मुरी पाथीमा)",
};
const anyOilKhetiSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//अन्य तेलहनखेति END
//तेलहन END

//तरकारी उत्पादन START
//तरकारी उत्पादन तथा बिक्रि विवरण
//If xa vaney कुन कुन तरकारी उत्पादन तथा बिक्रि विवरण छन् ?
const agriVegetableTitle = {
	english: "Detail of vegetables production and sales",
	nepali: "कुन कुन तरकारी उत्पादन तथा बिक्रि विवरण छन् ?",
};
const agriVegetableType = [
	{
		nepali: "आलु",
		english: "Potato",
		value: "potato",
	},
	{
		nepali: "बन्दा/काउली /बोडी",
		english: "Cabbage",
		value: "cabbage",
	},
	{
		nepali: "गोलभेडा",
		english: "Tomato",
		value: "tomato",
	},
	{
		nepali: "काक्रो/लौका /मुला",
		english: "Cucumber",
		value: "cucumber",
	},
	{
		nepali: "रायो (साग सब्जी )",
		english: "Saag",
		value: "saag",
	},
	{
		nepali: "करेला/घिरौला/फर्सी",
		english: "Ghiaraula",
		value: "bittergroud",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

//आलु खेति START
// आलु खेति गरिएको विवरण
const potatoInfo = {
	english: "Detail of potato farming",
	nepali: " आलु खेति गरिएको विवरण",
};
const potatoAreaQuestion = {
	english: "Area of potato farming",
	nepali: " आलु खेति गरिएको क्षेत्रफ़ल्",
};
const potatoArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//आलुखेति उत्पादन (के.जि.मा )
const potatoProductionQuantity = {
	nepali: "आलुखेति उत्पादन (के.जि.मा )",
	english: "Production of potato (kg)",
	value: "",
};

// आलुखेति विक्री परिमाण (के.जि.मा )
const potatoSaleQuantity = {
	nepali: "आलुखेति विक्री परिमाण (के.जि.मा )",
	english: "Sales quantity of potato(kg)",
	value: "",
};
const potatoSale = {
	nepali: "आलुखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "What is the yearly income from potato?",
	value: "",
};

//आलुखेति END

//बन्दा खेति START
// बन्दा खेति गरिएको विवरण
const cabbageInfo = { english: "", nepali: " बन्दा खेति गरिएको विवरण" };
const cabbageAreaQuestion = {
	english: "Area of cabbage farming",
	nepali: " बन्दा खेति गरिएको क्षेत्रफ़ल्",
};
const cabbageArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//बन्दा उत्पादन (के.जि.मा )
const cabbageProductionQuantity = {
	nepali: "बन्दा उत्पादन (के.जि.मा )",
	english: "Cabbage production(kg)",
	value: "",
};

// बन्दा विक्री परिमाण (के.जि.मा )
const cabbageSaleQuantity = {
	nepali: "बन्दा विक्री परिमाण (के.जि.मा )",
	english: "Sales quantity of cabbage(kg)",
	value: "",
};
const cabbageSale = {
	nepali: "बन्दा उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "What is the yearly income from cabbage?",
	value: "",
};

//बन्दा END

//काउली खेति START
// काउली खेति गरिएको विवरण
const cauliInfo = { english: "", nepali: " काउली खेति गरिएको विवरण" };
const cauliAreaQuestion = {
	english: "",
	nepali: " काउली खेति गरिएको क्षेत्रफ़ल्",
};
const cauliArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//काउली उत्पादन (के.जि.मा )
const cauliProductionQuantity = {
	nepali: "काउली उत्पादन (के.जि.मा )",
	english: "Cauliflower (kg)",
	value: "",
};

// काउली विक्री परिमाण (के.जि.मा )
const cauliSaleQuantity = {
	nepali: "काउली विक्री परिमाण (के.जि.मा )",
	english: "Sales quantity of cauliflower",
	value: "",
};
const cauliSale = {
	nepali: "काउली उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "What is the yearly income from cauliflower?",
	value: "",
};

//काउली END

//बोडी खेति START
// बोडी खेति गरिएको विवरण
const matarkoshaInfo = { english: "", nepali: " मटरकोशा खेति गरिएको विवरण" };
const matarkoshaAreaQuestion = {
	english: "Area of matarkosha farming",
	nepali: " मटरकोशा खेति गरिएको क्षेत्रफ़ल",
};
const matarkoshaArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//बोडी उत्पादन (के.जि.मा )
const matarkoshaProductionQuantity = {
	nepali: "मटरकोशा उत्पादन (के.जि.मा )",
	english: "Production of matarkosha(kg)",
	value: "",
};

// मटरकोशा विक्री परिमाण (के.जि.मा )
const matarkoshaSaleQuantity = {
	nepali: "मटरकोशा विक्री परिमाण (के.जि.मा )",
	english: "Matarkosha sales quantity(kg)",
	value: "",
};
const matarkoshaSale = {
	nepali: "मटरकोशा उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "What is the yearly income from matarkosha?",
	value: "",
};

//मटरकोशा END

// बोडी खेति गरिएको विवरण
const dhanyaInfo = { english: "", nepali: " धन्या खेति गरिएको विवरण" };
const dhanyaAreaQuestion = {
	english: "Area of dhaniya farming",
	nepali: " धन्या खेति गरिएको क्षेत्रफ़ल्",
};
const dhanyaArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//बोडी उत्पादन (के.जि.मा )
const dhanyaProductionQuantity = {
	nepali: "धनिया  (के.जि.मा )",
	english: "Production of dhaniya(kg)",
	value: "",
};

// मटरकोशा विक्री परिमाण (के.जि.मा )
const dhanyaSaleQuantity = {
	nepali: "धनिया बिक्रि परिमाण (के.जी मा)",
	english: "Sales quantity of dhaniya(kg)",
	value: "",
};
const dhanyaSale = {
	nepali: "धन्या उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "What is the yearly income of dhaniya ?",
	value: "",
};

//मटरकोशा END

//सिमि खेति START
// सिमि खेति गरिएको विवरण
const simiInfo = {
	english: "Detail of simi production",
	nepali: "सिमि खेति गरिएको विवरण",
};
const simiAreaQuestion = {
	english: "Area of simi farming",
	nepali: " सिमि खेति गरिएको क्षेत्रफ़ल्",
};
const simiArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//सिमि उत्पादन (के.जि.मा )
const simiProductionQuantity = {
	nepali: "सिमि उत्पादन (के.जि.मा )",
	english: "Production of simi(kg)",
	value: "",
};

// सिमि विक्री परिमाण (के.जि.मा )
const simiSaleQuantity = {
	nepali: "सिमि विक्री परिमाण (के.जि.मा )",
	english: "Sales quantity of simi(kg)",
	value: "",
};
const simiSale = {
	nepali: "सिमि उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "What is the yearly production from simi?",
	value: "",
};

//सिमि END

//गोलभेडा खेति START
// गोलभेडा खेति गरिएको विवरण
const tomatoInfo = { english: "", nepali: " गोलभेडा खेति गरिएको विवरण" };
const tomatoAreaQuestion = {
	english: "Area of tomato farming",
	nepali: " गोलभेडा खेति गरिएको क्षेत्रफ़ल्",
};
const tomatoArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//गोलभेडा उत्पादन (के.जि.मा )
const tomatoProductionQuantity = {
	nepali: "गोलभेडा उत्पादन (के.जि.मा )",
	english: "Tomato production(kg)",
	value: "",
};

// गोलभेडा विक्री परिमाण (के.जि.मा )
const tomatoSaleQuantity = {
	nepali: "गोलभेडा विक्री परिमाण (के.जि.मा )",
	english: "Sales quantity of tomato(kg)",
	value: "",
};
const tomatoSale = {
	nepali: "गोलभेडा उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "What is the yearly imcome from tomato?",
	value: "",
};

//गोलभेडा END

//काक्रो खेति START
// काक्रो खेति गरिएको विवरण
const cucumberInfo = { english: "", nepali: " काक्रो खेति गरिएको विवरण" };
const cucumberAreaQuestion = {
	english: "Area of cucumber farming",
	nepali: " काक्रो खेति गरिएको क्षेत्रफ़ल्",
};
const cucumberArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//काक्रो उत्पादन (के.जि.मा )
const cucumberProductionQuantity = {
	nepali: "काक्रो उत्पादन (के.जि.मा )",
	english: "Production of cucumber",
	value: "",
};

// काक्रो विक्री परिमाण (के.जि.मा )
const cucumberSaleQuantity = {
	nepali: "काक्रो विक्री परिमाण (के.जि.मा )",
	english: "Sales quantity of cucumber",
	value: "",
};
const cucumberSale = {
	nepali: "काक्रो उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "What is the yearly income from cucumber?",
	value: "",
};

//काक्रो END

//लौका खेति START
// लौका खेति गरिएको विवरण
const laukaInfo = { english: "", nepali: " लौका खेति गरिएको विवरण" };
const laukaAreaQuestion = {
	english: "Area of lauka farming",
	nepali: " लौका खेति गरिएको क्षेत्रफ़ल्",
};
const laukaArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//लौका उत्पादन (के.जि.मा )
const laukaProductionQuantity = {
	nepali: "लौका उत्पादन (के.जि.मा )",
	english: "Production of lauka(kg)",
	value: "",
};

// लौका विक्री परिमाण (के.जि.मा )
const laukaSaleQuantity = {
	nepali: "लौका विक्री परिमाण (के.जि.मा )",
	english: "Sales quantity of lauka(kg)",
	value: "",
};
const laukaSale = {
	nepali: "लौका उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "What is the yearly income of lauka?",
	value: "",
};

//लौका END

//फर्सी खेति START
// फर्सी खेति गरिएको विवरण
const saagInfo = { english: "", nepali: " साँग खेति गरिएको विवरण" };
const saagAreaQuestion = {
	english: "Area of saag farming",
	nepali: " साग खेति गरिएको क्षेत्रफ़ल्",
};
const saagArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//साँग उत्पादन (के.जि.मा )
const saagProductionQuantity = {
	nepali: "साँग उत्पादन (के.जि.मा )",
	english: "Saag production(kg)",
	value: "",
};

// साँग विक्री परिमाण (के.जि.मा )
const saagSaleQuantity = {
	nepali: "साँग विक्री परिमाण (के.जि.मा )",
	english: "Sales quantity of saag",
	value: "",
};
const saagSale = {
	nepali: "साँग उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "What is the yearly income of saag?",
	value: "",
};

//साँग END

//पर्वल खेति START
// पर्वल खेति गरिएको विवरण
const parwalInfo = { english: "", nepali: " पर्वल खेति गरिएको विवरण" };
const parwalAreaQuestion = {
	english: "Area of parwal farming",
	nepali: " पर्वल खेति गरिएको क्षेत्रफ़ल्",
};
const parwalArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//पर्वल उत्पादन (के.जि.मा )
const parwalProductionQuantity = {
	nepali: "पर्वल उत्पादन (के.जि.मा )",
	english: "Production of parwal(kg)",
	value: "",
};

// पर्वल विक्री परिमाण (के.जि.मा )
const parwalSaleQuantity = {
	nepali: "पर्वल विक्री परिमाण (के.जि.मा )",
	english: "Sales quantity of parwal(kg)",
	value: "",
};
const parwalSale = {
	nepali: "पर्वल उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "What is the yearly income of parwal?",
	value: "",
};

//पर्वल END

//करेला खेति START
// करेला खेति गरिएको विवरण
const karelaInfo = { english: "", nepali: " करेला खेति गरिएको विवरण" };
const karelaAreaQuestion = {
	english: "Area of karela farming",
	nepali: " करेला खेति गरिएको क्षेत्रफ़ल्",
};
const karelaArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//करेला उत्पादन (के.जि.मा )
const karelaProductionQuantity = {
	nepali: "करेला उत्पादन (के.जि.मा )",
	english: "Production of karela(kg)",
	value: "",
};

// करेला विक्री परिमाण (के.जि.मा )
const karelaSaleQuantity = {
	nepali: "करेला विक्री परिमाण (के.जि.मा )",
	english: "Sales quantity of karela(kg)",
	value: "",
};
const karelaSale = {
	nepali: "करेला उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "What is the yearly income of karela?",
	value: "",
};

//करेला END

//घिरौला खेति START
// घिरौला खेति गरिएको विवरण
const ghiraulaInfo = { english: "", nepali: " घिरौला खेति गरिएको विवरण" };
const ghiraulaAreaQuestion = {
	english: "Area of gharaula farming",
	nepali: " घिरौला खेति गरिएको क्षेत्रफ़ल्",
};
const ghiraulaArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//घिरौला उत्पादन (के.जि.मा )
const ghiraulaProductionQuantity = {
	nepali: "घिरौला उत्पादन (के.जि.मा )",
	english: "Production of ghiraula(kg)",
	value: "",
};

// घिरौला विक्री परिमाण (के.जि.मा )
const ghiraulaSaleQuantity = {
	nepali: "घिरौला विक्री परिमाण (के.जि.मा )",
	english: "Sales quantity of ghiraula(kg)",
	value: "",
};
const ghiraulaSale = {
	nepali: "घिरौला उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "What is the yearly income of ghiraula?",
	value: "",
};

//घिरौला END

//चिचिन्दो खेति START
// चिचिन्दो खेति गरिएको विवरण
const chichindoInfo = { english: "", nepali: " चिचिन्दो खेति गरिएको विवरण" };
const chichindoAreaQuestion = {
	english: "Area of chichindo farming",
	nepali: " चिचिन्दो खेति गरिएको क्षेत्रफ़ल्",
};
const chichindoArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//चिचिन्दो उत्पादन (के.जि.मा )
const chichindoProductionQuantity = {
	nepali: "चिचिन्दो उत्पादन (के.जि.मा )",
	english: "Production of chichindo(kg)",
	value: "",
};

// चिचिन्दो विक्री परिमाण (के.जि.मा )
const chichindoSaleQuantity = {
	nepali: "चिचिन्दो विक्री परिमाण (के.जि.मा )",
	english: "Sales quantity of chichindo(kg) ",
	value: "",
};
const chichindoSale = {
	nepali: "चिचिन्दो उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "What is the yearly income of chichindo?",
	value: "",
};

//चिचिन्दो END

//अन्य तरकारी  START
//माथि भन्दा अन्यखेति कति प्रकार को तरकारी वाली गरिएको छ ?
const anyTarkariKheti = {
	english: "Types of crops",
	nepali: "माथि भन्दा अन्यखेति कति प्रकार को तरकारी वाली गरिएको छ ?",
	value: "",
};
const anyTarkariInfo = {
	english: "Detail of other crops farming",
	nepali: "अन्य तरकारी खेति गरिएको विवरण",
};
const anyTarkariInfoTitle = {
	engish: "Mention if any",
	nepali: "अन्य भए उल्लेख गर्नु हाेस।",
	value: "",
};
const anyTarkariKhetiInfo = {
	english: "Farming detail",
	nepali: "खेति गरिएको विवरण",
	value: "",
};
const anyTarkariKhetiAreaQuestion = {
	english: "Other vegetable farming area",
	nepali: "अन्य तरकारीखेति गरिएको क्षेत्रफ़ल्",
};
const anyTarkariKhetiArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//अन्य उत्पादन (के.जि.मा )
const anyaProductionQuantity = {
	nepali: " चिचीन्ड़ो खेति उत्पादन (के.जि.मा)",
	english: "production farming (kg)",
	value: "",
};

// चिचिन्दो विक्री परिमाण (के.जि.मा )
const anyaSaleQuantity = {
	nepali: "खेति विक्री परिमाण (के.जि.मा)",
	english: "",
	value: "",
};
const anyaSale = {
	nepali: "खेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "",
	value: "",
};
//अन्य तरकारी END
//तरकारी END

//फलफुल START
//फलफुल उत्पादन तथा बिक्रि विवरण
//If xa vaney   कुन कुन फलफुल उत्पादन तथा बिक्रि विवरण छन् ?
const agriFruitTitle = {
	english: "Detail of fruits production",
	nepali: "फलफुल उत्पादन विवरण सुची ?",
};
const agriFruitType = [
	{
		nepali: "आप/लिचि",
		english: "Mango",
		value: "mango",
	},
	{
		nepali: "केरा",
		english: "Banana",
		value: "banana",
	},
	{
		nepali: "सुन्तला/जुनार /कागती (सुन्तला जात )",
		english: "Orange",
		value: "orange",
	},
	{
		nepali: "स्याउ",
		english: "Apple",
		value: "apple",
	},
	{
		nepali: "किवी",
		english: "Kiwi",
		value: "kiwi",
	},
	{
		nepali: "अभोगाड़ो",
		english: "Avagado",
		value: "avogado",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
// आपखेति गरिएको विवरण
const mangoInfo = {
	english: "Detail of mango farming",
	nepali: "आपखेति गरिएको विवरण",
};
const mangoAreaQuestion = { english: "", nepali: "आपखेति गरिएको क्षेत्रफ़ल्" };
const mangoArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//आपखेतिि उत्पादन (मुरी पाथीमा)
const mangoProduction = {
	english: "Mango production",
	nepali: "आपखेति उत्पादन (मुरी पाथीमा)",
};
const mangoProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// आपखेतिि विक्री परिमाण (मुरी पाथीमा)
const mangoSale = {
	english: "Sales quantity of mango",
	nepali: "आपखेति विक्री परिमाण (मुरी पाथीमा)",
};
const mangoSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "आपखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income of mango?",
		value: "",
	},
];
//आपखेति END

//लिची START
// आरुबखडाखेति गरिएको विवरण
const aarubakhadaInfo = { english: "", nepali: " आरुबखडाखेति गरिएको विवरण" };
const aarubakhadaAreaQuestion = {
	english: "Area of arubakhada farming",
	nepali: "आरुबखडाखेति गरिएको क्षेत्रफ़ल्",
};
const aarubakhadaArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//आरुबखडाखेति उत्पादन (मुरी पाथीमा)
const aarubakhadaProduction = {
	english: "Production of arubakhada(muri pathi)",
	nepali: "आरुबखडाखेति उत्पादन (मुरी पाथीमा)",
};
const aarubakhadaProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// विक्री परिमाण (मुरी पाथीमा)
const aarubakhadaSale = {
	english: "Sales quantity of arubakhada(muri pathi)",
	nepali: "आरुबखडाखेति विक्री परिमाण (मुरी पाथीमा)",
};
const aarubakhadaSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "आरुबखडाखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income of arubhakada?",
		value: "",
	},
];
//आरुबखडाखेति END

//केरा START
// केराखेति गरिएको विवरण
const bananaInfo = {
	english: "Detail of banana farming",
	nepali: " केराखेति गरिएको विवरण",
};
const bananaAreaQuestion = {
	english: "Area of banana farming",
	nepali: " केराखेति गरिएको क्षेत्रफ़ल",
};
const bananaArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//केरा उत्पादन (मुरी पाथीमा)
const bananaProduction = {
	english: "Production of banana(muri pathi)",
	nepali: "केरा उत्पादन (मुरी पाथीमा)",
};
const bananaProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// विक्री परिमाण (मुरी पाथीमा)
const bananaSale = {
	english: "Sales quantity of banana(muri pathi)",
	nepali: "केरा विक्री परिमाण (मुरी पाथीमा)",
};
const bananaSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "केराखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income of banana?",
		value: "",
	},
];
//केरा END

//सुन्तलाखेति START
// सुन्तलाखेति गरिएको विवरण
const orangeInfo = { english: "", nepali: " सुन्तलाखेति गरिएको विवरण" };
const orangeAreaQuestion = {
	english: "Area of orange farming",
	nepali: " सुन्तलाखेति गरिएको क्षेत्रफ़ल्",
};
const orangeArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//सुन्तला उत्पादन (मुरी पाथीमा)
const orangeProduction = {
	english: "Production of orange (muri pathi)",
	nepali: "सुन्तला उत्पादन (मुरी पाथीमा)",
};
const orangeProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// विक्री परिमाण (मुरी पाथीमा)
const orangeSale = {
	english: "Sales quantity of orange(muri pathi)",
	nepali: "सुन्तला विक्री परिमाण (मुरी पाथीमा)",
};
const orangeSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "सुन्तलाखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income of orange?",
		value: "",
	},
];
//सुन्तलाखेति END

//अम्बकखेति START
// अम्बकखेति गरिएको विवरण
const guvaInfo = {
	english: "Detail of guava farming",
	nepali: "अम्बकखेति गरिएको विवरण",
};
const guvaAreaQuestion = {
	english: "Area of guava farming",
	nepali: " अम्बकखेति गरिएको क्षेत्रफ़ल",
};
const guvaArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//जुनार उत्पादन (मुरी पाथीमा)
const guvaProduction = {
	english: "Production of guava ",
	nepali: "अम्बकखेति उत्पादन (मुरी पाथीमा)",
};
const guvaProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//जुनार विक्री परिमाण (मुरी पाथीमा)
const guvaSale = {
	english: "Sales quantity of guava(muri pathi)",
	nepali: "अम्बकखेति विक्री परिमाण (मुरी पाथीमा)",
};
const guvaSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "अम्बकखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income of guava?",
		value: "",
	},
];
//अम्बकखेति खेति END

//कागतीखेति START
// कागतीखेति गरिएको विवरण
const lemonInfo = {
	english: "Detail of lemon farming",
	nepali: " कागतीखेति गरिएको विवरण",
};
const lemonAreaQuestion = {
	english: "Area of lemon farming",
	nepali: " कागतीखेति गरिएको क्षेत्रफ़ल",
};
const lemonArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//कागती उत्पादन (मुरी पाथीमा)
const lemonProduction = {
	english: "Production of lemon(muri pathi)",
	nepali: "कागती उत्पादन (मुरी पाथीमा)",
};
const lemonProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//कागती विक्री परिमाण (मुरी पाथीमा)
const lemonSale = {
	english: "Sales quantity of lemon(muri pathi)",
	nepali: "कागती विक्री परिमाण (मुरी पाथीमा)",
};
const lemonSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "कागती उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income of lemon?",
		value: "",
	},
];
//कागती खेति END

//स्याउखेति START
// स्याउखेति गरिएको विवरण
const appleInfo = {
	english: "Detail of apple farming",
	nepali: " स्याउखेति गरिएको विवरण",
};
const appleAreaQuestion = {
	english: "Area of apple farming",
	nepali: " स्याउखेति गरिएको क्षेत्रफ़ल",
};
const appleArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//स्याउ उत्पादन (मुरी पाथीमा)
const appleProduction = {
	english: "Production of apple(muri pathi)",
	nepali: "स्याउ उत्पादन (मुरी पाथीमा)",
};
const appleProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//स्याउ विक्री परिमाण (मुरी पाथीमा)
const appleSale = {
	english: "Sales quantity of apple(muri pathi) ",
	nepali: "स्याउ विक्री परिमाण (मुरी पाथीमा)",
};
const appleSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "स्याउ उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income of apple?",
		value: "",
	},
];
//स्याउ खेति END

//किवीखेति START
// किवीखेति गरिएको विवरण
const kiwiInfo = {
	english: "Detail of kiwi farming",
	nepali: " किवीखेति गरिएको विवरण",
};
const kiwiAreaQuestion = {
	english: "Area of kiwi farming",
	nepali: " किवीखेति गरिएको क्षेत्रफ़ल",
};
const kiwiArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//किवी उत्पादन (मुरी पाथीमा)
const kiwiProduction = {
	english: "Production of kiwi(muri pathi)",
	nepali: "किवी उत्पादन (मुरी पाथीमा)",
};
const kiwiProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//किवी विक्री परिमाण (मुरी पाथीमा)
const kiwiSale = {
	english: "Sales quantity of kiwi(muri pathi)",
	nepali: "किवी विक्री परिमाण (मुरी पाथीमा)",
};
const kiwiSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "किवी उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income of kiwi?",
		value: "",
	},
];
//किवी खेति END

// नासपातीखेति START
// नासपातीखेति गरिएको विवरण
const naspatiInfo = {
	english: "Detail of pear farming",
	nepali: " नासपातीखेति गरिएको विवरण",
};
const naspatiAreaQuestion = {
	english: "Area of pear farming",
	nepali: " नासपातीखेति गरिएको क्षेत्रफ़ल",
};
const naspatiArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//नासपाती उत्पादन (मुरी पाथीमा)
const naspatiProduction = {
	english: "Production of pear(muri pathi)",
	nepali: "नासपाती उत्पादन (मुरी पाथीमा)",
};
const naspatiProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//नासपाती विक्री परिमाण (मुरी पाथीमा)
const naspatiSale = {
	english: "Sales quantity of pear(muri pathi)",
	nepali: "नासपाती विक्री परिमाण (मुरी पाथीमा)",
};
const naspatiSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "नासपाती उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income of pear?",
		value: "",
	},
];
//नासपाती खेति END

// अभोगाड़ो START
// अभोगाड़ो गरिएको विवरण
const avogadoInfo = {
	english: "Detail of avogado farming",
	nepali: " अभोगाड़ो गरिएको विवरण",
};
const avogadoAreaQuestion = {
	english: "Area of avogado farming",
	nepali: " अभोगाड़ो गरिएको क्षेत्रफ़ल",
};
const avogadoArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//अभोगाड़ो उत्पादन (मुरी पाथीमा)
const avogadoProduction = {
	english: "Production of avogado(muri pathi)",
	nepali: "अभोगाड़ो उत्पादन (मुरी पाथीमा)",
};
const avogadoProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//अभोगाड़ो विक्री परिमाण (मुरी पाथीमा)
const avogadoSale = {
	english: "Sales quantity of avogado(muri pathi) ",
	nepali: "अभोगाड़ो विक्री परिमाण (मुरी पाथीमा)",
};
const avogadoSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "अभोगाड़ो उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income of avogado?",
		value: "",
	},
];
//अभोगाड़ो खेति END

//अन्यखेति START
//माथि भन्दा अन्यखेति कति प्रकार को फलफुल खेति गरिएको छ ?
const agriAnyFruit = {
	english: "Different crops farm",
	nepali: "माथि भन्दा अन्यखेति कति प्रकार को फलफुल खेति गरिएको छ ?",
	value: "",
};
const agriAnyFruitInfo = {
	english: "Detail of other fruits farming",
	nepali: "अन्य फलफुल खेति गरिएको विवरण",
};
const agriAnyInfoFruitTitle = {
	engish: "Mention if any",
	nepali: "अन्य भए उल्लेख गर्नु हाेस।",
	value: "",
};
const agriAnyFruitKhetiInfo = {
	english: "Detail about farming",
	nepali: "खेति गरिएको विवरण",
	value: "",
};
const agriAnyFruitKhetiAreaQuestion = {
	english: "Area of farming",
	nepali: "अन्यखेति गरिएको क्षेत्रफ़ल्",
};
const agriAnyFruitKhetiArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//अन्यखेति उत्पादन (मुरी पाथीमा)
const agriAnyFruitKhetiProduction = {
	english: "Different crops production",
	nepali: "अन्यखेति उत्पादन (मुरी पाथीमा)",
};
const agriAnyFruitKhetiProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//अन्यखेति विक्री परिमाण (मुरी पाथीमा)
const agriAnyFruitKhetiSale = {
	english: "Other farming detail",
	nepali: "अन्यखेति विक्री परिमाण (मुरी पाथीमा)",
};
const agriAnyFruitKhetiSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "अन्यखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "What is the yearly income of other crops?",
		value: "",
	},
];
//अन्यखेति END
//फलफुल END

//नगदेवाली START

//नगदेवाली उत्पादन तथा बिक्रि विवरण
//If xa vaney   कुन कुन नगदेवाली उत्पादन तथा बिक्रि विवरण छन् ?
const cashCropTitle = {
	english: "Production of cash crop detail",
	nepali: "नगदेवाली उत्पादन विवरण सुची",
};
const cashCropType = [
	{
		nepali: "अलैची",
		english: "Alaichi",
		value: "alaichi",
	},
	{
		nepali: "रुद्राक्ष(दाना)",
		english: "Rudraskh",
		value: "rudraksh",
	},
	{
		nepali: "अम्रिसो",
		english: "Amriso",
		value: "amriso",
	},
	{
		nepali: "कफी",
		english: "Coffee",
		value: "coffee",
	},
	{
		nepali: "चिया",
		english: "Tea",
		value: "tea",
	},
	{
		nepali: "अदुवा/बेसार",
		english: "Ginger",
		value: "ginger",
	},
	{
		nepali: "लसुन/प्याज",
		english: "Garlic",
		value: "garlic",
	},
	{
		nepali: "च्याउ",
		english: "Mushroom",
		value: "mushroom",
	},
	{
		nepali: "उखु",
		english: "Suagrcane",
		value: "sugarcane",
	},
	{
		nepali: "बदाम",
		english: "Nut",
		value: "nut",
	},
	{
		nepali: "खुर्सानी",
		english: "Chilly",
		value: "chilly",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
// धसिंगरेखेति गरिएको विवरण
const dhasingareInfo = {
	english: "Detailof dhasingre ",
	nepali: "धसिंगरेखेति गरिएको विवरण",
};
const dhasingareAreaQuestion = {
	english: "Area of dhisingare farm",
	nepali: "धसिंगरेखेति गरिएको क्षेत्रफ़ल",
};
const dhasingareArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//धसिंगरेखेति उत्पादन (मुरी पाथीमा)
const dhasingareProduction = {
	english: "Production of dhasingare(muri pathi)",
	nepali: "धसिंगरेखेति उत्पादन (मुरी पाथीमा)",
};
const dhasingareProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// धसिंगरेखेति विक्री परिमाण (मुरी पाथीमा)
const dhasingareSale = {
	english: "Sales quantity of dhasingare(muri pathi)",
	nepali: "धसिंगरेखेति विक्री परिमाण (मुरी पाथीमा)",
};
const dhasingareSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//धसिंगरेखेति END

//अलैचीखेति START
// अलैचीखेति गरिएको विवरण
const alaichiInfo = {
	english: "Detail of alaichi farming",
	nepali: "अलैचीखेति गरिएको विवरण",
};
const alaichiAreaQuestion = {
	english: "Area of alaichi faraming",
	nepali: "अलैचीखेति गरिएको क्षेत्रफ़ल",
};
const alaichiArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//अलैचीखेति उत्पादन (मुरी पाथीमा)
const alaichiProduction = {
	english: "Production of alaichi(muri pathi)",
	nepali: "अलैचीखेति उत्पादन (मुरी पाथीमा)",
};
const alaichiProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
// अलैचीखेति विक्री परिमाण (मुरी पाथीमा)
const alaichiSale = {
	english: "Sales quantity of alaichi(muri pathi) ",
	nepali: "अलैचीखेति विक्री परिमाण (मुरी पाथीमा)",
};
const alaichiSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//अलैचीखेति END

//रुद्राक्ष START
// रुद्राक्षखेति गरिएको विवरण
const rudrakshInfo = {
	english: "Detail of rudrakshya farming",
	nepali: "रुद्राक्षखेति गरिएको विवरण",
};
const rudrakshAreaQuestion = {
	english: "Area of rudrakshya farming",
	nepali: "रुद्राक्षखेति गरिएको क्षेत्रफ़ल्",
};
const rudrakshArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//रुद्राक्षको बोट संख्या
const rudrakshProduction = {
	english: "No of rudrakshya plant",
	nepali: "रुद्राक्षको बोट संख्या",
	value: "",
};

// रुद्राक्षखेति विक्री परिमाण (के.जि.मा)
const rudrakshSale = {
	english: "What is the yearly income of rudrakshya?",
	nepali:
		"रुद्राक्ष(दाना) खेति उत्पादन वाट बार्षिक बिक्रि कति हो ? (के.जि. मा)",
	value: "",
};
//रुद्राक्षखेति END

//अम्रिसोखेति START
// अम्रिसोखेति गरिएको विवरण
const amrisoInfo = {
	english: "Detail of amriso farming",
	nepali: "अम्रिसोखेति गरिएको विवरण",
};
const amrisoAreaQuestion = {
	english: "Area of amriso farming",
	nepali: "अम्रिसोखेति गरिएको क्षेत्रफ़ल",
};
const amrisoArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//अम्रिसोखेति उत्पादन (मुरी पाथीमा)
const amrisoProduction = {
	english: "Production of amriso(muri pathi)",
	nepali: "अम्रिसोखेति उत्पादन (मुरी पाथीमा)",
};
const amrisoProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];

// अम्रिसोखेति विक्री परिमाण (मुरी पाथीमा)
const amrisoSale = {
	english: "Sales quantity of amriso(muri pathi)",
	nepali: "अम्रिसोखेति विक्री परिमाण (मुरी पाथीमा)",
};
const amrisoSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//अम्रिसोखेति END

//कफीखेति START
// कफीखेति गरिएको विवरण
const coffeeInfo = {
	english: "Detail of coffee farming",
	nepali: "कफीखेति गरिएको विवरण",
};
//कफीखेति उत्पादन (मुरी पाथीमा)
const coffeeTreeNumber = {
	nepali: "कफीखेति गरिएको बोट संख्या",
	english: "No of coffee plant",
	value: "",
};
const coffeeProduction = {
	nepali: "कफीखेति उत्पादन (के.जि.मा)",
	english: "Production of coffee (kg)",
	value: "",
};
// कफीखेति विक्री परिमाण (मुरी पाथीमा)
const coffeeSale = {
	nepali: "कफीखेति विक्री परिमाण (के.जि.मा)",
	english: "Sales quantity of coffee(kg)",
	value: "",
};
//कफीखेति END

//चियाखेति START
// चियाखेति गरिएको विवरण
const teaInfo = {
	english: "Detail of tea farming",
	nepali: "चियाखेति गरिएको विवरण",
};
const teaAreaQuestion = { english: "", nepali: "चियाखेति गरिएको क्षेत्रफ़ल्" };
const teaArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//चियाखेति बोट संख्या
const teaProduction = {
	nepali: "चियाखेति उत्पादन (के.जि.मा)",
	english: "Production of tea(kg)",
	value: "",
};

// चियाखेति विक्री परिमाण (के.जि.मा)
const teaSale = {
	english: "Sales quantity of tea(kg)",
	nepali: "चियाखेति विक्री परिमाण (के.जि.मा)",
	value: "",
};
//चियाखेति END

//निबुवाखेति START
// निबुवाखेति गरिएको विवरण
const nibuwaInfo = {
	english: "Detail of nibuwa farm",
	nepali: "निबुवाखेति गरिएको विवरण",
};
const nibuwaAreaQuestion = {
	english: "Area of nibuwa farming",
	nepali: "निबुवाखेति गरिएको क्षेत्रफ़ल",
};
const nibuwaArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//निबुवाखेति उत्पादन (मुरी पाथीमा)
const nibuwaProduction = {
	english: "Production of nibuwa(muri pathi)",
	nepali: "निबुवाखेति उत्पादन (मुरी पाथीमा)",
};
const nibuwaProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];

// निबुवाखेति विक्री परिमाण (मुरी पाथीमा)
const nibuwaSale = {
	english: "Sales quantity of nibuwa(muri pathi)",
	nepali: "निबुवाखेति विक्री परिमाण (मुरी पाथीमा)",
};
const nibuwaSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//निबुवाखेति END

//अदुवाखेति START
// अदुवाखेति गरिएको विवरण
const gingerInfo = { english: "", nepali: "अदुवाखेति गरिएको विवरण" };
const gingerAreaQuestion = {
	english: "Area of ginger farming",
	nepali: "अदुवाखेति गरिएको क्षेत्रफ़ल",
};
const gingerArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//अदुवाखेति उत्पादन (मुरी पाथीमा)
const gingerProduction = {
	english: "Production of ginger(muri pathi)",
	nepali: "अदुवाखेति उत्पादन (मुरी पाथीमा)",
};
const gingerProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];

// अदुवाखेति विक्री परिमाण (मुरी पाथीमा)
const gingerSale = {
	english: "Sales quantity of ginger ",
	nepali: "अदुवाखेति विक्री परिमाण (मुरी पाथीमा)",
};
const gingerSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//अदुवाखेति END

//बेसारखेति START
// बेसारखेति गरिएको विवरण
const besaarInfo = {
	english: "Detail of beshar farming",
	nepali: "बेसारखेति गरिएको विवरण",
};
const besaarAreaQuestion = {
	english: "Area of beshar farming",
	nepali: "बेसारखेति गरिएको क्षेत्रफ़ल",
};
const besaarArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//बेसारखेति उत्पादन (मुरी पाथीमा)
const besaarProduction = {
	english: "Production of beshar(muri pathi)",
	nepali: "बेसारखेति उत्पादन (मुरी पाथीमा)",
};
const besaarProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];

// बेसारखेति विक्री परिमाण (मुरी पाथीमा)
const besaarSale = {
	english: "Sales quantity of beshar(muri pathi)",
	nepali: "बेसारखेति विक्री परिमाण (मुरी पाथीमा)",
};
const besaarSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//बेसारखेति END

//लसुनखेति START
// लसुनखेति गरिएको विवरण
const garlicInfo = {
	english: "Detail of fgarlic farming",
	nepali: "लसुनखेति गरिएको विवरण",
};
const garlicAreaQuestion = {
	english: "Area of garlic farming",
	nepali: "लसुनखेति गरिएको क्षेत्रफ़ल",
};
const garlicArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//लसुनखेति उत्पादन (मुरी पाथीमा)
const garlicProduction = {
	english: "Production of garlic(muri pathi)",
	nepali: "लसुनखेति उत्पादन (मुरी पाथीमा)",
};
const garlicProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];

// लसुनखेति विक्री परिमाण (मुरी पाथीमा)
const garlicSale = {
	english: "Sales quantity of garlic (muri pathi)",
	nepali: "लसुनखेति विक्री परिमाण (मुरी पाथीमा)",
};
const garlicSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//लसुनखेति END

//प्याजखेति START
// प्याजखेति गरिएको विवरण
const onionInfo = {
	english: "Detail of onion farming",
	nepali: "प्याजखेति गरिएको विवरण",
};
const onionAreaQuestion = {
	english: "Area of onion farming",
	nepali: "प्याजखेति गरिएको क्षेत्रफ़ल",
};
const onionArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//प्याजखेति उत्पादन (मुरी पाथीमा)
const onionProduction = {
	english: "Production of onion (muri pathi)",
	nepali: "प्याजखेति उत्पादन (मुरी पाथीमा)",
};
const onionProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];

// प्याजखेति विक्री परिमाण (मुरी पाथीमा)
const onionSale = {
	english: "Sales quantity of onion (muri pathi)",
	nepali: "प्याजखेति विक्री परिमाण (मुरी पाथीमा)",
};
const onionSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//प्याजखेति END

//च्याउखेति START
// च्याउखेति गरिएको विवरण
const mushroomInfo = {
	english: "Detail of mushroom farming",
	nepali: "च्याउखेति गरिएको विवरण",
};
const mushroomAreaQuestion = {
	english: "Area of mushroom farming",
	nepali: "च्याउखेति गरिएको क्षेत्रफ़ल",
};
const mushroomArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//च्याउखेति उत्पादन (मुरी पाथीमा)
const mushroomProduction = {
	english: "Production of mushroom(muri pathi)",
	nepali: "च्याउखेति उत्पादन (मुरी पाथीमा)",
};
const mushroomProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];

// च्याउखेति विक्री परिमाण (मुरी पाथीमा)
const mushroomSale = {
	english: "Sales quantity of mushroom(muri pathi)",
	nepali: "च्याउखेति विक्री परिमाण (मुरी पाथीमा)",
};
const mushroomSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//च्याउखेति END

//उखुखेति START
// उखुखेति गरिएको विवरण
const sugarcaneInfo = {
	english: "Detail of sugarcane production",
	nepali: "उखुखेति गरिएको विवरण",
};
const sugarcaneAreaQuestion = {
	english: "Area of sugarcane farming",
	nepali: "उखुखेति गरिएको क्षेत्रफ़ल",
};
const sugarcaneArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//उखुखेति उत्पादन (मुरी पाथीमा)
const sugarcaneProduction = {
	english: "Production of sugarcane(muri pathi)",
	nepali: "उखुखेति उत्पादन (मुरी पाथीमा)",
};
const sugarcaneProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];

// उखुखेति विक्री परिमाण (मुरी पाथीमा)
const sugarcaneSale = {
	english: "Sales quantity of sugarcane (muri pathi)",
	nepali: "उखुखेति विक्री परिमाण (मुरी पाथीमा)",
};
const sugarcaneSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//उखुखेति END

//बदामखेति START
// बदामखेति गरिएको विवरण
const nutInfo = {
	english: "Detail of nut farming",
	nepali: "बदामखेति गरिएको विवरण",
};
const nutAreaQuestion = {
	english: "Area of nut farming",
	nepali: "बदामखेति गरिएको क्षेत्रफ़ल",
};
const nutArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//बदामखेति उत्पादन (मुरी पाथीमा)
const nutProduction = {
	english: "Production of nut (muri pathi)",
	nepali: "बदामखेति उत्पादन (मुरी पाथीमा)",
};
const nutProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];

// बदामखेति विक्री परिमाण (मुरी पाथीमा)
const nutSale = {
	english: "Sales quantity of nut (muri pathi)",
	nepali: "बदामखेति विक्री परिमाण (मुरी पाथीमा)",
};
const nutSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//बदामखेति END

//खुर्सानीखेति START
// खुर्सानीखेति गरिएको विवरण
const chillyInfo = {
	english: "Detail of chilly farming",
	nepali: "खुर्सानीखेति गरिएको विवरण",
};
const chillyAreaQuestion = {
	english: "Area of chilly production",
	nepali: "खुर्सानीखेति गरिएको क्षेत्रफ़ल",
};
const chillyArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//खुर्सानीखेति उत्पादन (मुरी पाथीमा)
const chillyProduction = {
	english: "Production of chilly (muri pathi)",
	nepali: "खुर्सानीखेति उत्पादन (मुरी पाथीमा)",
};
const chillyProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];

// खुर्सानीखेति विक्री परिमाण (मुरी पाथीमा)
const chillySale = {
	english: "Sales quantity of chilly(muri pathi)",
	nepali: "खुर्सानीखेति विक्री परिमाण (मुरी पाथीमा)",
};
const chillySaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//खुर्सानीखेति END
//अन्य नगदेबाली  START
//माथि भन्दा अन्य नगदेबाली कति प्रकार को अन्नवाली गरिएको छ ?
const anyCashCropKheti = {
	english: "Different kind of crops farming",
	nepali: "माथि भन्दा अन्य नगदेबाली कति प्रकार को अन्नवाली गरिएको छ ?",
	value: "",
};
const anyCashCropKhetiInfo = {
	english: "Different cash crop farming detail",
	nepali: "अन्य नगदेबाली गरिएको विवरण",
};
const anyCashCropInfoTitle = {
	engish: "Mention if any",
	nepali: "अन्य भए उल्लेख गर्नु हाेस।",
	value: "",
};
const anyCashCropInfo = {
	english: "Detail of farming",
	nepali: "खेति गरिएको विवरण",
	value: "",
};
const anyCashCropAreaQuestion = {
	english: "Area of other cashcrop",
	nepali: "अन्य नगदेबाली  गरिएको क्षेत्रफ़ल",
};
const anyCashCropArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//अन्य नगदेबाली  उत्पादन (मुरी पाथीमा)
const anyCashCropProduction = {
	english: "Other cashsrop production(muri pathi)",
	nepali: "अन्य नगदेबाली  उत्पादन (मुरी पाथीमा)",
};
const anyCashCropProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//अन्य नगदेबाली  विक्री परिमाण (मुरी पाथीमा)
const anyCashCropSale = {
	english: "Sales quantity of other cashcrop(muri pathi)",
	nepali: "अन्य नगदेबाली विक्री परिमाण (मुरी पाथीमा)",
};
const anyCashCropSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//अन्य नगदेबाली END
//नगदेवाली END

// फुलखेती गरिएको विवरण
const flowerTitle = {
	english: "Flower farming",
	nepali: "फुलखेती",
};
const flowerAreaQuestion = {
	english: "Area of flower farming",
	nepali: "फुलखेती गरिएको क्षेत्रफ़ल्",
};
const flowerArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//फुलखेती उत्पादन (मुरी पाथीमा)
const flowerProduction = {
	english: "Production of flower",
	nepali: "फुलखेतीको उत्पादन",
	value: "",
};

// फुलखेती विक्री परिमाण (मुरी पाथीमा)
const flowerSale = {
	english: "Sales quantity of flower (kg)",
	nepali: "विक्री परिमाण (के.जी. मा)",
	value: "",
};
const flowerSaleQuantity = {
	nepali: " फुलखेती उत्पादन वाट बार्षिक आम्दानी कति हो ?",
	english: "What is the yearly income of flower?",
	value: "",
};

//फुलखेती END

//किट नाशक औषधि/मल को प्रयोग  START
const insecticideQuestion = {
	nepali: "कृषि बाली अनुसार किट नाशक औषधि/मल को प्रयोग गर्नु भएको छ ?",
	english: "Do you use insecticide and pesticide in crops?",
};
const insecticideYesNo = [
	{
		nepali: "छ",
		english: "yes",
		value: "chemical_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "chemical_no",
	},
];
//if xa vanye
const insecticideYes = {
	nepali: "वाली अनुसार मलको प्रयोग गर्नु भएको छ भने ?",
	english: "Do you use fertilizer according to crops?",
};
const insecticideType = [
	{
		nepali: "स्थानीय अर्गानिक",
		english: "Organic",
		value: "organic",
	},
	{
		nepali: "रासायनिक किट नासक औषधि",
		english: "Chemical fertilizer",
		value: "chemical",
	},
	{
		nepali: "दुवै",
		english: "Both",
		value: "both_org_chem",
	},
	{
		nepali: "छैन",
		english: "No",
		value: "no",
	},
];
//किट नाशक औषधि/मल को प्रयोग  END

//पशुपन्छी START
const livestockQuestion = {
	nepali: "तपाइको घरमा कुनै पशुपन्छी पाल्नु भएको छ ?",
	english: "Do u rear animals in your home?",
};
const livestockYesNo = [
	{
		nepali: "छ",
		english: "yes",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "no",
	},
];
//if xa vanye
const livestockYes = {
	nepali: "के के पाल्नु भएको छ ?",
	english: "Which animal you rear?",
};
const livestockType = [
	{
		nepali: "पशु",
		english: "Animal",
		value: "animal",
	},
	{
		nepali: "पन्छी",
		english: "Bird",
		value: "bird",
	},
];
//if पशु xa vaney
//If xa vaney कुन कुन पशु पाल्नु भएको छ ?
const livestockTitle = {
	english: "Which animal you rear?",
	nepali: "कुन कुन पशु पाल्नु भएको छ ?",
};
const livestockDiffType = [
	{
		nepali: "गाई/बाछी",
		english: "Cow",
		value: "cow",
	},
	{
		nepali: "भैसी",
		english: "Bufffalo",
		value: "buffallo",
	},
	{
		nepali: "खसी /बोका/बाख्रा",
		english: "Goat/boka",
		value: "goat/boka",
	},
	{
		nepali: "राँगा/पाडा/पाडी",
		english: "Raanga/paada/padi",
		value: "ranga",
	},
	{
		nepali: "सुँगुर/बंगुर",
		english: "Pig",
		value: "pig",
	},
	{
		nepali: "भेडा/च्यांग्रा",
		english: "Sheep",
		value: "sheep",
	},
	{
		nepali: "गोरु",
		english: "Goru",
		value: "garu",
	},
	{
		nepali: "गधा/घोडा/ खच्चड",
		english: "Donkey",
		value: "gadha",
	},
	{
		nepali: "याक",
		english: "Yak",
		value: "yak",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
//गाई START
// गाई  विवरण
const cowInfo = {
	english: "Detail of cow rearing",
	nepali: "गाई सम्बन्धी विवरण",
};
const cowNumber = {
	nepali: "गाईको संख्या",
	english: "No of cow",
	value: "",
};
const cowMilkProduction = {
	nepali: "गाईको दैनिक दुध उत्पादन (लिटरमा )",
	english: "Daily production of cow milk (litre)",
	value: "",
};
const cowMilkProductionSale = {
	nepali: "दुध बिक्रि परिमाण कति होला ? (औसत बार्षिक )",
	english: "Daily production of cow milk (litre)",
	value: "",
};
const cowMilkSale = {
	nepali: "दुध बिक्रिबाट हुने बार्षिक आम्दानी (रु हजारमा )",
	english: "Income from sales of cow milk(in 1000)",
	value: "",
};

//गाई END

//भैसी START
// भैसी  विवरण
const buffalloInfo = {
	english: "Detail related buffalo",
	nepali: "भैसी सम्बन्धी विवरण",
};
const buffalloNumber = {
	nepali: "भैसीको  संख्या",
	english: "No of buffalo",
	value: "",
};
const buffalloMilkProduction = {
	nepali: "भैसीको दैनिक दुध उत्पादन (लिटरमा )",
	english: "Daily production of buffalo milk",
	value: "",
};
const buffalloMilkProductionSale = {
	nepali: "दुध बिक्रि परिमाण (बार्षिक )",
	english: "Daily production of buffalo milk",
	value: "",
};
const buffalloMilkSale = {
	nepali: "दुध बिक्रिबाट हुने बार्षिक आम्दानी (रु हजारमा )",
	english: "Yearly income from sales of buffalo milk",
	value: "",
};

//भैसी END

//खसी/बोका  START
// खसी/बोका   विवरण
const khasiInfo = {
	english: "Detail related to khasi",
	nepali: "खसी/बोका सम्बन्धी विवरण",
};

const khasiNumber = {
	nepali: "खसी/बोकाको संख्या",
	english: "	No of khasi/boka",
	value: "",
};
const khasiMeatProduction = {
	nepali: "खसी/बोका मासुबिक्रि (के जिमा )",
	english: "Sales of khasi /boka meat",
	value: "",
};
const khasiMeatIncome = {
	nepali: "मासु वा खसी/बोका बिक्रिबाट बार्षिक आम्दानी (रु हजारमा )",
	english: "Yearly income from the sales of khasi boka meat",
	value: "",
};

//खसी/बोक END

//बाख्राा  START
// बाख्रा विवरण
const goatInfo = {
	english: "Detail related to bakhra",
	nepali: "बाख्रा सम्बन्धी विवरण",
};

const goatNumber = {
	nepali: "बाख्राको संख्या",
	english: "No of goat",
	value: "",
};

const goatMeatIncome = {
	nepali: "बाख्राको मासु वा बाख्रा बिक्रीबाट बार्षिक आम्दानी",
	english: "Yearly income from the sales of goat meat",
	value: "",
};
//बाख्रा END

//राँगा/पाडा/पाडी  START
// राँगा/पाडा/पाडीा   विवरण
const rangaInfo = {
	english: "Detail related to ranga/paada/padi",
	nepali: "राँगा/पाडा/पाडी सम्बन्धी विवरण",
};
const rangaNumber = {
	nepali: "राँगा/पाडा/पाडीो संख्या",
	english: "No of ranga / pada/paadi",
	value: "",
};
const rangaMeatProduction = {
	nepali: "राँगा /पाडा /पाडी मासु / छाला वा पशुबाट बार्षिक बिक्रि (के .जीमा )",
	english: "Yearly income from the sales of ranga /pada/padi skin",
	value: "",
};
const rangaMeatIncome = {
	nepali: "राँगा/पाडा/पाडी मासु वा पशु बिक्रिबाट बार्षिक आम्दानी (रु हजार )",
	english: "yearly income from the sales of ranga /pada/padi meat",
	value: "",
};
//राँगा/पाडा/पाडी END
//सुँगुर/बंगुर START
// सुँगुर/बंगुर  विवरण
const pigInfo = {
	english: "Detail related to pigbunger",
	nepali: "सुँगुर/बंगुर सम्बन्धी विवरण",
};
const pigNumber = {
	nepali: "सुँगुर/बंगुरको संख्या",
	english: "No of pig and bungur",
	value: "",
};
const pigMeatProduction = {
	nepali: "सुँगुर /बंगुरको मासु बिक्रि बार्षिक (के .जीमा )",
	english: "Yearly sales of pig meat",
	value: "",
};
const pigMeatIncome = {
	nepali: "सुँगुर / बंगुरको मासु वा पशु बिक्रिबाट बार्षिक आम्दानी (रु हजारमा)",
	english: "Yearly income from the sales of pig meat",
	value: "",
};
//सुँगुर/बंगुर END

//भेडा START
//भेडा  विवरण
const sheepInfo = {
	english: "Detail related to sheep",
	nepali: "भेडा सम्बन्धी विवरण",
};
const sheepNumber = {
	nepali: "भेडाको संख्या",
	english: "No of sheep",
	value: "",
};
const sheepMeatProduction = {
	nepali: "भेडाको मासु बिक्रि दैनिक (के जीमा )",
	english: "Daily sales of sheep meat(kg)",
	value: "",
};
const sheepMeatIncome = {
	nepali: "भेडाको उन उत्पादन तथा मासु बिक्रि बार्षिकरुपमा (रु हजारमा )",
	english:
		"What is the yearly income from the sales of wool and meat of sheep?",
	value: "",
};
//भेडा END

//बदेल START
//बदेल  विवरण
const badelInfo = {
	english: "Detail related to badel",
	nepali: "बदेल सम्बन्धी विवरण",
};
const badelNumber = {
	nepali: "बँदेलको संख्या",
	english: "No of badel",
	value: "",
};
const badelMeatProduction = {
	nepali: "बँदेलको मासु दैनिक रुपमा उत्पादन (के जीमा )",
	english: "Daily production of badel meat(kg)",
	value: "",
};
const badelMeatIncome = {
	nepali: "मासु वा पशु बिक्रीबाट बार्षिक आम्दानी (रु हजारमा )",
	english: "Income from sales of meat and badel",
	value: "",
};
//बदेल END

//गोरु START
//गोरु  विवरण
const oxInfo = { english: "Ox related detail", nepali: "गोरु सम्बन्धी विवरण" };
const oxNumber = {
	nepali: "गोरुको संख्याा",
	english: "No of ox",
	value: "",
};
const oxProduction = {
	nepali: "गोरुको मासु वा छाला उत्पादन (के जिमा )",
	english: "Production of ox meat and skin",
	value: "",
};
const oxMeatIncome = {
	nepali: "पशुबिक्रिबाट बार्षिक आम्दानी (रु हजारमा )",
	english: "Yearly income from animal sales",
	value: "",
};
//गोरु END

//गधा START
//गधा  विवरण
const gadhaInfo = {
	english: "Donkey realted detail",
	nepali: "गधा सम्बन्धी विवरण",
};
const gadhaNumber = {
	nepali: "गधाको संख्याा",
	english: "No of donkey",
	value: "",
};
const gadhaProduction = {
	nepali: "गधाको मासु वा छाला उत्पादन (केजीमा )",
	english: "Production of donkey meat and skin(kg)",
	value: "",
};
const gadhaMeatIncome = {
	nepali: "गधाको मासु वा छालाबाट बार्षिक आम्दानी (रु हजारमा )",
	english: "Yearly income from the sales of meat and animal",
	value: "",
};
//गधा END

//घोडा START
//घोडा  विवरण
const horseInfo = {
	english: "Horse related horse",
	nepali: "घोडा सम्बन्धी विवरण",
};
const horseNumber = {
	nepali: "घोडाको संख्याा",
	english: "No of horse",
	value: "",
};
//घोडा END

//याक START
//याक  विवरण
const yakInfo = { english: "Yak related detail", nepali: "याक सम्बन्धी विवरण" };
const yakNumber = {
	nepali: "याकको संख्या",
	english: "No of yak",
	value: "",
};
const yakMilkProduction = {
	nepali: "दुध उत्पादन दैनिकरुपमा (लिटरमा )",
	english: "Daily production of milk(litre)",
	value: "",
};
const yakProduction = {
	nepali: "छुर्पी उत्पादन गर्छ भने बार्षिक रुपमा कति केजी ?",
	english: "If produce churpi then mention in kg?",
	value: "",
};
const yakMeatIncome = {
	nepali:
		"याकको दुध वा छुर्पीबाट बार्षिक रुपमा कति आम्दानी हुन्छ? ( रु हजारमा )",
	english: "What is the yearly income from yak milk and churpi?",
	value: "",
};
//याक END

//च्यांग्रा START
//च्यांग्रा  विवरण
const chyangraInfo = {
	english: "Chyangra related detail",
	nepali: "च्यांग्रा सम्बन्धी विवरण",
};
const chyangraNumber = {
	nepali: "च्यांग्राको संख्या",
	english: "No of chyangra",
	value: "",
};
const chyangraProduction = {
	nepali: "च्यांग्राको मासु वा छाला उत्पादन (के जीमा )",
	english: "Chyangra meat and skin produce",
	value: "",
};
const chyangraMeatIncome = {
	nepali:
		"च्यांग्राको मासु / छाला वा पशु बिक्रीबाट बार्षिक आम्दानी (रु हजारमा )",
	english: "Yearly income from the sales of meat and animal",
	value: "",
};
//च्यांग्रा END
//अन्य पशु START
//माथि भन्दा अन्य कति किसिमको पशु पाल्नु भएको छ ?
const anyAnimal = {
	english: "How many kinds of animal you rear?",
	nepali: "माथि भन्दा अन्य कति किसिमको पशु पाल्नु भएको छ ?",
	value: "",
};
const anyAnimalInfo = { english: "", nepali: "अन्य पशु पालेको विवरण" };
const anyAnimalTitle = {
	engish: "Mention if any",
	nepali: "अन्य भए उल्लेख गर्नु हाेस।",
	value: "",
};
const anyAnimalInfoTitle = {
	english: "Other detail",
	nepali: "अन्य विवरण",
};
const animalNumber = {
	nepali: "को संख्या",
	english: "No.",
	value: "",
};
const animalProduction = {
	nepali: "को छाला वा पशुबाट बार्षिक बिक्रि (के .जीमा )",
	english: "Yearly sales of skin and animal",
	value: "",
};
const animalMeatIncome = {
	nepali: "को मासु / छाला वा पशु बिक्रीबाट बार्षिक आम्दानी (रु हजारमा )",
	english: "Yearly income from sales of meat and animal",
	value: "",
};

//अन्य END
//पशु END

//पन्छी START
//if पन्छी xa vaney
//If xa vaney  कुन कुन पन्छी पाल्नु भएको छ ?
const birdTitle = {
	english: "Which animal you rear ?",
	nepali: "कुन कुन पन्छी पाल्नु भएको छ ?",
};
const birdDiffType = [
	{
		nepali: "लोकल कुखुरा",
		english: "Chicken",
		value: "chicken",
	},
	{
		nepali: "हाइब्रिड कुखुरा",
		english: "Hybrid Chicken",
		value: "hybrid",
	},
	{
		nepali: "हाँस",
		english: "Duck",
		value: "pegion",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
//कुखुरा START
// कुखुरा  विवरण
const henInfo = {
	english: "Hen related detail",
	nepali: "कुखुरा सम्बन्धी विवरण",
};
const henNumber = {
	nepali:
		"कुखुराको संख्या (कुखुरा फारम भए फारममा कति संख्या कुखुरा छ उल्लेख गर्नुस )",
	english: "No of hen",
	value: "",
};
const henMeatProduction = {
	nepali: "कुखुराको मासुबिक्रि दैनिक रुपमा (के जीमा )",
	english: "Daily sales of hen meat(kg)",
	value: "",
};
const henEggSale = {
	nepali: "कुखुराको अन्डा बिक्रि दैनिक रुपमा (गोटा )",
	english: "Sales of hen egg and egg",
	value: "",
};
const henMeatIncome = {
	nepali:
		"कुखुराको मासु,अन्डा वा कुखुरा बिक्रीबाट बार्षिक रुपमा आम्दानी (रु हजारमा)",
	english: "Yearly income from sales of hen,egg and meat?",
	value: "",
};

//कुखुरा END

//हाँस START
// हाँस  विवरण
const duckInfo = {
	english: "Duck related detail",
	nepali: "हाँस सम्बन्धी विवरण",
};
const duckNumber = {
	nepali: "हाँसको संख्या",
	english: "No of duck",
	value: "",
};
const duckMeatProduction = {
	nepali: "हाइब्रिड कुखुरा मासु बिक्रि बार्षिक रुपमा",
	english: "Daily sales of duck meat(kg)",
	value: "",
};
const duckEggSale = {
	nepali: "हाँसको अन्डा बार्षिक उत्पादन",
	english: "Daily sales of egg ",
	value: "",
};
const duckMeatIncome = {
	nepali: "हाँसको मासु वा हाँस बिक्रि बाट बार्षिक आम्दानी (रु हजारमा )",
	english: "Yearly income from the sales of duck and meat",
	value: "",
};

//हाँस END

const hybridChickenInfo = {
	english: "",
	nepali: "हाइब्रिड कुखुरा सम्बन्धी विवरण",
};

const hybridNumber = {
	english: "",
	nepali: "हाइब्रिड कुखुरा संख्या",
};

const hybridMeatProduction = {
	nepali: "हाइब्रिड कुखुरा मासु बिक्रि बार्षिक रुपमा",
	english: "",
};

const hybridEggSale = {
	nepali: "हाइब्रिड खुराको अन्डा उत्पादन बार्षिक",
	english: "",
};

//अस्ट्रिच START
// अस्ट्रिच  विवरण
const ostrichInfo = {
	english: "Ostrich related detail",
	nepali: "अस्ट्रिच सम्बन्धी विवरण",
};
const ostrichNumber = {
	nepali: "अस्ट्रिचको संख्या",
	english: " No of ostrich ",
	value: "",
};
const ostrichMeatProduction = {
	nepali: "अस्ट्रिचको मासु उत्पादन वा बिक्रि दैनिक (के जीमा )",
	english: "Daily sales of ostrich meat ",
	value: "",
};
const ostrichMeatIncome = {
	nepali:
		"अस्ट्रिचको मासु वा अस्ट्रिच बिक्रीबाट बार्षिक रुपमा आम्दानी (रु हजारमा )",
	english: "Yearly income from sales of ostrich and meat",
	value: "",
};
//अस्ट्रिच END

//परेवा START
// परेवा  विवरण
const pegionInfo = {
	english: "Pegion related detail",
	nepali: "परेवा सम्बन्धी विवरण",
};
const pegion = {
	nepali: "परेवाको संख्या",
	english: "No of pegion",
	value: "",
};
const pegionMeatIncome = {
	nepali: "परेवा वा मासु बिक्रिबाट बार्षिक आम्दानी",
	english: "Yearly income from sales of pegion and meat",
	value: "",
};
//परेवा END

//अन्य पन्छी START
//माथि भन्दा अन्य कति किसिमको पन्छी पाल्नु भएको छ ?
const anyBird = {
	english: "Different kind of bird",
	nepali: "माथि भन्दा अन्य कति किसिमको पन्छी पाल्नु भएको छ ?",
	value: "",
};
const anyBirdInfo = {
	english: "Detail of other bird rearing",
	nepali: "अन्य पन्छी पालेको विवरण",
};
const anyBirdTitle = {
	engish: "Mention if any",
	nepali: "अन्य भए उल्लेख गर्नु हाेस।",
	value: "",
};
const anyBirdInfoTitle = {
	english: "Other detail",
	nepali: "अन्य विवरण",
};
const birdNumber = {
	nepali: "को संख्या",
	english: "No ",
	value: "",
};
const birdProduction = {
	nepali: "को अन्डा,मासु पन्छीबाट बार्षिक बिक्रि (के .जीमा )",
	english: "Yearly sales of egg,meat and bird ",
	value: "",
};
const birdMeatIncome = {
	nepali: "को अन्डा,मासु वा पन्छी बिक्रीबाट बार्षिक आम्दानी (रु हजारमा )",
	english: "Yearly income from the sale from egg , meat and bird (kg)",
	value: "",
};

//अन्य END
//पशुपन्छी END

//तपाइको परिवारको जम्मा जग्गा क्षेत्रफ़ल्
const totallandAreaTitle = {
	nepali: "तपाइको परिवारको जम्मा जग्गा क्षेत्रफ़ल् कति हो?",
	english: "How much land do u have?",
};
const totalLandArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//कृषि कार्यका लागी जग्गा प्रयोग गर्नु भएको छ?

const totalFamilyLandQn = {
	nepali: "आफ्नो परिवारको जम्मा जग्गा भोग चलनको आधारमा विवरण दिनुहोस्",
	english: "",
};

const totalFamilyLand = [
	{
		nepali: "एलानी (बिघा -कठा -धुर )",
		english: "",
		value: "elaani",
	},
	{
		nepali: "नम्बरी (विघा- कठा -धुर )",
		english: "",
		value: "nambari",
	},
];

const elaaniQn = { nepali: "ऐलानी (बिघा -कट्ठा -धुर )", english: "" };

const nambariQn = { nepali: "नम्बरी (बिघा -कट्ठा -धुर)", english: "" };

const landforAgri = {
	nepali: "कृषि कार्यका लागी जग्गा प्रयोग गर्नु भएको छ?",
	english: "Do you use land for agriculture purpose?",
};
const landforAgriYesNo = [
	{
		nepali: "छ",
		english: "yes",
		value: "land_used_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "land_used_no",
	},
];
//if xa vaney
const landforAgriYes = {
	nepali:
		"परिवारले कृषि कार्यका लागि जग्गा प्रयोग गरेको छ भने जग्गाको विवरण दिनुस ?",
	english: "",
};
//if xa vaney
const landforAgriList = [
	{
		nepali: "आफ्नो परिवारको नाममा रहेको जग्गा",
		english: "Land in your name",
		value: "own_land",
	},
	{
		nepali: "आफ्नो परिवार बाहेक अरुको नाममा रहेको जग्गा",
		english: "Land belongs to other",
		value: "other_land",
	},
];
//if xa vaney
const irrigationForLand = {
	english: "Which  way do you use for agriculture? ",
	nepali: "कृषि कार्यका लागि सिंचाईको माध्यम के हो ?",
};
const irrigationForLandList = [
	{
		nepali: "सिंचाई छैन",
		english: "No irrigation",
		value: "no_irrigation",
	},
	{
		nepali: "कुलो",
		english: "Kulo",
		value: "kulo",
	},
	{
		nepali: "नहर",
		english: "Nahar",
		value: "nahar",
	},
	{
		nepali: "बोरिंग",
		english: "Boring",
		value: "boring",
	},
	{
		nepali: "ट्युबवेल",
		english: "Tubewell",
		value: "tubewell",
	},
	{
		nepali: "लिफ्टिंङ बाँध",
		english: "Lifting badh",
		value: "lift_badh",
	},
	{
		nepali: "पोखरी",
		english: "Pokhari",
		value: "pokhari",
	},
	{
		nepali: "धारा/पाईपबाट",
		english: "Tap pipe",
		value: "tap_pipe",
	},
	{
		nepali: "वर्षाको पानी ",
		english: "Rain water",
		value: "rain_water",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const seedNeedQuestion = {
	nepali: "कृषी वालीका लागि विउ विजन प्राप्तिको श्रोत के छ ?",
	english: "",
};

const seedNeed = [
	{
		nepali: "सरकारी निकाय",
		english: "",
		value: "government_off",
	},
	{
		nepali: "एग्रोभेट (स्थानीय )",
		english: "",
		value: "agrovet",
	},
	{
		nepali: "स्थानीय श्रोत",
		english: "",
		value: "local",
	},
	{
		nepali: "अन्य ब्यापारी",
		english: "",
		value: "others_busines",
	},
];
//आफ्नो परिवारको नाममा रहेको जग्गा

const ownLand = {
	english: "Total land area belongs to own?",
	nepali: "आफ्नो परिवारको नाममा रहेको जग्गा कति छ?",
};
const ownLandArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//ifआफ्नो बाहेक अरुको जग्गा पनि कमाउनु भएको कति छ?
const otherLand = {
	english: "Do you use other's land for agriculture?",
	nepali: "आफ्नो बाहेक अरुको जग्गा पनि कमाउनु भएको कति छ?",
};
const otherLandArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//माछा मौरी र रेशम पालन START
const beefishsilkQuestion = {
	nepali: " तपाइको घरमा माछा मौरी र रेशम पालन गरिएको छ भने विवरण दिनुहोला",
	english: "If you  rear fish ,bee and resham in your home then mention?",
};

const beefishsilkYesNo = [
	{
		nepali: "छ",
		english: "yes",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "no",
	},
];
//if xa vanye
const beefishsilkYes = {
	nepali: " छ भने विवरण दिनुहोला :",
	english: "If yes plz mention",
};
const beefishsilkType = [
	{
		nepali: "माछा पालन",
		english: "Fish farming",
		value: "fish",
	},
	{
		nepali: "मौरी पालन",
		english: "Bee farming",
		value: "bee",
	},
	{
		nepali: "रेशम पालन",
		english: "Resham farming",
		value: "resham",
	},
];
//माछा पालन START
const fishTitle = {
	nepali: "माछापालनको विवरण ",
	english: "Detail of fish farming",
};
const totalPond = {
	nepali: "पोखरी  संख्या",
	engish: "No of pond",
	value: "",
};
const pondArea = {
	nepali: "पोखरी क्षेत्रफ़ल",
	engish: "Area of pond",
	value: "",
};
const fishProduction = {
	nepali: "बार्षिक माछा उत्पादन (के.जी)",
	engish: "Yearly fish produciton (kg)",
	value: "",
};
//माछा पालन END

//मौरीपालन START
const beeTitle = {
	nepali: "मौरीपालनको विवरण ",
	english: "Bee farming detail",
};
const totalGhaar = {
	nepali: "घार संख्या",
	engish: "No of ghaar",
	value: "",
};
const beeProduction = {
	nepali: "वार्षिक मह उत्पादन(के.जी)",
	engish: "Yearly production of honey(kg)",
	value: "",
};
//मौरीपालन END

//रेशमपालन START
const reshamTitle = {
	nepali: "रेशमपालनको विवरण",
	english: "Resham farming detail",
};
const reshamGhaar = {
	nepali: "कोयो संख्या",
	engish: "No of koyo",
	value: "",
};
const reshamProduction = {
	nepali: "उत्पादन (के.जि)",
	engish: "Production(kg)",
	value: "",
};
const totalBeefidhsilkIncome = {
	nepali: "माछा, मौरी र रेशम पालन बिक्रीबाट वार्षिक आम्दानी (रु)",
	english: "Yearly income from the sales of fish bee and resham(Rs)",
	value: "",
};
//रेशमपालन END
//माछा मौरी र रेशम पालन END

const businessListQuestion = {
	english: "Do your family have traditional business?",
	nepali: "तपाइको परिवारले कुनै घरेलु व्यवसाय कार्य गर्नु भएको छ ?",
};

const herbListQuestion = {
	english:
		"Do your family enagaged in sales and collection of medicinal herbs?",
	nepali:
		"तपाईको परिवारले कुनै जडिबुटी व्यापार तथा जडिबुटी सङ्कलनको काम गर्नु हुन्छ ?",
};

const businessLists = [
	{
		nepali: "भाडाकुडाका सामान बनाउने बेच्ने",
		english: "Karuwa",
		value: "karuwa",
	},
	{
		nepali: "खोलामा जालबाट माछा मार्ने तथा बिक्रि वितरण गर्ने",
		english: "Fishing",
		value: "river_fish_col",
	},
	{
		nepali: "होमस्टे ब्यबसाय संचालन",
		english: "Home Stay",
		value: "home_stay",
	},
	{
		nepali: "हातेकागज",
		english: "Hand paper",
		value: "hand_paper",
	},
	{
		nepali: "फलाम जन्य कार्य गर्ने",
		english: "Khuhuri",
		value: "khukuri",
	},
	{
		nepali: "राडीपाखी",
		english: "Raadi pakhi",
		value: "raadi_pakhi",
	},
	{
		nepali: "कुराउनी / सख्हर/ घिउ/छुर्पी आदि प्रसोधन तथा बिक्रि वितरण",
		english: "Kuarauni",
		value: "kurauni",
	},
	{
		nepali: "काठ जन्य फर्निचर निर्माण तथा बिक्रि",
		english: "Furniture",
		value: "furniture",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const herbLists = [
	{
		nepali: "अदामचल",
		english: "Adaamchal",
		value: "adaamchal",
	},
	{
		nepali: "आखनबेद",
		english: "Ankhanbed",
		value: "ankhanbed",
	},
	{
		nepali: "कुट्की",
		english: "Kutki",
		value: "kutki",
	},
	{
		nepali: "ठुलो ओखती",
		english: "Thulo okhati",
		value: "thulo_okhati",
	},
	{
		nepali: "हर्रो",
		english: "Harro",
		value: "harro",
	},
	{
		nepali: "अमला",
		english: "Aamala",
		value: "aamla",
	},
	{
		nepali: "टिमुर",
		english: "Timur",
		value: "timur",
	},
	{
		nepali: "चिरैतो",
		english: "Chiraito",
		value: "chirauto",
	},
	{
		nepali: "चानो",
		english: "Chano",
		value: "chano",
	},
	{
		nepali: "बर्रो",
		english: "Barro",
		value: "barro",
	},
	{
		nepali: "बोझो",
		english: "Bojho",
		value: "bojho",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const businesssQn = {
	nepali: "तपाइको परिवारले कुनै घरेलु व्यवसाय कार्य गर्नु भएको छ ?",
	english: "Do your family engaged in any traditional business?",
};

const herbQn = {
	nepali:
		"तपाईको परिवारले कुनै जडिबुटी व्यापार तथा जडिबुटी सङ्कलनको काम गर्नु हुन्छ ?",
	english:
		"Do your family enagaged in sales and collection of medicinal herbs?",
};
const businessRadio = [
	{
		nepali: "छ",
		english: "yes",
		value: "home_busi_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "home_busi_no",
	},
];

const herbRadio = [
	{
		nepali: "छ",
		english: "yes",
		value: "herbs_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "herbs_no",
	},
];

const birthCertificateQuestion = {
	nepali: "पाँच (५) बर्ष भन्दा मुनिको बालबालिकाको जन्म दर्ता गर्नु भएको छ ?",
	english: "Do you registar birth certificate of child below the age of 5?",
};
const birthCertificate = [
	{
		nepali: "छ",
		english: "yes",
		value: "birth_cert_yes",
	},
	{ nepali: "छैन", english: "no", value: "birth_cert_no" },
];

const contraceptiveUseQuestion = {
	nepali:
		"परिवारमा विवाहित सदस्यहरुले परिवार निवोजनको साधन प्रयोग गर्नु हुन्छ कि हुन्न ?",
	english: "",
};
const contraceptiveUse = [
	{
		nepali: "छ",
		english: "yes",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "no",
	},
];

const deliveryFromDoctorQuestion = {
	nepali: "गर्भवती आमाको दक्ष स्वास्थ्य ाकर्मी बाट सुत्केरी गराउनु भएको छ ?",
	english: "Do prenant women delivary baby from qualified healthworker?",
};
const deliveryFromDoctor = [
	{
		nepali: "छ",
		english: "yes",
		value: "delivery_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "delivery_no",
	},
];

const childDeathQuestion = {
	nepali: "विगत १ वर्ष भित्र हैजा, झाडापखालाबाट मृतु हुनु हुने बालबालिका छन ?",
	english: "",
};

const childDeath = [
	{
		nepali: "छ",
		english: "yes",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "no",
	},
];

const childDeathCount = {
	nepali: "छ भने कति जना",
	english: "If yes how many?",
};

const pregnanctCheckQuestion = {
	nepali: "गर्भवती महिलाको सम्पूर्ण जाँच गराउनु भएको छ कि छैन ?",
	english: "Regular check up for pregnent women? ",
};

const pregnanctCheck = [
	{
		nepali: "छ",
		english: "yes",
		value: "check_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "check_no",
	},
];

const childNutritionQuestion = {
	nepali: "बालबालिकामा पोषण सम्बन्धी समस्या छ कि छैन ?",
	english: "Do there any problem related to child malnutrition?  ",
};

const childNutrition = [
	{
		nepali: "छ",
		english: "yes",
		value: "mal_nutri_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "mal_nutri_no",
	},
];
const childLabourQuestion = {
	nepali: "तपाइको परिवारको कुनै १६ वर्ष मुनिका वालवालिका श्रम गर्न गएको छ ?",
	english: "Do your any family member beleow age of 16 are working?",
};
const childLaborQuestion = {
	nepali: "तपाईको घरमा १६ वर्ष मुनिका कामदार छन् ?",
	english: "Do you have worker below the age of 16 in your home?",
};
const isStillChildMarriage = {
	nepali: "के यस समुदायमा वालविवाहको चलन अझै छ ?",
	english: "",
};
const reasonForChildMarriage = {
	nepali: "समुदायमा बालविवाहको चलन चलिरहनुको कारण",
	english: "",
};
const controlChildMarriage = {
	nepali: "समुदायमा बालविवाहको चलनलाई नियन्त्रण गर्न",
	english: "",
};
const childLabour = [
	{
		nepali: "छ",
		english: "yes",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "no",
	},
];

const earlyMarriageQuestion = {
	nepali: "अठार (१८) वर्ष मुनिका वालवालिकाको बिवाह भएको छ ?",
	english: "Marriage below the age of 18?",
};
const earlyMarriage = [
	{
		nepali: "छ",
		english: "yes",
		value: "e_marry_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "e_marry_no",
	},
];

const pregnantTetanusQuestion = {
	nepali: "गर्भवती महिलाले टिटानस विरुद्धको २ वोटा खोप लगाउनु भएको छ?",
	english: "Do pregnant women get 2  vaccine  against titanus? ",
};
const pregnantTetanus = [
	{
		nepali: "छ",
		english: "yes",
		value: "tetanus_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "tetanus_no",
	},
];

const illTreamentQuestion = {
	nepali: "बिरामी हुदा स्वास्थ्य  उपचार सेवाको लागि कहाँ जानु हुन्छ?",
	english: "Where do you go for treatment?",
};

const illTreament = [
	{
		nepali: "धामी झाक्री",
		english: "Dhami jhakri",
		value: "dhaami_jhakri",
	},
	{
		nepali: "औषधि पसल",
		english: "Pharmecy",
		value: "medical",
	},
	{
		nepali: "क्लिनिक",
		english: "Clinic",
		value: "clinic",
	},
	{
		nepali: "स्वास्थ्य  चौकी",
		english: "Health post",
		value: "health_post",
	},
	{
		nepali: "जिल्लाका निजि अस्पताल",
		english: " District private hospital",
		value: "private_hospit",
	},
	{
		nepali: "जिल्ला बाहिर सरकारी अस्पताल",
		english: "District government hospital",
		value: "public_hospita",
	},
	{
		nepali: "स्थानीय स्वास्थ्यकर्मी",
		english: "Local health worker",
		value: "healthworker",
	},
	{
		nepali: "जिल्लाका सरकारी अस्पताल",
		english: "Local health worker",
		value: "district_govt_",
	},
];

//जिल्ला बाहिर सरकारी अस्पताल click pacchi aaune array
const govHospitalNameQuestion = {
	english: "District government hospital ",
	nepali: "जिल्ला बाहिर सरकारी अस्पताल",
};

const govHospitalName = [
	{
		nepali: "धरान",
		english: "Dharan",
		value: "dharan",
	},
	{
		nepali: "बिराटनगर",
		english: "Biratnagar",
		value: "biratnagar",
	},
	{
		nepali: "काठमाडौँ",
		english: "Kathmandu",
		value: "kathmandu",
	},
	{
		nepali: "गाईघाट",
		english: "Gaighat",
		value: "gaighat",
	},
	{
		nepali: "लाहान",
		english: "Lahan",
		value: "lahan",
	},
	{
		nepali: "जनकपुर",
		english: "Janakpur",
		value: "janakpur",
	},
	{
		nepali: "भरतपुर",
		english: "Bharatpur",
		value: "bharatpur",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const isChildMarriageExistsQn = {
	nepali: "के यस समुदायमा वालविवाहको चलन अझै छ ?",
	english: "",
};

const isChildMarriageExists = [
	{
		nepali: "छ",
		english: "",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "",
		value: "no",
	},
	{
		nepali: "थाहा छैन",
		english: "",
		value: "unknown",
	},
];

// if isChildMarriageExist "yes"
const childMarriageExistsQn = {
	nepali:
		"तपाइको समुदायमा बालविवाहको चलन चलिरहनुको कारण के के हो जस्तो लाग्छ ?",
	english: "",
};

const childMarriageExists = [
	{
		nepali: "पहिला देखि चलेको परम्परा भएर",
		english: "",
		value: "tradition",
	},
	{
		nepali: "गरीबि",
		english: "",
		value: "poverty",
	},
	{
		nepali: "उमेर बडदै जादा दाइजो पनि बढ्ने भएर",
		english: "",
		value: "increment",
	},
	{
		nepali: "आफै प्रेम सम्बन्ध भएर",
		english: "",
		value: "love_rel",
	},
	{
		nepali: "साथीको लायालायामा लागेर /देखासिखी गरेर",
		english: "",
		value: "friend_circle",
	},
	{
		nepali: "राम्रो घरको केटा /केटी सजिलै नपाउने भएर",
		english: "",
		value: "no_easy_access_of_good_family",
	},
	{
		nepali: "अपांगताको कारण",
		english: "",
		value: "disable",
	},
	{
		nepali: "ज्ञान नभएर वा अशिक्षित भएकाले",
		english: "",
		value: "uneducate",
	},
	{
		nepali: "थाहा छैन",
		english: "",
		value: "unknown",
	},
	{
		nepali: "अन्य (खुलाउनु होस )",
		english: "",
		value: "others",
	},
];

const controlChildMarriageQn = {
	nepali:
		"तपाइको समुदायमा बालविवाहको चलनलाई नियन्त्रण गर्नको लागि के के गर्नु पर्छ ?",
	english: "",
};

const controlChildMarriageList = [
	{
		nepali: "वालवालिकाहरुमा जनचेतना",
		english: "",
		value: "child_awarness",
	},
	{
		nepali: "आमा बुवा /अभिभावकमा जनचेतना",
		english: "",
		value: "parent_awarness",
	},
	{
		nepali: "कानुन लागु गरेर",
		english: "",
		value: "law",
	},
	{
		nepali: "अनिवार्य शिक्षा",
		english: "",
		value: "compulsary_education",
	},
	{
		nepali: "वाल क्लबहरु सक्रिय र परिचालित",
		english: "",
		value: "regulation_of_active_child_club",
	},
	{
		nepali: "सुरक्षा समितिहरु सक्रिय र परिचालित",
		english: "",
		value: "active_regulation",
	},
	{
		nepali: "आम्दानिमुलक गतिविधि बढाउने",
		english: "",
		value: "diverse_income",
	},
	{
		nepali: "रोजगारीको अवसर",
		english: "",
		value: "job_oppurtunities",
	},
	{
		nepali: "ब्यब्सायिक तालिमहरुको अवसरहरु उपलब्ध",
		english: "",
		value: "trainning_business",
	},
	{
		nepali: "उच्च शिक्षाको अवसर",
		english: "",
		value: "higher_education",
	},
	{
		nepali: "प्रभाबकारी सडक नाटक गाउँ गाउँमा देखाउने",
		english: "",
		value: "road_drama",
	},
	{
		nepali: "थाहा छैन",
		english: "",
		value: "unknown",
	},
	{
		nepali: "अन्य (खुलाउने)",
		english: "",
		value: "others",
	},
];

const isRevolutionCaseQn = {
	nepali: "तपाइको परिवारमा द्वन्द वा जनआन्दोलन प्रवाभित सदस्य हुनुहुन्छ ?",
	english: "",
};

const isRevolutionCase = [
	{
		nepali: "हुनुहुन्छ",
		english: "",
		value: "yes",
	},
	{
		nepali: "हुनुहुन्न",
		english: "",
		value: "no",
	},
];

const protestTypeQn = {
	english: "",
	nepali: "यदि हुनुहुन्छ भने कुन किसिमको प्रभावित",
};

const protestType = [
	{
		nepali: "जनआन्दोलन",
		english: "",
		value: "janaandolan",
	},
	{
		nepali: "ससत्र जनयुद्द",
		english: "",
		value: "combaties",
	},
	{
		nepali: "दुवै",
		english: "",
		value: "both",
	},
];

const whichSideForProtestQn = {
	nepali: "ससत्र जनयुद्द हो भने कुन पक्षबाट",
	english: "",
};

const whichSideForProtest = [
	{
		nepali: "राज्य",
		english: "",
		value: "government",
	},
	{
		nepali: "तत्कालिन बिद्रोही पक्ष",
		english: "",
		value: "dissident",
	},
	{
		nepali: "दुवै",
		english: "",
		value: "both",
	},
];

const effectFromProtestQn = {
	nepali: "कस्तो किसिमको पिडित",
	english: "",
};

const effectFromProtest = [
	{
		nepali: "मृत्यु",
		english: "",
		value: "death",
	},
	{
		nepali: "बेपत्ता",
		english: "",
		value: "bepatta",
	},
	{
		nepali: "घाइते",
		english: "",
		value: "injured",
	},
];

const effectFromCoronaQn = {
	nepali: "कोरोना महामारीले तपाइको परिवारमा पारेको प्रमुख असर ?",
	english: "",
};

const effectFromCorona = [
	{
		nepali: "परिवारको सदश्यको रोजगार गुमेको",
		english: "",
		value: "unemployment",
	},
	{
		nepali: "परिवार सदश्यमा मानसिक असर",
		english: "",
		value: "psychological",
	},
	{
		nepali: "आर्थिक अभाबले गर्दा परिवारमा कलह /झैँझगडा",
		english: "",
		value: "jhaijhagadaa",
	},
	{
		nepali: "ब्यापार - व्यवासय बन्द गर्नु परेको",
		english: "",
		value: "business",
	},
	{
		nepali: "कृषि उत्पादन बिक्रि नभएको",
		english: "",
		value: "agriculture",
	},
	{
		nepali: "घर परिवार सदस्यमा खानलाई समस्या",
		english: "",
		value: "khana_problem",
	},
	{
		nepali: "अन्य (खुलाउनु होस् )",
		english: "",
		value: "others",
	},
];
//ends

//DEATH INFO
//४.१. तपाईको परिवारमा गत १ बर्ष भित्र कसैको मृत्यु भएको छ कि छैन?
const deathQuestion = {
	english: "Do any child died within one year?",
	nepali: " तपाईको परिवारमा गत १ बर्ष भित्र कसैको मृत्यु भएको छ कि छैन?",
};
const anyDeath = [
	{
		nepali: "छ",
		english: "Yes",
		value: "death",
	},
	{
		nepali: "छैन",
		english: "No",
		value: "no_death",
	},
];

//IF तपाईको परिवारमा गत १ बर्ष भित्र कसैको मृत्यु भएको छ bhaney?
const deathInYear = {
	english: "If yes how many died?",
	nepali: "छ भने कति जना को मृत्यु भएको छ ?",
	value: "",
};

//IF तपाईको परिवारमा गत १ बर्ष भित्र कसैको मृत्यु भएको छ bhaney tesko name tala lekhney
const deathName = {
	nepali: "मृत्यु हुनेको नाम",
	english: "Name of died person",
	value: "dead_person_name",
};

//IF तपाईको परिवारमा गत १ बर्ष भित्र कसैको मृत्यु भएको छ bhaney tesko लिंग ko bibaran tala lekhney
const deathInYeargenderQuestion = { english: "Gender", nepali: "लिंग" };
const deathGender = [
	{
		nepali: "पुरुष",
		english: "Male",
		value: "dearh_male",
	},
	{
		nepali: "महिला",
		english: "Female",
		value: "death_female",
	},
	{
		nepali: "तेश्रो लिंगी",
		english: "Third Gender",
		value: "death_thrid",
	},
];
//IF तपाईको परिवारमा गत १ बर्ष भित्र कसैको मृत्यु भएको छ bhaney मृत्यु हुनेको उमेर
const deathAge = {
	nepali: "मृत्यु हुनेको उमेर",
	english: "Age of death ",
	value: "death_age",
};

//IF तपाईको परिवारमा गत १ बर्ष भित्र कसैको मृत्यु भएको छ bhaney मृत्यु को कारण
const deathInYearReasonQuestion = {
	english: "Reason of death",
	nepali: "मृत्यु को कारण",
};
const deathReason = [
	{
		nepali: "माहामारी",
		english: "Epidimics",
		value: "epidimics",
	},
	{
		nepali: "रोग लागेर",
		english: "Transferable diseases",
		value: "tranferable",
	},
	{
		nepali: "काल गति",
		english: "Aged",
		value: "aged",
	},
	{
		nepali: "मातृ मृत्यु",
		english: "maternal_death",
		value: "maternal_death",
	},
	{
		nepali: "दुर्घटना",
		english: "Accident",
		value: "accident",
	},
	{
		nepali: "आत्महत्या",
		english: "Suicide",
		value: "suicide",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const deathCertificateQn = {
	nepali: "मृत्यु दर्ता गर्नु भएको छ ?",
	english: "",
};
const deathCertificate = [
	{
		nepali: "छ",
		english: "yes",
		value: "death_cert_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "death_cert_no",
	},
];

const migrationType = [
	{
		nepali: "अध्यागमन",
		english: "Immigration",
		value: "immigration",
	},
	{
		nepali: "Internal Migration",
		english: "Internal Migration",
		value: "internal_migration",
	},
	{
		nepali: "External Migration",
		english: "External Migration",
		value: "external_migration",
	},
];

// INSURANCE
const insuranceQuestion = {
	english: "Do you have insurance?",
	nepali: "तपाइको बिमा गरेको छ ?",
};
const insuranceYesNo = [
	{
		nepali: "छ",
		english: "yes",
		value: "insurance",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "no_insurance",
	},
];
const insuranceListQuestion = {
	english: "Detail about insurance",
	nepali: "छ भने कुन -कुन बिमा गर्नु भाको छ ?",
};
const insuranceLists = [
	{
		nepali: "जीवन बीमा",
		english: "Life Insurance",
		value: "life_insurance",
	},
	{
		nepali: "स्वास्थ्य बीमा",
		english: "Health Insurance",
		value: "health_insurance",
	},
	{
		nepali: "कोरोना विमा",
		english: "Corona Insurance",
		value: "korona_insurance",
	},
];
const lifeInsurance = {
	nepali: "जीवन बीमा (संख्यामा)",
	english: "Number of Life Insurance",
	value: "",
};

const healthInsurance = {
	nepali: "स्वास्थ बीमा (संख्यामा)",
	english: "Number of Health Insurance",
	value: "",
};

const nonlifeInsurance = {
	nepali: "नीर जीवन बिमा (संख्यामा)",
	english: "Number of Non Life Insurance",
	value: "",
};
const animalInsurance = {
	nepali: "पशु पन्छी बिमा (संख्यामा)",
	english: "Number of Animal Insurance",
	value: "",
};

const insuranceOther = {
	nepali: "अन्य बिमा (संख्यामा)",
	english: "Number of Other Insurance",
	value: "",
};
//६.परिवारले प्रयोग गर्ने बारेमा
// ६.१. परिवारको खानेपानीको मुख्य श्रोत के हो ?
const drinkingwaterQuestion = {
	english: "What is the main sources of drinking water?",
	nepali: "परिवारको खानेपानीको मुख्य श्रोत के हो ?",
};
const drinkingwaterLists = [
	{
		nepali: "पाइप धारा घरमा",
		english: "Water pipe in home",
		value: "tap_home",
	},
	{
		nepali: "पाइप धारा सार्वजनिक",
		english: "Public pipe",
		value: "tap_public",
	},
	{
		nepali: "ट्युबवेल/हेन्डपम्पा",
		english: "Tubewell / handpump",
		value: "handpump",
	},
	{
		nepali: "ढाकिएको इनार/कुवाा",
		english: "Well",
		value: "well_cover",
	},
	{
		nepali: "नढाकिएको इनार/कुव",
		english: "Well uncover",
		value: "well_uncover",
	},
	{
		nepali: "मुलको पानि",
		english: "Mulko Pani",
		value: "main_water",
	},
	{
		nepali: "नदि खोला",
		english: "River water",
		value: "river",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
//६.२. तपाइको घरको खानेपानीको उपलब्ध स्थान कहाँ छ?
const drinkingwaterplaceQuestion = {
	english: "Where do you to get drinking water?",
	nepali: "घरको खानेपानी लिन जान ,भर्न र आउन कति समय लाग्छ?",
};
const drinkingwaterplaceLists = [
	{
		nepali: "घर आँगनमा",
		english: "Home courtyard",
		value: "home_courtyard",
	},
	{
		nepali: "१० मिनेट सम्मको पैदला",
		english: "10 Min walk ",
		value: "10_min_walk",
	},
	{
		nepali: "आधा घण्टा सम्मको दुरी",
		english: "Half hour walk",
		value: "half_hour_walk",
	},
	{
		nepali: "१ घण्टा वा सो भन्दा माथि",
		english: "1 hour or more than",
		value: "1_hour_walk",
	},
];
//COOKINGRESOURCES
//७. तपाईको परिवारमा खाना पकाउन कुन प्रमुख इन्धन/चुलो प्रयोग गर्नु हुन्छ ?
const cookingresourceQuestion = {
	english: "Which source of gas you use for cooking ?",
	nepali:
		"तपाईको परिवारमा खाना पकाउन कुन प्रमुख इन्धन/चुलो प्रयोग गर्नु हुन्छ ?",
};
const cookingresourceLists = [
	{
		nepali: "दाउरा/काठ(साधरण चुलो )",
		english: "Wood9sadharan chulo)",
		value: "wood_general",
	},
	{
		nepali: "दाउरा/काठ(सुधारिएको चुलो )",
		english: "Wood(sudhariyeko chulo",
		value: "wood_specific",
	},
	{
		nepali: "मट्टीतेल/स्टोभ",
		english: "Kerosene",
		value: "kerosene",
	},
	{
		nepali: "एल.पी.जी.ग्यास",
		english: "LGP Gas",
		value: "lpg_gas",
	},
	{
		nepali: "वायो ग्यास",
		english: "Bio gas",
		value: "bio_gas",
	},
	{
		nepali: "विधुत",
		english: "Electricity",
		value: "electricity",
	},
	{
		nepali: "सौर्य उर्जा",
		english: "Solar energy",
		value: "solar",
	},
	{
		nepali: "गुइठा",
		english: "Dung",
		value: "guithaa",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
//LIGHTRESOURCES
//८. परिवारले प्रयोग गर्ने वत्तिको मुख्य स्रोत के हो?
const lightresourceQuestion = {
	english: "What is the main source of electricity?",
	nepali: "परिवारले प्रयोग गर्ने वत्तिको मुख्य स्रोत के हो?",
};
const lightresourceLists = [
	{
		nepali: "बिधुतलाईन",
		english: "Electric line",
		value: "electric",
	},
	{
		nepali: "मट्टीतेल/टुकी",
		english: "Kerosene",
		value: "kerosene",
	},
	{
		nepali: "सोलार",
		english: "Solar",
		value: "solar",
	},
	{
		nepali: "वायो ग्यास",
		english: "Bio Gas",
		value: "bio_gas",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const isElectricMeterQn = {
	nepali: "तपाईको घरमा बिधुत मिटर जडान गरिएको छ कि छैन ?",
	english: "",
};

const isElectricMeter = [
	{
		nepali: "छ",
		english: "",
		value: "YES",
	},
	{
		nepali: "छैन",
		english: "",
		value: "NO",
	},
];
//TOILET TYPE
//९.१. परिवारले प्रयोग गर्ने शौचालयको किसिम कस्तो छ?
const toiletYesNo = [
	{
		nepali: "छ",
		english: "Yes",
		value: "yes",
	},
	{
		nepali: "छैन (शौचालय नभएको )",
		english: "No Toilet",
		value: "no",
	},
	{
		nepali: "छ तर प्रयोग गरेको छैन",
		english: "No USE",
		value: "yes_but_not_us",
	},
];
const toiletNoQuestion = {
	english: "",
	nepali: "शौचालयको किसिम कस्तो छ?",
};

const noToiletLists = [
	{
		nepali: "जग्गा नभएर",
		english: "",
		value: "no_land",
	},
	{
		nepali: "बनाउने पैसा नभएर",
		english: "",
		value: "no_money",
	},
	{
		nepali: "छिमेकीको प्रयोग गरेर",
		english: "",
		value: "neighbour_use",
	},
	{
		nepali: "अन्य",
		english: "",
		value: "other",
	},
];

const toiletYesNoUseQn = { nepali: "शौचालय किन संचालन नगरेको ?", english: "" };

const toiletYesNoUse = [
	{
		nepali: "प्रयोग गर्न नमिल्ने भएर",
		english: "",
		value: "not_usable",
	},
	{
		nepali: "पुरानो तर अर्को बनाउन नसकेर",
		english: "",
		value: "old_cannot_afford",
	},
	{
		nepali: "अरुको प्रयोग गरिन्छ",
		english: "",
		value: "using_others",
	},
	{
		nepali: "ढोका नभएर",
		english: "",
		value: "no_door",
	},
	{
		nepali: "अन्य",
		english: "",
		value: "other",
	},
];

const toilettypeQuestion = {
	english: "Which types of toilet you use?",
	nepali: "परिवारले प्रयोग गर्ने शौचालयको किसिम कस्तो छ?",
};
const toiletresourceLists = [
	{
		nepali: "फ्लस भएको(सार्वजनिक ढल)",
		english: "Public tank",
		value: "public_tank",
	},
	{
		nepali: "फ्लस भएको(सेफ्टी ट्याङ्क)",
		english: "Private tank",
		value: "private_tank",
	},
	{
		nepali: "साधारण गोबर ग्यास प्लान्टमा मिसिने",
		english: "Ordinary tank",
		value: "ordinary_tankgas",
	},
	{
		nepali: "साधारण",
		english: "Ordinary",
		value: "ordinary",
	},
];
// WASTE MANAGEMENT
//९.२. तपाईको परिवारले घरबाट निस्कने ठोस फोहरमहिला कहाँ फाल्नु हुन्छ ?
const wasteQuestion = {
	english: "Where do you dispose waste mayterial?",
	nepali: "तपाईको परिवारले घरबाट निस्कने ठोस फोहरमहिला कहाँ फाल्नु हुन्छ ?",
};
const wastemanagementLists = [
	{
		nepali: "जैविक मल बनाउने",
		english: "",
		value: "organic_mal",
	},
	{
		nepali: "संकलित फोहोर जलाउने",
		english: "Burn the waste",
		value: "garbage_burn",
	},
	{
		nepali: "गोबर ग्यास प्लान्ट प्रायोग गर्ने",
		english: "Use gobar gas",
		value: "gobar_gas",
	},
	{
		nepali: "निजि संस्थाले घरबाटै उठाउने",
		english: "Collect by private organization",
		value: "private_org",
	},
	{
		nepali: "अव्यवस्थित तबरले बाहिर जताभावी फ्याल्ने",
		english: "Throw unmanaged way",
		value: "unmanaged",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

//ROAD CONDITION
//९.३. घर सम्म पुग्ने सडक/बाटोको अवास्था कस्तो छ?
const roadQuestion = {
	english: "What is the condition of road?",
	nepali: "घर सम्म पुग्ने सडक/बाटोको अवस्था कस्तो छ?",
};
const roadLists = [
	{
		nepali: "पक्कि/कालोपत्रो सडक",
		english: "Black pitched",
		value: "black_pitched",
	},
	{
		nepali: "ग्रावेल सडक",
		english: "Gravel",
		value: "gravel",
	},
	{
		nepali: "धुले सडक (कच्ची)",
		english: "Dusty road",
		value: "dusty",
	},
	{
		nepali: "गोरेटो बाटो जोडिएको",
		english: "Goreto",
		value: "goreto",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
//BANK DETAILS
//९.४. परिवारको सदस्यको बैंक तथा वित्तीय सस्थामा खाता छ?

const bankaccountQuestion = {
	english: "Do any member of your family have bank account?",
	nepali: " परिवारको सदस्यको बैंक तथा वित्तीय सस्थामा खाता छ?",
};
const bankaccountYesNo = [
	{
		nepali: "छ",
		english: "Yes",
		value: "account_yes",
	},
	{
		nepali: "छैन",
		english: "No",
		value: "account_no",
	},
];

const numberOfBankAccount = {
	english: "If yes how many do you have?",
	nepali: "छ भने कति जना को खाता छ ?",
	value: "",
};

const bankAccountMale = {
	english: "Female",
	nepali: "महिला",
};

const bankAccountFemale = {
	english: "Male",
	nepali: "पुरुष",
};

//SALT
//१०. तपाईको घरमा परिवारले कस्तो नून प्रयोग गर्नु हुन्छ ?
const salttypeQuestion = {
	english: "Which salt do you use?",
	nepali: "तपाईको घरमा परिवारले कस्तो नून प्रयोग गर्नु हुन्छ ?",
};
const salttypeLists = [
	{
		nepali: "आयोडिन्युक्त नून",
		english: "Iodine Salt",
		value: "iodine_salt",
	},
	{
		nepali: "ढिको नून",
		english: "Dhiko salt",
		value: "dhiko_salt",
	},
	{
		nepali: "खुल्ला नून",
		english: "open salt",
		value: "open_salt",
	},
];
//AGRICULTURE AND LIVESTOCKS
//१३.४. कृषि उत्पादन तथा बिक्रि विवरण
const agriProduction = {
	english: "Detail of agricultural production and sales",
	nepali: "कृषि उत्पादन तथा बिक्रि विवरण के के छन् ?",
};
const agriYesNo = [
	{
		nepali: "छ",
		english: "Yes",
		value: "agri_sales_yes",
	},
	{
		nepali: "छैन",
		english: "No",
		value: "agri_sales_no",
	},
];

//If xa vaney कृषि उत्पादन तथा बिक्रि विवरण के के छन् ?
const agriProductionTypeQuestion = {
	english: "Detail of agricultural production and sales",
	nepali: "कृषि उत्पादन तथा बिक्रि विवरण के के छन् ?",
};
const agriProductionType = [
	{
		nepali: "अन्न वाली",
		english: "Crops",
		value: "crops",
	},
	{
		nepali: "दाल वाली",
		english: "Daal",
		value: "daal",
	},
	{
		nepali: "तेलहन उत्पादन",
		english: "Oil seed",
		value: "oilseed",
	},
	{
		nepali: "तरकारी उत्पादन",
		english: "Vegetable production",
		value: "vegetable",
	},
	{
		nepali: "फलफुल",
		english: "Fruit",
		value: "fruit",
	},
	{
		nepali: "नगदेवाली",
		english: "Cash crops",
		value: "cash_crops",
	},
	{
		nepali: "फुलखेती",
		english: "Flower farm",
		value: "flower",
	},
];
//If xa vaney  कुन कुन अन्नवालिको उत्पादन तथा बिक्रि विवरण छन् ?
const agriTypeProduction = {
	english: "Which crops are produced and sales?",
	nepali: "कुन कुन अन्नवालिको उत्पादन तथा बिक्रि विवरण छन् ?",
};
const agriCropType = [
	{
		nepali: "धान",
		english: "Paddy",
		value: "paddy",
	},
	{
		nepali: "मकै",
		english: "Maize",
		value: "maize",
	},
	{
		nepali: "कोदो/फापर",
		english: "Kodo",
		value: "kodo",
	},
	{
		nepali: "गहू/ जौ",
		english: "Gahu",
		value: "gahu",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
//धानखेति गरिएको विवरण
const agriDhanInfo = {
	english: "Detail of paddy Farming ",
	nepali: "धानखेति गरिएको विवरण",
};
const agriDhanAreaQuestion = {
	english: "Area of paddy farming",
	nepali: "धानखेति गरिएको क्षेत्रफ़ल",
};
const agriCropArea = [
	{
		nepali: "रोपनी",
		english: "Ropani",
		value: "",
	},
	{
		nepali: "आना",
		english: "Aana",
		value: "",
	},
	{
		nepali: "पैसा",
		english: "Paisa",
		value: "",
	},
];
//धानखेति उत्पादन (मुरी पाथीमा)
const agriDhanProduction = {
	english: "Production of paddy(muri pathi)",
	nepali: "धानखेति उत्पादन (मुरी पाथीमा)",
};
const agriDhanProductionQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
];
//धानखेति विक्री परिमाण (मुरी पाथीमा)
const agriDhanSale = {
	english: "Sales quantity of paddy(muri pathi)",
	nepali: "धानखेति विक्री परिमाण (मुरी पाथीमा)",
};
const agriDhanSaleQuantity = [
	{
		nepali: "मुरी",
		english: "Muri",
		value: "",
	},
	{
		nepali: "पाथी",
		english: "Paathi",
		value: "",
	},
	{
		nepali: "धानखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
		english: "Total Income From Crops",
		value: "",
	},
];

const welfareYesNoQuestion = {
	nepali:
		"तपाईको परिवारमा सामाजिक सुरक्षा भत्ता प्राप्त गर्ने कोहि हुनुहुन्छ ?",
	english:
		"Is  there anyone in your family who are getting social security allowances?",
};

const welfareYesNo = [
	{
		nepali: "छ",
		english: "yes",
		value: "social_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "social_no",
	},
];

//welfare if yes
const bhattaQuestion = {
	nepali: "परिवारमा सामाजिक सुरक्षा भत्ता प्राप्त गर्नेको संख्या?",
	english: "No of member who gets social security allowances",
};

const bhattaLists = [
	{
		nepali: "ज्येष्ठ नागरिक",
		english: "Old aged citizen",
		value: "old_citizen",
	},
	{
		nepali: "एकल महिला",
		english: "Single women",
		value: "single_women",
	},
	{
		nepali: "पूर्ण अपाङ्ग",
		english: "Fully Disables",
		value: "full_disable",
	},
	{
		nepali: "आंसिक अपाङ्ग",
		english: "Half disable",
		value: "half_disable",
	},
	{
		nepali: "बाल सुरक्षा अनुदान",
		english: "Child security allowances",
		value: "child_security",
	},
	{
		nepali: "दलित वर्ग",
		english: "Dalit",
		value: "dalit",
	},
	{
		nepali: "लोपोन्मुख वर्ग",
		english: "Rare caste",
		value: "rare_caste",
	},
];
const oldcitizenMale = {
	nepali: "ज्येष्ठ नागरिक पुरुष संख्या",
	english: "No of male old aged citizen",
	value: "",
};

const oldcitizenFemale = {
	nepali: "ज्येष्ठ नागरिक महिला संख्या",
	english: "No of female old ages citizen",
	value: "",
};

const singleWomen = {
	nepali: "एकल महिला संख्या",
	english: "No of single women",
	value: "",
};

const disableMen = {
	nepali: "पूर्ण अपाङ्ग पुरुष संख्या",
	english: "No of fully disabled male",
	value: "",
};

const disableWomen = {
	nepali: "पूर्ण अपाङ्ग महिला संख्या",
	english: "No of fully disabled female",
	value: "",
};

const halfdisableMen = {
	nepali: "आंसिक अपाङ्ग पुरुष संख्या",
	english: "No of half disabled male",
	value: "",
};

const halfdisableWomen = {
	nepali: "आंसिक अपाङ्ग महिला संख्या",
	english: "No of half disabled female",
	value: "",
};
const childsecurityMale = {
	nepali: "बाल सुरक्षा अनुदान पुरुष संख्या",
	english: "No of male Child security ",
	value: "",
};
const childsecurityFemale = {
	nepali: "बाल सुरक्षा अनुदान महिला संख्या",
	english: "No of female child security ",
	value: "",
};
const dalitMale = {
	nepali: "दलित वर्ग पुरुष संख्या",
	english: "No of male dalit ",
	value: "",
};
const dalitFemale = {
	nepali: "दलित वर्ग महिला संख्या",
	english: "No of female dalit",
	value: "",
};

const rareCasteMale = {
	nepali: "लोपोन्मुख वर्ग पुरुष संख्या",
	english: "No of male rare caste  ",
	value: "",
};
const rareCasteFemale = {
	nepali: "लोपोन्मुख वर्ग महिला संख्या",
	english: "No of female rare caste",
	value: "",
};
//end bhatta
const houseproblem = {
	nepali: "घर सम्बन्धीकुनै समस्या भए उल्लेख गर्नुहोस",
	english: "If you have any house problem then mention",
	value: "",
};
const suggestion = {
	nepali: "गाउँपालिकालाई तपाइले आफ्नो स्थानको लागी केहि सुझाव/सल्लाह छ?",
	english: "Do you have any suggestation for rural municipality?",
	value: "",
};

//houseprimary detail
const wardQuestion = { nepali: "वडा नं", english: "Ward no" };
const newWardQuestion = { nepali: "बसाइँ सराई गरेको वडा नं", english: "Ward no" };

const wardNumber = [
	{
		nepali: "१",
		english: "1",
		value: "1",
	},
	{
		nepali: "२",
		english: "2",
		value: "2",
	},
	{
		nepali: "३",
		english: "3",
		value: "3",
	},
	{
		nepali: "४",
		english: "4",
		value: "4",
	},
	{
		nepali: "५",
		english: "5",
		value: "5",
	},
	{
		nepali: "६",
		english: "6",
		value: "6",
	},
	{
		nepali: "७",
		english: "7",
		value: "7",
	},
];

const toleOneQn = { nepali: "टोल वा वस्तीको वार्ड १", english: "" };
const toleQnAll = { nepali: "टोल वा वस्तीको नाम", english: "" };
const toleTwoQn = { nepali: "टोल वा वस्तीको वार्ड २", english: "" };
const toleThreeQn = { nepali: "टोल वा वस्तीको वार्ड ३", english: "" };
const toleFourQn = { nepali: "टोल वा वस्तीको वार्ड ४", english: "" };
const toleFiveQn = { nepali: "टोल वा वस्तीको वार्ड ५", english: "" };
const toleSixQn = { nepali: "टोल वा वस्तीको वार्ड ६", english: "" };
const toleSevenQn = { nepali: "टोल वा वस्तीको वार्ड ७", english: "" };

const allToleName = [
	{
		nepali: "खलंगा",
		english: "khalanga",
		value: "khalanga",
	},
	{
		nepali: "टुडिखेल",
		english: "tudikhel",
		value: "tudikhel",
	},
	{
		nepali: "मधुवनी",
		english: "madhuwan",
		value: "madhuwan",
	},
	{
		nepali: "कार्तिके",
		english: "kaattike",
		value: "kaattike",
	},
	{
		nepali: "भदौरे",
		english: "bhadaure",
		value: "bhadaure",
	},
	{
		nepali: "वादरे",
		english: "baadare",
		value: "baadare",
	},
	{
		nepali: "मदले",
		english: "madale",
		value: "madale",
	},
	{
		nepali: "सुगुरे",
		english: "sugure",
		value: "sugure",
	},
	{
		nepali: "वेतपानी",
		english: "wetapaani",
		value: "wetapaani",
	},
	{
		nepali: "हायुटार",
		english: "haaayutaaar",
		value: "haaayutaaar",
	},
	{
		nepali: "गोठडाँडा",
		english: "gothdada",
		value: "gothdada",
	},
	{
		nepali: "कुरेनी मिग्रे",
		english: "kureni",
		value: "kureni",
	},
	{
		nepali: "चुक्ते",
		english: "chukte",
		value: "chukte",
	},
	{
		nepali: "पाँचघरे",
		english: "paanchghare",
		value: "paanchghare",
	},
	{
		nepali: "शिखरडाँडा",
		english: "sikharedada",
		value: "sikharedada",
	},
	{
		nepali: "कटौजे",
		english: "katauje",
		value: "katauje",
	},
	{
		nepali: "छापजन्गार",
		english: "chhapjangaar",
		value: "chhapjangaar",
	},
	{
		nepali: "खराने",
		english: "kharaane",
		value: "kharaane",
	},
	{
		nepali: "सेपाहा",
		english: "sepaaha",
		value: "sepaaha",
	},
	{
		nepali: "रातमाटा",
		english: "raaatmaata",
		value: "raaatmaata",
	},
	{
		nepali: "सिर्थौली",
		english: "sirthauli",
		value: "sirthauli",
	},
	{
		nepali: "मुग्रे",
		english: "mugre",
		value: "mugre",
	},
	{
		nepali: "सखौरी",
		english: "sakhauri",
		value: "sakhauri",
	},
	{
		nepali: "भोक्टेनी",
		english: "bhokteni",
		value: "bhokteni",
	},
	{
		nepali: "लामिवागर",
		english: "laamiwaabagar",
		value: "laamiwaabagar",
	},
	{
		nepali: "कटन",
		english: "katan",
		value: "katan",
	},
	{
		nepali: "पांग्रे",
		english: "paangre",
		value: "paangre",
	},
	{
		nepali: "बर्खे",
		english: "barkhe",
		value: "barkhe",
	},
	{
		nepali: "सिन्दुरे दोभान",
		english: "sindhure_dobhaan",
		value: "sindhure_dobhaan",
	},
	{
		nepali: "वाका",
		english: "waakaa",
		value: "waakaa",
	},
	{
		nepali: "खारदमार",
		english: "khaardamaar",
		value: "khaardamaar",
	},
	{
		nepali: "शिखरडाँडा",
		english: "sikhar_dada",
		value: "sikhar_dada",
	},
	{
		nepali: "देउराली",
		english: "deurali",
		value: "deurali",
	},
	{
		nepali: "भद्राइ",
		english: "bhadrai",
		value: "bhadrai",
	},
	{
		nepali: "छतिवन",
		english: "chatiwan",
		value: "chatiwan",
	},
	{
		nepali: "सिमलदमार",
		english: "simaldamaar",
		value: "simaldamaar",
	},
	{
		nepali: "सिमलटार",
		english: "simaltar",
		value: "simaltar",
	},
	{
		nepali: "माथिल्लो रातमाटा",
		english: "mathillo_satmato",
		value: "mathillo_satmato",
	},
	{
		nepali: "माथिल्लो सखौरी",
		english: "mathillo_sakhauri",
		value: "mathillo_sakhauri",
	},
	{
		nepali: "तेर्से",
		english: "terse",
		value: "terse",
	},
	{
		nepali: "खोल्माडाँडा",
		english: "kholma_danda",
		value: "kholma_danda",
	},
	{
		nepali: "भित्रिमुन्ग्रे",
		english: "bhitrimungre",
		value: "bhitrimungre",
	},
	{
		nepali: "भलाये",
		english: "bhalaye",
		value: "bhalaye",
	},
	{
		nepali: "सिरटोल",
		english: "sirtole",
		value: "sirtole",
	},
	{
		nepali: "भालुमारा",
		english: "bhalumara",
		value: "bhalumara",
	},
	{
		nepali: "नक्कली",
		english: "nakkali",
		value: "nakkali",
	},
	{
		nepali: "मथौली",
		english: "nathauli",
		value: "nathauli",
	},
	{
		nepali: "चनौटे",
		english: "chanaute",
		value: "chanaute",
	},
	{
		nepali: "नक्कली ध्याल्पे",
		english: "nakkali_dhyalpe",
		value: "nakkali_dhyalpe",
	},
	{
		nepali: "नक्कली डाँडा",
		english: "nakkali_dada",
		value: "nakkali_dada",
	},
	{
		nepali: "नक्कली खोलाघरी",
		english: "nakkalikholaghari",
		value: "nakkalikholaghari",
	},
	{
		nepali: "सुके खहरे",
		english: "suke_khahare",
		value: "suke_khahare",
	},
	{
		nepali: "माझीडाँडा",
		english: "majhi_dada",
		value: "majhi_dada",
	},
	{
		nepali: "रानीपर्ता",
		english: "ratiparta",
		value: "ratiparta",
	},
	{
		nepali: "ढोका",
		english: "dhoka",
		value: "dhoka",
	},
	{
		nepali: "डाँडाटोल",
		english: "dada_tole",
		value: "dada_tole",
	},
	{
		nepali: "जरायोटार",
		english: "jarayotar",
		value: "jarayotar",
	},
	{
		nepali: "झाप्सी",
		english: "jhapsi",
		value: "jhapsi",
	},
	{
		nepali: "भलायो भित्रि",
		english: "bhalayo_bhitri",
		value: "bhalayo_bhitri",
	},
	{
		nepali: "ढुग्रे",
		english: "dhugre",
		value: "dhugre",
	},
	{
		nepali: "धप्सार",
		english: "dhapsar",
		value: "dhapsar",
	},
	{
		nepali: "देउताडाँडा",
		english: "deuta_dada",
		value: "deuta_dada",
	},
	{
		nepali: "ढोकाखोला",
		english: "dhoka_khola",
		value: "dhoka_khola",
	},
	{
		nepali: "खारखोला",
		english: "kharkhola",
		value: "kharkhola",
	},
	{
		nepali: "मुक्तान्खोला",
		english: "muktankhola",
		value: "muktankhola",
	},
	{
		nepali: "गोठखोला",
		english: "gothkhola",
		value: "gothkhola",
	},
	{
		nepali: "गगटेडाँडा",
		english: "gagate_dada",
		value: "gagate_dada",
	},
	{
		nepali: "दलितटोल",
		english: "dalit_tole",
		value: "dalit_tole",
	},
	{
		nepali: "गङतान टोल",
		english: "gantan_tole",
		value: "gantan_tole",
	},
	{
		nepali: "चिहाने",
		english: "chihane",
		value: "chihane",
	},
	{
		nepali: "भोगटेझोरा",
		english: "bhogatejhora",
		value: "bhogatejhora",
	},
	{
		nepali: "वान्सिंगटार",
		english: "wansigtar",
		value: "wansigtar",
	},
	{
		nepali: "लामीदमार",
		english: "laamidmar",
		value: "laamidmar",
	},
	{
		nepali: "सुकेज्यामिरे",
		english: "sukejyamire",
		value: "sukejyamire",
	},
	{
		nepali: "भडारचन",
		english: "bhadarchan",
		value: "bhadarchan",
	},
	{
		nepali: "फुलवारी",
		english: "phulbari",
		value: "phulbari",
	},
	{
		nepali: "साततले",
		english: "saattale",
		value: "saattale",
	},
	{
		nepali: "कुडुले",
		english: "kudule",
		value: "kudule",
	},
	{
		nepali: "मंगनिशिर",
		english: "mangalishir",
		value: "mangalishir",
	},
	{
		nepali: "तामे",
		english: "tame",
		value: "tame",
	},
	{
		nepali: "नेटो",
		english: "neto",
		value: "neto",
	},
	{
		nepali: "निउरेनी",
		english: "neureni",
		value: "neureni",
	},
	{
		nepali: "पारिजुटपानी",
		english: "paarijutpaani",
		value: "paarijutpaani",
	},
	{
		nepali: "ठूलीदमार",
		english: "thulidamaar",
		value: "thulidamaar",
	},
	{
		nepali: "मरुवा",
		english: "maruwa",
		value: "maruwa",
	},
	{
		nepali: "साहान",
		english: "sahan",
		value: "sahan",
	},
	{
		nepali: "वारीजुटपानी",
		english: "warijutpani",
		value: "warijutpani",
	},
	{
		nepali: "पहारीखोला",
		english: "parikhola",
		value: "parikhola",
	},
	{
		nepali: "आदामारा",
		english: "aadaamaara",
		value: "aadaamaara",
	},
	{
		nepali: "खाएरसाल",
		english: "khayersal",
		value: "khayersal",
	},
	{
		nepali: "डण्डी",
		english: "dandi",
		value: "dandi",
	},
	{
		nepali: "दुग्रे",
		english: "dugre",
		value: "dugre",
	},
	{
		nepali: "भडारे",
		english: "bhadare",
		value: "bhadare",
	},
	{
		nepali: "पिउरिशिर",
		english: "piurishir",
		value: "piurishir",
	},
	{
		nepali: "छत्तीसाल",
		english: "chattisala",
		value: "chattisala",
	},
	{
		nepali: "ज्यामिरे",
		english: "jyamire",
		value: "jyamire",
	},
	{
		nepali: "चौकितेस्रो टोल",
		english: "chauki_tesro_tole",
		value: "chauki_tesro_tole",
	},
	{
		nepali: "चौकीडाँडा",
		english: "chauki_dada",
		value: "chauki_dada",
	},
	{
		nepali: "सुनखानीडाँडा",
		english: "sunkhani_dada",
		value: "sunkhani_dada",
	},
	{
		nepali: "वडीमुहान",
		english: "wadimuhan",
		value: "wadimuhan",
	},
	{
		nepali: "नयाँवस्ती",
		english: "naya_wasti",
		value: "naya_wasti",
	},
	{
		nepali: "वदीमुहन",
		english: "wadimuhan_1",
		value: "wadimuhan_1",
	},
	{
		nepali: "अम्वास",
		english: "amwas",
		value: "amwas",
	},
	{
		nepali: "ज्यामिरे",
		english: "jyamire_1",
		value: "jyamire_1",
	},
	{
		nepali: "फर्से",
		english: "pharse",
		value: "pharse",
	},
	{
		nepali: "घलानचौर",
		english: "ghalaanchaur",
		value: "ghalaanchaur",
	},
	{
		nepali: "धोंधों",
		english: "dhondhon",
		value: "dhondhon",
	},
	{
		nepali: "धलान टोल",
		english: "dhalan_tole",
		value: "dhalan_tole",
	},
	{
		nepali: "तिलवारि",
		english: "tilwari",
		value: "tilwari",
	},
	{
		nepali: "मगरटोल",
		english: "magar_tole",
		value: "magar_tole",
	},
	{
		nepali: "घुम्तीटोल",
		english: "ghumtitole",
		value: "ghumtitole",
	},
	{
		nepali: "वदाला",
		english: "wadala",
		value: "wadala",
	},
	{
		nepali: "पोखरीडाँडा",
		english: "pokhari_dada",
		value: "pokhari_dada",
	},
	{
		nepali: "पन्चकन्य घाटा",
		english: "panchakanya_ghata",
		value: "panchakanya_ghata",
	},
	{
		nepali: "वेतिनी",
		english: "wetini",
		value: "wetini",
	},
	{
		nepali: "बोझेडाँडा",
		english: "bojho_dada",
		value: "bojho_dada",
	},
	{
		nepali: "सालु भन्झ्यांग",
		english: "salu_bhanjhyang",
		value: "salu_bhanjhyang",
	},
	{
		nepali: "तेल्नि",
		english: "telni",
		value: "telni",
	},
	{
		nepali: "भालुवडार",
		english: "bhaluwadar",
		value: "bhaluwadar",
	},
	{
		nepali: "आपदमार",
		english: "aapdmar",
		value: "aapdmar",
	},
	{
		nepali: "तिनतले वाईवाटार",
		english: "tintale_waiwatar",
		value: "tintale_waiwatar",
	},
	{
		nepali: "तिनदोभान",
		english: "tindobhan",
		value: "tindobhan",
	},
	{
		nepali: "भुताहा",
		english: "butaha",
		value: "butaha",
	},
	{
		nepali: "नयाँवस्ती",
		english: "nayawasti",
		value: "nayawasti",
	},
	{
		nepali: "धामीले",
		english: "dhamile",
		value: "dhamile",
	},
	{
		nepali: "सिरुटार",
		english: "sirutar",
		value: "sirutar",
	},
	{
		nepali: "भट्टी भंझ्यंग",
		english: "bhatti_bhanjhyang",
		value: "bhatti_bhanjhyang",
	},
	{
		nepali: "वसरी",
		english: "wasari",
		value: "wasari",
	},
	{
		nepali: "लहरे",
		english: "lahare",
		value: "lahare",
	},
	{
		nepali: "लामाडाँडा",
		english: "lama_dada",
		value: "lama_dada",
	},
	{
		nepali: "टुडी",
		english: "tudi",
		value: "tudi",
	},
	{
		nepali: "कपिलाकोट डाँडा",
		english: "kapilakot_dada",
		value: "kapilakot_dada",
	},
	{
		nepali: "कमाउटि",
		english: "kmauti",
		value: "kmauti",
	},
	{
		nepali: "भगवती खोला",
		english: "bhagwati_khola",
		value: "bhagwati_khola",
	},
	{
		nepali: "दलित वस्ती",
		english: "dalit_wasti",
		value: "dalit_wasti",
	},
	{
		nepali: "गौरीसंकर टोल",
		english: "gaurisankar_tole",
		value: "gaurisankar_tole",
	},
	{
		nepali: "सोल्टीमोड",
		english: "soltimode",
		value: "soltimode",
	},
	{
		nepali: "वार्ड कार्यालय चौक",
		english: "ward_office_chowk",
		value: "ward_office_chowk",
	},
	{
		nepali: "छाप",
		english: "chaap",
		value: "chaap",
	},
	{
		nepali: "वेलवोट",
		english: "welwot",
		value: "welwot",
	},
	{
		nepali: "छाप माझीटोल",
		english: "chap_majhi_tole",
		value: "chap_majhi_tole",
	},
	{
		nepali: "अठुवा",
		english: "athuwa",
		value: "athuwa",
	},
	{
		nepali: "दाहाल डाँडा",
		english: "dahal_dada",
		value: "dahal_dada",
	},
	{
		nepali: "देउजोर",
		english: "leujor",
		value: "leujor",
	},
	{
		nepali: "महादेवमादी",
		english: "mahadewmadi",
		value: "mahadewmadi",
	},
	{
		nepali: "मलेटो",
		english: "maleto",
		value: "maleto",
	},
	{
		nepali: "गढीदमार",
		english: "gadidamar",
		value: "gadidamar",
	},
	{
		nepali: "सोकन",
		english: "sokan",
		value: "sokan",
	},
	{
		nepali: "सोकनघारि",
		english: "sokanghari",
		value: "sokanghari",
	},
	{
		nepali: "जयमंगल",
		english: "jayamangal",
		value: "jayamangal",
	},
	{
		nepali: "सुके",
		english: "suke",
		value: "suke",
	},
	{
		nepali: "सुकेदमार",
		english: "sukedamaar",
		value: "sukedamaar",
	},
	{
		nepali: "मगरडाँडा",
		english: "magar_dada",
		value: "magar_dada",
	},
	{
		nepali: "गैरिगाउँ",
		english: "gauri_gaun",
		value: "gauri_gaun",
	},
	{
		nepali: "गौरिटार",
		english: "gauritaar",
		value: "gauritaar",
	},
	{
		nepali: "भुडीफोर",
		english: "bhudibhor",
		value: "bhudibhor",
	},
	{
		nepali: "बाह्रविसे",
		english: "barabise",
		value: "barabise",
	},
	{
		nepali: "भीमसेनडाँडा",
		english: "bhimsen_dada",
		value: "bhimsen_dada",
	},
	{
		nepali: "खोलाघारी",
		english: "khola_ghari",
		value: "khola_ghari",
	},
	{
		nepali: "राँङमन्गे",
		english: "rangdange",
		value: "rangdange",
	},
	{
		nepali: "ददी",
		english: "ददी",
		value: "dadi",
	},
	{
		nepali: "ददीदमार",
		english: "dadidamaar",
		value: "dadidamaar",
	},
	{
		nepali: "ठाटि",
		english: "thati",
		value: "thati",
	},
	{
		nepali: "महेस्वता",
		english: "mheswata",
		value: "mheswata",
	},
	{
		nepali: "नंगेरी",
		english: "nangere",
		value: "nangere",
	},
	{
		nepali: "क्याम्पसचौक",
		english: "campus_chowk",
		value: "campus_chowk",
	},
];

const toleWardOne = [
	{
		nepali: "खलंगा",
		english: "khalanga",
		value: "khalanga",
	},
	{
		nepali: "टुडिखेल",
		english: "tudikhel",
		value: "tudikhel",
	},
	{
		nepali: "मधुवनी",
		english: "madhuwan",
		value: "madhuwan",
	},
	{
		nepali: "कार्तिके",
		english: "kaattike",
		value: "kaattike",
	},
	{
		nepali: "भदौरे",
		english: "bhadaure",
		value: "bhadaure",
	},
	{
		nepali: "वादरे",
		english: "baadare",
		value: "baadare",
	},
	{
		nepali: "मदले",
		english: "madale",
		value: "madale",
	},
	{
		nepali: "सुगुरे",
		english: "sugure",
		value: "sugure",
	},
	{
		nepali: "वेतपानी",
		english: "wetapaani",
		value: "wetapaani",
	},
	{
		nepali: "हायुटार",
		english: "haaayutaaar",
		value: "haaayutaaar",
	},
	{
		nepali: "गोठडाँडा",
		english: "gothdada",
		value: "gothdada",
	},
	{
		nepali: "कुरेनी मिग्रे",
		english: "kureni",
		value: "kureni",
	},
	{
		nepali: "चुक्ते",
		english: "chukte",
		value: "chukte",
	},
	{
		nepali: "पाँचघरे",
		english: "paanchghare",
		value: "paanchghare",
	},
	{
		nepali: "शिखरडाँडा",
		english: "sikharedada",
		value: "sikharedada",
	},
	{
		nepali: "कटौजे",
		english: "katauje",
		value: "katauje",
	},
	{
		nepali: "छापजन्गार",
		english: "chhapjangaar",
		value: "chhapjangaar",
	},
	{
		nepali: "खराने",
		english: "kharaane",
		value: "kharaane",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "other",
	},
];

const toleWardTwo = [
	{
		nepali: "सेपाहा",
		english: "sepaaha",
		value: "sepaaha",
	},
	{
		nepali: "रातमाटा",
		english: "raaatmaata",
		value: "raaatmaata",
	},
	{
		nepali: "सिर्थौली",
		english: "sirthauli",
		value: "sirthauli",
	},
	{
		nepali: "मुग्रे",
		english: "mugre",
		value: "mugre",
	},
	{
		nepali: "सखौरी",
		english: "sakhauri",
		value: "sakhauri",
	},
	{
		nepali: "भोक्टेनी",
		english: "bhokteni",
		value: "bhokteni",
	},
	{
		nepali: "लामिवागर",
		english: "laamiwaabagar",
		value: "laamiwaabagar",
	},
	{
		nepali: "कटन",
		english: "katan",
		value: "katan",
	},
	{
		nepali: "पांग्रे",
		english: "paangre",
		value: "paangre",
	},
	{
		nepali: "बर्खे",
		english: "barkhe",
		value: "barkhe",
	},
	{
		nepali: "सिन्दुरे दोभान",
		english: "sindhure_dobhaan",
		value: "sindhure_dobhaan",
	},
	{
		nepali: "वाका",
		english: "waakaa",
		value: "waakaa",
	},
	{
		nepali: "खारदमार",
		english: "khaardamaar",
		value: "khaardamaar",
	},
	{
		nepali: "शिखरडाँडा",
		english: "sikhar_dada",
		value: "sikhar_dada",
	},
	{
		nepali: "देउराली",
		english: "deurali",
		value: "deurali",
	},
	{
		nepali: "भद्राइ",
		english: "bhadrai",
		value: "bhadrai",
	},
	{
		nepali: "छतिवन",
		english: "chatiwan",
		value: "chatiwan",
	},
	{
		nepali: "सिमलदमार",
		english: "simaldamaar",
		value: "simaldamaar",
	},
	{
		nepali: "सिमलटार",
		english: "simaltar",
		value: "simaltar",
	},
	{
		nepali: "माथिल्लो रातमाटा",
		english: "mathillo_satmato",
		value: "mathillo_satmato",
	},
	{
		nepali: "माथिल्लो सखौरी",
		english: "mathillo_sakhauri",
		value: "mathillo_sakhauri",
	},
	{
		nepali: "तेर्से",
		english: "terse",
		value: "terse",
	},
	{
		nepali: "खोल्माडाँडा",
		english: "kholma_danda",
		value: "kholma_danda",
	},
	{
		nepali: "भित्रिमुन्ग्रे",
		english: "bhitrimungre",
		value: "bhitrimungre",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "other",
	},
];

const toleWardThree = [
	{
		nepali: "भलाये",
		english: "bhalaye",
		value: "bhalaye",
	},
	{
		nepali: "सिरटोल",
		english: "sirtole",
		value: "sirtole",
	},
	{
		nepali: "भालुमारा",
		english: "bhalumara",
		value: "bhalumara",
	},
	{
		nepali: "नक्कली",
		english: "nakkali",
		value: "nakkali",
	},
	{
		nepali: "मथौली",
		english: "nathauli",
		value: "nathauli",
	},
	{
		nepali: "चनौटे",
		english: "chanaute",
		value: "chanaute",
	},
	{
		nepali: "नक्कली ध्याल्पे",
		english: "nakkali_dhyalpe",
		value: "nakkali_dhyalpe",
	},
	{
		nepali: "नक्कली डाँडा",
		english: "nakkali_dada",
		value: "nakkali_dada",
	},
	{
		nepali: "नक्कली खोलाघरी",
		english: "nakkalikholaghari",
		value: "nakkalikholaghari",
	},
	{
		nepali: "सुके खहरे",
		english: "suke_khahare",
		value: "suke_khahare",
	},
	{
		nepali: "माझीडाँडा",
		english: "majhi_dada",
		value: "majhi_dada",
	},
	{
		nepali: "रानीपर्ता",
		english: "ratiparta",
		value: "ratiparta",
	},
	{
		nepali: "ढोका",
		english: "dhoka",
		value: "dhoka",
	},
	{
		nepali: "डाँडाटोल",
		english: "dada_tole",
		value: "dada_tole",
	},
	{
		nepali: "जरायोटार",
		english: "jarayotar",
		value: "jarayotar",
	},
	{
		nepali: "झाप्सी",
		english: "jhapsi",
		value: "jhapsi",
	},
	{
		nepali: "भलायो भित्रि",
		english: "bhalayo_bhitri",
		value: "bhalayo_bhitri",
	},
	{
		nepali: "ढुग्रे",
		english: "dhugre",
		value: "dhugre",
	},
	{
		nepali: "धप्सार",
		english: "dhapsar",
		value: "dhapsar",
	},
	{
		nepali: "देउताडाँडा",
		english: "deuta_dada",
		value: "deuta_dada",
	},
	{
		nepali: "ढोकाखोला",
		english: "dhoka_khola",
		value: "dhoka_khola",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "other",
	},
];

const toleWardFour = [
	{
		nepali: "खारखोला",
		english: "kharkhola",
		value: "kharkhola",
	},
	{
		nepali: "मुक्तान्खोला",
		english: "muktankhola",
		value: "muktankhola",
	},
	{
		nepali: "गोठखोला",
		english: "gothkhola",
		value: "gothkhola",
	},
	{
		nepali: "गगटेडाँडा",
		english: "gagate_dada",
		value: "gagate_dada",
	},
	{
		nepali: "दलितटोल",
		english: "dalit_tole",
		value: "dalit_tole",
	},
	{
		nepali: "गङतान टोल",
		english: "gantan_tole",
		value: "gantan_tole",
	},
	{
		nepali: "चिहाने",
		english: "chihane",
		value: "chihane",
	},
	{
		nepali: "भोगटेझोरा",
		english: "bhogatejhora",
		value: "bhogatejhora",
	},
	{
		nepali: "वान्सिंगटार",
		english: "wansigtar",
		value: "wansigtar",
	},
	{
		nepali: "लामीदमार",
		english: "laamidmar",
		value: "laamidmar",
	},
	{
		nepali: "सुकेज्यामिरे",
		english: "sukejyamire",
		value: "sukejyamire",
	},
	{
		nepali: "भडारचन",
		english: "bhadarchan",
		value: "bhadarchan",
	},
	{
		nepali: "फुलवारी",
		english: "phulbari",
		value: "phulbari",
	},
	{
		nepali: "साततले",
		english: "saattale",
		value: "saattale",
	},
	{
		nepali: "कुडुले",
		english: "kudule",
		value: "kudule",
	},
	{
		nepali: "मंगनिशिर",
		english: "mangalishir",
		value: "mangalishir",
	},
	{
		nepali: "तामे",
		english: "tame",
		value: "tame",
	},
	{
		nepali: "नेटो",
		english: "neto",
		value: "neto",
	},
	{
		nepali: "निउरेनी",
		english: "neureni",
		value: "neureni",
	},
	{
		nepali: "पारिजुटपानी",
		english: "paarijutpaani",
		value: "paarijutpaani",
	},
	{
		nepali: "ठूलीदमार",
		english: "thulidamaar",
		value: "thulidamaar",
	},
	{
		nepali: "मरुवा",
		english: "maruwa",
		value: "maruwa",
	},
	{
		nepali: "साहान",
		english: "sahan",
		value: "sahan",
	},
	{
		nepali: "वारीजुटपानी",
		english: "warijutpani",
		value: "warijutpani",
	},
	{
		nepali: "पहारीखोला",
		english: "parikhola",
		value: "parikhola",
	},
	{
		nepali: "आदामारा",
		english: "aadaamaara",
		value: "aadaamaara",
	},
	{
		nepali: "खाएरसाल",
		english: "khayersal",
		value: "khayersal",
	},
	{
		nepali: "डण्डी",
		english: "dandi",
		value: "dandi",
	},
	{
		nepali: "दुग्रे",
		english: "dugre",
		value: "dugre",
	},
	{
		nepali: "भडारे",
		english: "bhadare",
		value: "bhadare",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "other",
	},
];

const toleWardFive = [
	{
		nepali: "पिउरिशिर",
		english: "piurishir",
		value: "piurishir",
	},
	{
		nepali: "छत्तीसाल",
		english: "chattisala",
		value: "chattisala",
	},
	{
		nepali: "ज्यामिरे",
		english: "jyamire",
		value: "jyamire",
	},
	{
		nepali: "चौकितेस्रो टोल",
		english: "chauki_tesro_tole",
		value: "chauki_tesro_tole",
	},
	{
		nepali: "चौकीडाँडा",
		english: "chauki_dada",
		value: "chauki_dada",
	},
	{
		nepali: "सुनखानीडाँडा",
		english: "sunkhani_dada",
		value: "sunkhani_dada",
	},
	{
		nepali: "वडीमुहान",
		english: "wadimuhan",
		value: "wadimuhan",
	},
	{
		nepali: "नयाँवस्ती",
		english: "naya_wasti",
		value: "naya_wasti",
	},
	{
		nepali: "वदीमुहन",
		english: "wadimuhan_1",
		value: "wadimuhan_1",
	},
	{
		nepali: "अम्वास",
		english: "amwas",
		value: "amwas",
	},
	{
		nepali: "ज्यामिरे",
		english: "jyamire_1",
		value: "jyamire_1",
	},
	{
		nepali: "फर्से",
		english: "pharse",
		value: "pharse",
	},
	{
		nepali: "घलानचौर",
		english: "ghalaanchaur",
		value: "ghalaanchaur",
	},
	{
		nepali: "धोंधों",
		english: "dhondhon",
		value: "dhondhon",
	},
	{
		nepali: "धलान टोल",
		english: "dhalan_tole",
		value: "dhalan_tole",
	},
	{
		nepali: "तिलवारि",
		english: "tilwari",
		value: "tilwari",
	},
	{
		nepali: "मगरटोल",
		english: "magar_tole",
		value: "magar_tole",
	},
	{
		nepali: "घुम्तीटोल",
		english: "ghumtitole",
		value: "ghumtitole",
	},
	{
		nepali: "वदाला",
		english: "wadala",
		value: "wadala",
	},
	{
		nepali: "पोखरीडाँडा",
		english: "pokhari_dada",
		value: "pokhari_dada",
	},
	{
		nepali: "पन्चकन्य घाटा",
		english: "panchakanya_ghata",
		value: "panchakanya_ghata",
	},
	{
		nepali: "वेतिनी",
		english: "wetini",
		value: "wetini",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "other",
	},
];

const toleWardSix = [
	{
		nepali: "बोझेडाँडा",
		english: "bojho_dada",
		value: "bojho_dada",
	},
	{
		nepali: "सालु भन्झ्यांग",
		english: "salu_bhanjhyang",
		value: "salu_bhanjhyang",
	},
	{
		nepali: "तेल्नि",
		english: "telni",
		value: "telni",
	},
	{
		nepali: "भालुवडार",
		english: "bhaluwadar",
		value: "bhaluwadar",
	},
	{
		nepali: "आपदमार",
		english: "aapdmar",
		value: "aapdmar",
	},
	{
		nepali: "तिनतले वाईवाटार",
		english: "tintale_waiwatar",
		value: "tintale_waiwatar",
	},
	{
		nepali: "तिनदोभान",
		english: "tindobhan",
		value: "tindobhan",
	},
	{
		nepali: "भुताहा",
		english: "butaha",
		value: "butaha",
	},
	{
		nepali: "नयाँवस्ती",
		english: "nayawasti",
		value: "nayawasti",
	},
	{
		nepali: "धामीले",
		english: "dhamile",
		value: "dhamile",
	},
	{
		nepali: "सिरुटार",
		english: "sirutar",
		value: "sirutar",
	},
	{
		nepali: "भट्टी भंझ्यंग",
		english: "bhatti_bhanjhyang",
		value: "bhatti_bhanjhyang",
	},
	{
		nepali: "वसरी",
		english: "wasari",
		value: "wasari",
	},
	{
		nepali: "लहरे",
		english: "lahare",
		value: "lahare",
	},
	{
		nepali: "लामाडाँडा",
		english: "lama_dada",
		value: "lama_dada",
	},
	{
		nepali: "टुडी",
		english: "tudi",
		value: "tudi",
	},
	{
		nepali: "कपिलाकोट डाँडा",
		english: "kapilakot_dada",
		value: "kapilakot_dada",
	},
	{
		nepali: "कमाउटि",
		english: "kmauti",
		value: "kmauti",
	},
	{
		nepali: "भगवती खोला",
		english: "bhagwati_khola",
		value: "bhagwati_khola",
	},
	{
		nepali: "दलित वस्ती",
		english: "dalit_wasti",
		value: "dalit_wasti",
	},
	{
		nepali: "गौरीसंकर टोल",
		english: "gaurisankar_tole",
		value: "gaurisankar_tole",
	},
	{
		nepali: "सोल्टीमोड",
		english: "soltimode",
		value: "soltimode",
	},
	{
		nepali: "वार्ड कार्यालय चौक",
		english: "ward_office_chowk",
		value: "ward_office_chowk",
	},
	{
		nepali: "छाप",
		english: "chaap",
		value: "chaap",
	},
	{
		nepali: "वेलवोट",
		english: "welwot",
		value: "welwot",
	},
	{
		nepali: "छाप माझीटोल",
		english: "chap_majhi_tole",
		value: "chap_majhi_tole",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "other",
	},
];

const toleWardSeven = [
	{
		nepali: "अठुवा",
		english: "athuwa",
		value: "athuwa",
	},
	{
		nepali: "दाहाल डाँडा",
		english: "dahal_dada",
		value: "dahal_dada",
	},
	{
		nepali: "देउजोर",
		english: "leujor",
		value: "leujor",
	},
	{
		nepali: "महादेवमादी",
		english: "mahadewmadi",
		value: "mahadewmadi",
	},
	{
		nepali: "मलेटो",
		english: "maleto",
		value: "maleto",
	},
	{
		nepali: "गढीदमार",
		english: "gadidamar",
		value: "gadidamar",
	},
	{
		nepali: "सोकन",
		english: "sokan",
		value: "sokan",
	},
	{
		nepali: "सोकनघारि",
		english: "sokanghari",
		value: "sokanghari",
	},
	{
		nepali: "जयमंगल",
		english: "jayamangal",
		value: "jayamangal",
	},
	{
		nepali: "सुके",
		english: "suke",
		value: "suke",
	},
	{
		nepali: "सुकेदमार",
		english: "sukedamaar",
		value: "sukedamaar",
	},
	{
		nepali: "मगरडाँडा",
		english: "magar_dada",
		value: "magar_dada",
	},
	{
		nepali: "गैरिगाउँ",
		english: "gauri_gaun",
		value: "gauri_gaun",
	},
	{
		nepali: "गौरिटार",
		english: "gauritaar",
		value: "gauritaar",
	},
	{
		nepali: "भुडीफोर",
		english: "bhudibhor",
		value: "bhudibhor",
	},
	{
		nepali: "बाह्रविसे",
		english: "barabise",
		value: "barabise",
	},
	{
		nepali: "भीमसेनडाँडा",
		english: "bhimsen_dada",
		value: "bhimsen_dada",
	},
	{
		nepali: "खोलाघारी",
		english: "khola_ghari",
		value: "khola_ghari",
	},
	{
		nepali: "राँङमन्गे",
		english: "rangdange",
		value: "rangdange",
	},
	{
		nepali: "ददी",
		english: "ददी",
		value: "dadi",
	},
	{
		nepali: "ददीदमार",
		english: "dadidamaar",
		value: "dadidamaar",
	},
	{
		nepali: "ठाटि",
		english: "thati",
		value: "thati",
	},
	{
		nepali: "महेस्वता",
		english: "mheswata",
		value: "mheswata",
	},
	{
		nepali: "नंगेरी",
		english: "nangere",
		value: "nangere",
	},
	{
		nepali: "क्याम्पसचौक",
		english: "campus_chowk",
		value: "campus_chowk",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "other",
	},
];

const gharNumber = {
	nepali: "घर नं",
	english: "House No.",
	value: "",
};

const sabikGabisaQuestion = {
	nepali: "साविक गा.वि.स",
	english: "Sabik gaa bi sa",
};

const sabikGabisa = [
	{
		nepali: "बाग्लुङपानी",
		english: "Baaglungpani",
		value: "baaglungpaani",
	},
	{
		nepali: "मालिङ",
		english: "Maaling",
		value: "maaling",
	},
	{
		nepali: "उत्तरकन्या",
		english: "Uttarkanya",
		value: "uttarkanya",
	},
	{
		nepali: "भुजुङ",
		english: "Bhujung",
		value: "bhujung",
	},
	{
		nepali: "पसगाउँ",
		english: "Pasgaau",
		value: "pasgaau",
	},
	{
		nepali: "भोजे",
		english: "Bhoje",
		value: "bhoje",
	},
	{
		nepali: "गिलुङ",
		english: "Gilung",
		value: "gilung",
	},
];

const sabikWard = { nepali: "साविक वडा नं ", english: "Sabik ward no." };

const tole = { nepali: "टोल वा वस्तीको नाम", english: "Name of tole " };

const toleNumber = { nepali: "टोल संकेत नं ", english: "Tole no." };

const streetName = {
	nepali: "मार्गको नाम (घर छेउको बाटो )",
	english: "Street name",
};

const answererName = { nepali: "उत्तरदाताको नाम ", english: "Answerer name" };

const answererRelationQuestion = {
	nepali: "उत्तरदाताको घरमुली संगको नाता के हो ?",
	english: "What is the answerer relation with house owner?",
};

// use both in primary detail and fmaily details
const answererRelation = [
	{
		nepali: "घरमुली आफै",
		english: "Self house owner",
		value: "self_house_own",
	},
	{
		nepali: "श्रीमान",
		english: "Husband",
		value: "husband",
	},
	{
		nepali: "श्रीमती",
		english: "Wife",
		value: "wife",
	},
	{
		nepali: "आमा",
		english: "Mother",
		value: "mother",
	},
	{
		nepali: "बुवा",
		english: "Father",
		value: "father",
	},
	{
		nepali: "छोरा",
		english: "Son",
		value: "son",
	},
	{
		nepali: "छोरी",
		english: "Daughter",
		value: "daughter",
	},
	{
		nepali: "बुहारी",
		english: "Buhari",
		value: "buhari",
	},
	{
		nepali: "ज्वाई",
		english: "Jwai",
		value: "jwai",
	},
	{
		nepali: "सासु",
		english: "Sasu",
		value: "sashu",
	},
	{
		nepali: "ससुरा",
		english: "Sasura",
		value: "sasura",
	},
	{
		nepali: "काका",
		english: "Kaka",
		value: "kaka",
	},
	{
		nepali: "काकी",
		english: "Kaki",
		value: "kaki",
	},
	{
		nepali: "फुपू",
		english: "Fupu",
		value: "fupu",
	},
	{
		nepali: "फुपाजु",
		english: "Fupaju",
		value: "fupaju",
	},
	{
		nepali: "मामा",
		english: "Mama",
		value: "mama",
	},
	{
		nepali: "माइजु",
		english: "Maiju",
		value: "maiju",
	},
	{
		nepali: "नाती",
		english: "Nati",
		value: "nati",
	},
	{
		nepali: "नातिनी",
		english: "Natini",
		value: "natini",
	},
	{
		nepali: "दाजु",
		english: "Daju",
		value: "daju",
	},
	{
		nepali: "भाई",
		english: "Bhai",
		value: "bhai",
	},
	{
		nepali: "दिदि",
		english: "Didi",
		value: "didi",
	},
	{
		nepali: "बहिनी",
		english: "Bahini",
		value: "bahini",
	},
	{
		nepali: "भाउजु",
		english: "Bhauju",
		value: "bhauju",
	},
	{
		nepali: "भाई बुहारी",
		english: "Bhai Buhari",
		value: "bhai_buhari",
	},
	{
		nepali: "जेठान",
		english: "Jethan",
		value: "jethan",
	},
	{
		nepali: "ठुलो बुवा",
		english: "Thulo Buwa",
		value: "thulo_bbuwa",
	},
	{
		nepali: "ठुलो आमा",
		english: "Thulo aama",
		value: "thulo_aama",
	},
	{
		nepali: "हजुर बुवा",
		english: "",
		value: "grand_father",
	},
	{
		nepali: "हजुर आमा",
		english: "Grand mother",
		value: "grand_mother",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const familyMale = {
	nepali: "परिवारको पुरुष संख्या",
	english: "No of male in family",
};

const familyFemale = {
	nepali: "परिवारको महिला संख्या",
	english: "No of female in family",
};

const familyThirdGender = {
	nepali: "तेस्रो लिंगी सदस्य संख्या",
	english: "No of third gender",
};

const totalMember = {
	nepali: "जम्मा सदस्य संख्या",
	english: "Total no of family member",
};

const geoLocation = { nepali: "जियो कोड", english: "Geo code" };

const latitude = { nepali: "अक्षांश", english: "Latitude" };

const longitude = { nepali: "देशान्तर", english: "Longitude" };

const housePhoto = { nepali: "घर को फोटो", english: "House photo" };
const citizenshipFrontPhoto = {
	nepali: "नागरिकताको अगाडिको फोटो",
	english: "Front photo of citizenship",
};
const citizenshipBackPhoto = {
	nepali: "नागरिकताको पछाडिको  फोटो",
	english: "Back photo of citizenship",
};

const livingtypeQuestion = {
	nepali: "तपाईको परिवारको यहाको बसोबास कस्तो छ ?",
	english: "How is your living style here?",
};
const geoCode = { nepali: "जियो कोड", english: "Geo code" };
const livingType = [
	{
		nepali: "अस्थाई",
		english: "temporary",
		value: "temporary",
	},
	{
		nepali: "स्थाई",
		english: "Permanent",
		value: "permanent",
	},
	{
		nepali: "सुकुम्बासी",
		english: "sukumbaasi",
		value: "sukumbaasi",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

//if अस्थाई clicked
const permanentQuestion = {
	nepali: "स्थाई वसोवास कहाँ छ ?",
	english: "Where are you living now?",
};

const permanent = [
	{
		nepali: "अर्को वडा",
		english: "Next ward",
		value: "next_ward",
	},
	{
		nepali: "यही जिल्ला",
		english: "Same district",
		value: "same_district",
	},
	{
		nepali: "अर्को जिल्ला",
		english: "Next district",
		value: "next_district",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

//Family details

const memberName = { nepali: "नाम/थर", english: "Full name" };

const contactNo = { nepali: "को सम्पर्क न", english: "Contact no" };

const genderQuestion = { nepali: "को लिङ्ग ?", english: "Gender" };

const dateQuestion = { nepali: "जन्म मिती", english: "Date of Birth" };

const ageQuestion = { nepali: "को उमेर", english: "Age" };

const gender = [
	{
		nepali: "पुरुष",
		english: "Male",
		value: "male",
	},
	{
		nepali: "महिला",
		english: "Female",
		value: "female",
	},
	{
		nepali: "तेश्रो लिंगी",
		english: "Third gender",
		value: "third_gender",
	},
];

const livingStatusQuestion = {
	nepali: "को वसोवासको स्थिति",
	english: "Living situation",
};

const livingStatus = [
	{
		nepali: "गणना गरेको ठाउँ",
		english: "Same place of data collection",
		value: "same_place",
	},
	{
		nepali: "स्वदेश अन्यत्र",
		english: "Other place",
		value: "other_place",
	},
	{
		nepali: "विदेश",
		english: "Foreign",
		value: "foreign",
	},
];

//if विदेश is clicked

const abroadReasonQuestion = {
	nepali: "विदेश गएको कारण ?",
	english: "Reason to go abroad",
};

const abroadReason = [
	{
		nepali: "शिक्षा",
		english: "Education",
		value: "education",
	},
	{
		nepali: "रोजगार",
		english: "Employment",
		value: "employment",
	},
	{
		nepali: "घरेलु कामदार (महिला)",
		english: "Houser worker(female)",
		value: "house_worker",
	},
	{
		nepali: "डी.भी. चिठ्ठा",
		english: "DV Lottery",
		value: "dv_lottery",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const abroadYearQuestion = {
	nepali:
		"विदेश गएको कति बर्ष भयो ? (६ महिना भयो भने 0.6 लेख्ने २ वर्ष ११ महिना भयो भने 2.11 लेख्ने )",
	english: "How many year you have been in foreign?",
};

const abroadCountryQuestion = {
	nepali: "विदेश गएको देश?",
	english: "Which country?",
};

const abroadCountry = [
	{
		nepali: "भारत",
		english: "India",
		value: "india",
	},
	{
		nepali: "कतार",
		english: "Qatar",
		value: "quatar",
	},
	{
		nepali: "भारत बाहेक अन्य सार्क राष्ट्र",
		english: "SAARC Country except India",
		value: "saarc",
	},
	{
		nepali: "साउदी अरब",
		english: "Saudi arab",
		value: "saudi_arab",
	},
	{
		nepali: "मलेसिया",
		english: "Malaysia",
		value: "malaysia",
	},
	{
		nepali: "जापान",
		english: "Japan",
		value: "japan",
	},
	{
		nepali: "यु.ए.ई. (दुबई)",
		english: "UAE",
		value: "uae",
	},
	{
		nepali: "दक्षिण कोरिया",
		english: "South korea",
		value: "south_korea",
	},
	{
		nepali: "अष्ट्रेलिया",
		english: "Australia",
		value: "australia",
	},
	{
		nepali: "अमेरिका",
		english: "USA",
		value: "usa",
	},
	{
		nepali: "इराक",
		english: "Iraq",
		value: "iraq",
	},
	{
		nepali: "बेलायत",
		english: "England",
		value: "england",
	},
	{
		nepali: "क्यानाडा",
		english: "Canada",
		value: "canada",
	},
	{
		nepali: "जर्मनी",
		english: "Germany",
		value: "germany",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const casteQuestion = { nepali: "को जात/जाति", english: "caste" };

const casteList = [
	{
		english: "brahmin chhetri",
		nepali: "ब्राहमण /क्षेत्री",
		value: "brahmin_chhetri",
	},
	{
		english: "Caste",
		nepali: "आदिवासी जनजाती (माझी, राई, मगर, लिम्बु, नेवार , शेर्पा, तामांग)",
		value: "janjaati_aadiw",
	},
	{
		english: "",
		nepali: "सन्यासी (गिरि /पुरी)",
		value: "sanyaasi",
	},
	{
		english: "Madeshi other caste",
		nepali: "मधेसी",
		value: "madhesi_sampur",
	},
	{
		english: "Pariyar( damahi ,darji ,suchikar)",
		nepali: "दमाई (परिवार ,दर्नाल ,सुन्सूदास, नेपाली, दर्जी, लाखे)",
		value: "pariyar",
	},
	{
		english: "Bishwokarma(kami,lohar,od,darji, chunra,parki)",
		nepali: "विश्वकर्मा (कामि,लोहार,ओड,दर्जी चुनरा,पार्की )",
		value: "biswokarma",
	},
	{
		english: "Sarki(mijar,charmakar mul)",
		nepali: "सार्की (मिजार,चर्माकार मूल )",
		value: "sarki",
	},
	{ english: "Thakuri", nepali: "ठकुरी", value: "thakuri" },
	{ english: "Majhi", nepali: "माझी", value: "majhi" },
	{ english: "Mushlim", nepali: "मुस्लिम", value: "muslim" },
	{ english: "Mushlim", nepali: "थारु", value: "tharu" },
	{ english: "Mushlim", nepali: "धामी", value: "dhaami" },
	{ english: "Mushlim", nepali: "पहरी", value: "pahari" },
	{ english: "Mushlim", nepali: "थामी", value: "thaami" },
	{ english: "Mushlim", nepali: "बोटे", value: "bote" },
	{ english: "Mushlim", nepali: "हायु", value: "haayu" },
	{ english: "Other", nepali: "अन्य", value: "other" },
];

// if जनजाती आदिवासी is clicked
const aadibasiQuestion = { nepali: "कुन जनजाती हो ?", english: "Which caste" };

const aadibasi = [
	{
		nepali: "राई",
		english: "Rai",
		value: "rai",
	},
	{
		nepali: "मगर",
		english: "Magar",
		value: "magar",
	},
	{
		nepali: "शेर्पा",
		english: "Sherpa",
		value: "sherpa",
	},
	{
		nepali: "गुरुङ्ग",
		english: "Gurung",
		value: "gurung",
	},
	{
		nepali: "सुनुवार",
		english: "Sunuwar",
		value: "sunuwar",
	},
	{
		nepali: "तामाङ्ग",
		english: "Tamang",
		value: "tamang",
	},
	{
		nepali: "नेवार",
		english: "Newar",
		value: "newar",
	},
	{
		nepali: "थकाली",
		english: "Thakali",
		value: "thakali",
	},
	{
		nepali: "लिम्बु",
		english: "Lilbu",
		value: "limbu",
	},
	{
		nepali: "भुजेल",
		english: "Bhujel",
		value: "bhujel",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

//if  पहाडी अन्य जाति is clkicked

// const pahadiQuestion = { nepali: "कुन पहाडी अन्य जाती हो ?", english: "" };
//
// const pahadi = [
// 	{
// 		nepali: "गिरि",
// 		english: "Giri",
// 		value: "giri"
// 	},
// 	{
// 		nepali: "पुरी",
// 		english: "Puri",
// 		value: "puri"
// 	},
// 	{
// 		nepali: "भारती",
// 		english: "Bharati",
// 		value: "bharati"
// 	},
// 	{
// 		nepali: "सन्यासी/दशनामी",
// 		english: "Sanyashi",
// 		value: "sansayi"
// 	},
// 	{
// 		nepali: "योगी",
// 		english: "Yogi",
// 		value: "yogi"
// 	},
// 	{
// 		nepali: "कुँवर",
// 		english: "Kunwar",
// 		value: "kunwar"
// 	}
// ];

//religion
const religionQuestion = { nepali: "को धर्म", english: "religion" };

const religionList = [
	{ english: "Hindu", nepali: "हिन्दु", value: "hindu" },
	{ english: "Buddhist", nepali: "बुद्ध", value: "buddhist" },
	{ english: "Muslim", nepali: "इस्लाम (मुस्लिम)", value: "muslim" },
	{ english: "Christian", nepali: "इसाई (क्रिश्चियन)", value: "christian" },
	{ english: "Kirat", nepali: "किंरात", value: "kirat" },
	{ english: "Other", nepali: "अन्य", value: "other" },
];

//marriage

const marriageQuestion = {
	nepali: "को बैवाहिक स्थिति",
	english: "Marital status",
};

const marriage = [
	{
		nepali: "अविवाहित",
		english: "Unmarried",
		value: "unmarried",
	},
	{
		nepali: "विवाहित",
		english: "Married",
		value: "married",
	},
	{
		nepali: "बहु बिबाह",
		english: "Multi marriage",
		value: "multi_marriage",
	},
	{
		nepali: "पुन बिबाह",
		english: "Re married",
		value: "re_married",
	},
	{
		nepali: "बिधुर",
		english: "Widow",
		value: "widow",
	},
	{
		nepali: "बिधुवा",
		english: "Widol",
		value: "wido1",
	},
	{
		nepali: "सम्बन्ध बिच्छेद (विवाहित तर अलग बसेको)",
		english: "Divorce",
		value: "divorce",
	},
	{
		nepali: "विवाहित तर अलग बसेको",
		english: "Under age",
		value: "marriage_but_l",
	},
];

//education

//  age >= 4 and age <16
const isGoingToSchoolQn = {
	nepali: "हाल विद्यालय जानु हुन्छ कि हुन्न ?",
	english: "",
};

const isGoingToSchool = [
	{
		english: "Yes",
		nepali: "जानु हुन्छ",
		value: "yes_going",
	},
	{
		english: "No",
		nepali: "जानु हुन्न",
		value: "not_going",
	},
];

const notGoingSchoolQn = {
	nepali: "जानु हुन्न भने किन जानु हुन्न ?",
	english: "",
};

const notGoingSchool = [
	{
		nepali: "शिक्षाको महत्वो थाहा नभएर",
		english: "",
		value: "unknown_about_importance_of_education",
	},
	{
		nepali: "अभिभावकले ध्यान नदिएर",
		english: "",
		value: "no_guidance_of_parents",
	},
	{
		nepali: "बिद्यालय टाडा भएकोले",
		english: "",
		value: "far_school",
	},
	{
		nepali: "विद्यालयमा कमजोर विद्यार्थीलाई ध्यान नदिएर",
		english: "",
		value: "weak_students",
	},
	{
		nepali: "नराम्रो साथीको संगतमा वा साथीको लहैलहैमा लागेर",
		english: "",
		value: "bad_friend",
	},
	{
		nepali: "अन्य",
		english: "",
		value: "other",
	},
];
// close

const educationQuestion = { nepali: "को शिक्षा", english: "Education" };

const education = [
	{
		nepali: "बाल विकास कक्षा",
		english: "Kinder garten",
		value: "kinder_garten",
	},
	{
		nepali: "आधारभूत तह",
		english: "Primary",
		value: "primary",
	},
	{
		nepali: "माध्यमिक तह",
		english: "Secondary",
		value: "secondary",
	},
	{
		nepali: "प्राविधिक एस.एल.सी. (Overseer)",
		english: "T SLC",
		value: "t_slc",
	},
	{
		nepali: "स्नातक तह",
		english: "Bachelors",
		value: "bachelors",
	},
	{
		nepali: "स्नातककोत्तर तह",
		english: "Masters",
		value: "masters",
	},
	{
		nepali: "एमफिल",
		english: "M phil",
		value: "m_phil",
	},
	{
		nepali: "विधा बारिधि",
		english: "PHD",
		value: "phd",
	},
	{
		nepali: "साधारण लेखपढ((साक्षर)",
		english: "literate",
		value: "literate",
	},
	{
		nepali: "निरक्षर (लेखपढ गर्न नसक्ने)",
		english: "Illiterate",
		value: "illiterate",
	},
];

const studyFieldQn = { nepali: "अध्ययनको क्षेत्रकुन हो ?", english: "" };

const studyField = [
	{
		nepali: "व्यवस्थापन",
		english: "",
		value: "management",
	},
	{
		nepali: "विज्ञान तथा प्रविधि (BSC)",
		english: "",
		value: "science_and_tech",
	},
	{
		nepali: "शिक्षा",
		english: "",
		value: "education",
	},
	{
		nepali: "इन्जिनियरिंग",
		english: "",
		value: "engineer",
	},
	{
		nepali: "डाक्टर",
		english: "",
		value: "doctor",
	},
	{
		nepali: "नर्स",
		english: "",
		value: "nurse",
	},
	{
		nepali: "वकिल",
		english: "",
		value: "wokil",
	},
	{
		nepali: "मानविकी",
		english: "",
		value: "manawiki",
	},
	{
		nepali: "कृषि (Bsc. Ag.)",
		english: "",
		value: "agi",
	},
	{
		nepali: "अन्य",
		english: "",
		value: "other",
	},
];

//if पूर्व प्राथमिक is clicked

const preprimaryQuestion = {
	nepali: "पूर्व प्राथमिक कुन कक्षा?",
	english: "Pre primary education",
};

const preprimary = [
	{
		nepali: "१",
		english: "1",
		value: "class_1",
	},
	{
		nepali: "२",
		english: "2",
		value: "class_2",
	},
	{
		nepali: "३",
		english: "3",
		value: "class_3",
	},
	{
		nepali: "४",
		english: "4",
		value: "class_4",
	},
	{
		nepali: "५",
		english: "5",
		value: "class_5",
	},
];

//if आधारभूत तह is clicked

const primaryQuestion = { nepali: "आधारभूत तह", english: "Primary" };

const primary = [
	{
		nepali: "१",
		english: "1",
		value: "class_1",
	},
	{
		nepali: "२",
		english: "2",
		value: "class_2",
	},
	{
		nepali: "३",
		english: "3",
		value: "class_3",
	},
	{
		nepali: "४",
		english: "4",
		value: "class_4",
	},
	{
		nepali: "५",
		english: "5",
		value: "class_5",
	},
	{
		nepali: "६",
		english: "6",
		value: "class_6",
	},
	{
		nepali: "७",
		english: "7",
		value: "class_7",
	},
	{
		nepali: "८",
		english: "8",
		value: "class_8",
	},
];

//if माध्यमिक तह is clicked

const secondaryQuestion = {
	nepali: "माध्यमिक तह कुन कक्षा?",
	english: "Secondary education",
};

const secondary = [
	{
		nepali: "९",
		english: "9",
		value: "class_9",
	},
	{
		nepali: "१०",
		english: "10",
		value: "class_10",
	},
	{
		nepali: "११",
		english: "11",
		value: "class_11",
	},
	{
		nepali: "१२",
		english: "12",
		value: "class_12",
	},
];

//occupation

const occupationQuestion = { nepali: "को पेशा", english: "Ocupation" };

const occupation = [
	{
		nepali: "कृषि तथा पशुपालन(घरेलु)",
		english: "Agriculture",
		value: "agriculture",
	},
	{
		nepali: "कृषि तथा पशुपालन (व्यावसाहिक )",
		english: "Agriculture",
		value: "agriculture_animal_business",
	},
	{
		nepali: "नोकरी जागिर",
		english: "Job",
		value: "job",
	},
	{
		nepali: "उद्योग व्यापार",
		english: "Industry",
		value: "industry",
	},
	{
		nepali: "बेरोजगार",
		english: "Unemployment",
		value: "unemployment",
	},
	{
		nepali: "व्यवसायिक कार्य",
		english: "Bussiness",
		value: "business",
	},
	{
		nepali: "ज्याला मजदुरी",
		english: "Lbor",
		value: "labor",
	},
	{
		nepali: "विद्यार्थी (अध्यनरत)",
		english: "Student",
		value: "student",
	},
	{
		nepali: "गृहणी",
		english: "House wife",
		value: "house_wife",
	},
	{
		nepali: "बैदेशिक रोजगार",
		english: "Foreign job",
		value: "foreign_job",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

//if नोकरी \ जागिर is clicked

const jobQuestion = { nepali: "को नोकरी  जागिर", english: "Job" };

const job = [
	{
		nepali: "प्राइभेट",
		english: "Private",
		value: "private",
	},
	{
		nepali: "सरकारी (निजामती कर्मचारी )",
		english: "Government",
		value: "government",
	},
	{
		nepali: "करार कर्मचारी (सरकारी कार्यालय )",
		english: "karaar",
		value: "karaar",
	},
	{
		nepali: "सुरक्षा निकाय",
		english: "Security depart",
		value: "security",
	},
	{
		nepali: "N.G.O.  I.N.G.O संघ संस्था",
		english: "NGO",
		value: "ngo_ingo",
	},
	{
		nepali: "बैंक तथा वित्तिय संस्था",
		english: "Bank finance",
		value: "bank_finance",
	},
	{
		nepali: "चिकित्सक तथा स्वास्थकर्मी",
		english: "Health and health worker",
		value: "health",
	},
	{
		nepali: "शिक्षक/शिक्षिका",
		english: "Teacher",
		value: "teacher",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

//if सुरक्षा निकाय is clicked

const securityQuestion = {
	nepali: "कुन सुरक्षा निकाय?",
	english: "Which security depart?",
};

const security = [
	{
		nepali: "नेपाल प्रहरी",
		english: "Nepal police",
		value: "nepal_police",
	},
	{
		nepali: "सशस्त्र प्रहरी",
		english: "APF",
		value: "a_p_f",
	},
	{
		nepali: "नेपाली सेना",
		english: "Nepal army",
		value: "nepal_army",
	},
];

//if शिक्षक is clicked

const teacherQuestion = { nepali: "कुन शिक्षक?", english: "Which teacher?" };

const teacher = [
	{
		nepali: "स्थानीय श्रोत",
		english: "Local source",
		value: "local",
	},
	{
		nepali: "सामुदायिक",
		english: "Government",
		value: "government",
	},
	{
		nepali: "राहत",
		english: "Rahat",
		value: "rahat",
	},
];

//if व्यवसायिक कार्य is clicked

const industryQuestion = {
	nepali: "को व्यवसायिक कार्य",
	english: "Business work",
};

const industry = [
	{
		nepali: "पत्रकार",
		english: "Journalist",
		value: "journalist",
	},
	{
		nepali: "वकिल",
		english: "Advocate",
		value: "lawer",
	},
	{
		nepali: "परामर्श",
		english: "Counsulting",
		value: "counselling",
	},
	{
		nepali: "ठेक्का  पट्टा",
		english: "Contract",
		value: "contract",
	},
	{
		nepali: "पुजारी",
		english: "Priest",
		value: "priest",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

//healthcondition

const healthconditionQuesiton = {
	nepali: "को स्वास्थ्य स्थिति",
	english: "Health condition",
};

const healthcondition = [
	{
		nepali: "स्वास्थ्य",
		english: "Health",
		value: "helathy",
	},
	{
		nepali: "सामान्य रोग लागेको",
		english: "Normal diseases patient",
		value: "normal_patient",
	},
	{
		nepali: "दिर्घ रोग लागेको",
		english: "Unhealthy",
		value: "unhelathy",
	},
	// {
	// 	nepali: "मानसिक समस्या भएको",
	// 	english: "Mental",
	// 	value: "mental"
	// },
	// {
	// 	nepali: "क्यान्सर रोग",
	// 	english: "Cancer",
	// 	value: "cancer"
	// },
	// {
	// 	nepali: "कुष्ठ रोग",
	// 	english: "Tuberclosis",
	// 	value: "tuberclosis"
	// },
	// {
	// 	nepali: "HIV AIDS",
	// 	english: "HIV AIDS",
	// 	value: "hiv"
	// }
];

//if दिर्घ रोग लागेको clicked

const unhealthyQuestion = {
	nepali: "दिर्घ रोग लागेको छ भने ? कुन किसिम को ?",
	english: "",
};

const unhealthy = [
	{
		nepali: "उच्च रक्तचाप",
		english: "High blood pressure",
		value: "high_blood_pre",
	},
	{
		nepali: "मुटु रोग",
		english: "Heart diseases",
		value: "heart",
	},
	{
		nepali: "डाइबेटिज/मधुमेह/सुगर",
		english: "Diabetes",
		value: "diabetes",
	},
	{
		nepali: "आस्थमा/दम",
		english: "Aasthama",
		value: "aasthama",
	},
	{
		nepali: "मृगोला सम्बन्धी",
		english: "Liver related",
		value: "liver",
	},
	{
		nepali: "HIV AIDS",
		english: "HIV AIDS",
		value: "hiv",
	},
	{
		nepali: "क्षयरोग (टि .बि. नया तथा पुरानो )",
		english: "",
		value: "tb",
	},
	{
		nepali: "हाडजोर्नी तथा नसा सम्बन्धि रोग (बाथ रोग )",
		english: "",
		value: "bornjoint",
	},
	{
		nepali: "क्षारे रोग",
		english: "",
		value: "epilepsy",
	},
	{
		nepali: "कडा खालको मानसिक रोग",
		english: "",
		value: "manasik",
	},
	{
		nepali: "रगत सम्बन्धि रोग",
		english: "",
		value: "thalassemia",
	},
	{
		nepali: "क्यान्सर",
		english: "",
		value: "cancer",
	},
	{
		nepali: "थाइराईड रोग",
		english: "",
		value: "thairoid",
	},
	{
		nepali: "पाठेघर झर्ने खस्ने रोग",
		english: "",
		value: "paatheghar",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const mentalYesNoQuestion = { nepali: "को अपांगता", english: "Disabled" };

const mentalyesno = [
	{
		nepali: "छ",
		english: "yes",
		value: "disability",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "no_disability",
	},
];

const mentaltypeQuestion = {
	nepali: "अपांगताको किसिम?",
	english: "Types of disablility",
};

//if mental छ is clicked
const mentaltype = [
	{
		nepali: "जन्मजात",
		english: "By birth",
		value: "by_birth",
	},
	{
		nepali: "रोग",
		english: "Diseases",
		value: "disease",
	},
	{
		nepali: "दुर्घटना",
		english: "Accident",
		value: "accident",
	},
	{
		nepali: "जनआन्दोलनमा घाइते भएर",
		english: "janaaandolan",
		value: "janaaandolan",
	},
	{
		nepali: "सस्त्र द्वन्द्वमा घाइते भएर",
		english: "AUTOMATIC",
		value: "AUTOMATIC",
	},
];

const birthCertificateQn = {
	nepali: "पाइले जन्म दर्ता गर्नु भएको छ कि छैन ?",
	english: "",
};

const lessThanSixBirthCertificate = [
	{
		nepali: "छ",
		english: "",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "",
		value: "no",
	},
];

//if mental छ is clicked
const mentalconditionQuestion = {
	nepali: "को अपांगताको स्थिति",
	english: "Condition of disability",
};

const mentalcondition = [
	{
		nepali: "दृष्टि सम्बन्धी",
		english: "Vision",
		value: "vision",
	},
	{
		nepali: "सुनाइ सम्बन्धी ",
		english: "Listening",
		value: "listening",
	},
	{
		nepali: "शारिरिक अपाङ्गता",
		english: "Disability",
		value: "disability",
	},
	{
		nepali: "श्रावण दृष्टिबिहिन अपांगता",
		english: "",
		value: "hear_vision",
	},
	{
		nepali: "बौद्धिक अपाङ्गता",
		english: "Aged Mental",
		value: "aged_mental",
	},
	{
		nepali: "अटिज्म सम्बन्धि",
		english: "",
		value: "atijam",
	},
	{
		nepali: "बोलाइ सम्बन्धी ",
		english: "speak",
		value: "speak",
	},
	{
		nepali: "मानसिक रुपमा अपाङ्गता",
		english: "Mental",
		value: "mental",
	},
	{
		nepali: "बहु अपांगता",
		english: "many_disable",
		value: "many_disable",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
//को अपांगताको कार्ड दिएको छ कि छैन?
const mentalCardQuestion = {
	nepali: "को अपांगताको कार्ड दिएको छ कि छैन?",
	english: "Do you have disability card?",
};

const mentalCardYesNo = [
	{
		nepali: "छ",
		english: "yes",
		value: "card_taken",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "card_not_taken",
	},
];
//if xa vaney KUN CARD XA ?
const mentalCardTypeQuestion = { english: "If yes", nepali: "छ भने" };
const mentalCardtype = [
	{
		nepali: "रातो (पूर्ण आसक्त) क वर्ग",
		english: "Red card",
		value: "red_card",
	},
	{
		nepali: "निलो (अरुको सहयोगबाट) ख वर्ग",
		english: "Blue card",
		value: "blue_card",
	},
	{
		nepali: "पहेलो (कृतिम अंगको ) ग वर्ग",
		english: "Yellow card",
		value: "yellow_card",
	},
	{
		nepali: "सेतो (सामान्य) घ वर्ग्ग",
		english: "White card",
		value: "white_card",
	},
];
//mother tongue

const mothertongueQuestion = {
	nepali: "को मातृभाषा / बोलिने भाषा ?",
	english: "Mother tongue",
};

const mothertongue = [
	{
		nepali: "नेपाली",
		english: "Nepali",
		value: "nepali",
	},
	{
		nepali: "राई",
		english: "Rai",
		value: "rai",
	},
	{
		nepali: "मगर",
		english: "Magar",
		value: "magar",
	},
	{
		nepali: "तामाङ",
		english: "Tamang",
		value: "tamang",
	},
	{
		nepali: "नेपाल भाषा (नेवारी)",
		english: "Newari",
		value: "newari",
	},
	{
		nepali: "गुरुङ",
		english: "Gurung",
		value: "gurung",
	},
	{
		nepali: "लिम्बु",
		english: "Limbu",
		value: "limbu",
	},
	{
		nepali: "शेर्पा",
		english: "Sherpa",
		value: "sherpa",
	},
	{
		nepali: "मैथिली",
		english: "Maithali",
		value: "maithali",
	},
	{
		nepali: "थारु",
		english: "Tharu",
		value: "tharu",
	},
	{
		nepali: "भोजपुरी",
		english: "Bhojpuri",
		value: "bhojpuri",
	},
	{
		nepali: "माझी",
		english: "Majhi",
		value: "maajhi",
	},
	{
		nepali: "भोटे",
		english: "Bhote",
		value: "bhote",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

// if राई is clicked

const raiQuestion = { nepali: "राई भाषा", english: "Rai language" };

const raiType = [
	{
		nepali: "बान्तवा",
		english: "Bantawa",
		value: "bantawa",
	},
	{
		nepali: "खालिंङ",
		english: "Khaling",
		value: "khaling",
	},
	{
		nepali: "थुलुंग",
		english: "Thulung",
		value: "thulung",
	},
	{
		nepali: "कुलुंग",
		english: "Kulung",
		value: "kulung",
	},
	{
		nepali: "याक्खा",
		english: "Yakha",
		value: "yakha",
	},
	{
		nepali: "चामलिंङ",
		english: "Chamling",
		value: "chamling",
	},
	{
		nepali: "वादीङ",
		english: "Wading",
		value: "wading",
	},
	{
		nepali: "तिलुंङ",
		english: "Tilung",
		value: "tilung",
	},
	{
		nepali: "वाम्वुले",
		english: "Bambule",
		value: "bambule",
	},
];

//bloodgroup

const bloodgroupQuestion = { nepali: "को रगत समुह", english: "Blood group" };

const bloodgroup = [
	{
		nepali: "O+",
		english: "O+",
		value: "o_positive",
	},
	{
		nepali: "O-",
		english: "O-",
		value: "o_negative",
	},
	{
		nepali: "A+",
		english: "A+",
		value: "a_positive",
	},
	{
		nepali: "A-",
		english: "A-",
		value: "a_neagtive",
	},
	{
		nepali: "B+",
		english: "B+",
		value: "b_positive",
	},
	{
		nepali: "B-",
		english: "B-",
		value: "b_negative",
	},
	{
		nepali: "AB+",
		english: "AB+",
		value: "ab_positive",
	},
	{
		nepali: "AB-",
		english: "AB-",
		value: "ab_neagtive",
	},
	{
		nepali: "थाहा छैन",
		english: "Donot know",
		value: "no_blood_group",
	},
];

// voter card

const votercardQuestion = {
	nepali: "को मतदाता परिचय पत्र छ /छैन ?",
	english: "Voter card yes / no",
};

const votercard = [
	{
		nepali: "छ",
		english: "yes",
		value: "voter_id_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "voter_id_no",
	},
];
const stepperStep = [
	{
		english: "House Hold",
		nepali: "घरधुरी विवरण",
	},
	{
		english: "Family detail",
		nepali: "परिवार विवरण",
	},
	{
		english: "Death",
		nepali: "मृत्यु",
	},
	{
		english: "Secondary house detail",
		nepali: "माध्यमिक घर विवरण",
	},
	{
		english: "Primary house detail",
		nepali: "प्रथामिक घर विवरण",
	},
	{
		english: "Agriculture",
		nepali: "कृषी तथा पशु",
	},
	{
		english: "Economic",
		nepali: "आर्थिक",
	},
	{
		english: "Child Marriage Related",
		nepali: "बाल बिवाह सम्बन्धि",
	},
	{
		english: "Women and children",
		nepali: "महिला तथा बालबालिका",
	},
];

const detectCurrentLocation = {
	nepali: "हालको स्थान पत्ता लगाउनुहोस्",
	english: "Detect current location",
};

// child Marriage starts
const isChildMarriageInFamilyQn = {
	nepali:
		"तपाइको परिवारमा बिगत ३ बर्ष भित्र छोरीको विवाह भएको छ? (२५ बर्ष भन्दा तलको उल्लेख गर्ने )",
	english: "",
};
const isChildMarriageInFamily = [
	{
		nepali: "छ",
		english: "",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "",
		value: "no",
	},
];

const childMarriageCount = {
	nepali: "कति जनाको विवाह भएको छ ?",
	english: "",
};

const childMartialStatusQn = {
	nepali: "बैबाहिक अवस्था कस्तो छ ?",
	english: "",
};

const childMartialStatus = [
	{
		nepali: "विवाहित",
		english: "",
		value: "Marriage",
	},
	{
		nepali: "छुट्टिएको",
		english: "",
		value: "seperate",
	},
	{
		nepali: "विधुर /विधुवा",
		english: "",
		value: "widow",
	},
	{
		nepali: "एकल",
		english: "",
		value: "single",
	},
	{
		nepali: "पारपाचुके भएको",
		english: "",
		value: "devorce",
	},
];

const childMarriageAge = {
	nepali: "बिवाहा हुदाको उमेर",
	english: "",
};

const childMarriageTypeQn = {
	nepali: "बिवाहाको प्रकार",
	english: "",
};

const childMarriageType = [
	{
		nepali: "मागी बिवाह",
		english: "",
		value: "arrange",
	},
	{
		nepali: "भागि बिवाह",
		english: "",
		value: "elloped",
	},
	{
		nepali: "प्रेम बिवाह",
		english: "",
		value: "love_marriage",
	},
	{
		nepali: "अन्य",
		english: "",
		value: "other",
	},
];

const childMarriageEducationQn = {
	nepali: "शैक्षिकस्तर",
	english: "",
};

const childMarriageEducation = [
	{
		nepali: "साक्षर",
		english: "",
		value: "litrate",
	},
	{
		nepali: "निरक्षर",
		english: "",
		value: "uneducate",
	},
	{
		nepali: "वाल विकास",
		english: "",
		value: "primary_education",
	},
	{
		nepali: "आधारभूत",
		english: "",
		value: "basic",
	},
	{
		nepali: "माध्यमिक",
		english: "",
		value: "secondary",
	},
	{
		nepali: "स्नातक",
		english: "",
		value: "graduate",
	},
	{
		nepali: "स्नातक माथी",
		english: "",
		value: "above_graduate",
	},
];

const childMarriageOccupationQn = {
	nepali: "पेशा",
	english: "",
};

const childMarriageOccupation = [
	{
		nepali: "कृषि तथा पशुपालन",
		english: "",
		value: "agriculture",
	},
	{
		nepali: "गृहणी",
		english: "",
		value: "housewife",
	},
	{
		nepali: "ब्यापार व्यावसाय",
		english: "",
		value: "business",
	},
	{
		nepali: "नकारी जागिर",
		english: "",
		value: "employee",
	},
	{
		nepali: "बेरोजगार",
		english: "",
		value: "unemployee",
	},
	{
		nepali: "अन्य",
		english: "",
		value: "other",
	},
];

const childMarriageKidQn = {
	nepali: "के उंहाको बच्चा छ ?",
	english: "",
};

const childMarriageKid = [
	{
		nepali: "छ",
		english: "",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "",
		value: "no",
	},
];

// ends

// Economics

const mainIncomeSourceQuestion = {
	nepali: "तपाइको परिवारको सरदर वार्षिक आम्दानीको मुख्य स्रोत के हो ?",
	english: "What is the income source of your family?",
};

const mainIncomeSource = [
	{
		nepali: "कृषि कार्य",
		english: "Agriculture activities",
		value: "agriculture_ac",
	},
	{
		nepali: "उधोग",
		english: "Industry",
		value: "industry",
	},
	{
		nepali: "व्यापार व्यवसाय",
		english: "Business",
		value: "bussiness",
	},
	{
		nepali: "नोकरीजागिर",
		english: "Job",
		value: "job",
	},
	{
		nepali: "पेन्सन",
		english: "Pension",
		value: "pension",
	},
	{
		nepali: "घर तथा जग्गा को भाडा",
		english: "Rent land and house",
		value: "rent_of_landor",
	},
	{
		nepali: "सवारी साधनको भाडा",
		english: "Rent of vehicle",
		value: "rent_of_vehicl",
	},
	{
		nepali: "मेसिन तथा उपकरणको भाडा",
		english: "Rent of machine",
		value: "rent_of_machin",
	},
	{
		nepali: "व्याज",
		english: "Interest",
		value: "interest",
	},
	{
		nepali: "विप्रेषण (वैदेशिक रोजगार)",
		english: "Remitance",
		value: "remittances_fo",
	},
	{
		nepali: "विप्रेषण (आन्तरिक)",
		english: "Remittance",
		value: "remittances_in",
	},
	{
		nepali: "दैनिक ज्याला / मजदुरी",
		english: "Daily labor",
		value: "dialy_labour",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const totalIncome = {
	nepali: "तपाई को परिवारको सरदर वार्षिक आम्दानी कति छ?",
	english: "How much is the yearly income of your family? ",
	value: "",
};

const totalExpenditure = {
	nepali: "तपाई को परिवारको सरदर वार्षिक खर्च कति छ?",
	english: "How much is the yearly expenditure?",
	value: "",
};

const foodForMonthQuestion = {
	nepali: "आफ्नो उत्पादनले तपाई को परिवारलाई कति महिना खान पुग्छ ?",
	english: "How ",
};

const foodForMonth = [
	{
		nepali: "३ महिना",
		english: "3 month",
		value: "3_month",
	},
	{
		nepali: "४ देखि ६ महिना",
		english: "4-6 month",
		value: "4_6_month",
	},
	{
		nepali: "७ देखि ९ महिना सम्म",
		english: "7-9 month",
		value: "7_9_month",
	},
	{
		nepali: "९ महिना भन्दा बढी",
		english: "9 month above",
		value: "9_month_above",
	},
	{
		nepali: "१२ महिना मजदुरी गरेर खाने",
		english: "12 months",
		value: "12_months",
	},
];

//if ३ महिना && ४ देखि ६ महिना && ७ देखि ९ महिना सम्म is clicked

const foodAlternateQuestion = {
	nepali:
		"नपुग आवादीको लागि परिवार धान्न कसरी व्वस्था म्लाव्नु भयो? (विगत १ वर्षमा)",
	english: "",
};

const foodAlternate = [
	{
		nepali: "संचित घनको प्रयोग गरेको",
		english: "Saving amount",
		value: "saving_amount",
	},
	{
		nepali: "पैचो सापटी लिएको",
		english: "Borrow amount",
		value: "borrow_amount",
	},
	{
		nepali: "ऋण लिएको",
		english: "Loan amount",
		value: "loan_amount",
	},
	{
		nepali: "चल-अचल सम्पति विक्री गरेको",
		english: "Movable imovable property",
		value: "im_imovable_pr",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const loanTakenInFamilyQuestion = {
	nepali: "तपाईको परिवारले ऋण लिनु भएको छ?",
	english: "Do you have taken loan?",
};

const loanTakenInFamily = [
	{
		nepali: "छ",
		english: "yes",
		value: "loan_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "loan_no",
	},
];

const loanQuestion = {
	nepali: "ऋण लुनु भएको छ भने",
	english: "If you have taken loan",
};

// if loantakeninfamily छ is clicked
const loan = [
	{
		nepali: "बैंक बाट",
		english: "Loan from bank",
		value: "loan_bank",
	},
	{
		nepali: "व्यक्ति बाट",
		english: "Loan from person",
		value: "loan_person",
	},
	{
		nepali: "सहकारी बाट",
		english: "Loan from finance",
		value: "loan_sahakari",
	},
	{
		nepali: "लघुबित्त बाट",
		english: "Loan from lagubitta",
		value: "loan_laghubitt",
	},
	{
		nepali: "कृषक समुह",
		english: "Loan from farmer group",
		value: "loan_farmer",
	},
	{
		nepali: "महिला समुह",
		english: "Loan from women group",
		value: "loan_women",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

//if बैंक बाट is checked
const rate = [
	{
		nepali: "बैंकको व्याज दर कति प्रतिशत (%) छ ?",
		english: "What is the interest rate of bank?",
		value: "",
	},
	{
		nepali: "व्यक्तिको व्याज दर कति प्रतिशत (%) छ ?",
		english: "What is the interest rate of loan from person?",
		value: "",
	},
	{
		nepali: "सहकारीको व्याज दर कति प्रतिशत (%) छ ?",
		english: "What is the interest rate of loan from sahakari?",
		value: "",
	},
	{
		nepali: "लघुबित्तको व्याज दर कति प्रतिशत (%) छ ?",
		english: "What is the interest rate of loan from laghubitta",
		value: "",
	},
	{
		nepali: "कृषकको व्याज दर कति प्रतिशत (%) छ ?",
		english: "What is the interest rate of loan from farmer group?",
		value: "",
	},
	{
		nepali: "महिला समुहको व्याज दर कति प्रतिशत (%) छ ?",
		english: "What is the interest rate of loan from women group?",
		value: "",
	},
	{
		nepali: "अन्य व्याज दर कति प्रतिशत (%) छ ?",
		english: "What is the interest rate of loan from other?",
		value: "",
	},
];
const bankrate = {
	nepali: "बैंकको व्याज दर कति प्रतिशत (%) छ ?",
	english: "What is the interest rate of bank?",
	value: "",
};

//if व्यक्ति बाट is checked
const personrate = {
	nepali: "व्यक्तिको व्याज दर कति प्रतिशत (%) छ ?",
	english: "What is the interest rate of loan from person?",
	value: "",
};

//if सहकारी बाट is checked
const sahakarirate = {
	nepali: "सहकारीको व्याज दर कति प्रतिशत (%) छ ?",
	english: "What is the interest rate of loan from sahakari?",
	value: "",
};

//if लघुबित्त बाट is checked
const laghubittrate = {
	nepali: "लघुबित्तको व्याज दर कति प्रतिशत (%) छ ?",
	english: "What is the interest rate of loan from lagubitta?",
	value: "",
};

// if कृषक समुह is checked
const farmerrate = {
	nepali: "कृषकको व्याज दर कति प्रतिशत (%) छ ?",
	english: "What is the interest rate for farmer?",
	value: "",
};

//if महिला समुह is checked
const femalerate = {
	nepali: "महिला समुहको व्याज दर कति प्रतिशत (%) छ ?",
	english: "What is the interest of women group?",
	value: "",
};

//if अन्य is checeked
const otherrate = {
	nepali: "अन्य व्याज दर कति प्रतिशत (%) छ ?",
	english: "What is the interest rate of other?",
	value: "",
};

const loanTakingReasonQuestion = {
	nepali: "ऋणको उदेश्यके हो ?",
	english: "What is the reason for taking loan?",
	value: "",
};

const loanTakingReason = [
	{
		nepali: "व्यापार",
		english: "Business",
		value: "loan_business",
	},
	{
		nepali: "छोरा /छोरी पढाउन",
		english: "Education",
		value: "loan_education",
	},
	{
		nepali: "स्वस्थ उपचार गर्न",
		english: "Health",
		value: "loan_health",
	},
	{
		nepali: "कृषि तथा पशुपालन गर्न",
		english: "Agriculture",
		value: "loan_agricultu",
	},
	{
		nepali: "घरायसी उपयोग गर्ने",
		english: "For house hold purpose",
		value: "loan_house",
	},
	{
		nepali: "विबहा /ब्रतबन्ध कालागि",
		english: "Birth / death / ceremony",
		value: "loan_rituals",
	},
	{
		nepali: "चार्ड पर्व मनाउन",
		english: "For celebration of festival",
		value: "loan_festival",
	},
	{
		nepali: "घर निर्माण का लागि",
		english: "For building house",
		value: "loan_building",
	},
	{
		nepali: "जग्गा जमिन किन्न का लागि",
		english: "To buy land",
		value: "loan_land",
	},
	{
		nepali: "विदेश जानको लागि",
		english: "For ABroad Study",
		value: "outside_countr",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const dhukutiKarobar = {
	nepali: "तपाइको परिवारको ढुकुटी संग कारोवार छ कि छैन ?",
	english: "",
};

const dhukutiKarobarYesNo = [
	{
		nepali: "छ",
		english: "Yes",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "No",
		value: "no",
	},
];

const dhukutiKarobarCount = {
	nepali: "यदि छ भने कति ?",
	english: "",
};

const cashLoanGivenQuestion = {
	nepali: "तपाईको परिवारबाट वा तपाई बाट कसैलाइ नगद ऋण दिनु भएको छ?",
	english: "Do anyone in your family give loan to other?",
};

const cashLoanGiven = [
	{
		nepali: "छ",
		english: "yes",
		value: "cash_loan_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "cash_loan_no",
	},
];

const cashLoanRate = {
	nepali: "छ भने कति प्रतिशत (%) व्याज दरमा दिनु भएको छ ?",
	english: "If yes mention the interest rate?",
	value: "",
};

//house detail discrimination
const discriminationQuestion = {
	nepali:
		"तपाईको बिचारमा यस स्थानमा जनजाती लिंग वा समुदाय विशेष भएको आधारमा भेदभावको अनुभव गर्नु भएको छ?",
	english:
		"In your view is there any discrimination on the base of gender,caste in your society?",
};

const discrimination = [
	{
		nepali: "छ",
		english: "Yes",
		value: "inequality_yes",
	},
	{
		nepali: "छैन",
		english: "No",
		value: "inequality_no",
	},
	{
		nepali: "थाहा छैन",
		english: "Donot know",
		value: "not_know_inequ",
	},
];

//skillls

const skillTrainingQuestion = {
	nepali: "तपाईले शिपमुलक तालिम वा कुनै शिप जान्नु हुन्छ ?",
	english: "Do you have any skill?",
};

const skillTraining = [
	{
		nepali: "छ",
		english: "Yes",
		value: "skill_yes",
	},
	{
		nepali: "छैन",
		english: "No",
		value: "skill_no",
	},
];

//if skilltraining छ is clicked

const skillDetailQuestion = {
	nepali: "शिपमुलक तालिम छ भने",
	english: "If yes",
};

const skillDetail = [
	{
		nepali: "कृषि प्राविधिक तालिम तथा अध्ययन",
		english: "Agriculture",
		value: "agri",
	},
	{
		nepali: "डकर्मी / सिकर्मी",
		english: "",
		value: "mason/_carpent",
	},
	{
		nepali: "प्लम्बिंग /कारपेन्टर",
		english: "",
		value: "plumbing",
	},
	{
		nepali: "शिलाई, बुनाई , बुटिक ,सृंगार ,पार्लर आदि",
		english: "knitting",
		value: "parlor",
	},
	{
		nepali: "पत्रकारिता/ रेडियो / टि. भी संबन्धि तालिम",
		english: "Journalism",
		value: "jaournalist",
	},
	{
		nepali: "पर्यटन , टुर गाइड, ट्राभेल र सत्कार सम्बन्धि",
		english: "Tourism",
		value: "tourism_guide",
	},
	{
		nepali: "इलेक्ट्रिकल मर्मत तालिम",
		english: "",
		value: "vechile_mainta",
	},
	{
		nepali: "ईलेक्त्रोनिक्स मर्मत तालिम",
		english: "Electronics",
		value: "electronic",
	},
	{
		nepali: "इलेक्ट्रिकल ( बिजुली बत्ति मर्मत ) तालिम",
		english: "",
		value: "electric",
	},
	{
		nepali: "होटल कुक , वेटर तालिम",
		english: "",
		value: "hotel_cook_wai",
	},
	{
		nepali: "गायन/ नृत्य ,लेखनको क्षमता तथा तालिम",
		english: "",
		value: "arts",
	},
	{
		nepali: "वोकिल",
		english: "",
		value: "lawer",
	},
	{
		nepali: "सवारी साधन चलाउने",
		english: "",
		value: "draiving",
	},
	{
		nepali: "जनस्वास्थ्य सम्बन्धि",
		english: "Health",
		value: "health",
	},
	{
		nepali: "पशु स्वाथ्य सम्बन्धि",
		english: "Animal Health Related",
		value: "animal_health",
	},
	{
		nepali: "बन सम्बन्धि",
		english: "forest",
		value: "forest",
	},
	{
		nepali: "मुडा बनाउने",
		english: "Muda",
		value: "mudaa",
	},
	{
		nepali: "विपदव्यस्थापन सम्बन्धि",
		english: "Disaster Management",
		value: "disastar_management",
	},
	{
		nepali: "अन्य",
		english: "other",
		value: "other",
	},
];

//if निर्माण सम्बन्धी(मिस्त्री/कार्पेन्टर ) is checked
const constructionMale = {
	nepali: "निर्माण सम्बन्धी (मिस्त्री/कार्पेन्टर ) पुरुष संख्या",
	english: "No of male in construction",
	value: "",
};

const constructionFemale = {
	nepali: "निर्माण सम्बन्धी(मिस्त्री/कार्पेन्टर ) महिला संख्या",
	english: "No of female in construction ",
	value: "",
};

// if कृषि सम्बन्धी(जे.टि, जे.टि.ए र खद प्रसोधन ) is checked
const agricultureMale = {
	nepali: "कृषि सम्बन्धी(जे.टि, जे.टि.ए र खद प्रसोधन ) पुरुष संख्या",
	english: "No of male in agriculture",
	value: "",
};

const agricultureFemale = {
	nepali: "कृषि सम्बन्धी(जे.टि, जे.टि.ए र खद प्रसोधन ) महिला संख्या",
	english: "No of female in Agriculture",
	value: "",
};

//if बास तथा छाला बाट निर्मित हस्त सामग्री is chekced
const handmadeMale = {
	nepali: "बास तथा छाला बाट निर्मित हस्त सामग्री सम्बन्धीपुरुष संख्या",
	english: "No of male in handicraft work",
	value: "",
};

const handmadeFemale = {
	nepali: "बास तथा छाला बाट निर्मित हस्त सामग्री सम्बन्धीमहिला संख्या",
	english: "No of female in handicraft work",
	value: "",
};

// if सूचना प्रविधि ,इलेक्ट्रोनिकस,इलेक्त्रोनिकल(कम्पुटर /मोबाइल/रेडियो/घडी मर्मत) is checked
const electricMale = {
	nepali:
		"सूचना प्रविधि ,इलेक्ट्रोनिकस,इलेक्त्रोनिकल(कम्पुटर /मोबाइल/रेडियो/घडी मर्मत) पुरुष संख्या",
	english: "No of male in Electronic works",
	value: "",
};

const electricFemale = {
	nepali:
		"सूचना प्रविधि ,इलेक्ट्रोनिकस,इलेक्त्रोनिकल(कम्पुटर /मोबाइल/रेडियो/घडी मर्मत) महिला संख्या",
	english: "No of female in Electronic works",
	value: "",
};

// if सिलाई ,बुनाइ ,बुटिक ,पर्लोर, सृंगार is checked
const fashionMale = {
	nepali: "सिलाई ,बुनाइ ,बुटिक ,पर्लोर, सृंगार पुरुष संख्या",
	english: "No of male in sewing , beautiparlour and botique",
	value: "",
};

const fashionFemale = {
	nepali: "सिलाई ,बुनाइ ,बुटिक ,पर्लोर, सृंगार महिला संख्या",
	english: "No of female in sewing , beautiparlour and botique",
	value: "",
};

//if जनस्वास्थ सम्बन्धीis checked
const healthMale = {
	nepali: "जनस्वास्थ सम्बन्धीपुरुष संख्या",
	english: "",
	value: "",
};

const healthFemale = {
	nepali: "जनस्वास्थ सम्बन्धी महिला संख्या",
	english: "No of female in public health ",
	value: "",
};

// if पशु स्वास्थ्य  सम्बन्धीis checked
const animalhealthMale = {
	nepali: "पशु स्वास्थ्य  सम्बन्धीपुरुष संख्या",
	english: "No of male in animal health",
	value: "",
};

const animalhealthFemale = {
	nepali: "पशु स्वास्थ्य  सम्बन्धीमहिला संख्या",
	english: "No of female in animal health",
	value: "",
};

//if पर्यटन , टुर गाइड, ट्राभेल र सत्कार सम्बन्धीis checked
const tourismMale = {
	nepali: "पर्यटन , टुर गाइड, ट्राभेल र सत्कार सम्बन्धीपुरुष संख्या",
	english: "No of male in tourism, tour guide work",
	value: "",
};

const tourismFemale = {
	nepali: "पर्यटन , टुर गाइड, ट्राभेल र सत्कार सम्बन्धीमहिला संख्या",
	english: "No of female in tourism, tour guide work",
	value: "",
};

// if कला सम्बन्धीis checked
const skillMale = {
	nepali: "कला सम्बन्धीपुरुष संख्या",
	english: "No of male in art work",
	value: "",
};

const skillFemale = {
	nepali: "कला सम्बन्धीमहिला संख्या",
	english: "No of female in art work",
	value: "",
};

const familyInLocalOrganQuestion = {
	nepali:
		"परिवारको सदस्यहरु स्थानीय तह वा कुनै संघ संस्थामा संग्लग्न भएका छन् ?",
	english:
		"Do any member of your family are engaged in local level organization?",
};

const familyInvolvementLocalOrgan = [
	{
		nepali: "छ",
		english: "yes",
		value: "local_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "local_no",
	},
];

//if familyInvolvementLocalOrgan छ is clicked

const familyLocalGovInvolveQuestion = {
	nepali:
		"संग्लग्न हुनुन्छ भने के कस्ता संघ संस्था वा स्थानीय तह तथा राजनैतिक क्रियाकालाप मा हुनुन्छ?",
	english: "If yes then which department?",
};

const familyLocalGovInvolve = [
	{
		nepali: "स्थानीय तह",
		english: "Local government",
		value: "local_gov",
	},
	{
		nepali: "स्थानीय विद्यालय व्यस्थापन समिति",
		english: "Local school management group",
		value: "local_school",
	},
	{
		nepali: "कृषक समूह",
		english: "Agriculture group ",
		value: "agri_group",
	},
	{
		nepali: "वन उपभोक्ता समुह",
		english: "Jungle consumer group",
		value: "forest",
	},
	{
		nepali: "सहकारी संस्था",
		english: "Sahakari",
		value: "sahakari",
	},
	{
		nepali: "आमा समूह",
		english: "Mother group",
		value: "mother_grp",
	},
	{
		nepali: "महिला समूह",
		english: "women group",
		value: "women_grp",
	},
	{
		nepali: "खानेपानी उपभोक्ता समुह",
		english: "Drinking water group",
		value: "khanepani",
	},
	{
		nepali: "सिंचाई /जल उपभोक्ता समुह",
		english: "Irrigation",
		value: "irrigation",
	},
	{
		nepali: "स्थानीय बाल समुह (बाल क्लब)",
		english: "Local child group",
		value: "child_club",
	},
	{
		nepali: "युवा समूह",
		english: "Youth club",
		value: "youth_club",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

// house detail rent detail
const buildingQuestion = {
	nepali: "तपाइको कति वटा घर/मतान छ ?",
	english: "How many house do you have?",
};

const building = [
	{
		nepali: "घर",
		english: "House",
		value: "ghar",
	},
	{
		nepali: "मतान",
		english: "Matan",
		value: "matan",
	},
];

//if मतान is checked

const matanCount = {
	nepali: "तपाइको कति वटा मतान छ ?",
	english: "How many matan do you have?",
	value: "",
};

// if घर is checked
const houseCount = { nepali: "तपाइको कति वटा घर ?", english: "", value: "" };

const familyAnotherHouseQuestion = {
	nepali:
		"तपाइको परिवारका सदस्यको नाममा यस गाउँपालिका भित्र वा अन्य स्थानमा घर छ?",
	english: "Do you have land in this reral municipality?",
};

const familyMemberAnotherHouse = [
	{
		nepali: "छ",
		english: "yes",
		value: "house_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "house_no",
	},
];

//if familyMemberAnotherHouse ko छ is clicked
const familyMemberAnotherHouseLocation = {
	nepali: "छ भने कहाँ",
	english: "If yes then where?",
	value: "",
};

const totalRoom = {
	nepali: "तपाइको घरमा कति वटा कोठा छ?",
	english: "How many room do you have?",
	value: "",
};

const totalStorey = {
	nepali: "तपाइको घर कति तल्लाको छ?",
	english: "How many floor do you have ?",
	value: "",
};

const houseAge = {
	nepali: "तपाइँले प्रयोग गरेको घर कहिले बनेको हो ? (वर्ष उल्लेख गर्नुहोस् )",
	english: "What is the age of the house you are living now?",
	value: "",
};

const landUsedLivingQuestion = {
	nepali: " परिवारले प्रयोग गरेको घर रहेको जग्गाको स्वामित्व कस्तो हो ?",
	english: "Who is the owner of the house you are living now?",
};

const landUsedLiving = [
	{
		nepali: "निजि/नम्बरी",
		english: "Self",
		value: "self",
	},
	{
		nepali: "गुठि",
		english: "Guthi",
		value: "guthi",
	},
	{
		nepali: "सार्वजनिक",
		english: "Public",
		value: "public_land",
	},
	{
		nepali: "ऐलानी",
		english: "Yelani",
		value: "yelani",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const houseTypeQuestion = {
	nepali: "तपाइको घरको प्रकार कस्तो हो?",
	english: "Types of house",
};

const houseType = [
	{
		nepali: "आरसीसी",
		english: "rcc",
		value: "aarcc",
	},
	{
		nepali: "ढुंगाको घर (सिमेन्टको जोडाइ)",
		english: "Stone house",
		value: "stone_house",
	},
	{
		nepali: "पक्कि इँटाको घर सिमेन्टको जोडाइ",
		english: "Break house",
		value: "break_house",
	},
	{
		nepali: "ढुंगाको घर (माटोको जोड़ाई)",
		english: "Stone house",
		value: "stone_mud_house",
	},
	{
		nepali: "जस्ताले बेरेको",
		english: "tin",
		value: "tin",
	},
	{
		nepali: "बास /टाटि घर",
		english: "Bamboo house",
		value: "bamboo_house",
	},
	{
		nepali: "सेन्टर बेरा",
		english: "senta house",
		value: "sentar_beraa",
	},
	{
		nepali: "काठको खम्बा (काठको घर )",
		english: "Wooden",
		value: "wooden",
	},
	{
		nepali: "ब्लक र सिमेन्टको जोडाइ",
		english: "Block and Cement",
		value: "block_and_cement",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const roofTypeQuestion = {
	nepali: "तपाइको घरको छानाको बनौट कस्तो छ?",
	english: "Types of roof",
};

const roofType = [
	{
		nepali: "जस्ता पाता वा च्यादर छाना भएको",
		english: "Jasta pata",
		value: "steel",
	},
	{
		nepali: "फुस वा खरको",
		english: "Fush / khar",
		value: "roof_grass",
	},
	{
		nepali: "टायल/खपडा/ढुंगा",
		english: "Tiles",
		value: "tiles",
	},
	{
		nepali: "सिमेन्ट/ ढलान",
		english: "RCC Cement",
		value: "rcc",
	},
	{
		nepali: "काठ/ फल्याक",
		english: "Wooden flake",
		value: "wooden",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const hasLandDocumentQuestion = {
	nepali: "घर निर्माण प्रमाण पत्र लिनु भएको छ ?",
	english: "Do you have hoouse paper of your house?",
};

const hasLandDocument = [
	{
		nepali: "छ",
		english: "yes",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "no",
	},
];

const doHouseCriteriaFullfillQuestion = {
	nepali: "तपाइको घर भवन मापदण्ड अनुसार बनेको छ?",
	english: "Do your house made under the criteria?",
};

const doHouseCriteriaFullfill = [
	{
		nepali: "छ",
		english: "Yes",
		value: "criteria_yes",
	},
	{
		nepali: "छैन",
		english: "No",
		value: "criteria_no",
	},
	{
		nepali: "थाहा छैन",
		english: "Dont know",
		value: "donot_know_cri",
	},
];

const isHouseEarthquakeResistanceQuestion = {
	nepali: "तपाइको घर परिवारको जोखिम पारिवारिक योजना बनेको छ ?",
	english: "Do your house earthquake resistance?",
};

const isHouseEarthquakeResistance = [
	{
		nepali: "छ",
		english: "Yes",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "No",
		value: "no",
	},
];

const hasHouseMapPassQuestion = {
	nepali: "तपाइको घरको नक्शा पास भएको छ कि छैन?",
	english: "Do your house map passed or not?",
};

const hasHouseMapPass = [
	{
		nepali: "छ",
		english: "Yes",
		value: "map_yes",
	},
	{
		nepali: "छैन",
		english: "No",
		value: "map_know",
	},
];

const isHouseOnRentQuestion = {
	nepali: "तपाइले घर भाडामा दिनु भएको छ कि छैन?",
	english: "Do u give your house in rent?",
};

const isHouseOnRent = [
	{
		nepali: "छ",
		english: "Yes",
		value: "rent",
	},
	{
		nepali: "छैन",
		english: "No",
		value: "no_rent",
	},
];

//if isHouseOnRent छ is cliked

const rentGivenToQuestion = {
	nepali: "यदि छ भने कुन प्रयोजनको लागि भाडामा दिनुभएको छ ?",
	english: "If yes then for ehat purpose?",
};

const rentGivenTo = [
	{
		nepali: "अन्य परिवारलाई",
		english: "Other family",
		value: "other_family",
	},
	{
		nepali: "होटल /रेस्टुरेन्ट /पसल",
		english: "Hotel",
		value: "hotel_restaura_1",
	},
	{
		nepali: "गैर सरकारी संघ संस्थालाई",
		english: " For Non government organization",
		value: "semi_governmen",
	},
	{
		nepali: "बिधालय संचालनको लागि",
		english: "To open school",
		value: "school",
	},
	{
		nepali: "उधोगलाई",
		english: "For industry",
		value: "industry",
	},
	{
		nepali: "स्वास्थ्य केन्द्रलाई",
		english: "For health post",
		value: "helath_post",
	},
	{
		nepali: "बैंक तथा वित्तीय संस्थालाई",
		english: "For bank and finance",
		value: "bank",
	},
	{
		nepali: "सरकारी कार्यालयलाई",
		english: "Government office",
		value: "government",
	},
	{
		nepali: "सेवामुलक संस्थालाई",
		english: "For Service oriented organization",
		value: "service_organi",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

const rentTypeQuestion = {
	nepali: "प्रयोजनको लागि भाडामा दिएको छ भने:",
	english: "For what purpose given room in rent",
};

// radio button not checkbox
const rentType = [
	{
		nepali: "तल्ला",
		english: "Storey",
		value: "storey",
	},
	{
		nepali: "कोठा",
		english: "Room",
		value: "room",
	},
	{
		nepali: "दुबै",
		english: "Both",
		value: "both",
	},
];

//if तल्ला is checked
const storey = {
	nepali: "कति तल्ला ?",
	english: "How many storey?",
	value: "",
};

//if कोठा is checked
const room = { nepali: "कति कोठा ?", english: "How many room?", value: "" };

//house Facility
const houseFacilityQuestion = {
	nepali: "तपाइको घरमा के के सुविधाहरु छन्?",
	english: "What are the facilities do you have in your room?",
};

const houseFacility = [
	{
		nepali: "टि.भी",
		english: "T.V",
		value: "tv",
	},
	{
		nepali: "रेडियो",
		english: "Radio",
		value: "radio",
	},
	{
		nepali: "टेलिफोन",
		english: "Telephone",
		value: "telephone",
	},
	{
		nepali: "मोबाइल",
		english: "Mobile",
		value: "mobile",
	},
	{
		nepali: "ल्यापटप /कम्पुटर डेस्कटप",
		english: "Laptop",
		value: "laptop",
	},
	{
		nepali: "इन्टरनेट",
		english: "Internet",
		value: "internet",
	},
	{
		nepali: "मोटरसाइकल/ स्कुटर",
		english: "Motercycle",
		value: "motorcycle",
	},
	{
		nepali: "कार",
		english: "Car",
		value: "car",
	},
	{
		nepali: "जिप /अटो",
		english: "Jeep",
		value: "jeep",
	},
	{
		nepali: "ट्राकटर",
		english: "Tractor",
		value: "tractor",
	},
	{
		nepali: "ट्रक",
		english: "Truck",
		value: "truck",
	},
	{
		nepali: "टेम्पो",
		english: "Tempo",
		value: "tempo",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

// if motorcycle is cliked
const motorcycle = {
	nepali: "मोटरसाइकल (संख्या)",
	english: "No of motercycle",
	value: "",
};
const scooter = {
	nepali: "स्कुटर (संख्या)",
	english: "No of Scooter",
	value: "",
};
const car = { nepali: "कार (संख्या)", english: "No of car", value: "" };
const jeep = { nepali: "जिप (संख्या)", english: "Jeep(no)", value: "" };
const tractor = {
	nepali: "ट्राकटर (संख्या)",
	english: "Tractor(no)",
	value: "",
};
const truck = { nepali: "ट्रक (संख्या)", english: "Truck(no)", value: "" };
const auto = { nepali: "अटो (संख्या)", english: "Auto(no)", value: "" };
const tempo = { nepali: "टेम्पो (संख्या)", english: "Tempo(no)", value: "" };
const dozar = { nepali: "डोज़र (संख्या)", english: "Dojar(no)", value: "" };
const tipper = { nepali: "टिप्पर (संख्या)", english: "Tipper(no)", value: "" };
const scavater = {
	nepali: "स्काभेटर (संख्या)",
	english: "Skavator(no)",
	value: "",
};
const other = {
	nepali: "अन्य भए उल्लेख गर्नु हाेस ।",
	english: "IF any then mention",
	value: "",
};
const otherCount = { nepali: "अन्य (संख्या)", english: "Other(no)", value: "" };

const handWash = {
	nepali: "तपाईको परिवारमा साबुनपानीले हात धुने गर्नु हुन्छ कि हुन्न ?",
	english: "",
};
const handWashOption = [
	{
		nepali: "धुन्छौ",
		english: "Yes",
		value: "yes",
	},
	{
		nepali: "धुन्नौ",
		english: "No",
		value: "no",
	},
];
const alcoholTitle = { nepali: "मदिरा सेवन", english: "Alcohol Usages" };
const alcoholUsage = {
	nepali: "तपाईको परिवारमा कोही सदस्य मदिरा सेवन गर्ने हुनु हुन्छ ?",
	english: "",
};
const alcoholUsageOption = [
	{
		nepali: "छन्",
		english: "Yes",
		value: "alcohol_yes",
	},
	{
		nepali: "छैनन्",
		english: "No",
		value: "alcohol_no",
	},
];

// if alcohol usage in family yes then
const alcoholUsageCount = {
	nepali: "छन् भने कति जना छन् ?",
	english: "If yes how many ?",
};
const alcoholCount = { nepali: "कति जना छन् ?", english: "If yes how many ?" };

const alcoholUsageCard = {
	nepali: "मदिरा सेवन कार्डको प्रयोग गर्ने गरेको छ ?",
	english: "",
};

const alcoholUsageCardOption = [
	{
		nepali: "छ",
		english: "Yes",
		value: "alcohol_card_y",
	},
	{
		nepali: "छैन",
		english: "No",
		value: "alcohol_card_n",
	},
];

// if alcoholUsageCard yes then
const effectiveFromCard = {
	nepali: "मदिरा सेवन कार्डको प्रयोगले तपाईको परिवारमा कस्तो प्रभाव पारेको छ ?",
	english: "",
};
const effectiveFromCardOption = [
	{
		english: "",
		nepali: "राम्रो प्रभाव पारेको छ",
		value: "best",
	},
	{
		english: "",
		nepali: "ठिकै प्रभाव पारेको छ",
		value: "good",
	},
	{
		english: "",
		nepali: "खासै राम्रो प्रभाव पारेको छैन",
		value: "bad",
	},
];

const alcoholUsageExpense = {
	nepali: "मदिरा सेवनमा मासिक अनुमानित कति खर्च हुन्छ ?",
	english: "",
};

const problemFromAlcohol = {
	nepali: "मदिरा सेवनले तपाईको परिवारमा कस्तो समस्या पारेको छ ?",
	english: "",
};

const problemFromAlcoholOption = [
	{
		nepali: "पारिवारिक कलह",
		english: "",
		value: "family_conflit",
	},
	{
		nepali: "सामाजिक असर",
		english: "",
		value: "social_effect",
	},
	{
		nepali: "स्वास्थ्यमा प्रभाव",
		english: "",
		value: "health_problem",
	},
	{
		nepali: "आर्थिक प्रभाव",
		english: "",
		value: "economic_issue",
	},
	{
		nepali: "हत्या हिंशा",
		english: "",
		value: "crime",
	},
	{
		nepali: "कुटपिट",
		english: "",
		value: "fight",
	},
];

const deathFromAlcohol = {
	nepali: "मदिरा सेवनले परिवारमा कसैको मृत्यू भएको छ ?",
	english: "",
};

const deathFromAlcoholOption = [
	{
		nepali: "छ",
		english: "Yes",
		value: "death_yes",
	},
	{
		nepali: "छैन",
		english: "Yes",
		value: "death_no",
	},
];

const deathCount = {
	nepali: "मदिरा सेवनले परिवारमा कति जनाको मृत्यू भएको छ ?",
	english: "",
};
// if alcohol usage in family yes enddd

const houseUsedLiving = {
	nepali: "तपाई बसेको घरको स्वामित्व कस्तो हो ?",
	english: "",
};
const houseUsedLivingOption = [
	{
		english: "",
		nepali: "निजि आफ्नै",
		value: "niji",
	},
	{
		english: "",
		nepali: "भाडामा",
		value: "rent",
	},
	{
		english: "",
		nepali: "संस्थागत (सरकारी वा संस्थाको क्वार्टर )",
		value: "qarter",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "other",
	},
];

const returnFromAbroad = {
	nepali:
		"तपाईको परिवारमा बिगत तीन बर्षमा विदेश बसेर स्थाई रुपमा यहाँ बसोबास गर्नु हुने कोहि हुनु हुन्छ?",
	english: "",
};
const returnFromAbroadOption = [
	{
		nepali: "छ",
		english: "",
		value: "foreign_3_yes",
	},
	{
		nepali: "छैन",
		english: "",
		value: "foreign_3_no",
	},
];

const stayedYearAbroad = {
	nepali: "छ भने कति बर्ष बसेर फर्किनु भएको हो ?",
	english: "",
};

const suggestionTitle = {
	nepali: "घर बाट सुझाव/सल्लाह",
	english: "Suggestion By house",
};

module.exports = {
	//new fields from kobo
	migrationType,
	isStillChildMarriage,
	reasonForChildMarriage,
	controlChildMarriage,
	aandolanHeader,
	aandolanYesNo,
	aandolanEffect,
	combatSide,
	painFromAandolan,
	effectFromPandemic,
	illTreatment,
	bigha,
	kattha,
	dhur,
	suggestionTitle,
	handWash,
	handWashOption,
	alcoholUsage,
	alcoholUsageOption,
	alcoholUsageCount,
	alcoholUsageCard,
	alcoholUsageCardOption,
	effectiveFromCard,
	effectiveFromCardOption,
	alcoholUsageExpense,
	problemFromAlcohol,
	problemFromAlcoholOption,
	deathFromAlcohol,
	deathFromAlcoholOption,
	deathCount,
	alcoholCount,
	alcoholTitle,
	houseUsedLiving,
	houseUsedLivingOption,
	returnFromAbroad,
	returnFromAbroadOption,
	stayedYearAbroad,
	//end
	//child marriage starts
	isChildMarriageInFamilyQn,
	isChildMarriageInFamily,
	childMarriageCount,
	childMartialStatusQn,
	childMartialStatus,
	childMarriageAge,
	childMarriageTypeQn,
	childMarriageType,
	childMarriageEducationQn,
	childMarriageEducation,
	childMarriageOccupationQn,
	childMarriageOccupation,
	childMarriageKidQn,
	childMarriageKid,
	//ends
	// economics
	mainIncomeSourceQuestion,
	mainIncomeSource,
	foodForMonthQuestion,
	foodForMonth,
	foodAlternateQuestion,
	foodAlternate,
	totalIncome,
	totalExpenditure,
	loanQuestion,
	loan,
	rate,
	loanTakenInFamilyQuestion,
	loanTakenInFamily,
	bankrate,
	farmerrate,
	laghubittrate,
	femalerate,
	personrate,
	sahakarirate,
	otherrate,
	loanTakingReasonQuestion,
	loanTakingReason,
	dhukutiKarobar,
	dhukutiKarobarCount,
	dhukutiKarobarYesNo,
	cashLoanGivenQuestion,
	cashLoanGiven,
	cashLoanRate,
	//end economics

	//house detail discrimination
	discriminationQuestion,
	discrimination,
	//ends

	// skills
	skillTrainingQuestion,
	skillTraining,
	skillDetailQuestion,
	skillDetail,
	constructionMale,
	constructionFemale,
	handmadeMale,
	handmadeFemale,
	electricMale,
	electricFemale,
	agricultureMale,
	agricultureFemale,
	tourismMale,
	tourismFemale,
	healthMale,
	healthFemale,
	fashionMale,
	fashionFemale,
	animalhealthMale,
	animalhealthFemale,
	skillMale,
	skillFemale,
	familyInLocalOrganQuestion,
	familyInvolvementLocalOrgan,
	familyLocalGovInvolveQuestion,
	familyLocalGovInvolve,
	//skills ends

	// about house rent etc
	buildingQuestion,
	building,
	houseCount,
	familyMemberAnotherHouseLocation,
	familyAnotherHouseQuestion,
	familyMemberAnotherHouse,
	totalRoom,
	totalStorey,
	houseAge,
	landUsedLivingQuestion,
	landUsedLiving,
	houseTypeQuestion,
	houseType,
	roofTypeQuestion,
	roofType,
	hasLandDocumentQuestion,
	hasLandDocument,
	doHouseCriteriaFullfillQuestion,
	doHouseCriteriaFullfill,
	isHouseEarthquakeResistanceQuestion,
	isHouseEarthquakeResistance,
	hasHouseMapPassQuestion,
	hasHouseMapPass,
	isHouseOnRentQuestion,
	isHouseOnRent,
	matanCount,
	rentGivenToQuestion,
	rentGivenTo,
	rentTypeQuestion,
	rentType,
	storey,
	room,
	//ends about house rent etc
	detectCurrentLocation,
	geoCode,
	// death
	deathQuestion,
	deathInYear,
	deathInYeargenderQuestion,
	deathInYearReasonQuestion,
	anyDeath,
	deathName,
	deathGender,
	deathAge,
	deathReason,
	deathCertificateQn,
	deathCertificate,
	//agrijaustart
	agriYesNo,
	agriVegetableTitle,
	agriDhanAreaQuestion,
	agriTypeProduction,
	agriCropType,

	//agrijauwend
	//agrimakaistart
	agriMakaiInfo,
	agriProduction,
	agriProductionTypeQuestion,
	agriProductionType,
	agriMakaiAreaQuestion,
	agriMakaiArea,
	agriMakaiProduction,
	agriMakaiProductionQuantity,
	agriMakaiSale,

	//agrimakaiend
	//agrikodostart
	agriKodoInfo,
	agriKodoAreaQuestion,
	agriKodoArea,
	agriKodoProduction,
	agriKodoProductionQuantity,
	agriKodoSale,

	//agrikodoend
	//agrifaparstart
	agriFaparInfo,
	agriFaparAreaQuestion,
	agriFaparArea,
	agriFaparProduction,
	agriFaparProductionQuantity,
	agriFaparSale,

	//agrifaparend
	//agrigauend
	//agrigaustart
	//agrianyekhetistart
	agriAnyKheti,
	agriAnyInfo,
	agriAnyInfoTitle,
	agriAnyKhetiInfo,
	agriAnyKhetiAreaQuestion,
	agriAnyKhetiArea,
	agriAnyKhetiProduction,
	agriAnyKhetiSale,
	agriAnyKhetiProductionQuantity,
	agriAnyKhetiSaleQuantity,
	//agrianyekhetiend
	// family details
	//DAAL START
	agriDaalTitle,
	agriDaalType,
	//agriblackdaalstart
	blackDaalInfo,
	blackDaalAreaQuestion,
	blackDaalArea,
	blackDaalProduction,
	blackDaalProductionQuantity,
	agriBlackDaalSale,
	blackDaalSaleQuantity,
	//agriblackdaalend
	//agrirahardaalstart
	raharInfo,
	raharAreaQuestion,
	raharArea,
	raharProduction,
	raharProductionQuantity,
	raharSale,
	raharSaleQuantity,
	//agrirahardaalstart
	//musuridaalstart
	musuriInfo,
	musuriAreaQuestion,
	musuriArea,
	musuriProduction,
	musuriProductionQuantity,
	musuriSale,
	musuriSaleQuantity,
	//musuridaalend
	//chanadaalstart
	chanaInfo,
	chanaAreaQuestion,
	chanaArea,
	chanaProduction,
	chanaProductionQuantity,
	chanaSale,
	chanaSaleQuantity,
	//chanadaalend
	//bhatmasdaalstart
	bhatmasInfo,
	bhatmasAreaQuestion,
	bhatmasArea,
	bhatmasProduction,
	bhatmasProductionQuantity,
	bhatmasSale,
	bhatmasSaleQuantity,
	//bhatmasdaalend
	//gahatdaalstart
	gahatInfo,
	gahatAreaQuestion,
	gahatArea,
	gahatProduction,
	gahatProductionQuantity,
	gahatSale,
	gahatSaleQuantity,
	//gahatdaalend
	//mashyamdaalstart
	mashyamInfo,
	mashyamAreaQuestion,
	mashyamArea,
	mashyamProduction,
	mashyamProductionQuantity,
	mashyamSale,
	mashyamSaleQuantity,
	//mashyamdaalend
	//anyadaalstart
	anyDaalKheti,
	anyDaalInfo,
	anyDaalInfoTitle,
	anyDaalKhetiInfo,
	anyDaalKhetiAreaQuestion,
	anyDaalKhetiArea,
	anyDaalKhetiProduction,
	anyDaalKhetiProductionQuantity,
	anyDaalKhetiSale,
	anyDaalKhetiSaleQuantity,
	//anyadaalstart
	//DAAL END
	//OILSEED START
	agriOilTitle,
	agriOilType,
	//OILSEED TOORI START
	tooriInfo,
	tooriAreaQuestion,
	tooriArea,
	tooriProduction,
	tooriProductionQuantity,
	tooriSale,
	tooriSaleQuantity,
	//OILSEED TOORI END
	//OILSEED SURYA START
	suryaInfo,
	suryaAreaQuestion,
	suryaArea,
	suryaProduction,
	suryaProductionQuantity,
	suryaSale,
	suryaSaleQuantity,
	//OILSEED SURYA END
	//OILSEED AALASH START
	aalashInfo,
	aalashAreaQuestion,
	aalashArea,
	aalashProduction,
	aalashProductionQuantity,
	aalashSale,
	aalashSaleQuantity,
	//OILSEED AALASH END
	//OILSEED FILUNGE START
	filungeInfo,
	filungeAreaQuestion,
	filungeArea,
	filungeProduction,
	filungeProductionQuantity,
	filungeSale,
	filungeSaleQuantity,
	//OILSEED FILUNGE END
	//OILSEED TIL START
	tilInfo,
	tilAreaQuestion,
	tilArea,
	tilProduction,
	tilProductionQuantity,
	tilSale,
	tilSaleQuantity,
	//OILSEED TIL END
	//OILSEED SUNFLOWER START
	sunflowerInfo,
	sunflowerAreaQuestion,
	sunflowerArea,
	sunflowerProduction,
	sunflowerProductionQuantity,
	sunflowerSale,
	sunflowerSaleQuantity,
	//OILSEED SUNFLOWER END
	//OILSEED ANYOILSEED START
	anyOilKheti,
	anyOilInfo,
	anyOilInfoTitle,
	anyOilKhetiInfo,
	anyOilKhetiAreaQuestion,
	anyOilKhetiArea,
	anyOilKhetiProduction,
	anyOilKhetiProductionQuantity,
	anyOilKhetiSale,
	anyOilKhetiSaleQuantity,
	//OILSEED ANYOILSEED END
	//OILSEED END

	//VEGETABLE START
	//POTATO START
	potatoInfo,
	potatoAreaQuestion,
	potatoArea,
	potatoProductionQuantity,
	potatoSaleQuantity,
	potatoSale,
	//POTATO END
	//CABBAGE START
	cabbageInfo,
	cabbageAreaQuestion,
	cabbageArea,
	cabbageProductionQuantity,
	cabbageSaleQuantity,
	cabbageSale,
	//CABBAGE END
	//CAULIFLOWER START
	cauliInfo,
	cauliAreaQuestion,
	cauliArea,
	cauliProductionQuantity,
	cauliSaleQuantity,
	cauliSale,
	//CAULIFLOWER END
	//BODI START
	matarkoshaInfo,
	matarkoshaAreaQuestion,
	matarkoshaArea,
	matarkoshaProductionQuantity,
	matarkoshaSaleQuantity,
	matarkoshaSale,
	//BODI END
	//SIMI START
	simiInfo,
	simiAreaQuestion,
	simiArea,
	simiProductionQuantity,
	simiSaleQuantity,
	simiSale,
	//SIMI END
	//TOMATO START
	tomatoInfo,
	tomatoAreaQuestion,
	tomatoArea,
	tomatoProductionQuantity,
	tomatoSaleQuantity,
	tomatoSale,
	//TOMATO END
	//CUCUMBER START
	cucumberInfo,
	cucumberAreaQuestion,
	cucumberArea,
	cucumberProductionQuantity,
	cucumberSaleQuantity,
	cucumberSale,
	//CUCUMBER END
	//LAUKA START
	laukaInfo,
	laukaAreaQuestion,
	laukaArea,
	laukaProductionQuantity,
	laukaSaleQuantity,
	laukaSale,
	//LAUKA END
	dhanyaInfo,
	dhanyaAreaQuestion,
	dhanyaArea,
	dhanyaProductionQuantity,
	dhanyaSaleQuantity,
	dhanyaSale,
	//FARSI START
	saagInfo,
	saagAreaQuestion,
	saagArea,
	saagProductionQuantity,
	saagSaleQuantity,
	saagSale,
	//FARSI END
	//PARWAL START
	parwalInfo,
	parwalAreaQuestion,
	parwalArea,
	parwalProductionQuantity,
	parwalSaleQuantity,
	parwalSale,
	//PARWAL END
	//KARELA START
	karelaInfo,
	karelaAreaQuestion,
	karelaArea,
	karelaProductionQuantity,
	karelaSaleQuantity,
	karelaSale,
	//KARELA END
	//GHIRAULA START
	ghiraulaInfo,
	ghiraulaAreaQuestion,
	ghiraulaArea,
	ghiraulaProductionQuantity,
	ghiraulaSaleQuantity,
	ghiraulaSale,
	//GHIRAULA END
	//CHICHINDO START
	chichindoInfo,
	chichindoAreaQuestion,
	chichindoArea,
	chichindoProductionQuantity,
	chichindoSaleQuantity,
	chichindoSale,
	//CHICHINDO END
	//ANYATARKARI START
	anyTarkariKheti,
	agriVegetableType,
	anyTarkariInfo,
	anyTarkariInfoTitle,
	anyTarkariKhetiInfo,
	anyTarkariKhetiAreaQuestion,
	anyTarkariKhetiArea,
	anyaProductionQuantity,
	anyaSaleQuantity,
	anyaSale,
	//ANYATARKARI END
	//VEGETABLE END
	//FRUIT START
	agriFruitTitle,
	agriFruitType,
	mangoInfo,
	mangoAreaQuestion,
	mangoArea,
	mangoProduction,
	mangoProductionQuantity,
	mangoSale,
	mangoSaleQuantity,
	aarubakhadaInfo,
	aarubakhadaAreaQuestion,
	aarubakhadaArea,
	aarubakhadaProduction,
	aarubakhadaProductionQuantity,
	aarubakhadaSale,
	aarubakhadaSaleQuantity,
	bananaInfo,
	bananaAreaQuestion,
	bananaArea,
	bananaProduction,
	bananaProductionQuantity,
	bananaSale,
	bananaSaleQuantity,
	orangeInfo,
	orangeAreaQuestion,
	orangeArea,
	orangeProduction,
	orangeProductionQuantity,
	orangeSale,
	orangeSaleQuantity,
	guvaInfo,
	guvaAreaQuestion,
	guvaArea,
	guvaProduction,
	guvaProductionQuantity,
	guvaSale,
	guvaSaleQuantity,
	lemonInfo,
	lemonAreaQuestion,
	lemonArea,
	lemonProduction,
	lemonProductionQuantity,
	lemonSale,
	lemonSaleQuantity,
	appleInfo,
	appleAreaQuestion,
	appleArea,
	appleProduction,
	appleProductionQuantity,
	appleSale,
	appleSaleQuantity,
	kiwiInfo,
	kiwiAreaQuestion,
	kiwiArea,
	kiwiProduction,
	kiwiProductionQuantity,
	kiwiSale,
	kiwiSaleQuantity,
	naspatiInfo,
	naspatiAreaQuestion,
	naspatiArea,
	naspatiProduction,
	naspatiProductionQuantity,
	naspatiSale,
	naspatiSaleQuantity,
	avogadoInfo,
	avogadoAreaQuestion,
	avogadoArea,
	avogadoProduction,
	avogadoProductionQuantity,
	avogadoSale,
	avogadoSaleQuantity,
	agriAnyFruit,
	agriAnyFruitInfo,
	agriAnyInfoFruitTitle,
	agriAnyFruitKhetiInfo,
	agriAnyFruitKhetiAreaQuestion,
	agriAnyFruitKhetiArea,
	agriAnyFruitKhetiProduction,
	agriAnyFruitKhetiProductionQuantity,
	agriAnyFruitKhetiSale,
	agriAnyFruitKhetiSaleQuantity,

	//FRUIT END

	//CASHCROP START
	cashCropType,
	dhasingareInfo,
	dhasingareAreaQuestion,
	dhasingareArea,
	dhasingareProduction,
	dhasingareProductionQuantity,
	dhasingareSale,
	dhasingareSaleQuantity,
	alaichiInfo,
	alaichiAreaQuestion,
	alaichiArea,
	alaichiProduction,
	alaichiProductionQuantity,
	alaichiSale,
	alaichiSaleQuantity,
	rudrakshInfo,
	rudrakshAreaQuestion,
	rudrakshArea,
	rudrakshProduction,
	rudrakshSale,
	amrisoInfo,
	amrisoAreaQuestion,
	amrisoArea,
	amrisoProduction,
	amrisoProductionQuantity,
	amrisoSale,
	amrisoSaleQuantity,
	coffeeInfo,
	coffeeTreeNumber,
	coffeeProduction,
	coffeeSale,
	teaInfo,
	teaAreaQuestion,
	teaArea,
	teaProduction,
	teaSale,
	nibuwaInfo,
	nibuwaAreaQuestion,
	nibuwaArea,
	nibuwaProduction,
	nibuwaProductionQuantity,
	nibuwaSale,
	nibuwaSaleQuantity,
	gingerInfo,
	gingerAreaQuestion,
	gingerArea,
	gingerProduction,
	gingerProductionQuantity,
	gingerSale,
	gingerSaleQuantity,
	besaarInfo,
	besaarAreaQuestion,
	besaarArea,
	besaarProduction,
	besaarProductionQuantity,
	besaarSale,
	besaarSaleQuantity,
	garlicInfo,
	garlicAreaQuestion,
	garlicArea,
	garlicProduction,
	garlicProductionQuantity,
	garlicSale,
	garlicSaleQuantity,
	onionInfo,
	onionAreaQuestion,
	onionArea,
	onionProduction,
	onionProductionQuantity,
	onionSale,
	onionSaleQuantity,
	mushroomInfo,
	mushroomAreaQuestion,
	mushroomArea,
	mushroomProduction,
	mushroomProductionQuantity,
	mushroomSale,
	mushroomSaleQuantity,
	sugarcaneInfo,
	sugarcaneAreaQuestion,
	sugarcaneArea,
	sugarcaneProduction,
	sugarcaneProductionQuantity,
	sugarcaneSale,
	sugarcaneSaleQuantity,
	nutInfo,
	nutAreaQuestion,
	nutArea,
	nutProduction,
	nutProductionQuantity,
	nutSale,
	nutSaleQuantity,
	chillyInfo,
	chillyAreaQuestion,
	chillyArea,
	chillyProduction,
	chillyProductionQuantity,
	chillySale,
	chillySaleQuantity,
	anyCashCropKheti,
	anyCashCropKhetiInfo,
	anyCashCropInfoTitle,
	anyCashCropInfo,
	anyCashCropAreaQuestion,
	anyCashCropArea,
	anyCashCropProduction,
	anyCashCropProductionQuantity,
	anyCashCropSale,
	anyCashCropSaleQuantity,
	//CASHCROP END
	//FLOWER START
	flowerTitle,
	flowerAreaQuestion,
	flowerArea,
	flowerProduction,
	flowerSale,
	flowerSaleQuantity,
	//FLOWER END
	//INSECTICIDES START
	insecticideQuestion,
	insecticideYesNo,
	insecticideYes,
	insecticideType,
	//INSECTICIDES END

	//LIVESTOCKS START
	//ANIMAL START
	livestockQuestion,
	livestockYesNo,
	livestockYes,
	livestockType,
	livestockTitle,
	livestockDiffType,
	cowInfo,
	cowNumber,
	cowMilkProduction,
	cowMilkProductionSale,
	cowMilkSale,
	buffalloInfo,
	buffalloNumber,
	buffalloMilkProduction,
	buffalloMilkProductionSale,
	buffalloMilkSale,
	khasiInfo,
	khasiNumber,
	khasiMeatProduction,
	khasiMeatIncome,
	goatInfo,
	goatNumber,
	goatMeatIncome,
	rangaInfo,
	rangaNumber,
	rangaMeatProduction,
	rangaMeatIncome,
	pigInfo,
	pigNumber,
	pigMeatProduction,
	pigMeatIncome,
	sheepInfo,
	sheepNumber,
	sheepMeatProduction,
	sheepMeatIncome,
	badelInfo,
	badelNumber,
	badelMeatProduction,
	badelMeatIncome,
	oxInfo,
	oxNumber,
	oxProduction,
	oxMeatIncome,
	gadhaInfo,
	gadhaNumber,
	gadhaProduction,
	gadhaMeatIncome,
	horseInfo,
	horseNumber,
	yakInfo,
	yakNumber,
	yakMilkProduction,
	yakProduction,
	yakMeatIncome,
	chyangraInfo,
	chyangraNumber,
	chyangraProduction,
	chyangraMeatIncome,
	anyAnimal,
	anyAnimalInfo,
	anyAnimalTitle,
	anyAnimalInfoTitle,
	animalNumber,
	animalProduction,
	animalMeatIncome,
	//ANIMAL END
	//BIRD START
	birdTitle,
	birdDiffType,
	henInfo,
	henNumber,
	henMeatProduction,
	henEggSale,
	henMeatIncome,
	duckInfo,
	duckNumber,
	duckMeatProduction,
	duckEggSale,
	duckMeatIncome,
	hybridChickenInfo,
	hybridMeatProduction,
	hybridNumber,
	hybridEggSale,
	ostrichInfo,
	ostrichNumber,
	ostrichMeatProduction,
	ostrichMeatIncome,
	pegionInfo,
	pegion,
	pegionMeatIncome,
	anyBird,
	anyBirdInfo,
	anyBirdTitle,
	anyBirdInfoTitle,
	birdNumber,
	birdProduction,
	birdMeatIncome,
	//BIRD END
	//LIVESTOCKS END
	//BEEFISHSILK START
	beefishsilkQuestion,
	beefishsilkYesNo,
	beefishsilkYes,
	beefishsilkType,
	fishTitle,
	totalPond,
	pondArea,
	fishProduction,
	beeTitle,
	totalGhaar,
	beeProduction,
	reshamTitle,
	reshamGhaar,
	reshamProduction,
	totalBeefidhsilkIncome,
	//BEEFISHSILK END

	// family details
	ageQuestion,
	dateQuestion,
	memberName,
	contactNo,
	genderQuestion,
	gender,
	livingStatusQuestion,
	livingStatus,
	abroadReasonQuestion,
	abroadReason,
	abroadYearQuestion,
	abroadCountryQuestion,
	abroadCountry,
	casteQuestion,
	casteList,
	aadibasiQuestion,
	aadibasi,
	// pahadiQuestion,
	// pahadi,
	religionQuestion,
	religionList,
	marriageQuestion,
	marriage,
	isGoingToSchool,
	isGoingToSchoolQn,
	notGoingSchoolQn,
	notGoingSchool,
	educationQuestion,
	education,
	studyFieldQn,
	studyField,
	preprimaryQuestion,
	preprimary,
	primaryQuestion,
	primary,
	secondaryQuestion,
	secondary,
	occupationQuestion,
	occupation,
	jobQuestion,
	job,
	securityQuestion,
	security,
	teacherQuestion,
	teacher,
	industryQuestion,
	industry,
	healthconditionQuesiton,
	healthcondition,
	lessThanSixBirthCertificate,
	birthCertificateQn,
	unhealthyQuestion,
	unhealthy,
	mentalYesNoQuestion,
	mentaltype,
	mentalconditionQuestion,
	mentalcondition,
	mentalyesno,
	mentalCardYesNo,
	mentalCardtype,
	mentalCardQuestion,
	mentalCardTypeQuestion,
	mentaltypeQuestion,
	mothertongueQuestion,
	mothertongue,
	raiQuestion,
	raiType,
	bloodgroupQuestion,
	bloodgroup,
	votercardQuestion,
	votercard,
	//family details ends

	businessLists,
	herbLists,
	insuranceLists,
	insuranceQuestion,
	insuranceYesNo,

	// secondaryHouse
	drinkingwaterQuestion,
	drinkingwaterLists,
	drinkingwaterplaceQuestion,
	drinkingwaterplaceLists,
	cookingresourceQuestion,
	lightresourceQuestion,
	lightresourceLists,
	isElectricMeterQn,
	isElectricMeter,
	cookingresourceLists,
	toilettypeQuestion,
	toiletYesNo,
	toiletNoQuestion,
	noToiletLists,
	toiletYesNoUseQn,
	toiletYesNoUse,
	toiletresourceLists,
	roadQuestion,
	roadLists,
	bankaccountYesNo,
	bankaccountQuestion,
	// Secondary house detail ends

	// women child

	numberOfBankAccount,
	bankAccountMale,
	bankAccountFemale,
	salttypeQuestion,
	salttypeLists,
	lifeInsurance,
	healthInsurance,
	nonlifeInsurance,
	animalInsurance,
	insuranceOther,
	insuranceListQuestion,
	wasteQuestion,
	wastemanagementLists,
	birthCertificateQuestion,
	birthCertificate,
	contraceptiveUseQuestion,
	contraceptiveUse,
	deliveryFromDoctorQuestion,
	deliveryFromDoctor,
	childDeathQuestion,
	childDeath,
	childDeathCount,
	pregnanctCheckQuestion,
	pregnanctCheck,
	childNutritionQuestion,
	childNutrition,
	childLabourQuestion,
	childLabour,
	childLaborQuestion,
	earlyMarriageQuestion,
	earlyMarriage,
	pregnantTetanusQuestion,
	pregnantTetanus,
	illTreamentQuestion,
	illTreament,
	govHospitalNameQuestion,
	govHospitalName,
	isChildMarriageExistsQn,
	isChildMarriageExists,
	childMarriageExistsQn,
	childMarriageExists,
	controlChildMarriageQn,
	controlChildMarriageList,
	isRevolutionCaseQn,
	isRevolutionCase,
	protestTypeQn,
	protestType,
	whichSideForProtestQn,
	whichSideForProtest,
	effectFromProtestQn,
	effectFromProtest,
	effectFromCoronaQn,
	effectFromCorona,
	// women child ends

	// social welfare
	bhattaQuestion,
	bhattaLists,
	oldcitizenMale,
	oldcitizenFemale,
	singleWomen,
	disableMen,
	disableWomen,
	halfdisableMen,
	halfdisableWomen,
	childsecurityMale,
	childsecurityFemale,
	dalitMale,
	dalitFemale,
	rareCasteMale,
	rareCasteFemale,
	welfareYesNoQuestion,
	welfareYesNo,
	// social welfare ends

	// house
	houseproblem,
	suggestion,

	// primaryHouse
	wardQuestion,
	newWardQuestion,
	wardNumber,
	allToleName,
	gharNumber,
	toleQnAll,
	toleOneQn,
	toleTwoQn,
	toleThreeQn,
	toleFourQn,
	toleFiveQn,
	toleSixQn,
	toleSevenQn,
	toleWardOne,
	toleWardTwo,
	toleWardThree,
	toleWardFour,
	toleWardFive,
	toleWardSix,
	toleWardSeven,
	sabikGabisaQuestion,
	sabikGabisa,
	sabikWard,
	tole,
	toleNumber,
	streetName,
	answererName,
	answererRelationQuestion,
	answererRelation,
	familyMale,
	familyFemale,
	familyThirdGender,
	totalMember,
	geoLocation,
	latitude,
	longitude,
	housePhoto,
	livingtypeQuestion,
	livingType,
	permanentQuestion,
	permanent,
	cashCropTitle,
	// primaryHouse ends

	// Business start
	businesssQn,
	businessRadio,
	businessListQuestion,
	herbRadio,
	herbListQuestion,
	herbQn,
	//business ends

	//houseFacility
	houseFacilityQuestion,
	houseFacility,
	motorcycle,
	car,
	jeep,
	scooter,
	tractor,
	truck,
	auto,
	tempo,
	dozar,
	tipper,
	scavater,
	other,
	otherCount,
	//ends
	stepperStep,
	agriDhanProduction,
	agriDhanProductionQuantity,
	agriDhanSale,
	agriDhanSaleQuantity,
	totallandAreaTitle,
	totalLandArea,
	totalFamilyLandQn,
	totalFamilyLand,
	elaaniQn,
	nambariQn,
	landforAgriYesNo,
	landforAgriYes,
	landforAgri,
	landforAgriList,
	irrigationForLand,
	irrigationForLandList,
	seedNeed,
	seedNeedQuestion,
	otherLand,
	otherLandArea,
	ownLand,
	ownLandArea,
	agricultureTitle,
	irrigation,
	mal,
	malType,
	waliType,
	wali,
	area,
	productionShow,
	sales,
	production,
	expense,
	agriInfo,
	yearlyInfo,
	beefishTitle,
	beefishType,
	beefishTotal,
	beefishProduction,
	fish,
	silk,
	bee,
	beefishYesNo,
	businessQ,
	businessType,
	businessTypeName,
	businessProfit,
	businessName,
	herbName,
	gharTitle,
	genderMale,
	genderFemale,
	genderThird,
	genderTotal,
	economic,
	mainincome,
	yearlyIncome,
	yearlyExpenditure,
	loanGiven,
	loanGivenInterest,
	loanTaken,
	loanType,
	loanInterest,
	houseFacilityTitle,
	facilityType,
	facilityCount,
	childMarriageHeader,
	familyTitle,
	waterSource,
	cookingSource,
	electricSource,
	isElectricityMeterQn,
	toiletType,
	toiletQuestion,
	toiletTypeNo,
	toiletTypeNoUse,
	anyBankAccount,
	numberofBankAccount,
	homeAge,
	saltUsed,
	hasinsur,
	insuranceDetail,
	totalHouseCount,
	totalMatanCount,
	gharJaggaTitle,
	totalHouseRoom,
	totalHouseStorey,
	houseMapPass,
	houseCriteriaFullfill,
	houseLandUsedLiving,
	houseroofType,
	hoousesType,
	wasteDisposal,
	road,
	houseRent,
	houseConstructionCertificate,
	totalArea,
	ropani,
	aana,
	paisa,
	liveStockInfo,
	livestockName,
	livestockDetail,
	animalCount,
	annualIncome,
	skills,
	skillType,
	skillMaleNo,
	skillFemaleNo,
	skillYesNo,
	welfare,
	welfareType,
	welfareMale,
	welfareFemale,
	bhattaYesNo,
	dirtyWaterQuestion,
	dirtyWaterManagement,
	riskAreaAroundHouseQn,
	riskAreaAroundHouse,
	meansOfMunicipalInformationQn,
	meansOfMunicipalInformation,
	waterPurificationQn,
	waterPurification,
	womenchild,
	childLabor,
	citizenshipFrontPhoto,
	citizenshipBackPhoto,
};
