import axios from "axios";
import { SAVE_MARRIED_DAUGHTER, SET_LOADING, SHOW_MARRIED_DAUGHTER } from "./types";
import { startSnackBar } from "../actions/utilAction";

export const addMarriedDaughterSurvey = (data, history) => (dispatch) => {
	return axios
		.post(`/api/daughterMarriage/`, data)
		.then((res) => {
			history.push(`/survey-by-daughterMarriage`);

			return dispatch({
				type: SAVE_MARRIED_DAUGHTER,
				payload: res.data,
			});
		})

		.catch((error) => {
			if (Array.isArray(error.response.data)) {
				dispatch(
					startSnackBar({
						open: true,
						variant: "error",
						message: error.response.data,
					})
				);
			}
		});
};

export const getDaughterByHouseId = (houseId) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: true,
  });
  axios
    .get(`/api/daughterMarriage/show/${houseId}`)
    .then((res) => {
      dispatch({
        type: SHOW_MARRIED_DAUGHTER,
        payload: res.data,
      });
    })
    .catch((error) => {
			if (Array.isArray(error.response.data)) {
				dispatch(
					startSnackBar({
						open: true,
						variant: "error",
						message: error.response.data,
					})
				);
			}
		});
};



export const deleteInstitute = (category, id) => {
	return axios
		.delete(`/api/${category}/delete/${id}`)
		.then((res) => {
			console.log("res", res.data);
			return Promise.resolve(true);
		})
		.catch((err) => {
			return Promise.reject(false);
		});
};
