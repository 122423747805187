import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import { selectedLanguage } from "../../../../../variable/global";
import { childMarriageHeader } from "../../../../../variable/houseSurvey";
import { Title } from "../../../../common/frequent/QuestionAnswer";

function mapValue(value, obj) {
	return obj[value];
}

class ChildMarriage extends Component {
	render() {
		const { classes, marriage } = this.props;
		return (
			<Fragment>
				{marriage !== undefined && marriage.length > 0 && (
					<Grid item container direction="column" className={classes.container}>
						<Title title={childMarriageHeader} />

						<Grid item container>
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										<TableCell>नाम</TableCell>
										<TableCell align="right">लिङ्ग</TableCell>
										<TableCell align="right">उमेर</TableCell>
										<TableCell align="right">बिवाहा हुदाको उमेर</TableCell>
										<TableCell align="right">बैबाहिक अवस्था</TableCell>
										<TableCell align="right">बिवाहाको प्रकार</TableCell>
										<TableCell align="right">शैक्षिकस्तर</TableCell>
										<TableCell align="right">पेशा</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{marriage.map((row, key) => (
										<TableRow key={key}>
											<TableCell scope="row">{row.fullName}</TableCell>
											<TableCell align="right">
												{mapValue(row.gender, {
													male: "पुरुष",
													female: "महिला",
												})}
											</TableCell>
											<TableCell align="right">
												{getNepalNumber(row.age)}
											</TableCell>
											<TableCell align="right">
												{getNepalNumber(row.marriageAge)}
											</TableCell>
											<TableCell align="right">
												{mapValue(row.martialStatus, {
													Marriage: "विवाहित",
													seperate: "छुट्टिएको",
													widow: "विधुर /विधुवा",
													single: "एकल",
													devorce: "पारपाचुके भएको",
												})}
											</TableCell>
											<TableCell align="right">
												{mapValue(row.marriageType, {
													arrange: "मागी बिवाह",
													elloped: "भागि बिवाह",
													love_marriage: "प्रेम बिवाह",
													others: "अन्य",
												})}
											</TableCell>
											<TableCell align="right">
												{mapValue(row.education, {
													litrate: "साक्षर",
													uneducate: "निरक्षर",
													primary_education: "वाल विकास",
													basic: "आधारभूत",
													secondary: "माध्यमिक",
													graduate: "स्नातक",
													above_graduate: "स्नातक माथी",
												})}
											</TableCell>
											<TableCell align="right">
												{mapValue(row.occupation, {
													agriculture: "कृषि तथा पशुपालन",
													housewife: "गृहणी",
													business: "ब्यापार व्यावसाय",
													employee: "नकारी जागिर",
													unemployee: "बेरोजगार",
													others: "अन्य",
												})}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Grid>
					</Grid>
				)}
			</Fragment>
		);
	}
}

const styles = (theme) => ({
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3,
		overflowX: "auto",
	},
	table: {
		width: "auto",
	},
	container: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 2,
	},
	eachQnAns: {
		marginTop: theme.spacing.unit * 1,
	},
});

ChildMarriage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChildMarriage);
