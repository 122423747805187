import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { Button, Divider, Form, Input, Select, Typography } from "antd";
import Axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addProdEducationSurvey } from "../../../actions/instituteAction";
import { female, male, selectedLanguage, ward } from "../../../variable/global";
import {
	add,
	helper,
	name,
	staffProd,
	studentProd,
	targetedCategory,
	wardQuestion,
} from "../../../variable/institute";

const { Option } = Select;

class Project extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};
	state = {
		uniqueIdentifier:
			Number(this.random(10000, 99999)).toFixed(0) +
			Number(this.random(1000, 9999)).toFixed(0),
		name: "",
		ward: "",
		maleProd: "",
		femaleProd: "",
		maleStaff: "",
		femaleStaff: "",
		helpingInstitute: "",
		targetedCategory: "",
		isView: this.props.location.view,
	};

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	componentDidMount() {
		let projectId = this.props.match.params.id;
		Axios.get(`/api/prodEducation/fetch/${projectId}`)
			.then((res) => {
				this.setState({
					uniqueIdentifier: res.data.uniqueIdentifier,
					name: res.data.name,
					ward: res.data.ward,
					maleProd: res.data.students && res.data.students.male,
					femaleProd: res.data.students && res.data.students.female,
					maleStaff: res.data.staff && res.data.staff.male,
					femaleStaff: res.data.staff && res.data.staff.female,
					helpingInstitute: res.data.helpingInstitute,
					targetedCategory: res.data.targetedCategory,
				});
			})
			.catch((err) => {});
	}

	onSubmit = (event) => {
		event.preventDefault();
		const newProd = {
			name: this.state.name,
			uniqueIdentifier: this.state.uniqueIdentifier,
			ward: this.state.ward,
			maleProd: this.state.maleProd,
			femaleProd: this.state.femaleProd,
			maleStaff: this.state.maleStaff,
			femaleStaff: this.state.femaleStaff,
			helpingInstitute: this.state.helpingInstitute,
			targetedCategory: this.state.targetedCategory,
		};

		if (this.props.isUpdate) {
			newProd.id = this.props.match.params.id;
			this.props.addProdEducationSurvey(
				newProd,
				this.props.history,
				this.props.match.params.category
			);
		} else
			this.props.addProdEducationSurvey(
				newProd,
				this.props.history,
				this.props.match.params.category
			);
	};

	handleSelectChange = (name, value) => {
		console.log("handle ", name, value);
		this.setState({
			[name]: value,
		});
	};

	render() {
		const { classes, title } = this.props;

		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form {...formItemLayout} onSubmit={this.onSubmit}>
					<Grid xs={6}>
						<Form.Item label={name[selectedLanguage]}>
							<Input
								onChange={this.handleChange("name")}
								value={this.state.name}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={wardQuestion[selectedLanguage]}>
							<Select
								placeholder="------------"
								onChange={(val) => this.handleSelectChange("ward", val)}
								value={this.state.ward}
								disabled={this.state.isView}
							>
								{ward.map((woda) => (
									<Option value={woda.value}>{woda.ward}</Option>
								))}
							</Select>
						</Form.Item>
					</Grid>
					<Divider orientation="left">{studentProd[selectedLanguage]}</Divider>
					<Grid xs={6}>
						<Form.Item label={male[selectedLanguage]}>
							<Input
								onChange={this.handleChange("maleProd")}
								value={this.state.maleProd}
								disabled={this.state.isView}
							/>
						</Form.Item>
						<Form.Item label={female[selectedLanguage]}>
							<Input
								onChange={this.handleChange("femaleProd")}
								value={this.state.femaleProd}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Divider orientation="left">{staffProd[selectedLanguage]}</Divider>
					<Grid xs={6}>
						<Form.Item label={male[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleChange("maleStaff")}
								value={this.state.maleStaff}
								disabled={this.state.isView}
							/>
						</Form.Item>
						<Form.Item label={female[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleChange("femaleStaff")}
								value={this.state.femaleStaff}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Divider />
					<Grid xs={6}>
						<Form.Item label={targetedCategory[selectedLanguage]}>
							<Input
								onChange={this.handleChange("targetedCategory")}
								value={this.state.targetedCategory}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={helper[selectedLanguage]}>
							<Input
								onChange={this.handleChange("helpingInstitute")}
								value={this.state.helpingInstitute}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							disabled={this.state.isView}
						>
							{add[selectedLanguage]}
						</Button>
					</Form.Item>
				</Form>
			</Paper>
		);
	}
}
const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addProdEducationSurvey })(withRouter(Project))
);
