import UserAddOutlined from "@ant-design/icons/UserAddOutlined";
import {
  Breadcrumb,
  Icon,
  Upload,
  Row,
  Typography,
  Select,
  Form,
  Col,
  message,
  Button,
  Spin,
} from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { selectedLanguage, ward } from "../../variable/global";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Grid, IconButton } from "@material-ui/core";
import Selection from "../../components/common/frequent/Selection";
import TextField1 from "@material-ui/core/TextField";
import CheckBox from "../../components/common/frequent/CheckBox";
import DeleteIcon from "@material-ui/icons/Delete";

import BoxBorder from "../../components/common/frequent/BoxBorder";
import MultipleSelect from "../../components/common/frequent/MultipleSelect";
import RadioButton from "../../components/common/frequent/RadioButton";
import TextField from "../../components/common/frequent/TextField";
import {
  wardNumber,
  aadibasi,
  aadibasiQuestion,
  abroadCountry,
  abroadCountryQuestion,
  abroadReason,
  abroadReasonQuestion,
  abroadYearQuestion,
  answererRelation,
  answererRelationQuestion,
  bloodgroup,
  bloodgroupQuestion,
  casteList,
  casteQuestion,
  contactNo,
  education,
  educationQuestion,
  gender,
  genderQuestion,
  healthcondition,
  healthconditionQuesiton,
  industry,
  industryQuestion,
  job,
  jobQuestion,
  livingStatus,
  livingStatusQuestion,
  marriage,
  marriageQuestion,
  memberName,
  mentalCardQuestion,
  mentalCardtype,
  mentalCardTypeQuestion,
  mentalCardYesNo,
  mentalcondition,
  mentalconditionQuestion,
  mentaltype,
  mentaltypeQuestion,
  mentalyesno,
  mentalYesNoQuestion,
  occupation,
  occupationQuestion,
  pahadi,
  pahadiQuestion,
  preprimary,
  preprimaryQuestion,
  primary,
  primaryQuestion,
  raiQuestion,
  raiType,
  religionList,
  religionQuestion,
  secondary,
  secondaryQuestion,
  security,
  securityQuestion,
  teacher,
  teacherQuestion,
  unhealthy,
  unhealthyQuestion,
  insuranceYesNo,
  insuranceQuestion,
  insuranceListQuestion,
  insuranceLists,
  votercard,
  votercardQuestion,
  mothertongueQuestion,
  mothertongue,
  familyFemale,
  familyMale,
  totalMember,
  familyThirdGender,
  citizenshipFrontPhoto,
  citizenshipBackPhoto,
  ageQuestion,
  dateQuestion,
  skillTrainingQuestion,
  skillTraining,
  skillDetailQuestion,
  skillDetail,
} from "../../variable/houseSurvey";
import Axios from "axios";
import FullScreenDialog from "../../components/common/frequent/dialog/FullScreen";
import HouseDetail from "../../components/dashboard/search/advancedHouseSearch/houseNumber/HouseDetail";
const { Title } = Typography;
const { Option } = Select;
const Is = (props) => props.visible && props.children;

const FamilyForm = ({ handleChange, thisError, form }) => {
  return (
    <Grid container direction="column">
      <Grid xs={12} md={8}>
        <TextField
          error={thisError && thisError.fullName}
          question={memberName}
          name={`fullName`}
          require
          isVisible
          placeholder=""
          value={form[`fullName`]}
          handleChange={handleChange}
        />
      </Grid>
      <Grid xs={12} md={8}>
        <Selection
          error={thisError && thisError.answererRelation}
          question={answererRelationQuestion}
          name={`answererRelation`}
          selectList={answererRelation}
          value={form[`answererRelation`]}
          isVisible
          handleChange={handleChange}
        />
      </Grid>
      <Grid xs={12} md={8}>
        <Typography variant="subheading" gutterBottom>
          {dateQuestion[selectedLanguage]}
        </Typography>
        <TextField1
          name={`dateOfBirth`}
          isVisible
          placeholder="YY/MM/DD"
          value={form[`dateOfBirth`]}
          onChange={(event) => {
            handleChange("dateOfBirth", event.target.value);
          }}
          variant="outlined"
        />
      </Grid>
      <Grid xs={12} md={8}>
        <TextField
          type="number"
          question={ageQuestion}
          name={`age`}
          require
          isVisible
          placeholder=""
          value={form[`age`]}
          handleChange={handleChange}
        />
      </Grid>
      <Grid xs={12} md={8}>
        <TextField
          error={thisError && thisError.contactNumber}
          type="number"
          question={contactNo}
          name={`contactNumber`}
          require
          isVisible
          placeholder=""
          value={form[`contactNumber`]}
          handleChange={handleChange}
        />
      </Grid>
      <Grid>
        <RadioButton
          error={thisError && thisError.gender}
          handleChange={handleChange}
          isVisible
          question={genderQuestion}
          name={`gender`}
          radioLists={gender}
          value={form[`gender`]}
        />
      </Grid>
      <Grid>
        <RadioButton
          error={thisError && thisError.livingStatus}
          handleChange={handleChange}
          isVisible
          question={livingStatusQuestion}
          name={`livingStatus`}
          radioLists={livingStatus}
          value={form[`livingStatus`]}
        />
      </Grid>
      <BoxBorder isVisible={form[`livingStatus`] === "foreign"}>
        <RadioButton
          error={thisError && thisError.abroadReason}
          handleChange={handleChange}
          isVisible
          question={abroadReasonQuestion}
          name={`abroadReason`}
          radioLists={abroadReason}
          value={form[`abroadReason`]}
          other={form[`abroadReasonOther`]}
        />
        <TextField
          error={thisError && thisError.bideshiyekoYear}
          question={abroadYearQuestion}
          name={`bideshiyekoYear`}
          require
          isVisible
          placeholder=""
          value={form[`bideshiyekoYear`]}
          handleChange={handleChange}
        />
        <RadioButton
          error={thisError && thisError.abroadCountry}
          handleChange={handleChange}
          isVisible
          question={abroadCountryQuestion}
          name={`abroadCountry`}
          radioLists={abroadCountry}
          value={form[`abroadCountry`]}
          other={form[`abroadCountryOther`]}
        />
      </BoxBorder>
      <Grid xs={12} md={8}>
        <Selection
          error={thisError && thisError.casteList}
          question={casteQuestion}
          name={`casteList`}
          selectList={casteList}
          value={form[`casteList`]}
          isVisible
          handleChange={handleChange}
          other={form[`casteListOther`]}
        />
      </Grid>
      <Grid>
        <RadioButton
          error={thisError && thisError.aadibasi}
          handleChange={handleChange}
          isVisible={form[`casteList`] === "janjaati_aadiw"}
          question={aadibasiQuestion}
          name={`aadibasi`}
          radioLists={aadibasi}
          value={form[`addiwasCaste`]}
          other={form[`addiwasCasteOther`]}
        />
      </Grid>
      <Grid>
        <RadioButton
          error={thisError && thisError.religionList}
          handleChange={handleChange}
          isVisible
          question={religionQuestion}
          name={`religionList`}
          radioLists={religionList}
          value={form[`religionList`]}
          other={form[`religionListOther`]}
        />
      </Grid>
      <Grid xs={12} md={8}>
        <Selection
          error={thisError && thisError.marriage}
          question={marriageQuestion}
          name={`marriage`}
          selectList={marriage}
          value={form[`marriage`]}
          isVisible
          handleChange={handleChange}
        />
      </Grid>
      <Grid xs={12} md={8}>
        <Selection
          error={thisError && thisError.education}
          question={educationQuestion}
          name={`education`}
          selectList={education}
          value={form[`education`]}
          isVisible
          handleChange={handleChange}
        />
      </Grid>
      <Grid>
        <RadioButton
          error={thisError && thisError.primary}
          handleChange={handleChange}
          isVisible={form[`education`] === "primary"}
          question={primaryQuestion}
          name={`primary`}
          radioLists={primary}
          value={form[`primary`]}
        />
      </Grid>
      <Grid>
        <RadioButton
          error={thisError && thisError.secondary}
          handleChange={handleChange}
          isVisible={form[`education`] === "secondary"}
          question={secondaryQuestion}
          name={`secondary`}
          radioLists={secondary}
          value={form[`secondary`]}
        />
      </Grid>
      <Grid xs={12} md={8}>
        <Selection
          error={thisError && thisError.occupation}
          question={occupationQuestion}
          name={`occupation`}
          selectList={occupation}
          value={form[`occupation`]}
          isVisible
          handleChange={handleChange}
          other={form[`occupationOther`]}
        />
      </Grid>
      <Grid>
        <RadioButton
          error={thisError && thisError.job}
          handleChange={handleChange}
          isVisible={form[`occupation`] === "job"}
          question={jobQuestion}
          name={`job`}
          radioLists={job}
          value={form[`job`]}
          other={form[`jobOther`]}
        />
      </Grid>
      {/* <Grid>
            <RadioButton
                error={thisError && thisError.security}
                handleChange={handleChange}
                isVisible={form[`job`] === "security"}
                question={securityQuestion}
                name={`security`}
                radioLists={security}
                value={form[`security`]}
                other={form[`securityOther`]}
            />
        </Grid>
        <Grid>
            <RadioButton
                error={thisError && thisError.teacher}
                handleChange={handleChange}
                isVisible={form[`job`] === "teacher"}
                question={teacherQuestion}
                name={`teacher`}
                radioLists={teacher}
                value={form[`teacher`]}
            />
        </Grid> */}
      <Grid>
        <RadioButton
          error={thisError && thisError.industry}
          handleChange={handleChange}
          isVisible={form[`occupation`] === "business"}
          question={industryQuestion}
          name={`industry`}
          radioLists={industry}
          value={form[`industry`]}
          other={form[`industryOther`]}
        />
      </Grid>
      <BoxBorder
        isVisible={
          form[`livingStatus`] === "same_place" ||
          form[`livingStatus`] === "other_place"
        }
      >
        <Grid>
          <RadioButton
            error={thisError && thisError.healthcondition$}
            handleChange={handleChange}
            isVisible
            question={healthconditionQuesiton}
            name={`healthcondition`}
            radioLists={healthcondition}
            value={form[`healthcondition`]}
            // other ={form[`casteListOther`]}
          />
        </Grid>
        <Grid xs={12} md={8}>
          <MultipleSelect
            error={thisError && thisError.unhealthy}
            question={unhealthyQuestion}
            name={`unhealthy`}
            value={form[`unhealthy`]}
            tagLists={unhealthy}
            isVisible={form[`healthcondition`] === "unhelathy"}
            handleChange={handleChange}
            other={form[`unhealthyOther`]}
          />
        </Grid>
        <Grid>
          <RadioButton
            error={thisError && thisError.mentalyesno}
            handleChange={handleChange}
            isVisible
            question={mentalYesNoQuestion}
            name={`mentalyesno`}
            radioLists={mentalyesno}
            value={form[`mentalyesno`]}
            // other ={form[`casteListOther`]}
          />
        </Grid>
        <BoxBorder isVisible={form[`mentalyesno`] === "disability"}>
          <RadioButton
            error={thisError && thisError.mentaltype}
            handleChange={handleChange}
            isVisible
            question={mentaltypeQuestion}
            name={`mentaltype`}
            radioLists={mentaltype}
            value={form[`mentaltype`]}
          />
          <RadioButton
            error={thisError && thisError.mentalcondition}
            handleChange={handleChange}
            isVisible
            question={mentalconditionQuestion}
            name={`mentalcondition`}
            radioLists={mentalcondition}
            value={form[`mentalcondition`]}
            other={form[`mentalconditionOther`]}
          />
          <Grid container>
            <RadioButton
              error={thisError && thisError.mentalCardYesNo}
              handleChange={handleChange}
              isVisible
              question={mentalCardQuestion}
              name={`mentalCardYesNo`}
              radioLists={mentalCardYesNo}
              value={form[`mentalCardYesNo`]}
            />
          </Grid>
          <Grid container>
            <RadioButton
              error={thisError && thisError.mentalCardtype}
              handleChange={handleChange}
              isVisible={form[`mentalCardYesNo`] === "card_taken"}
              question={mentalCardTypeQuestion}
              name={`mentalCardtype`}
              radioLists={mentalCardtype}
              value={form[`mentalCardtype`]}
            />
          </Grid>
        </BoxBorder>
        <Grid container>
          <RadioButton
            error={thisError && thisError.insuranceYesNo}
            handleChange={handleChange}
            isVisible
            question={insuranceQuestion}
            name={`insuranceYesNo`}
            radioLists={insuranceYesNo}
            value={form[`insuranceYesNo`]}
          />
        </Grid>
        <BoxBorder isVisible={form[`insuranceYesNo`] === "insurance"}>
          <RadioButton
            error={thisError && thisError.insuranceLists}
            handleChange={handleChange}
            isVisible
            question={insuranceListQuestion}
            name={`insuranceLists`}
            radioLists={insuranceLists}
            value={form[`insuranceLists`]}
          />
        </BoxBorder>
      </BoxBorder>
      <Grid container>
        <Selection
          error={thisError && thisError.mothertongue}
          question={mothertongueQuestion}
          name={`mothertongue`}
          selectList={mothertongue}
          value={form[`mothertongue`]}
          isVisible
          handleChange={handleChange}
          other={form[`mothertongueOther`]}
        />
      </Grid>

      <Grid container>
        <RadioButton
          error={thisError && thisError.raiType}
          handleChange={handleChange}
          isVisible={form[`mothertongue`] === "rai"}
          question={raiQuestion}
          name={`raiType`}
          radioLists={raiType}
          value={form[`raiType`]}
        />
      </Grid>
      <Grid container>
        <RadioButton
          error={thisError && thisError.bloodgroup}
          handleChange={handleChange}
          isVisible
          question={bloodgroupQuestion}
          name={`bloodgroup`}
          radioLists={bloodgroup}
          value={form[`bloodgroup`]}
          other={form[`bloodgroupOther`]}
        />
      </Grid>
      <Grid container>
        <RadioButton
          error={thisError && thisError.votercard}
          handleChange={handleChange}
          isVisible
          question={votercardQuestion}
          name={`votercard`}
          radioLists={votercard}
          value={form[`votercard`]}
        />
      </Grid>
      <Grid container>
        <RadioButton
          handleChange={handleChange}
          isVisible
          question={skillTrainingQuestion}
          radioLists={skillTraining}
          name={`skillTraining`}
          value={form[`skillTraining`]}
        />
      </Grid>

      <Grid container>
        <CheckBox
          question={skillDetailQuestion}
          checkList={skillDetail}
          value={form[`skillDetail`]}
          name={`skillDetail`}
          isVisible={
            form[`skillTraining`] && form[`skillTraining`].includes("skill_yes")
          }
          handleCompChange={handleChange}
          other={form[`skillTrainingOther`]}
        />
      </Grid>
    </Grid>
  );
};

export default function vitalBirth({ history }) {
  const [form, setForm] = useState({
    dateofAdopting: moment(),
  });
  const [houseOpen, setHouseOpen] = useState(false);
  const [houseOfWard, setHouseOfWard] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const handleInputChange = (name) => (value) => {
    setForm((prevValue) => ({ ...prevValue, [name]: value }));
    if (name == "ward") {
      if (form.ward !== value)
        setForm((prevValue) => ({ ...prevValue, houseId: null }));

      fetchHouseId(value);
    }
  };
  const fetchHouseId = (ward) => {
    console.log("fetching user", ward);
    setHouseOfWard([]);
    setFetching(true);
    Axios.get(`/api/vital/birth/?ward=${ward}`)
      .then((response) => {
        const data = response.data.data.map((house) => ({
          text: house.houseNumber + "",
          value: house._id,
        }));
        setHouseOfWard(data);
      })
      .finally((each) => {
        setFetching(false);
      });
  };

  const onSubmit = () => {
    if (false) return message.warning("कृपया सबै ठाउ भर्नुहोस्");
    if (!form.houseId) return;
    // else Axios ni done vao

    setSubmitting(true);
    Axios.post(`/api/vital/family/?house_id=${form.houseId}`, form)
      .then((res) => {
        message.success("सदस्य पूर्ण रुपमा थपिएको छ");
        history.push("/vital-registration");
      })
      .catch((err) => {
        message.error("Error On Saving Data");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  console.log("form", form);
  return (
    <Row
      style={{
        marginBottom: 50,
      }}
    >
      <Row>
        {houseOpen && (
          <FullScreenDialog
            name="houseOpen"
            open={houseOpen}
            title={`House Detail `}
            handleClose={() => setHouseOpen(false)}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              {(() => {
                let house = houseOfWard.find(
                  (each) => each.value == form.houseId
                );
                return (
                  <HouseDetail
                    houseId={form && form.houseId && form.houseId}
                    houseNumber={house.text}
                  />
                );
              })()}
            </Grid>
          </FullScreenDialog>
        )}
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <Icon type="home" />
          </Breadcrumb.Item>
          <Link to="/vital-registration">
            <Breadcrumb.Item>
              <Icon
                component={() => (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z" />
                  </svg>
                )}
              />
              <span>घटना दर्ता</span>
            </Breadcrumb.Item>
          </Link>
          <Breadcrumb.Item>
            <UserAddOutlined />
            <span>जन्म दर्ता</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row
        style={{
          marginTop: 50,
        }}
      >
        <Title
          level={3}
          style={{
            fontSize: 28,
            color: "#3d7ec5",
          }}
        >
          परिवारमा सदस्य थप्नुहोस
        </Title>
      </Row>
      <Row>
        <Col xs={24} md={12}>
          <Form.Item
            label="वडा चयन गर्नुहोस"
            style={{
              display: "flex",
            }}
          >
            <Select
              showSearch={true}
              size="large"
              style={{
                minWidth: 300,
              }}
              placeholder="वडा रोज्नुहोस"
              onChange={handleInputChange("ward")}
            >
              {ward.map((each) => (
                <Option value={each.value}>{each.ward}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            label="कुन घरमा सदस्य थप्नुपर्ने"
            style={{
              display: "flex",
            }}
          >
            <Select
              disabled={!form.ward}
              size="large"
              showSearch
              // labelInValue
              value={form.houseId}
              placeholder="घर नम्बर खोज्नुहोस"
              notFoundContent={
                fetching ? (
                  <Spin size="small" />
                ) : houseOfWard.length == 0 ? (
                  <span>Not Found</span>
                ) : null
              }
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={handleInputChange("houseId")}
              style={{ width: "100%", minWidth: 250 }}
            >
              {houseOfWard.map((d) => (
                <Option key={d.value}>{d.text}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {(() => {
          let house = houseOfWard.find((each) => each.value == form.houseId);
          if (house)
            return (
              <Col
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Button
                  type="link"
                  onClick={() => setHouseOpen(true)}
                  style={{
                    padding: 0,
                  }}
                >
                  <span
                    style={{
                      fontSize: 20,
                    }}
                  >
                    {house.text} घर नम्बर को विवरण हेर्नुहोस्
                  </span>
                </Button>
              </Col>
            );
          else return null;
        })()}

        <Col xs={24} md={12}>
          <Form.Item
            label="सदस्य थप्नु को कारण"
            style={{
              display: "flex",
            }}
          >
            <Select
              showSearch={true}
              size="large"
              style={{
                minWidth: 300,
              }}
              placeholder="कारण रोज्नुहोस"
              onChange={handleInputChange("reasonOfAdoption")}
            >
              <Option value="left_on_survey">
                सर्भेमा छुटेको/left on survey
              </Option>
              <Option value="birth">बच्चाको जन्म भएको/birth</Option>
              <Option value="adoption">बच्चालाई गोद लिएको/Adoption</Option>
            </Select>
          </Form.Item>
        </Col>
        {form.reasonOfAddition}
        <Is visible={form.reasonOfAddition == "adoption"}>
          <Col xs={24} md={12}>
            <Form.Item
              label="गोद लिएको मिति"
              style={{
                display: "flex",
              }}
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  InputProps={{}}
                  endAdornment={false}
                  disableToolbar
                  variant="inline"
                  format="MM/DD/YYYY"
                  value={form.dateofAdopting}
                  onChange={handleInputChange("dateofAdopting")}
                />
              </MuiPickersUtilsProvider>
            </Form.Item>
          </Col>
        </Is>
      </Row>
      <Row>
        <FamilyForm
          handleChange={(name, value) => handleInputChange(name)(value)}
          form={form}
          thisError={{}}
        />
      </Row>
      <Row
        type="flex"
        style={{
          marginTop: 20,
        }}
      >
        <Col>
          <Link to="/vital-registration">
            <Button
              style={{
                fontSize: 15,
              }}
            >
              Cancel
            </Button>
          </Link>
        </Col>
        <Col>
          <Button
            onClick={onSubmit}
            type="primary"
            style={{
              fontSize: 15,
              marginLeft: 15,
            }}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </Row>
  );
}
