import {
	Chip,
	ExpansionPanel,
	withStyles,
	Icon,
	IconButton,
} from "@material-ui/core";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandIcon from "@material-ui/icons/ExpandMore";

import Grid from "@material-ui/core/Grid";
import React, { Component } from "react";
import {
	anyDeath,
	deathAge,
	deathGender,
	deathInYear,
	deathInYeargenderQuestion,
	deathInYearReasonQuestion,
	deathName,
	deathQuestion,
	deathReason,
	deathCertificateQn,
	deathCertificate,
} from "../../../../variable/houseSurvey";
import PropTypes from "prop-types";

import BoxBorder from "../../../common/frequent/BoxBorder";
import MultipleSelect from "../../../common/frequent/MultipleSelect";
import RadioButton from "../../../common/frequent/RadioButton";
import TextField from "../../../common/frequent/TextField";
import BoxBorderArray from "../../../common/frequent/BoxBorderArray";
import ArrayWholeComponent from "../../../common/ArrayWholeComponent";
import DeleteIcon from "@material-ui/icons/Delete";
class Death extends Component {
	state = {
		index: -1,
		activeIndex: 0,
		expanded: "death0",
	};

	handleExpansion = (panel) => (event, expanded) => {
		this.setState({
			expanded: expanded ? panel : false,
		});
	};

	handleChange = (name, value) => {
		this.props.handleChange(`death.${name}`, value);
	};

	resetThisForm = (index) => (event) => {
		const { death } = this.props;
		const total = parseInt(death.deathCount);
		if (total > 0) this.props.handleResetDeath(index);
	};

	getDeathMemberForm = () => {
		const { death, classes } = this.props;
		const total = parseInt(death.deathCount);
		const error = false;
		if (!isNaN(total) && total > 0) {
			return (
				<Grid container direction="column">
					{[...Array(total).keys()].map((each) => (
						<ExpansionPanel
							key={each}
							style={{ padding: 0, backgroundColor: "transparent" }}
							expanded={this.state.expanded === `death${each}`}
							onChange={this.handleExpansion(`death${each}`)}
						>
							<ExpansionPanelSummary
								className={classes.summary}
								expandIcon={<ExpandIcon className={classes.white} />}
							>
								<Grid
									container
									className={error ? classes.headerError : classes.header}
								/>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails className={classes.details}>
								<Grid
									container
									direction="column"
									className={classes.eachDeath}
								>
									<Grid container>
										<TextField
											question={deathName}
											name={`deathName${each}`}
											require
											placeholder="नाम"
											value={death[`deathName${each}`]}
											isVisible
											handleChange={this.handleChange}
										/>
									</Grid>
									<Grid container>
										<RadioButton
											handleChange={this.handleChange}
											isVisible
											question={deathInYeargenderQuestion}
											name={`deathGender${each}`}
											radioLists={deathGender}
											value={death[`deathGender${each}`]}
										/>
									</Grid>

									<Grid container>
										<TextField
											type="number"
											question={deathAge}
											name={`deathAge${each}`}
											require
											placeholder="उमेर  "
											value={death[`deathAge${each}`]}
											isVisible
											handleChange={this.handleChange}
										/>
									</Grid>

									<Grid container>
										<MultipleSelect
											question={deathInYearReasonQuestion}
											name={`deathReason${each}`}
											value={death[`deathReason${each}`]}
											tagLists={deathReason}
											isVisible
											handleChange={this.handleChange}
											other={death["deathReason" + each + "Other"]}
										/>
									</Grid>

									<Grid container>
										<RadioButton
											handleChange={this.handleChange}
											isVisible
											question={deathCertificateQn}
											name={`isDeathCertificate${each}`}
											radioLists={deathCertificate}
											value={death[`isDeathCertificate${each}`]}
										/>
									</Grid>

									<Grid
										container
										direction="row-reverse"
										justify="flex-start"
										alignItems="flex-start"
									>
										<Grid item>
											<IconButton
												onClick={this.resetThisForm(each)}
												aria-label="Delete"
												className={classes.margin}
											>
												<DeleteIcon fontSize="large" style={{ color: "red" }} />
											</IconButton>
										</Grid>
									</Grid>
								</Grid>
							</ExpansionPanelDetails>
						</ExpansionPanel>
					))}
				</Grid>
			);
		}
	};
	render() {
		const { death } = this.props;
		return (
			<Grid container direction="column">
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={deathQuestion}
						name="anyDeath"
						radioLists={anyDeath}
						value={death.anyDeath}
					/>
				</Grid>

				<Grid container>
					<TextField
						question={deathInYear}
						name="deathCount"
						require
						type="number"
						placeholder="कति जना  "
						value={death.deathCount}
						isVisible={death.anyDeath === "death"}
						handleChange={this.handleChange}
					/>
				</Grid>

				<Grid container item xs={6}>
					{death.anyDeath === "death" && this.getDeathMemberForm()}
				</Grid>
			</Grid>
		);
	}
}

const styles = (theme) => ({
	header: {
		width: "100%",
		height: 20,
		backgroundColor: "#00A97c",
		borderTopRightRadius: 5,
		borderTopLeftRadius: 5,
		padding: 20,
	},
	headerError: {
		height: 20,
		padding: 20,
		width: "100%",
		borderTopRightRadius: 5,
		borderTopLeftRadius: 5,
		backgroundColor: "#FB3E44",
	},
	container: {
		backgroundColor: "transparent",
		// padding: 0,
		// margin: 0,
		border: "none",
	},
	summary: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	category: {
		// minHeight: 30,
		color: "#FFF",
	},
	details: {
		padding: 0,
		margin: 0,
		flexWrap: "nowrap",
		// border: "none",
		// marginTop: theme.spacing.unit * 1
	},
	noteContainer: {
		// margin: theme.spacing.unit
		flexWrap: "nowrap",
	},
	white: {
		color: "#FFF",
	},
	eachLink: {
		marginTop: 1,
	},
	eachDeath: {
		width: "100%",
		border: "1px solid  rgba(0, 0, 0, 0.19)",
		borderTop: "none !important",
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 1}px ${
			theme.spacing.unit * 2
		}px ${theme.spacing.unit * 2}px`,
		borderBottomRightRadius: 5,
		borderBottomLeftRadius: 5,
		marginBottom: 20,
	},
});

Death.propTypes = {
	classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Death);
