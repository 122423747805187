import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import { selectedLanguage } from "../../../variable/global";
import isEmpty from "../../../utils/validation/is-empty";
import { stepperStep } from "../../../variable/houseSurvey";
import AgriLivestock from "./pages/AgriLivestock";
import Business from "./pages/Business";
import Death from "./pages/Death";
import Economics from "./pages/Economics";
import FamilyDetail from "./pages/FamilyDetail";
import HouseDetail from "./pages/HouseDetail";
import Insurance from "./pages/Insurance";
import PrimaryHouse from "./pages/PrimaryHouse.jsx";
import SecondaryHouse from "./pages/SecondaryHouse";
import SocialWelfare from "./pages/SocialWelfare";
import WomenChild from "./pages/WomenChild";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	editHouseSurvey,
	addHouseSurvey,
} from "../../../actions/houseSurveyAction";
import Axios from "axios";
import { message } from "antd";
import ChildMarriage from "./pages/ChildMarriage";

class index extends Component {
	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			(prevState) => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName:
							prevState.primaryHouse.gharNumber + "_" + imageName + ".jpg",
					},
				},
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;
		let fileName =
			this.state.primaryHouse.gharNumber + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: (ProgressEvent) => {
				this.setState({
					loaded: Math.round(
						(ProgressEvent.loaded / ProgressEvent.total) * 100
					),
				});
			},
		})
			.then((res) => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = (imageName) => {
		let fileName =
			this.state.primaryHouse.gharNumber + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then((res) => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};
	addMarker = (e) => {
		this.setState({
			gpsFetched: false,
			lat: e.latlng.lat,
			lng: e.latlng.lng,
		});
	};
	// for leaflet maps
	resetMap = (latlng) => {
		const { set, lat, lng } = latlng;
		this.setState({ lat, lng, gpsFetched: set });
	};

	handleMap = (name, value) => {
		this.setState({ [name]: value });
	};

	// name be name,gender,age,reason
	componentDidUpdate(prevProps) {
		if (prevProps.data !== this.props.data) {
			const houseNo = this.props.data.primaryHouse.gharNumber;
			console.log("primaryHouse", this.props.data.primaryHouse);
			this.setState({
				_houseId: this.props.data.houseId,
				primaryHouse: this.props.data.primaryHouse,
				familyDetail: this.props.data.familyDetail,
				// death state
				fileList: {
					house: {
						fileName: `${houseNo}_house.jpg`,
						image: [
							{
								uid: "-1",
								name: `${houseNo}_house.jpg`,
								status: "done",
								url: `/api/imageUpload/image/${houseNo}_house.jpg`,
							},
						],
					},
					citizen_front: {
						fileName: `${houseNo}_citizen_front.jpg`,
						image: [
							{
								uid: "-1",
								name: `${houseNo}_citizen_front.jpg`,
								status: "done",
								url: `/api/imageUpload/image/${houseNo}_citizen_front.jpg`,
							},
						],
					},
					citizen_back: {
						fileName: `${houseNo}_citizen_back.jpg`,
						image: [
							{
								uid: "-1",
								name: `${houseNo}_citizen_back.jpg`,
								status: "done",
								url: `/api/imageUpload/image/${houseNo}_citizen_back.jpg`,
							},
						],
					},
				},
				death: this.props.data.death,
				childMarriage: this.props.data.childMarriage,
				// insurance: this.props.data.insurance,
				secondaryHouse: this.props.data.secondaryHouse,
				houseDetail: this.props.data.houseDetail,
				agriLivestock: this.props.data.agriLivestock,
				economics: this.props.data.economics,
				business: this.props.data.business,
				womenChild: this.props.data.womenChild,
				socialWelfare: this.props.data.socialWelfare,
				lat: this.props.data.lat,
				lng: this.props.data.long,
			});
		}
	}

	state = {
		//image
		isHouseNumberValid: true,
		houseHelperText: {
			english: "",
			nepali: "",
		},
		// maps
		fileList: {},
		// maps
		gpsFetched: false,
		lat: 27.704206,
		lng: 85.331871,
		zoom: 15,
		// page Level State
		primaryHouse: {},
		familyDetail: {
			dateOfBirth: "२०१९-०३-०३",
			age: 46,
		},
		// death state
		death: {
			anyDeath: null,
			deathCount: 1,
		},
		childMarriage: {
			isChildMarriage: null,
			childCount: 1,
		},
		// insurance: {},
		secondaryHouse: {},
		houseDetail: {},
		agriLivestock: {},
		economics: {},
		business: {},
		womenChild: {},
		socialWelfare: {},

		activeStep: 0,
		completed: [],
		text: "",

		// womenchild
		birthCertificate: "",
		childVaccine: "",
		deliveryFromDoctor: "",
		isChildDeathFromDisease: "",
		childDeathCount: "",
		pregnanctCheck: "",
		contraceptiveUse: "",
		childLabour: "",
		childLabor: "",
		illTreament: "",
		govHospitalName: "",
		govHospitalNameOther: "",
		earlyMarriage: "",
		pregnantTetanus: "",
	};

	// handleChangeDeath = (name, value, i) => {
	// 	this.setState(prevState => {
	// 		const death = [...prevState.death];
	// 		const detail = [...death.detail];
	// 		const data = [...detail[i]];
	// 		data[name] = value;
	// 		death.detail.splice(i, 1, data);
	// 		return { death };
	// 	});
	// };

	getSteps = () => {
		return stepperStep.map((each) => each[selectedLanguage]);
	};

	getStepContent = (step) => {
		const {
			position,
			zoom,
			primaryHouse,
			familyDetail,
			death,
			deathDetail,
			childMarriage,
			childMarriageDetail,
			// insurance,
			secondaryHouse,
			houseDetail,
			agriLivestock,
			economics,
			business,
			womenChild,
			socialWelfare,
			lat,
			lng,
			gpsFetched,
			fileList,
			// women child end
		} = this.state;
		switch (step) {
			case 0:
				if (!isEmpty(primaryHouse))
					return (
						<PrimaryHouse
							isHouseNumberValid={this.state.isHouseNumberValid}
							houseHelperText={this.state.houseHelperText}
							setHouseNumberValid={this.setHouseNumberValid}
							handleUpload={this.handleUpload}
							handleChangeFile={this.handleChangeFile}
							fileList={fileList}
							isEdit={true}
							handleChange={this.handleChange}
							primaryHouse={primaryHouse}
							addMarker={this.addMarker}
							handleMap={this.handleMap}
							lat={lat}
							lng={lng}
							zoom={zoom}
							gpsFetched={gpsFetched}
							resetMap={this.resetMap}
						/>
					);
				else return <p>loading</p>;

			case 1:
				return (
					<FamilyDetail
						handleChange={this.handleChange}
						familyDetail={familyDetail}
						isHouseNumberValid={this.state.isHouseNumberValid}
						houseHelperText={this.state.houseHelperText}
						setHouseNumberValid={this.setHouseNumberValid}
						handleUpload={this.handleUpload}
						handleChangeFile={this.handleChangeFile}
						fileList={fileList}
					/>
				);
			case 2:
				return (
					<Death
						handleChange={this.handleChange}
						death={death}
						deathDetail={deathDetail}
						handleChangeDeath={this.handleChangeDeath}
					/>
				);
			// case 3:
			// 	return (
			// 		<Insurance handleChange={this.handleChange} insurance={insurance} />
			// 	);
			case 3:
				return (
					<SecondaryHouse
						handleChange={this.handleChange}
						secondaryHouse={secondaryHouse}
					/>
				);
			case 4:
				return (
					<HouseDetail
						handleChange={this.handleChange}
						houseDetail={houseDetail}
					/>
				);
			case 5:
				return (
					<AgriLivestock
						handleChange={this.handleChange}
						agriLivestock={agriLivestock}
					/>
				);
			case 6:
				return (
					<Economics handleChange={this.handleChange} economics={economics} />
				);
			case 7:
				return (
					<ChildMarriage
						handleChange={this.handleChange}
						childMarriage={childMarriage}
						childMarriageDetail={childMarriageDetail}
					/>
				);
			case 8:
				return (
					<WomenChild
						handleChange={this.handleChange}
						womenChild={womenChild}
					/>
				);
			case 9:
				return (
					<SocialWelfare
						handleChange={this.handleChange}
						socialWelfare={socialWelfare}
					/>
				);
			default:
				return "Unknown step";
		}
	};

	totalSteps = () => this.getSteps().length;

	handleNext = () => {
		let activeStep;

		if (this.isLastStep() && !this.allStepsCompleted()) {
			// It's the last step, but not all steps have been completed
			// find the first step that has been completed
			const steps = this.getSteps();
			activeStep = steps.findIndex(
				(step, i) => !this.state.completed.indexOf(i) >= 0
			);
		} else {
			activeStep = this.state.activeStep + 1;
		}
		this.setState({
			activeStep,
		});
	};

	handleBack = () => {
		this.setState((state) => ({
			activeStep: state.activeStep - 1,
		}));
	};

	handleStep = (step) => () => {
		this.setState({
			activeStep: step,
		});
	};

	handleComplete = () => {
		// eslint-disable-next-line react/no-access-state-in-setstate
		const { completed } = this.state;
		completed.push(this.state.activeStep);
		this.setState({
			completed,
		});

		/**
		 * Sigh... it would be much nicer to replace the following if conditional with
		 * `if (!this.allStepsComplete())` however state is not set when we do this,
		 * thus we have to resort to not being very DRY.
		 */
		if (completed.length !== this.totalSteps()) {
			this.handleNext();
		}
	};

	handleReset = () => {
		this.setState({
			activeStep: 0,
			completed: [],
			skipped: [],
		});
	};

	isStepComplete(step) {
		return this.state.completed.indexOf(step) !== -1;
	}

	completedSteps() {
		return this.state.completed.length;
	}

	allStepsCompleted() {
		return this.completedSteps() === this.totalSteps();
	}

	isLastStep() {
		return this.state.activeStep === this.totalSteps() - 1;
	}

	handleChange = (name, value) => {
		const data = name.split(".");
		const wc = this.state[data[0]];
		const assign = { [data[1]]: value };
		this.setState((state) => ({
			...state,
			[data[0]]: {
				...wc,
				...assign,
			},
		}));
	};

	componentDidMount() {
		let houseNumber = this.props.match.params.houseId;
		this.props.editHouseSurvey(houseNumber);
	}

	onSubmit = () => {
		let data = _.pick(
			this.state,
			"lat",
			"lng",
			"primaryHouse",
			"familyDetail",
			"death",
			"childMarriage",
			"insurance",
			"secondaryHouse",
			"houseDetail",
			"agriLivestock",
			"economics",
			"business",
			"womenChild",
			"socialWelfare"
		);
		data._houseId = this.state._houseId;
		console.log("data", data);
		this.props.addHouseSurvey(data, true);
	};

	render() {
		const { classes } = this.props;
		const steps = this.getSteps();
		const { activeStep } = this.state;
		console.log(this.state.death);
		return (
			<div>
				<Stepper alternativeLabel nonLinear activeStep={activeStep}>
					{steps.map((label, index) => {
						const props = {};
						const buttonProps = {};
						return (
							<Step key={label} {...props}>
								<StepButton
									onClick={this.handleStep(index)}
									completed={this.isStepComplete(index)}
									{...buttonProps}
								>
									{label}
								</StepButton>
							</Step>
						);
					})}
				</Stepper>
				<div>
					{this.allStepsCompleted() ? (
						<div>
							<Typography className={classes.instructions}>
								All steps completed - you&apos;re finished
							</Typography>
							<Button onClick={this.handleReset}>Reset</Button>
						</div>
					) : (
						<div>
							<Card className={classes.card}>
								<CardHeader
									title={steps[activeStep]}
									// subheader="* चिन्ह भएका अनिबार्य भर्नुहोला "
								/>
								<CardContent>{this.getStepContent(activeStep)}</CardContent>
							</Card>
							{/* <Typography className={classes.instructions}>

							</Typography> */}
							<div>
								<Button
									disabled={activeStep === 0}
									onClick={this.handleBack}
									className={classes.button}
								>
									Back
								</Button>
								<Button
									variant="contained"
									color="primary"
									onClick={this.handleNext}
									className={classes.button}
								>
									Next
								</Button>
								<Button
									variant="contained"
									color="primary"
									onClick={this.onSubmit}
								>
									submit
								</Button>

								{activeStep !== steps.length &&
									(this.state.completed.indexOf(this.state.activeStep) >= 0 ? (
										<Typography variant="caption" className={classes.completed}>
											Step {activeStep + 1} already completed
										</Typography>
									) : (
										<Button
											variant="contained"
											color="primary"
											onClick={this.handleComplete}
										>
											{this.completedSteps() === this.totalSteps() - 1
												? "Finish"
												: "Complete Step"}
										</Button>
									))}
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const styles = (theme) => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0",
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF",
		},
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1",
		},
	},
	root: {
		width: "98%",
		marginTop: theme.spacing.unit * 3,
	},
	table: {
		minWidth: 500,
	},
	tableWrapper: {
		overflowX: "auto",
	},
	fab: {
		float: "right",
	},
	button: {
		marginRight: theme.spacing.unit,
	},
	backButton: {
		marginRight: theme.spacing.unit,
	},
	completed: {
		display: "inline-block",
	},
	instructions: {
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
	},
	card: {
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit * 3,
		paddingTop: theme.spacing.unit * 2,
		paddingLeft: theme.spacing.unit * 2,
		paddingRight: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 0,
	},
});

index.propTypes = {
	classes: PropTypes.object.isRequired,
	addHouseSurvey: PropTypes.func.isRequired,
};

const mapStateToProps = ({ houseSurvey: { data } }) => ({
	data,
});

export default withStyles(styles)(
	connect(mapStateToProps, { editHouseSurvey, addHouseSurvey })(index)
);
