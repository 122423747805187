import React, { Component } from "react";
import { Grid, Modal } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import {
	Button,
	Divider,
	Form,
	Icon,
	Input,
	message,
	Select,
	Typography,
	Radio,
} from "antd";
import { add } from "../../../variable/institute";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addMarriedDaughterSurvey } from "../../../actions/daughterMarriageAction";
import Selection from "../../common/frequent/Selection";
import {
	name,
	age,
	gender,
	marriageAge,
	martialStatus,
	martialStatusList,
	martialType,
	martialTypeList,
	education,
	educationList,
	occupation,
	occupationList,
	isChild,
} from "../../../variable/marriedDaughterSurvey";
import {
	no,
	selectedLanguage,
	genderList,
	yes,
} from "../../../variable/global";
import { genderMale, genderFemale } from "../../../variable/houseSurvey";
import Axios from "axios";

const { TextArea } = Input;
const { Option } = Select;
const RadioGroup = Radio.Group;

class AddMarriedDaughter extends Component {
	state = {
		name: "",
		gender: "",
		age: 0,
		martialStatus: "",
		marriageAge: 0,
		marriageType: "",
		education: "",
		occupation: "",
		isFemaleGenderChild: "no",
		houseId: "",
		daughterId: "",
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	handleChangePage = () => {
		this.props.onChangePage()
	}

	handleInputChange = (name) => (event) => {
		console.log("name", event.target.value);
		this.setState({
			[name]: event.target.value,
		});
	};

	handleSelectChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	componentDidMount() {
		let daughter = this.props.daughter;
		this.setState({
			name: daughter.fullName,
			gender: daughter.gender,
			age: daughter.age,
			martialStatus: daughter.martialStatus,
			marriageAge: daughter.marriageAge,
			marriageType: daughter.marriageType,
			education: daughter.education,
			occupation: daughter.occupation,
			isFemaleGenderChild: daughter.isFemaleGenderChild === true ? "yes" : "no",
			houseId: daughter.house,
			daughterId: daughter.daughterId,
		});
		console.log("idd", this.state.houseId);
	}

	componentDidUpdate(prevProps) {
		let daughter = this.props.daughter;

		if (prevProps.daughter.house !== this.props.daughter.house) {
			this.setState({
				name: daughter.fullName,
				gender: daughter.gender,
				age: daughter.age,
				martialStatus: daughter.martialStatus,
				marriageAge: daughter.marriageAge,
				marriageType: daughter.marriageType,
				education: daughter.education,
				occupation: daughter.occupation,
				isFemaleGenderChild:
					daughter.isFemaleGenderChild === true ? "yes" : "no",
				houseId: daughter.house,
				daughterId: daughter.daughterId,
			});
		}
	}

	onSubmit = (event) => {
		event.preventDefault();
		const newData = {
			name: this.state.name,
			age: this.state.age,
			marriageAge: this.state.marriageAge,
			martialStatus: this.state.martialStatus,
			gender: this.state.gender,
			marriageType: this.state.marriageType,
			education: this.state.education,
			occupation: this.state.occupation,
			isFemaleGenderChild: this.state.isFemaleGenderChild,
			house: this.state.houseId,
			daughterId: this.state.daughterId,
		};

		this.props.addMarriedDaughterSurvey(newData, this.props.history);
		this.handleChangePage();
		
	};

	handleNewChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	render() {
		const { classes, daughter, title } = this.props;

		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">Daughter Marriage</Typography>
				<Form onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing={3}
					>
						<Grid item xs={6}>
							<Form.Item label={name[selectedLanguage]}>
								<Input
									name="name"
									onChange={this.handleInputChange("name")}
									value={this.state.name}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6} style={{ marginTop: "20px" }}>
							<Form.Item label={gender[selectedLanguage]}>
								<RadioGroup
									name="gender"
									onChange={this.handleChange}
									value={this.state.gender}
								>
									<Radio value="male">{genderList[1].value}</Radio>
									<Radio value="female">{genderList[0].value}</Radio>
								</RadioGroup>
							</Form.Item>
						</Grid>

						{this.state.gender == "female" && (
							<Grid item xs={6} style={{ marginTop: "20px" }}>
								<Form.Item label={isChild[selectedLanguage]}>
									<RadioGroup
										name="isFemaleGenderChild"
										onChange={this.handleChange}
										value={this.state.isFemaleGenderChild}
									>
										<Radio value="yes">{yes[selectedLanguage]}</Radio>
										<Radio value="no">{no[selectedLanguage]}</Radio>
									</RadioGroup>
								</Form.Item>
							</Grid>
						)}

						<Grid item xs={6}>
							<Form.Item label={age[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("age")}
									value={this.state.age}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={martialStatus[selectedLanguage]}>
								<Selection
									// question={martialStatus}
									name="martialStatus"
									selectList={martialStatusList}
									value={this.state.martialStatus}
									isVisible
									handleChange={this.handleSelectChange}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={marriageAge[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									onChange={this.handleInputChange("marriageAge")}
									value={this.state.marriageAge}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={martialType[selectedLanguage]}>
								<Selection
									// question={martialType}
									name="marriageType"
									selectList={martialTypeList}
									value={this.state.marriageType}
									isVisible
									handleChange={this.handleSelectChange}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={education[selectedLanguage]}>
								<Selection
									// question={education}
									name="education"
									selectList={educationList}
									value={this.state.education}
									isVisible
									handleChange={this.handleSelectChange}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={occupation[selectedLanguage]}>
								<Selection
									// question={occupation}
									name="occupation"
									selectList={occupationList}
									value={this.state.occupation}
									isVisible
									handleChange={this.handleSelectChange}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={12}>
							<Button type="primary" htmlType="submit"
							>
								{add[selectedLanguage]}
							</Button>
						</Grid>
					</Grid>
				</Form>
			</Paper>
		);
	}
}

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addMarriedDaughterSurvey })(
		withRouter(AddMarriedDaughter)
	)
);
